.everyday-faith__introduction {
    padding: $width-gap * 2 !important;
}

.everyday-faith__panel {
    margin-top: $width-gap;

    .panel__content {
        background: $color-background-light;
        padding: $width-gap * 1.5;
    }

    @media all and (min-width: 768px) {
        .panel__grid + & {
            margin-top: 0;
        }
    }
}

.everyday-faith__story--full-width {
    .video__wrapper {
        margin-top: 1rem;
    }

    display: grid;
    gap: $width-gap * 2;

    @include mobile {
        .video__wrapper {
            margin-top: 0;
        }

        grid-template-columns: 1fr 1fr;
    }

    @include desktop-large {
        gap: $width-gap * 4;
    }
}

.journeys__grid {
    @include mobile {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        gap: $width-gap;

        .everyday-faith__panel:nth-child(3) {
            margin-top: 0;
        }
    }

    @include desktop-large {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        .everyday-faith__panel:nth-child(3) {
            margin-top: 1rem;
        }
    }
}

.featured-journey {
    p {
        margin: 1.5rem 0;
    }

    @include mobile {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $width-gap * 2;
    }

    @include desktop-large {
        gap: $width-gap * 4;
    }
}

.featured-journey__image {
    max-height: 15rem;
    object-fit: contain;
    margin-top: $width-gap * 2;

    @include mobile {
        margin-top: 0;
    }
}

.featured-journey .btn,
.journey .btn {
    color: $color-text;
    text-transform: none;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    width: fit-content;
}

.django-ckeditor-widget{
  width: 100%;
}
