.easter-search-page__wrapper {
    padding-top: 1rem;

    @include desktop {
        padding-top: 0;
    }

    .easter-header-image {
        height: 8rem;
        width: auto;
        max-width: 100%;
        margin: 0 auto;
        display: block;

        @media (min-width: 360px)  {
            height: 10rem;
        }

        @include mobile {
            height: 14rem;
        }

        @include desktop {
            height: 22rem;
        }
    }

    label[for="id_event_type"] {
        @include visually-hidden;
    }

    .easter-form-wrapper {
        padding: 1.375rem 1.375rem 2rem 1.375rem ;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;
        position: relative;

        #acny_search {
            order: 0;
        }

        #div_id_acny_search_place {
            width: 100%;
            min-width: 14rem;
        }

        .submit {
            margin-top: 2rem;
            background-color: $color-easter-yellow;
            color: $color-white;
            padding: 0.95rem 2rem;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.5rem;
            line-height: 1.5rem;
            text-transform: none;
            font-weight: 500;
            font-family: $base-font;

            &:hover {
                background-color: $color-navy;
                border-color: $color-navy;
                color: $color-white;
            }

            .icon {
                margin-left: 0.5rem;
            }
        }

        @include mobile {
            padding: 1.375rem 4.375rem;
        }

        @include desktop {
            padding: 2.5rem 1rem;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;

            .submit {
                margin-top: 0;
                margin-left: 2rem;
                width: fit-content;
                padding: 0.73rem 2rem;
            }
        }

        @include desktop-large {
            padding: 2.5rem;
        }
    }

    .search__form {
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
            font-family: $base-headline-font;
            font-size: 1.14rem;
            flex-shrink: 0;
            margin: 0.5rem 0;

            @include desktop {
                font-size: 2rem;
                margin: 2rem 0;
            }
        }
        #div_id_event_type{
            margin-bottom: 1rem;
            @include desktop {
                margin-bottom: 0;
            }
        }

        .search__bar {
            width: unset;
        }

        .search__bar,
        .custom__select-toggle-text,
        .toggle-icon__holder,
        .custom__field-value,
        .custom__field-icon {
            border-color: $color-text;
            border-width: 1px;
            background-color: $color-white;

            .icon {
                color: $color-text;
            }
        }

        .custom__select-toggle {
            min-width: 13.25rem;
        }

        .custom__select-toggle-text {
            padding: 0.75rem 1rem;
            text-wrap: nowrap;
        }

        @include desktop {
            flex-shrink: 0;
            flex-direction: row;
            align-items: center;

            p {
                padding: 0 1rem;
            }
        }

        @include desktop-large {
            p {
                padding: 0 2rem;
            }
        }
    }

}

.easter-content-boxes {
    margin-bottom: 4.125rem;

    .content-box {
        display: block;
        background-color: $color-white;
        border: 1px solid #6C9A4B;
        border-radius: 10px;
        margin: 1.375rem 1.375rem 4rem 1.375rem;
        padding: 3rem 3rem;
        position: relative;
        text-decoration: none;
        transition: all 0.5s ease;

        h4 {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        svg {
            width: 1rem;
            height: 1rem;
            flex-shrink: 0;
        }

        &:hover {
            background-color: #fdfdf7;
        }
    }

    @include mobile {
        padding: 2rem 3rem;

        .content-box {
            padding: 3.75rem 4rem;
        }
    }

    @include desktop {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1.375rem;
        margin-left: 1.375rem;
        margin-right: 1.375rem;

        .content-box {
            margin: 0;
        }
    }

    .box-one:before, .box-two:before {
        content: '🌱';
        display: block;
        position: absolute;
        top: -1rem;
        left: 1.2rem;
        font-size: 75px;

        @include mobile {
            top: -1.75rem;
            left: 4rem;
        }
    }

    .box-two:before {
        content: '🌿';
    }
}

.easter-landing {
    .easter-search-page__wrapper {
        padding-top: 2rem;
    }

    .header-background {
        display: none;
    }

    .page__wrapper {
        position: relative;
    }

    .page__wrapper:before {
        content: '';
        display: block;
        background: url(../img/easter_boxes_bg_mobile.svg);
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 50rem;
        bottom: 0;
        left: 0;

        @include mobile {
            height: 70rem;
        }

        @include desktop {
            background: url(../img/easter_boxes_bg.svg);
            width: 100%;
            height: 22rem;
        }
    }

    .page-header .cofe__navigation ul li a {
        color: $color-navy;
    }
}

.easter-events {
    .header-background {
        display: none;
    }

    .page-header {
        .cofe__navigation ul a {
            color: $color-white;
        }

        .primary__navigation {
            margin-bottom: 0;

            .navbar__holder {
                border-color: #012F95;
            }
        }

        @include desktop {
            background-color: #588061;
        }
    }

    .search__bar,
    .custom__select-toggle-text,
    .toggle-icon__holder,
    .custom__field-value,
    .custom__field-icon,
    .textInput,
    .textInput:focus {
        border-color: #012F95;
        border-width: 1px;
        background-color: $color-white;

        .icon {
            color: $color-black;
        }
    }

    .search__bar {
        margin-bottom: 1rem;
    }

    .custom__select-toggle-text {
        padding: 0.75rem 1rem;
    }

    label[for="id_event_type"],
    label[for="id_time_0"] {
        @include visually-hidden;
    }

    #div_id_date {
        margin-top: 1rem;
    }

    #div_id_time {
        margin-top: 1rem;

        .controls {
            display: flex;
            align-items: center;
            gap: 0.75rem;
        }
    }

    #div_id_distance {
        input {
            appearance: auto;
        }

        + p {
            margin-top: -0.5rem;
            font-size: 0.75rem;
        }
    }

    #div_id_distance,
    #div_id_event_type,
    #div_id_date_range {
        margin-top: 2rem;

        label {
            font-size: 1.5rem;
            font-family: $base-headline-font;

            @include desktop {
                font-size: 2rem;
            }
        }

        .controls {
            margin-top: 0.5rem;
        }
    }

    #div_id_event_type {
        .controls {
            max-height: 8rem;
            overflow-y: scroll;

            @include desktop {
                max-height: 18rem;
            }
        }

        .controls__checkbox .checkbox {
            margin-left: 0;
        }

        input[type="checkbox"] {
            @include visually-hidden;
        }

        .custom__checkbox .icon {
            color: $color-navy;
        }
    }

    #div_id_date_range {
        .custom__field-icon:last-child {
            border-color: $color-yellow;
        }
    }

    #div_id_date,
    #div_id_groups_and_activities {
        label {
            display: none;
            margin-top: 3rem;
            font-size: 2rem;
            font-family: $base-headline-font;
            margin-bottom: 1rem;

            @include desktop {
                display: block;
            }
        }
    }

    #div_id_groups_and_activities {
        display: none;

        @include desktop {
            display: block;
        }

        .taglinkwidget {
            padding: 0.2rem 0;
            list-style: none;

            li {
                a {
                    text-decoration: none;
                }
                &.selected {
                    background-color: $color-navy;
                    color: $color-white;
                }

                &:hover {
                    background-color: $color-navy;
                    border-color: $color-navy;
                    color: $color-white;
                }
            }
        }
    }

    .side-content {
        .h3 {
            font-size: 1.5rem;

            @include desktop {
                font-size: 2.25rem;
                margin-bottom: 1rem;
            }
        }

        .submit {
            color: $color-navy;
            font-weight: 700;
            background-color: $color-yellow;
            border-color: $color-yellow;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 1.5rem 0;
            font-family: $base-font;

            &:hover {
                background-color: $color-navy;
                border-color: $color-navy;
                color: $color-white;
            }
        }

        .taglinkwidget {
            padding: 0.2rem 0;
            list-style: none;

            li {
                a {
                    text-decoration: none;
                }
                &.selected {
                    background-color: $color-navy;
                }
            }
        }
    }

    @media all and (min-width: 1930px) {
        .page-header {
            background-color: $color-background-blue;
        }
    }

}

.easter-events-page__header {
    color: $color-white;
    padding: 2rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: url(../img/easter_events_header.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

    @include desktop {
        padding: 2.375rem 2rem 0 2rem;
    }

    h1 {
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 1rem;
        position: relative;
        padding: 0;
        width: fit-content;
        text-align: center;

        @include desktop {
            font-size: 3.4375rem;
            line-height: 5.125rem;
            font-family: "fisterra-morte", sans-serif;
            font-weight: 700;
            font-style: normal;
        }
    }

    img {
        max-height: 3rem;
        width: auto;
        margin: 0 auto;

        @include mobile {
            max-height: 4rem;
        }

        @include desktop {
            max-height: unset;
            max-width: 90%;
        }

        @include desktop-large {
            max-width: 74.25rem;
        }
    }
}

.easter-results-page__wrapper {
    position: relative;

    @include desktop {
        background-color: #F3F4EE;
    }

    &::before {
        display: block;
        content: '';
        position: absolute;
        bottom: 0rem;
        height: calc(100% - 40rem);
        width: 100%;
        background-image: url(../img/easter_events_bg.svg);
        background-repeat: repeat-y;
        background-size: 100%;
        z-index: 0;

        @include desktop {
            height: calc(100% - 20rem);
        }
    }
}

.easter-results-page__container {
    padding-top: 2rem;
    min-height: 42rem; // Need enough space for calendar dropdown to show

    .results-content {
        margin: 0 -1rem;
        padding: 2rem 1rem;
        background-color: #F3F4EE;

        .h3 {
            font-size: 1.5rem;
        }
    }

    .custom__select-dropdown {
        position: relative;
        border-width: 1px;
    }

    @include desktop {
        display: flex;
        column-gap: 2rem;
        min-height: 44rem; // Need enough space for calendar dropdown to show
        padding-bottom: 3rem;

        .side-content {
            background: none;
        }

        .results-content {
            background-color: unset;
            margin: 0;
            padding: 0;

            .h3 {
                margin-bottom: 1rem;
                font-size: 2.25rem;
            }

            .card--event:first-of-type {
                margin-top: 0;
            }
        }
    }

    @include desktop-large {
        padding-top: 4rem;
    }
}


.easter-pagination {
    padding: 0;
    position: relative;

    @include mobile {
        justify-content: flex-end;
    }

    .pages {
        align-items: flex-end;
    }

    .btn {
        background: none;
        border: none;
        border-bottom: 2px solid transparent !important;
        color: $color-navy;
        padding: 0.875rem 1rem;
        margin: 0 0.25rem;
        text-transform: none;
        font-size: 1rem;
        width: unset !important;
        height: unset !important;

        &:hover {
            border-bottom: 2px solid $color-navy !important;
        }
    }

    .btn.next,
    .btn.prev {
        font-weight: bold;
        background-color: $color-white;
        color: $color-navy;
        border-bottom-color: $color-white !important;

        &:hover {
            border-bottom-color: $color-navy !important;
            background-color: $color-navy;
            color: $color-white;
        }
    }

    .pages #pages__current {
        background: none;
        color: $color-navy;
        border: none !important;
        border-bottom: 2px solid $color-navy !important;
    }
}

.easter-branding {
    .search-empty {
        @include desktop {
            padding-top: 3rem;
        }

        @include desktop-large {
            padding-top: 6rem;
        }
    }

    .location-intro__image {
        background-color: #588061;
        &::after {
            background: rgba(#588061, 0.625);
        }
    }

    .event__intro,
    .news__intro {
        @include desktop {
            margin-top: 2rem !important;
        }
    }

    .church-page__wrapper {
        overflow: visible;
        padding-top: 1rem;
        background: url(../img/easter_events_bg.svg) 90% -10rem/100% repeat-y, #EBECE2;

        .side-content {
            @include desktop {
                margin-top: -5rem;
                padding-top: 14rem;
            }
        }

        .church__logo-holder {
            border-top: none;
        }

        .billboard__panel--page-detail {
            background-color: #EBECE2;
        }

        .billboard__side.no__image {
            color: $color-text;
        }
    }

    .church-page__main {
        @media all and (min-width: 980px) {
            padding: 7rem 0 1rem 1rem;
        }
    }

    &.admin-view {
        &.my-churches {
            @include desktop {
                .cofe__navigation {
                    display: block;
                }
            }

            .navbar__holder {
                padding: 0 1.875rem;
            }
        }

        .church-page__wrapper .side-content {
            margin-top: 0;
            padding-top: 0;
        }

        .church-page__main {
            @media all and (min-width: 980px) {
                padding-top: 0;
            }
        }

        .benefice-page__wrapper {
            margin-top: 0;
        }
    }

    .benefice-page__wrapper,
    &.benefice-page .church-page__wrapper {
        background: url(../img/easter_events_bg.svg) 90% -10rem/100% repeat-y, #EBECE2;

        .billboard__panel--benefice {
            background: #588061;
        }

        .billboard__panel--page-detail {
            background-color: #EBECE2;
        }

        @include desktop {
            margin-top: -3rem;
            padding-top: 5rem;
        }
    }

    .resource-page__wrapper,
    .tag-group-page .main-content,
    &.admin-analytics .page__wrapper,
    &.my-churches .page__wrapper,
    &.accounts .account-page__wrapper {
        @include desktop {
            padding-top: 6rem;
        }

        @include desktop-large {
            padding-top: 10rem;
        }
    }

    &.login-view .account-page__wrapper {
        padding-top: 3rem;
        padding-bottom: 3rem;

        @include desktop {
            padding-top: 10rem;
            padding-bottom: 5rem;
        }

        .main-content form {
            padding-bottom: 1rem;
        }
    }
}
