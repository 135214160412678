.generate-video__wrapper {

    .billboard__video {
        height: auto;
        width: 50%;
        display: none;
        position: absolute;
        right: 0;
        @media all and (min-width: 981px){
            display: inline-block;
        }
    }

    .billboard__summary {
        @media all and (min-width: 981px){
            min-height: 7.5rem;
        }
    }

    .embed-section {

        @media all and (min-width: 981px){
            height: 16rem;
        }

        p {
            width: 100%;

            @media all and (min-width: 981px){
                width: 40%;
            }
        }
    }

    .content__panel {
        margin-top: 0px;

        &.media-asset-generator {
            #div_id_social_platforms .controls {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .help-block {
                    display: block;
                    width: 100%;
                }
                .controls__checkbox {
                    margin-right: 2rem;
                }
                input {
                    margin: 0;
                    padding: 0;
                    height: 0;
                }
                label {
                    margin: 0;
                    padding: 0;
                }
            }
            .form__holder {
                &::before {
                    height: 2px;
                    width: 100%;
                }
                &:first-of-type {
                    margin-top: 0;
                    padding-top: 0;
                    &::before {
                        display: none;
                    }
                }
            }
            @media all and (min-width: 981px){
                min-height: 32em;
            }

            form > * {
                margin-bottom: 10px;
            }

            label {
                line-height: 2rem;
            }

            form {
                width: 100%;
                @media all and (min-width: 981px){
                    width: 100%;
                    display: inline-block;
                }
            }

            [name=subtitle] {
                height: 6rem;
            }

            .headline {
                margin-top: 0;
            }

            .video-preview-container {
                div {
                    font-size: 1.3125rem;
                    text-align: center;
                    // margin-bottom: 14px;
                }

                height: auto;
                width: 100%;
                margin-bottom: 30px;

                // @media all and (min-width: 981px){
                //     width: 50%;
                //     float: right;
                // }

                .video-preview {
                    position: relative;
                    text-align: center;
                    color: white;
                }

                .video-title {
                    position: absolute;
                    left: 50%;
                    top: 20%;
                    transform: translate(-50%, 0%);
                    font-family: 'Cabin';
                    font-weight: bold;
                    margin: 0;
                    word-wrap: break-word;
                    width: calc(100% - 100px);
                    font-size: 3.5vw;
                    @media all and (min-width: 981px){
                        top: 20%;
                        font-size: 1.4vw;
                    }
                }

                .video-subtitle {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, 0%);
                    font-family: 'Cabin';
                    font-weight: bold;
                    margin: 0;
                    word-wrap: break-word;
                    width: calc(100% - 100px);
                    font-size: 2.3vw;
                    @media all and (min-width: 981px){
                        font-size: 0.9vw;
                        top: 50%;
                    }
                }
                .video-preview-cta {
                    background-color: $color-grey;
                    padding: 5px 7px;
                    color: $color-text;
                    display: flex;
                    align-items: center;
                }
                .video-preview-btn {
                    border: none;
                    font-size: 14px;
                    text-transform: capitalize;
                    padding: 0;
                    margin-left: 8px;
                }
                &--landscape {
                    .video-title {
                        top: 25%;
                        @media all and (min-width: 981px){
                            top: 25%;
                            font-size: 1.1vw;
                            line-height: 1.2vw;
                        }
                    }
    
                    .video-subtitle {
                        left: 50%;
                        bottom: 30%;
                        @media all and (min-width: 981px){
                            bottom: 30%;
                            font-size: 0.7vw;
                            line-height: 0.8vw;
                        }
                    }
                }
            }
        }


        &.video-generator {
            @media all and (min-width: 981px){
                min-height: 32em;
            }

            form > * {
                margin-bottom: 10px;
            }

            label {
                line-height: 2rem;
            }

            form {
                width: 100%;
                @media all and (min-width: 981px){
                    width: 45%;
                    display: inline-block;
                }
            }

            [name=subtitle] {
                height: 6rem;
            }

            .headline {
                margin-top: 0;
            }

            .video-preview-container {
                div {
                    font-size: 1.3125rem;
                    text-align: center;
                    margin-bottom: 14px;
                }

                height: auto;
                width: 100%;

                @media all and (min-width: 981px){
                    width: 50%;
                    float: right;
                }

                .video-preview {
                    position: relative;
                    text-align: center;
                    color: white;
                }

                .video-title {
                    position: absolute;
                    left: 50%;
                    top: 40%;
                    transform: translate(-50%, 0%);
                    font-family: 'Cabin';
                    font-weight: bold;
                    margin: 0;
                    word-wrap: break-word;
                    width: 100%;
                    font-size: 3.5vw;
                    @media all and (min-width: 981px){
                        top: 51%;
                        font-size: 1.4vw;
                    }
                }

                .video-subtitle {
                    position: absolute;
                    left: 50%;
                    bottom: 24%;
                    transform: translate(-50%, 0%);
                    font-family: 'Cabin';
                    font-weight: bold;
                    margin: 0;
                    word-wrap: break-word;
                    width: 100%;
                    font-size: 2.3vw;
                    @media all and (min-width: 981px){
                        font-size: 0.9vw;
                        bottom: 23%
                    }
                }
            }
        }

        &.download-container {
            height: 6rem;
            padding-top: 1rem;
            padding-right: 2rem;

            #video-download-button {
                margin-right: 0px;
                margin-left: auto;
                display: block;
                height: 4rem;
                font-size: 21px;
                line-height: 34px;
                padding: 0.5rem 1rem;
                border: 3px solid #E8A065;
                -webkit-appearance: none;
                -moz-appearance: none;
                background: none;
                text-transform: uppercase;
                cursor: pointer;
                border-radius: 0;
                font-family: 'Cabin', Arial, sans-serif;
                color: rgb(74, 74, 74);
            }
        }

        form[id=social-generator-form]{
            width: 100%;
        }
    }
}
.success-video__wrapper {

    .headline {
        max-width: 45%;
    }

    .social {
        li {
            margin-right: 0.5rem;
        }

        .icon {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0;
        }
    }

    .social-container {
        padding: 0.75rem 0 0.75rem 2rem;
        position: relative;

        @media all and (min-width: 450px){
            right: 35px;
            position: absolute;
            display: inline-block;
        }

        @media all and (min-width: 981px){
            right: 20px;
        }

        > .icon:first-child {
            position: absolute;
            height: 1.5rem;
            width: 1.5rem;
            left: 0;
        }
    }

    #video-ready-preview {
        border-radius: 5px;
        margin-bottom: 20px;
    }
}

.success-video__wrapper,
.generate-video__wrapper {
    .pebble-trail svg{
        fill: $color-action;
    }

    .billboard__panel {
        display: block;
        margin-bottom: 0;
    }

    .billboard__summary {
        font-family: 'Cabin', Arial, sans-serif;
        font-size: 1.43rem;
        width: 100%;
        @media all and (min-width: 981px){
            width: 34rem;
        }
    }
    .campaign-two-col {    
        display: flex;
        flex-direction: column;

        @media all and (min-width: 700px){
            flex-direction: row;
        }
        .col-1 {
            @media all and (min-width: 700px){
                width: 50%;
            }
            .controls {
                width: fit-content;
            }
        }
        .col-2 {
            @media all and (min-width: 700px){
                width: 50%;
            }
            display: flex;
            flex-direction: column;
            img {
                max-width: 260px;
                width: 100%;
            }
            h4 {
                font-size: 21px;
                letter-spacing: 0;
                line-height: 24px;
                text-transform: uppercase;
                text-align: left;
            }
        }
        .video-preview-container {
            padding: 0 40px;
            h4 {
                font-size: 14px;
                letter-spacing: 0;
                line-height: 24px;
                text-transform: uppercase;
                text-align: left;
            }
        }
        .video-preview {
            img {
                max-width: 100%;
            }
        }
    }
    .video-preview-disclaimer {
        margin-bottom: 2rem;
        h3 {
            font-size: 21px;
            letter-spacing: 0;
            line-height: 24px;
            text-transform: uppercase;
            text-align: left;
        }
        p {
            font-size: 12px;
            letter-spacing: 0;
            line-height: 20px;
            text-align: left;
        }
    }
}
