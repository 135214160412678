.search-layout {
    padding: 0;
    position: relative;
    .side-content {
        background: #fff;
        padding: $width-gap;
    }
    .main-content {
        order: -1;
        padding: 0;
    }
    @media all and (min-width: 981px) {
        padding: 0 $width-gap 0 0;
        .main-content {
            padding: $width-gap 0 $width-gap $width-gap;
            order: 1;
        }
        .side-content {
            padding: 0 0 $width-gap $width-gap;
            padding-top: $width-gap;
            position: relative;
        }
    }
}

.search__bar {
    input {
        border: 0;
        color: $color-black;
        &.search__input-current-location::placeholder {
            color: $color-action;
            font-weight: bold;
        }
    }
    svg.search__button-current-location {
        color: $color-action;
        cursor: pointer;
    }
}

.search-layout .main-content .search__map-holder {
    padding-bottom: 0;
    height: calc(100vh - 14.75rem);
    @media all and (max-width: 980px) {
        height: 20rem;
        padding-bottom: 0;
        margin-top: 0;
    }
}

.search__string-holder {
    position: relative;

    #div_id_place {
        flex-grow: 1;
    }
}

#search_map {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.map__marker {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: $base-headline-font;
    font-size: 0.875rem;
    transform: translate(-50%, -50%);
    cursor: pointer;
    &:before {
        content: '';
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        z-index: -5;
        background: $color-primary;
    }
    &.school {
        display: none;
        &:before {
            background: $color-tertiary;
        }
    }
    &.active,
    &.legend {
        font-size: 0;
        z-index: 1000;
        &:before {
            width: 2rem;
            height: 2rem;
            background: #fff;
            border: 3px solid $color-text;
        }
        &:after {
            position: absolute;
            width: 2rem;
            height: 2rem;
            content: '';
            background: url(../img/cross.svg) center center/contain no-repeat;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 2.25rem;
            color: $color-text;
        }
    }
    &.legend:after {
        width: 0.875rem;
        height: 0.875rem;
    }
    &.pin {
        font-size: 0;
        z-index: -1;
        &:before {
            width: 2rem;
            height: 2rem;
            background: none;
            transform: rotate(0deg);
        }
        &:after {
            position: absolute;
            width: 1.675rem;
            height: 1.675rem;
            content: '';
            background: url(../icons/svg/pin.svg) center center/contain no-repeat;
            display: block;
            top: -0.85rem;
            left: 0.25rem;
            transform: rotate(45deg);
            font-size: 2.25rem;
            color: $color-text;
            z-index: 1000;
        }
    }
}

.center-pin{
    position:absolute;
    background:url(../icons/svg/pin.svg) no-repeat;
    top:50%;left:50%;
    z-index:1;
    margin-left:-10px;
    margin-top:-34px;
    height:34px;
    width:20px;
    cursor:pointer;
  }

.search__detail #search_list_holder {
    display: none;
}

.search__list #search_list_holder {
    width: calc(100% - #{$width-gap});
    @media all and (min-width: 981px) {
        position: absolute;
        top: 0;
        left: $width-gap;
        right: 0;
        bottom: 0;
        padding: $width-gap 0 0 0;
        overflow: auto;
        border-top: 3px solid $color-line;
    }
}

.support__headline {
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: 300;
    margin-top: -$width-gap/2;
}

.search__result {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: $width-gap/2 $width-gap $width-gap/2 0;
    cursor: pointer;
    &:hover h3 {
        text-decoration: underline;
    }
    &.school {
        .search__result-summary span {
            color: $color-tertiary;
        }
    }
    + .search__result {
        border-top: 3px solid $color-line;
    }
    &.parish-church .search__result-summary {
        &:after {
            display: block;
            content: 'This is the parish church';
            color: #dea26f;
            margin-top: 0.125rem;
            font-size: 0.875rem;
        }
    }
}

.search__detail-preview {
    font-size: 0.75rem;
    margin-bottom: $width-gap;
}

.search__result-summary {
    h4,
    p {
        margin: 0;
    }
    .search__result-location {
        text-transform: uppercase;
        margin-top: 0.125rem;
        font-size: 0.875rem;
    }
    .search__result-upcoming-events {
        font-size: 0.75rem;
        list-style-type: '-';
        padding-left: 0.3rem;
        margin-top: 0;
        a {
            color: #dea26f;
            text-decoration: none;
            padding: 0 0.2rem;
        }
    }
    span {
        color: $color-primary;
    }
    .tag {
        color: #fff;
        margin: 0.5em 0;
    }
}

.search__meta-list {
    margin-top: $width-gap;
    font-size: 1.25rem;
}

.search__meta {
    display: flex;
    padding: $width-gap * 0.75 $width-gap $width-gap * 0.75 0;
    + .search__meta {
        border-top: 3px solid $color-line;
    }
    .icon {
        flex-shrink: 0;
        margin-right: $width-gap * 0.75;
        color: $color-grey-light;
    }
    a {
        text-decoration: none;
    }
}

.search-control__row {
    display: flex;
    justify-content: space-between;
    padding: $width-gap * 0.75 $width-gap $width-gap * 0.75 0;
    border-top: 3px solid $color-line;
    border-bottom: 3px solid $color-line;
    font-size: 1.25rem;
    .icon {
        width: 1.5rem;
        height: 1.5rem;
        color: rgba($color-text, 0.5);
        &:hover {
            color: $color-text;
        }
    }
}

#search_back {
    display: flex;
    cursor: pointer;
    .icon {
        margin-right: $width-gap * 0.75;
        width: 1.25rem;
        height: 1.25rem;
    }
    &:hover {
        span {
            text-decoration: underline;
        }
        .icon {
            color: currentColor;
        }
    }
}

.search-navigation {
    display: flex;
    cursor: pointer;
    #search_next {
        margin-left: $width-gap;
    }
}

.search__detail-intro {
    padding-right: $width-gap;
}

.church__preview {
    flex-shrink: 0;
    width: 6rem;
    height: 6rem;
    margin-left: $width-gap * 0.75;
    position: relative;
    border: 3px solid $color-grey;
}

.list__count {
    display: inline-flex;
    align-items: center;
    padding: $width-gap * 0.375;
    position: relative;
    color: #fff;
    z-index: 5;
    flex-shrink: 0;
    margin: 0 $width-gap 0 $width-gap * 0.675;
    font-size: 0.875rem;
    &:before {
        content: '';
        display: block;
        width: $width-gap * 1.25;
        height: $width-gap * 1.25;
        background: $color-primary;
        border: 3px solid $color-primary;
        z-index: -5;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        .in__parish & {
            background: $color-action;
        }
        .result__type & {
            width: $width-gap * 0.5;
            height: $width-gap * 0.5;
        }
        .school & {
            background: $color-tertiary;
            border-color: $color-tertiary;
        }
    }
}

#search_filter {
    padding: 2rem 0 0.5rem 0;
    margin-bottom: 0;
    border-bottom: 3px solid $color-line;

    .result__type {
        display: block;
        position: relative;
        cursor: pointer;
        .list__count {
            display: inline-block;
            padding: 0.5rem;
            margin: 0 0.75rem 0 3rem !important;
            &:before {
                width: 0.5rem;
                height: 0.5rem;
            }
        }
        span {
            background: none;
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            ~ .checkmark {
                border: 2px solid #e4e4e4;
            }
        }
        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            &:after {
                content: '';
                position: absolute;
                display: none;
                left: 7px;
                top: 2px;
                width: 5px;
                height: 10px;
                border-style: solid;
                border-color: $color-primary;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &.school:after {
                border-color: $color-tertiary;
            }
        }
        /* Show the checkmark when checked */
        input:checked ~ .checkmark:after {
            display: block;
        }
    }
}

.church-list__map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.parish-info {
    display: flex;
    flex-direction: row;
    margin: 0.5em 0;
    padding: 0.5rem 0;
    border-bottom: 3px solid #f5f5f5;
    .icon {
        width: 3.4rem;
        height: 3.4rem;
        margin-right: 0.7em;
    }
    p {
        margin: 0;
    }
}

.parish__result {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-top: $width-gap;
    border-top: 3px solid $color-line;
    .parish__example {
        width: 2rem;
        height: 2rem;
        margin-right: $width-gap * 0.875;
        flex-shrink: 0;
    }
    p {
        margin-top: 0;
    }
    span {
        border-bottom: 1px solid $color-action;
    }
}

.result__type {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin: $width-gap 0 $width-gap * 0.5 0;
    padding-bottom: $width-gap/2;
    > span {
        margin-top: $width-gap * 0.75;
        .list__count {
            margin-right: $width-gap * 0.5;
        }
    }
    @media all and (min-width: 768px) {
        flex-flow: row nowrap;
        align-items: center;
        > span {
            margin-top: 0;
        }
    }
}

.result__school {
    .list__count {
        &:before {
            background: $color-tertiary;
            border-color: $color_tertiary;
        }
    }
    .search__result-location span {
        color: $color-tertiary;
    }
}

@media all and (max-width:980px) {
  #search_list_holder {
    display: grid;
  }
  #search-button, .search__form-holder { order: 5; }
  #search-button { order: 5; }
  #search_stats { order: 4; }
  #search_parish { order: 3; }
  #search_results { order: 1; }
}
