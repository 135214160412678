.tag__list {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    margin-bottom: $width-gap;
    padding: 0;
}

.tag {
    flex-shrink: 0;
    display: inline-block;
    padding: 0 $width-gap/2;
    background: lightgray;
    margin: 0px 0px 4px 0px;
    color: #fff;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-family: $base-headline-font;
    vertical-align: top;
    &.facilities {
        background: $color-primary;
    }
    &.features {
        background: $color-secondary;
    }
    &.location, &.action  {
        background: $color-action;
    }
    &.news-tags, &.tertiary {
        background: $color-tertiary;
    }
    &.communion, &.tertiary-light {
        background: $color-tertiary-light;
    }
    &.diocese {
        background: $color-diocese;
    }
    &.deanery {
        background: $color-deanery;
    }
    &.my-church {
        background: $color-primary;
    }
    &.my-benefice {
        background: $color-secondary;
    }
    &.status-green {
        background: $color-status-green;
    }
    &.with__description {
        padding: 0;
        display: inline-flex;
        flex-flow: column nowrap;
        .tag__name {
            padding: 0 0 0 $width-gap/2;
            label {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
    }
    &.h4 {
        font-size: 1.5rem;
        padding: $width-gap/2.5 $width-gap/2.5 !important;
    }
}

div[class*="tag__group"] {
    ~ div[class*="tag__group"],
    + div[class*="tag__group"] {
        margin-top: $width-gap * 2.5;
    }
}

.tag__name {
    display: inline-flex;
    user-select: none;
    justify-content: space-between;
    label {
        display: flex;
    }
}

.tag__control {
    display: inline-block;
    margin-left: $width-gap/2;
    width: $width-gap * 1.5;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.4);
    .close {
        display: none;
    }
}

.tag__description {
    display: block;
    text-transform: none;
    padding: $width-gap * 0.75 $width-gap;
    border: 3px solid $color-grey;
    border-top: none;
    color: $color-text;
    background: white;
}

input[type="checkbox"]:checked {
    + .tag__name {
        .tag__control {
            background: none;
            .open {
                display: none;
            }
            .close {
                display: block;
            }
        }
    }
    ~ .tag__description {
        display: block;
    }
}

.tag__holder {
    width: 100%;
    @media all and (min-width: 768px) {
        width: calc(50% - #{$width-gap/2});
        &:nth-of-type(2n) {
            margin-left: $width-gap;
        }
    }
}

._event-types, .group-services-and-events {
    color: $color-tertiary-light;
}
.services-and-events,
.advent,
.allages,
.allsaints,
.allsaintsandsouls,
.allsouls,
.ashwednesday,
.baptismchristening,
.bcp,
.biblesunday,
.brexitprayers,
.candlemass,
.carols,
.choirevent,
.christingle,
.christmaseve,
.christmas,
.coffee,
.communion,
.compline,
.confirmationservice,
.coronation,
.cribnativity,
.cw,
.dedication,
.easter,
.easterday,
.epiphany,
.episcopalservice,
.eveningworship,
.fam_fri,
.fathersday,
.followthestar,
.freshexpression,
.funeral,
.goodfriday,
.harvest,
.healing,
.holyweek,
.hotcrossbuns,
.hymnsevent,
.incense,
.lent,
.marriage,
.maundythursday,
.memorialservice,
.messychurch,
.midnightmass,
.mince_pies,
.morningworship,
.motheringsunday,
.mulled_wine,
.musicevent,
.nightprayer,
.ordinations,
.palmsunday,
.patronalfestival,
.pentecost,
.petservice,
.ploughsunday,
.prayers,
.queen,
.remembrance,
.rogationday,
.saintsdays,
.schoolservice,
.shrovetuesday,
.stationscross,
.signing,
.singingevent,
.tellservegive19,
.thanksgiving,
.thykingdomcome,
.wedding {
    background: $color-tertiary-light;
}

._event-locations, .group-event-locations {
    color: $color-action;
}
.event-locations,
.beach,
.cathedral,
.chapel,
.church,
.hall,
.shop,
.space,
.green,
.hired,
.nursing,
.others,
.private,
.pub,
.school,
.street,
.town,
.vicarage {
    background: $color-action;
}
._groups-courses-and-activities, .group-groups-and-activities {
    color: $color-secondary;
}
.groups-and-activities,
.addiction,
.afterschool,
.alpha,
.asylum,
.beavers,
.biblestudy,
.boysbrigade,
.brass,
.brownies,
.christianityexplored,
.coffemorning,
.communityevent,
.communitygroup,
.counselling,
.creche,
.cubs,
.debt,
.discipleship,
.employment,
.exercise,
.girlsbrigade,
.guides,
.healthclub,
.holidayclub,
.homesless,
.lentcourse,
.mentalheath,
.marriagecourse,
.mentoring,
.moneyadvice,
.mothersunion,
.nightshelter,
.nurture,
.over60s,
.parenting,
.parentstoddlers,
.pilgrim,
.playgroup,
.preschool,
.rainbows,
.refugees,
.rehab,
.scouts,
.seascouts,
.socialaction,
.streetpastor,
.sunday_school,
.voluntarygroup,
.youth,
.youthwork {
    background: $color-secondary;
}
._our-building {
    color: #8fab73;
}
.listed,
.postoffice {
    background: #8fab73;
}
._accessibility {
    color: $color-primary;
}
.toilets,
.baby,
.parish_church,
.parking,
.access_lav,
.access_phone,
.autism_aware,
.access_park,
.defibrilator,
.wheel,
.hearing,
.facilities-form .signing,
.braile,
.largep,
.access_dogs,
.dementia_aware {
    background: $color-primary;
}
._music-and-worship {
    color: $color-tertiary-light;
}
.bells,
.music,
.organ,
.bcp_services,
.choir {
    background: $color-tertiary-light;
}
._help-for-visitors, .group-news-tags {
    color: $color-tertiary;
}
.wifi,
.tours,
.guide,
.cafe,
.facilities-form .shop,
.church_open {
    background: $color-tertiary;
}
._other-features {
    color: $color-primary-dark;
}
.fairtrade,
.foodbank,
.audio,
.conservation,
.upa,
.credit_union,
.backtochurch,
.facilities-form .hall {
    background: $color-primary-dark;
}

.action_required {
    background: $color-error;
    &.icon {
        background: none;
        fill: $color-error;
    }
}

.contact_form {
    background: $color-primary;
    &.icon {
        background: none;
        fill: $color-primary;
    }
}
.tags__list-more {
    display: none;
}
.tags__list-initial {
    display: initial;
}

// Different tag styling on Christmas event cards
$color-tag-green: #ACD5B8;
$color-tag-green-text: #15361F;
$color-tag-blue: #B9D8E7;
$color-tag-blue-text: #004874;
$color-tag-yellow: #F3D591;
$color-tag-yellow-text: #5F4100;

.christmas-results-page__container,
.card--event-updated {
    .tag {
        color: $color-text;
        font-family: $base-font;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.3125rem;
        padding: 0.25rem 0.5rem;
        margin: 0 0.375rem 0.625rem 0;
    }

    .services-and-events,
    .advent,
    .allages,
    .allsaints,
    .allsaintsandsouls,
    .allsouls,
    .ashwednesday,
    .baptismchristening,
    .bcp,
    .biblesunday,
    .brexitprayers,
    .candlemass,
    .carols,
    .choirevent,
    .christingle,
    .christmaseve,
    .christmas,
    .coffee,
    .communion,
    .compline,
    .confirmationservice,
    .coronation,
    .cribnativity,
    .cw,
    .dedication,
    .easter,
    .easterday,
    .epiphany,
    .episcopalservice,
    .eveningworship,
    .fam_fri,
    .fathersday,
    .followthestar,
    .freshexpression,
    .funeral,
    .goodfriday,
    .harvest,
    .healing,
    .holyweek,
    .hotcrossbuns,
    .hymnsevent,
    .incense,
    .lent,
    .marriage,
    .maundythursday,
    .memorialservice,
    .messychurch,
    .midnightmass,
    .mince_pies,
    .morningworship,
    .motheringsunday,
    .mulled_wine,
    .musicevent,
    .nightprayer,
    .ordinations,
    .palmsunday,
    .patronalfestival,
    .pentecost,
    .petservice,
    .ploughsunday,
    .prayers,
    .queen,
    .remembrance,
    .rogationday,
    .saintsdays,
    .schoolservice,
    .shrovetuesday,
    .stationscross,
    .signing,
    .singingevent,
    .tellservegive19,
    .thanksgiving,
    .thykingdomcome,
    .wedding {
        background: $color-tag-green;
        color: $color-tag-green-text;
    }

    .event-locations,
    .beach,
    .cathedral,
    .chapel,
    .church,
    .hall,
    .shop,
    .space,
    .green,
    .hired,
    .nursing,
    .others,
    .private,
    .pub,
    .school,
    .street,
    .town,
    .vicarage {
        background: $color-tag-yellow;
        color: $color-tag-yellow-text;
    }

    .groups-and-activities,
    .addiction,
    .afterschool,
    .alpha,
    .asylum,
    .beavers,
    .biblestudy,
    .boysbrigade,
    .brass,
    .brownies,
    .christianityexplored,
    .coffemorning,
    .communityevent,
    .communitygroup,
    .counselling,
    .creche,
    .cubs,
    .debt,
    .discipleship,
    .employment,
    .exercise,
    .girlsbrigade,
    .guides,
    .healthclub,
    .holidayclub,
    .homesless,
    .lentcourse,
    .mentalheath,
    .marriagecourse,
    .mentoring,
    .moneyadvice,
    .mothersunion,
    .nightshelter,
    .nurture,
    .over60s,
    .parenting,
    .parentstoddlers,
    .pilgrim,
    .playgroup,
    .preschool,
    .rainbows,
    .refugees,
    .rehab,
    .scouts,
    .seascouts,
    .socialaction,
    .streetpastor,
    .sunday_school,
    .voluntarygroup,
    .youth,
    .youthwork {
        background: $color-tag-blue;
        color: $color-tag-blue-text;
    }
}
