.my-churches {
    &--page__wrapper {
        background-color: $color-grey;
        .mobile-admin__navigation {
            background-color: $color-white;
        }
    }
    &--block {
        background-color: $color-white;
        padding: 2rem;
        margin-top: 10px;
        h3 {
            margin-bottom: 0;
            &::after {
                content: '';
                display: block;
                height: 3px;
                width: 104px;
                background-color: $color-primary;
                margin-top: 20px;
                margin-bottom: 25px;
            }
            .icon {
                padding-top: 0.5rem;
                height: 2.25rem;
            }
        }
    }
    &--row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &--col-1 {
        font-size: 20px;
        letter-spacing: 0;
        line-height: 24px;
    }
    &--col-2 {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &--btn {
        color: $color-white !important;
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 3px 15px;
        border: none;
        text-transform: capitalize;
        display: inline-flex;
        align-items: center;
        .icon {
            margin-left: 6px;
        }
        &__view-live-church {
            background: $color-tertiary-light;
        }
        &__view-live-benefice {
            background: $color-tertiary-light;
        }
        &__edit-church {
            background: $color-primary;
        }
        &__edit-benefice {
            background: $color-secondary;
        }
        &__add {
            background: $color-action;
        }
    }
    &--tags {
        margin-top: 5px;
    }
    &--cta {
        margin-right: 25px;
    }
    &--stats {
        display: flex;
        flex-direction: column;
        margin-right: 33px;

        &-item {
            display: flex;
            flex-direction: row;
            padding: 0;
            margin: 0;
            color: $color-text;
            font-family: "Open Sans";
            font-size: 12px;
            letter-spacing: 0;
            line-height: 20px;
            text-transform: uppercase;
            justify-content: space-between;
            span {
                margin-right: 5px;
            }
        }
    }
    &--events {
        display: flex;
        flex-direction: column;
        a {
            color: $color-action;
            font-family: "Open Sans";
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 20px;
        }
    }
    &--col-names {
        display: flex;
        flex-direction: row;
        > span {
            width: 77%;
        }
        span {
            color: $color-text;
            font-family: "Open Sans";
            font-size: 12px;
            letter-spacing: 0;
            line-height: 20px;
            text-transform: uppercase;
        }
        div {
            width: 23%;
            display: flex;
            flex-direction: row-reverse;
            > span {
                margin-right: 13px;
            }
        }
    }
}
