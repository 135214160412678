.demo-announcement,
.saved-data-notification {
    padding: 0.75rem 0;
    background: #000;
    color: #fff;
    text-align: center;

    @media (max-width: 980px) {
        top: 130px;
    }

    @media (max-width: 767px) {
        top: 118px;
    }
}

.saved-data-notification {
    display: none;
    background: $color-status-purple;
}

.page-header {
    background: #fff;

    @media all and (max-width: 979px) {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        background: $color-background-light;

        .primary__navigation {
            display: none;
        }

        .navbar__holder {
            flex-flow: row wrap;

        }
    }
}

.cofe__toggle {
    cursor: pointer;
    background: $color-line;
    color: $color-text;
    padding: $width-gap * 0.75;
    flex-shrink: 0;
    user-select: none;
    width: 6rem;
    text-align: center;

    .icon {
        vertical-align: text-top;
    }

    .close {
        display: none;
    }

    @media all and (min-width: 980px) {
        display: none;
    }

    @media all and (max-width: 980px) {
        width: fit-content;
        flex: auto;
        align-self: normal;
    }
}

#cofe_toggle:checked {
    ~ .page-header {
        .cofe__bar {
            display: flex;

            .open {
                display: none;
            }
            .close {
                display: inline;
            }

            .navbar li {
                display: block;
            }
        }

        .primary__navigation {
            padding: $width-gap 0 0 0;
        }
    }
}

.header__toggle,
.mobile__toggle {
    margin-left: auto;
    font-size: 1em;
    position: relative;

    label {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
    }

    .close {
        display: none;
    }

    @media all and (min-width: 980px) {
        display: none !important;
    }
}

.page-header__buttons {
    margin-left: auto;
}

.cofe__bar {
    background: $color-primary;
    font-family: $base-headline-font;
    letter-spacing: 0.025em;
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    font-size: 0.825rem;
    letter-spacing: 0.05em;

    a {
        padding: $width-gap/2;
    }

    @media all and (min-width: 980px) {
        .navbar li {
            border-color: #fff;

            a:hover {
                color: #fff;
            }

            &.active a {
                background: $color-background-light;
                color: $color-text;
            }

            .hidden-tab{
                display: none;
            }
        }
    }

    @media all and (max-width: 980px) {
        text-align: center;
        letter-spacing: 0.02em;
        font-size: 0.75rem;
        letter-spacing: 0.02em;

        .container {
            margin-top: 0;
        }

        .navbar {
            max-width: 20rem;
            margin: auto;

            li {
                border-color: rgba($color-background-light, 0.125);
                display: none;
                align-items: center;

                &.active {
                    order: -1;
                    display: flex !important;
                    justify-content: space-between;
                    border-top: none;
                    color: $color-text;

                    a {
                        background: #fff;
                        width: fit-content;
                        padding: $width-gap * 0.75 $width-gap;
                        align-self: normal;
                    }

                    .hidden-tab{
                        background: $color-line;
                    }

                    .icon {
                        transform: scale(0.5);
                    }
                }
            }
        }
    }
}

.primary__navigation {
    width: 100%;
    padding: $width-gap * 0.5 0 $width-gap * 0.35 0;

    @media all and (min-width: 980px) {
        padding: $width-gap * 0.75 0 $width-gap * 0.75 $width-gap;

        &.acny-as-homepage {
            padding: 1rem 0 1rem 1rem;

            .container {
                max-width: unset;
            }

            .navbar {
                flex-wrap: wrap;
            }
        }
    }

    .navbar {
        li {
            font-family: $base-headline-font;
            padding: 1rem 0;
            border-left: none;
            text-transform: none;
            @media all and (min-width: 980px) {
                font-size: 1.25rem;
            }

            &.single__item {
                border: none;
                padding: $width-gap/2 0;
            }

            a {
                padding: 0;
                display: flex;
                align-items: center;
            }
            .icon {
                margin-right: 1rem;
                width: 1rem;
                height: 1rem;
                fill: $color-grey-light;
                flex-shrink: 0;
            }
            .navbar__item-holder{
                border-right: 2px solid $color-grey;
                padding-right: 2rem;
                p{
                    font-size: 0.875rem;
                }
            }
            &:last-of-type{
                border: none;
            }
        }
        li + li {
            margin-left: 2rem;
        }
    }

    .navlist__item.active {
        color: $color-primary;
    }
}

.navbar__brand {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    font-family: $base-headline-font;
    text-transform: uppercase;
    color: $color-primary-dark;
    text-decoration: none;
    font-size: 1.3125rem;

    .icon {
        color: $color-primary;
        width: 1.5em;
        height: 1.5em;
        margin-right: $width-gap/2;
        flex-shrink: 0;
    }
}

// New/alternate styling for Christmas 2023
.cofe__navigation {
    font-family: $base-headline-font;
    position: relative;
    display: none;
    border-top: 1px solid $color-navy;
    padding: 0 1rem !important;

    ul {
        list-style: none;
        padding: 0;
        padding-top: 2rem;

        li {
            margin: 0 0 2rem 0;
        }

        a {
            text-decoration: none;
            color: $color-navy;
            display: flex;
            align-items: center;

            .icon {
                width: 1.5rem;
                height: 1.5rem;
                margin-left: 0.25rem;
            }
        }
    }

    @include desktop {
        display: block;
        border-top: none;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            padding: 0.5rem;

            li {
                margin: 0.5rem 1rem;
            }
        }
    }
    .admin-view &{
        display: none;
    }
}

.church__navigation + .cofe__navigation {
    border-top: none;
}

.page-header .primary__navigation {
    padding: 0;
    position: relative;

    .container {
        padding: 0;
    }

    .navbar__right {
        margin-right: 0;
    }

    .navbar {
        width: 100%;
    }
    .navbar li a {
        display: flex;
    }

    .navlist__dropdown--churches {
        list-style: none;
        padding-left: 1rem;
        top: 5.5rem;
        left: 1rem;
        .dropdown__item {
            display: flex;
            .navlist__item-wrapper {
             margin-right: 1rem;
            }
         }
    }

    .navbar li {
        padding: 0.75rem 1rem;
        width: 100%;

        &.active {
            background-color: #BBD0FF;
        }
    }

    .navbar li + li {
        margin-left: 0;
        border-top: 0;
    }

    a {
        color: $color-black;
    }

    .acny__logo {
        display: none;
    }

    @include desktop {
        margin-bottom: 2rem;
        .admin-view & {
            margin-bottom: 0;
        }

        .container {
            padding: 0 1rem;
        }

        .navbar__holder {
            border: 1px solid $color-easter-purple;
            border-radius: 20px;
            padding: 0 1.875rem;
            background-color: $color-white;
            .admin-view & {
                border: none;
                padding: 0;
            }
        }

        .acny__logo {
            display: block;
        }

        .navbar {
            width: auto;
        }

        .navbar li {
            padding: 1.875rem 0;
            width: auto;

            &.active {
                background-color: unset;
            }
        }

        .navbar li + li {
            margin-left: 1rem;
        }

        .navbar li .icon {
            display: none;
        }

        .navlist__dropdown--churches li + li {
            margin-left: 0 !important;
        }

    }

    @media (min-width: 1130px) {
        .navbar li .icon {
            display: block;
        }
    }
    @media (min-width: 1195px) {
        .navbar li + li {
            margin-left: 2rem;
        }
    }
    &--christmas{
        .navbar__holder {
            border: 1px solid $color-tertiary;
        }
    }
}

.page-header .mobile__navigation .primary__navigation--updated,
.mobile__navigation .cofe__navigation {
    display: block;

    @include desktop {
        display: none;
    }
}

.header-background {
    display: none;
}

.christmas-branding {
    .page-header {
        position: relative;
        z-index: 10; // make blue image be above page content

        .navbar li a {
            display: flex;
        }

        .navlist__dropdown--churches {
            list-style: none;
            padding-left: 1rem;
            top: 5.5rem;
            left: 1rem;
        }

        @include desktop {
            background-color: $color-navy;
        }

        @media (min-width: 1930px) {
            background-color: $color-white;
        }
    }

    &.admin-view .page-header {
        .navlist__dropdown--churches {
            top: 4.5rem;
        }

        @include desktop {
            background-color: $color-white;
        }
    }

    &.accounts {
        .cofe__navigation {
            @include desktop {
                display: block;
            }
        }

        .navbar__holder {
            padding: 0 1.875rem !important;
        }
    }

    .cofe__navigation ul li a {
        @include desktop {
            color: $color-white;
        }
    }

    .primary__navigation {
        margin-bottom: 0;
        z-index: 100; // above background image
    }

    .header-background {
        z-index: -1;
        width: 100%;
        position: absolute;
        top: 0;

        @include desktop {
            display: block;
        }

        @media (min-width: 1033px) {
            min-height: 17rem;
        }

        @include desktop-large {
            max-height: 17rem;
        }
    }

    .header-background--events {
        width: calc(100% + 4rem);
        margin-left: -1rem;
        top: 4rem;

        @include desktop {
            max-height: 20rem;
        }

        @include desktop-large {
            max-height: unset;
        }

        @media (min-width: 1500px) {
            top: 0;
        }

        @media (min-width: 1730px) {
            max-height: 27rem;
        }

        + .fts-logo {
            display: none;
            position: absolute;
            z-index: 10;
            top: 9rem;
            width: 10rem;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;

            @include desktop {
                display: block;
                top: 11rem;
            }

            @media (min-width: 1080px) {
                width: 14rem;
            }

            @include desktop-large {
                top: 12rem;
            }
        }
    }

    .header-background--landing {
        width: calc(100% + 4rem);
        margin-left: -1rem;
        top: 4rem;

        @include desktop {
            max-height: 20rem;
        }

        @include desktop-large {
            max-height: unset;
        }

        @media (min-width: 1500px) {
            top: 0;
        }

        @media (min-width: 1730px) {
            max-height: 27rem;
        }

        + .fts-logo {
            display: none;
            position: absolute;
            z-index: 10;
            top: 9rem;
            width: 10rem;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;

            @include desktop {
                display: block;
                top: 11rem;
            }

            @media (min-width: 1080px) {
                width: 14rem;
            }

            @include desktop-large {
                top: 12rem;
            }
        }
    }
}

.easter-branding {
    .page-header {
        position: relative;
        z-index: 10; // make header image be above page content

        .navbar li a {
            display: flex;
        }

        .navlist__dropdown--churches {
            list-style: none;
            padding-left: 1rem;
            top: 5.5rem;
            left: 1rem;
        }
    }

    &.admin-view .header-background {
        display: none;
    }

    &.admin-view .page-header {
        .navlist__dropdown--churches {
            top: 4.5rem;
        }

        @include desktop {
            background-color: $color-white;
        }
    }

    &.accounts,
    &.my-churches {
        .cofe__navigation,
        .header-background {
            @include desktop {
                display: block;
            }
        }

        .navbar__holder {
            padding: 0 1.875rem !important;
        }
    }

    .cofe__navigation ul li a {
        @include desktop {
            color: $color-white;
        }
    }

    .primary__navigation {
        margin-bottom: 0;
        z-index: 100; // above background image
    }

    .header-background {
        z-index: -1;
        width: 100%;
        position: absolute;
        top: 4rem;

        @include desktop {
            display: block;
            top: 0;
            min-height: 15rem;
        }

        @include desktop-large {
            max-height: 17rem;
        }
    }

    &.home .header-background {
        display: block;
    }
}
