.sr__text,
.sr__input,
.sr__label {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

// Should be absolutely positioned as should not take up space,
// should be visible on focus
.focus-visible {
    position: absolute !important;
    z-index: 2;
    background-color: #fff;
    padding: 0.5rem;

    &:not(:focus) {
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        padding: 0;
        border: 0;
        opacity: 0;
    }
}
