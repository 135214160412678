.location-image__preview {
    position: relative;

    .button {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media all and (min-width: 981px) {
        position: relative;
        width: 100%;
        margin-left: 0;
    }

    @media all and (max-width: 540px) {
        display: none;
    }
}

.billboard__side + .location-image__preview {
    @media all and (min-width: 541px) and (max-width: 980px) {
        position: absolute;
        display: block;
        left: 50%;
        bottom: -$width-gap * 7;
        z-index: 20;
        transform: translateX(-50%);

        .has-church-image & {
            position: absolute;
            top: $width-gap * 3;
            left: $width-gap;
            transform: none;

            .church__image-carousel {
                width: $width-gap * 9;
            }
        }
        .has-church-image.billboard__side--page-detail-small & {
            width: 1000px;
        }
    }
}

.billboard__side--page-detail + .location-image__preview {
    @media all and (min-width: 541px) and (max-width: 980px) {
        .has-church-image & {
            display: none;
        }
    }
}

.church__image {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    flex-shrink: 0;
    background-color: #fff;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    box-shadow: inset 0px 0px 0px 3px rgba(#000, 0.05);
}

.church__logo-holder {
    width: 100%;
    background-color: #fff;
    padding: 4px;

    @media all and (min-width: 981px) {
        padding: 1.25rem;
        border-top: 3px solid $color-grey;
    }
}

.church__logo {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    @media all and (min-width: 981px) {
        padding-bottom: 66%;
    }

    &.active {
        transform: translateX(-100%);
    }
}

.church__image-carousel {
    display: none;
    width: 10rem;
    margin: auto;
    overflow: hidden;
    background: #fff;
    position: relative;

    @media all and (min-width: 541px) {
        display: flex;
    }

    @media all and (min-width: 981px) {
        width: 100%;
        border: none;
    }
}

.church__image-controls {
    position: absolute;
    bottom: $width-gap;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    .pip {
        width: 10px;
        height: 10px;
        margin: 0 $width-gap/3;
        border: 1px solid #fff;
        transform: rotate(-45deg);
        cursor: pointer;
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25),
            inset 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
        &.active {
            background: #fff;
            box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
        }
    }
}
.location-intro {
    color: $color-text;
    display: block;
    position: absolute;
    height: auto;
    padding-bottom: 0;
    margin-bottom: 0;
}
.location-intro__image {
    display: block;
    position: absolute;
    height: auto;
    padding-bottom: 0;
    margin-bottom: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .church-page__main & {
        top: 1rem;
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color-primary-dark, 0.625);
    }

    &--christmas {
        max-height: 527px;
        height: 100%;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($color-secondary, 0.625);
        }
    }

    @media all and (max-width: 980px) {
        display: none;
    }
}

.stats__bar {
    font-family: $base-headline-font;
    font-size: 1.25rem;
    .icon {
        color: $color-grey;
        margin-right: $width-gap;
    }
    @media all and (min-width: 768px) {
        color: $color-action;
        .stats span {
            color: $color-background-light;
        }
    }
    @media all and (min-width: 981px) {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        .stats {
            margin: auto;
            span {
                font-size: 1.25em;
                vertical-align: middle;
            }
            + .stats {
                padding-left: $width-gap;
            }
        }
        .stats:first-of-type {
            margin-left: 0;
        }
    }
}
