// Desktop devices
@mixin desktop-large {
    @media (min-width: #{$breakpoint-desktop-large}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$breakpoint-desktop}) {
        @content;
    }
}

// Mobile devices
@mixin mobile {
    @media (min-width: #{$breakpoint-mobile}) {
        @content;
    }
}

@mixin mobile-small {
    @media (min-width: #{$breakpoint-mobile-small}) {
        @content;
    }
}


// Desktop-first breakpoints for when they're necessary
@mixin smaller-than-desktop {
    @media (max-width: #{$breakpoint-desktop - 1}) {
        @content;
    }
}
