.image-select {
    display: grid;
    gap: 1rem;

    @include mobile {
        grid-template-columns: 1fr 1fr;
    }

    @include desktop-large {
        grid-template-columns: 1fr 1fr 1fr;
    }
    img{
        max-width: 210px;
        width: 100%;
        max-height: 160px;
        height: 100%;
        object-fit: cover;
    }
}
