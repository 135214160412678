// Tag group pages - showing churches by tag-group on national/diocese/deanery level
.tag-group-page {
    @media (max-width: 980px) {
        background: transparent;
    }

    h1 {
        @media (max-width: 767px) {
            font-size: 2.25rem;
        }
    }

    .headline.mt1 {
        margin-top: 2rem;
    }

    //-----------------------------------------------------------------
    // Side panel
    //-----------------------------------------------------------------
    .side-content {
        padding-top: 0;

        p {
            max-width: 100%;
            padding-right: 1rem;
        }

        @media (max-width: 980px) {
            padding-left: 0;
            padding-right: 0;

            > p {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }

        .search__form-button {
            position: absolute;
            padding-right: 0;
            bottom: 0;
            right: 0.125rem;

            @include desktop {
                bottom: 1rem;
            }
        }
    }

    .side__head-area {
        background-color: $color-primary-dark;
        color: #fff;
        padding: 1rem;
        .christmas-action-headline {
            margin-top: 45px;
        }

        .coronation-logo {
            display: block;
            width: 60%;
            max-width: 200px;
            margin: 2rem auto 0 auto;
        }

        .coronation-action-headline {
            margin-top: 3rem;
        }

        @media (min-width: 981px) {
            padding-right: 0;
        }

        @media (max-width: 767px) {
            .standfirst {
                margin-bottom: 0;
            }
        }
    }

    .headline:after {
        bottom: -0.35rem;
    }

    .standfirst {
        line-height: 1.6;
        font-size: 1.1rem;

        @media (min-width: 768px) {
            font-size: 1.35rem;
        }
    }

    //-----------------------------------------------------------------
    // Search form
    //-----------------------------------------------------------------
    .search__form-header {
        display: none;
    }

    .search__form-body {
        display: flex;
        padding: 0;

        @media (min-width: 541px) {
            margin-top: 1rem;
        }

        @media (min-width: 981px) {
            margin-top: 4rem;
        }
    }

    .search__form-button {
        width: auto;
        margin-left: 0.25rem;

        button {
            border: 3px #E8A065 solid;
            padding: 0.75rem;

            &:not(:focus) {
                background: transparent;
            }
        }

        span {
            position: absolute;
            left: -999999px;
        }

        .icon {
            width: 1.5em;
            height: 1.5em;
            margin-right: 0;
        }
    }

    .search__form-section {
        width: calc(100% - 4rem);

        // Hide 'tags' form field as this is pre-filled on this page type
        &:first-child {
            display: none;
        }
    }

    .search__string-holder {
        background-color: #fff;

        svg {
            fill: $color-text;
        }
    }

    //-----------------------------------------------------------------
    // Map
    //-----------------------------------------------------------------
    .main-content .search__map-holder {
        // slightly higher than standard search map
        height: calc(100vh - 10rem);

        @media (max-width: 980px) {
            height: 26rem;
        }
    }

    .tag-group__counter {
        text-decoration: none;

        &:hover,
        &:focus {
            z-index: 100;

            &:after {
                display: block;
            }
        }

        &:focus {
            // Firefox doesn't handle focus nicely with the transforms
            outline: none;

            &:before {
                border: 3px #9ecaed solid;
                box-shadow: 0 0 10px #9ecaed;
            }
        }

        &:before {
            width: 100%;
            min-width: 0.85rem;
            transform: translate(-50%, -50%);
            border-radius: 1rem;
            padding: 0 0.35rem;
        }

        &:after {
            content: attr(tooltip);
            display: none;
            position: absolute;
            right: -0.5rem;
            top: 50%;
            z-index: 100;
            transform: translateX(100%);
            padding: 0.1rem 0.3rem;
            background-color: rgba(0, 0, 0, 0.7);
        }
    }

    .church__legend {
        font-size: 1rem;
        line-height: 1.35;

        .tag-group__counter {
            transform: translate(0%, 0%);
            position: static;
            margin-right: 1.25rem;

            &:after {
                display: none;
            }
        }

        span {
            display: inline;
        }
    }

    //-----------------------------------------------------------------
    // Additional links (main panel)
    //-----------------------------------------------------------------
    .main-content {
        order: 1;
    }

    .panel__grid {
        margin-top: 1rem;

        @media (max-width: 980px) {
            margin: 1rem;
            margin-bottom: 2rem;
        }
    }

    .panel {
        margin-bottom: 0;

        &:nth-child(odd) .panel__image:after {
            background: rgba(166, 136, 183, 0.9);
        }

        &:nth-child(even) {
            background: $color-secondary;

            .panel__image:after {
                opacity: 0.8;
            }
        }

        @media (max-width: 1199px) {
            // prevent e.g. #followthestar breaking out of layout
            h3 {
                font-size: 1.75rem;
            }
        }

        @media (max-width: 980px) {
            margin-right: 0;
        }

        @media (max-width: 980px) and (min-width: 768px) {
            width: 50%;
        }
    }
}

.coronation__map-page {
    .panel__collage.collage__map-page .panel__1 {
        background: #7D1634; // coronation brand color
        color: $color-white;

        .icon {
            border-color: #B5800E; // coronation brand color
        }
    }
}

.panel__collage {
    &.collage__map-page {
        @media all and (min-width: 600px) {
            grid-template-columns: 50% 50%;
            grid-template-rows: auto auto;
        }
    }
}

.panel__collage.collage__map-page {

    .panel {
        color: $color-text;
        > .icon {
            border: 3px solid $color-navy;
            padding: $width-gap/2;
            width: $width-gap * 2.5;
            height: $width-gap * 2.5;
            bottom: 1.25rem;
        }
        .panel__content {
            padding: 1.25rem 1.75rem 2.7rem 1.75rem;
            h3 {
                margin-bottom: 0;
            }
            p {
                padding-right: $width-gap/4;
            }
        }
    }

    .panel__1 {
        background: $color-yellow;

        @media (max-width: 980px) and (min-width: 768px) {
            width: 100%;
        }
    }
    .panel__2 {
        background: $color-background;

        @media (max-width: 980px) and (min-width: 768px) {
            width: 100%;
        }
    }
    .panel.panel__1 > .panel__image {
        &::after {
            background-color: transparent;
        }
    }
}
