.custom__select-holder {
    position: relative;

    &:focus {
        outline: none;
    }
}

.custom__select-dropdown {
    position: relative;
    display: none;
    background: lighten($color-line, 3.25%);
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 20;
    max-height: 14rem;
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    z-index: 25;
    border: 3px solid $color-grey;
    border-top: none;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
    .custom__select-open + & {
        display: block;
    }
}

.custom__option,
.custom__select-toggle {
    color: inherit;
    cursor: pointer;
    padding: $width-gap * 0.5 $width-gap;
    position: relative;

    + .custom__option {
        border-top: 1px solid $color-grey;
    }

    &:hover {
        background: rgba($color-primary, 0.0625);
    }
}

#tags-search + .custom__option-group {
    margin-top: 1rem;
}

.custom__option-group .tag {
    margin: 0;
    flex-shrink: 1;
}

.custom__option-group {
    margin-bottom: $width-gap * 2;
}

.custom__select-toggle-text {
    padding: $width-gap * 0.5 $width-gap;
    border: 3px solid $color-action;
    border-right: none;
    width: 100%;
}

.toggle-icon__holder {
    border: 3px solid $color-action;
    border-left: none;
    flex-shrink: 0;
    width: 3.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom__tag-selected {
    margin-top: 0.5rem;

    .tag {
        margin-right: 5px;
    }
}

.custom__select-toggle {
    position: relative;
    display: flex;
    background: #fff;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    border-radius: $width-radius;
    background: #fff;
    vertical-align: top;
    line-height: inherit;
    z-index: 20; // needs to be lower than the Churches dropdown

    &:focus {
        border-color: darken($color-grey, 15%);
    }

    .icon {
        color: $color-action;
        width: 1.25rem;
        height: 1.25rem;
    }

    .toggle-close {
        display: none;
    }

    &.custom__select-open {
        border-bottom-color: darken($color-grey, 15%);

        .toggle-open {
            display: none;
        }
        .toggle-close {
            display: block;
        }
    }

    &:focus {
        outline: none;
    }
}

.mediumeditorwidget {
    min-height: 10em;
    outline: none;
    width: 100%;
    padding: 0.75rem 1rem;
    -webkit-font-smoothing: antialiased;
    border: 3px solid #e4e4e4;
}

.custom__select-button-holder {
    // holds a custom select and a button side by side
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    .btn {
        margin-left: auto;
    }
}


// Custom styling for multiple select (used in Service Register events field)

.listselect2multiple {
    .select2-selection__clear {
        display: none;
    }

    .select2-selection__rendered {
        padding: 0 !important;
    }

    .select2-selection__choice {
        max-width: 100%;
        white-space: normal;
    }

    .select2-search__field {
        border-right: 0 !important;
    }
}

.select2-search.select2-search--inline {
    width: calc(100% - 3.25rem) !important; // 3.25rem is the width of the search icon
    margin: 0.5rem 0 0 0;
}

.select2-search__field {
    width: 100% !important;
    margin-top: 0 !important;
}

.select2-container--default .select2-selection--multiple {
    border: 0 !important;
}
