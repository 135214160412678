@mixin visually-hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

.visually-hidden {
    @include visually-hidden;
}
.m-bottom {
    margin-bottom: 2rem;
}
