.mt0 {
    margin-top: 0;
}
.mt1 {
    margin-top: 1rem;
}
.mb0 {
    margin-bottom: 0;
}
.mb1 {
    margin-bottom: 1rem;
}
.mt1 {
    margin-top: 1rem;
}
.mr1 {
    margin-right: 1rem;
}
.height-auto {
    height: auto !important;
}

.thicker-border-bottom {
    border-bottom: 10px solid $color-grey !important;
}

.thicker-border-top {
    border-top: 10px solid $color-grey !important;
}

.no-scroll {
    overflow: hidden;
}
