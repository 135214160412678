.video-select {
    display: grid;
    gap: 1rem;

    @include mobile {
        grid-template-columns: 1fr 1fr;
    }

    @include desktop-large {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.video-select__item {
    label {
        display: flex !important;
        text-transform: none !important;
        font-size: 1rem !important;
        margin-top: 0.75rem;
    }
}

.video-select__control {
    width: 1.5rem;
    height: 1.5rem;
    border: 3px solid $color-grey;
    margin-right: $width-gap*0.75;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        display: none;
        color: $color-primary;
    }
}

.video-select__item input:checked {
    + .video-select__control {
        .icon { display: block; }
    }
}
