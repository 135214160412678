.account-page__wrapper {
    padding: 1rem;
    background: $color-background-lighter-grey;

    > .container--white {
        background-color: $color-white;
    }

    .main-content {
        padding: 0 $width-gap $width-gap*1.5 $width-gap;
    }

    + .page-footer {
        margin-top: 0;
    }
}
