.map__marker {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: $base-headline-font;
    font-size: 0.875rem;
    cursor: pointer;

    &:before {
        content: '';
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        z-index: -5;
        background: $color-primary;
    }

    &.school:before {
        background: $color-tertiary;
    }

    &.active,
    &.venue {
        font-size: 0;
        z-index: 1000;

        &:before {
            width: 2rem;
            height: 2rem;
            background: #fff;
            border: 3px solid $color-text;
        }

        &:after {
            position: absolute;
            width: 2rem;
            height: 2rem;
            content: '';
            background: url(../img/cross.svg) center center/contain no-repeat;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 2.25rem;
            color: $color-text;
        }
    }
    &.school.active:after{
        width: 1.25rem;
        height: 1.25rem;
        background: url(../img/school.svg) center center/contain no-repeat;
    }
    &.small {
        &:before {
            width: 1.375rem;
            height: 1.375rem;
        }
        &:after {
            font-size: 1.5rem;
            font-weight: bold;
        }
    }
}
