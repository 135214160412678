.big-survey {
    margin: 4.625rem 0 9rem 0;
    background-color: $color-background-lighter-grey;

    .page-header {
        display: none;
    }

    .page-footer {
        display: none;
    }

    .container.page-content {
        padding-left: 0;
        padding-right: 0;
    }
}

.big-survey__header,
.big-survey .alert-block {
    margin: 0 1rem 2rem;
    padding: 2rem 0.75rem 1.25rem;
    background-color: $color-background-light;

    @media all and (min-width: 22rem) {
        padding: 2rem 1.75rem 1.25rem;
    }

    @media all and (min-width: 70rem) {
        max-width: 61.5rem;
        margin-left: auto;
        margin-right: auto;
    }

    p {
        margin-top: 1rem !important;
    }
}

.big-survey__header--report {
    position: relative;
    text-align: center;

    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 13.375rem;
        left: 50%;
        bottom: 0;
        height: 5px;
        background: $color-action;
        transform: translateX(-50%);
    }
}

.big-survey-section,
.big-survey-section-group-intro,
.big-survey fieldset {
    margin: 0 1rem 2rem;
    padding: 2rem 1.5rem;
    border: none;
    background-color: $color-background-light;

    @media all and (min-width: 70rem) {
        max-width: 61.5rem;
        margin-left: auto;
        margin-right: auto;
    }
}

.big-survey-section-group-intro {
    margin-bottom: 0;
    font-size: 1.4375rem;
    line-height: 1.2;
}

.big-survey-section-group-intro--short {
    padding-bottom: 0;
}

.big-survey-section__content,
.big-survey-section-group-intro__content,
.big-survey .control-group,
.big-survey .controls,
.big-survey p,
.big-survey fieldset > legend,
.big-survey__submit-button-wrapper {
    max-width: 58.5rem;
    margin: auto;
}

.big-survey__submit-button-wrapper {
    display: flex;
}

.big-survey__submit-button {
    margin-left: auto;
}

.big-survey__no-chart {
    text-align: center;
    font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
    h5 {
        font-size: 18px;
    }
}
