@import 'benefice_map';

.benefice__logo {
    background: $color-white;
    padding: 2rem;

    img {
        max-height: 12rem;
        width: auto;
        margin: 0 auto;
        display: block;
    }

    // From 541px upwards the sidebar logo is embedded into the header so hide this one
    @media all and (min-width: 541px) {
        display: none;
    }
}

// The benefice events are laid out in a way that on mobile the ordering is incorrect,
// so we hide the event in the right hand column on mobile and show it under the other two
.benefice__event--hide-mobile {
    display: none;

    @media all and (min-width: 768px) {
        display: block;
    }
}

.benefice__event--show-mobile {
    // This uses visually-hidden so that the event is still accessible to screen readers in a
    // place that makes sense in the flow of the document. The right hand column event has
    // aria-hidden="true" set on it so it is ignored by screen readers.
    @media all and (min-width: 768px) {
        @include visually-hidden;
    }
}
