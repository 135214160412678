.resource-page__wrapper {
    background: $color-background-lighter-grey;

    .main-content {
        @include desktop {
            padding: 0 0 0 1.25rem;
        }

        height: 100%;
    }

    .side-content {
        background: none;
        width: 24rem;
    }

    .page-layout::after {
        content: none;
    }

    .pebble-trail {
        color: $color-text;
    }

    .billboard__panel {
        margin-top: 0 !important;
        margin-bottom: 0;
    }
}

.resourcehub_cat_icon {
    width: 0.5em;
    height: 0.5em;
    margin-top: 8px;
    margin-left: 6px;
}

.resourcehub-list__toggle:checked {

    ~ .navlist__item-title label {

        .open { display: none; }

        .close { display: inline-block; }
    }

    ~ .nested__list .resourcehub_child {
        display: block !important;
    }
}

.resourcehub-select {
    img {
        width: auto;
        float: left;
        padding-right: 14px;
    }
}

.asset-page__files {
    display: flex;
    flex-flow: row wrap;
    @include mobile{
        width: 70%;
        margin-right: 1rem;
    }
}

.asset-page__file-terms {
    padding: $width-gap/2 $width-gap;
    background: #fff;

    .checkbox {
        display: flex;
        font-size: inherit;
        text-transform: none;

        .custom__checkbox,
        .custom__checkbox .icon {
            border-color: $color-grey-light;
            color: $color-active;
        }

        span {
            margin-left: $width-gap*0.75;
        }
        font-size: 0.75rem;
    }

    @media all and (min-width: 1240px) {
        padding: $width-gap/2;
    }

    .asset-page__file-selected {
        display: flex;
        .checkbox {
            font-size: 1.125rem;
        }
        span {
            width: 80%;
            margin-left: 0rem;
        }
    }
}

.asset-page__file {
    display: block;
    padding: 0 $width-gap*2;
    width: 12.5rem;
    margin-right: 1.35rem;

    .asset-page__file-checkbox {
        display: flex;
        width: 12.5rem;
        padding: $width-gap/2 $width-gap/2 $width-gap/2 0;

        .custom__checkbox {
            margin-right: 0;
        }
        label.checkbox{
            width: 2rem;
        }
        .icon{
            width: 1.5rem;
            height: 1.5rem;
            color: $color-grey-light;
        }
    }

    .asset-page__file-label {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 $width-gap/2 $width-gap/2 $width-gap/2;
        text-decoration: none;
        text-transform: none;
        font-size: 0.75rem;
        line-height: 16px;
    }

    + .asset-page__file {
        border-top: 2px solid $color-line;
    }

    @media all and (min-width: 1240px) {
        padding: 0;

        .asset-page__file-checkbox {
            padding: $width-gap/2;
        }
    }
}

.asset-page__file-preview {
    width: 12.5rem;
    height: 12.5rem;
    cursor: zoom-in;
    position: relative;
    border-left: 2px solid $color-line;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        background: rgba(#000000, 0.1);
        z-index: 0;
        transition: background 0.2s ease-in-out;
    }

    &:hover::before {
        background: rgba(#ffffff, 0.7);
    }
}

.page-files__panel {
    width: 20rem;
    height: 30rem;
        @include mobile {
            position: sticky;
            top: 0;
            right: 1rem;
            margin-top: -2rem;
        }
    a.asset-page__files-download,
    button.asset-page__files-download {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        width: 100%;
        padding: $width-gap/2 $width-gap*2 $width-gap/2 $width-gap*1.75;
        min-height: 2.875rem;
        font-size: 1.3125rem;


        .icon {
            width: 1em;
            height: 1em;
            margin-right: $width-gap/2
        }

        @media all and (min-width: 1240px) {
            padding: $width-gap/2;
        }
    }
    input:disabled + label, button:disabled {
        opacity: 0.5;
    }
    input + label {
        opacity: 1;
    }
}

.asset-detail {

    .billboard__panel {
        min-height: 9rem;
    }
    .content__panel {
        position: relative;
    }
}
.asset-page__files-download-panel {
    background: $color-action;
    h4{
        padding: 1rem 0.635rem 0 0.635rem;
    }
}

.asset-page__form {
    @include mobile{
        overflow-x: unset;
        height: 100%;
        display: flex;
        margin-right: -2rem;
    }
}

.asset-page__message {
    font-size: 0.75rem;
}

.page__wrapper.asset-page {
    overflow: unset;
}
.asset__navigation .navlist {

    .navlist__item {
        display: block;
        & .indented__nav{
            .resource-nav__toggle{
                right: -0.35rem;
            }
        }
        &:hover .indented__nav a {
            color: $color-text;
        }
    }

    .navlist__item-title{
        margin-right: 2rem;
    }
}

.navlist .navlist__item .nested__list {
    display: none;
    margin: 0.5rem -0.75rem -0.75rem;
    background-color: $color-white;

    .navlist__item {
        background-color: $color-white;
        color: $color-text;
        border-top: 0 !important;

        .icon {
            fill: $color-grey-light !important;
        }

        &:hover {
            background: none;
        }
    }

    &[aria-hidden='false'] {
        display: block;
    }

    @include desktop {
        margin: 0.5rem -1.625rem -0.75rem -1.625rem;
    }
}

.nested__list{
    .navlist__item:last-of-type{
        border-bottom: none !important;
    }
}

.resource-nav__toggle {
    display: inline-flex;
    position: absolute;
    top: 1rem;
    right: 0.2rem;
    outline: none;
    background: none;
    border: none;
    .resource-nav__toggle-icon-close,
    .resource-nav__toggle-icon.icon {
        position: absolute;
        right: 0.5rem;
        top: 0.15rem;
        display: none;
        padding-left: 0.5rem;
        height: 0.75rem;
        transition: all 0.5s ease-in-out;
        fill: $color-grey-light;
        stroke: $color-grey-light;
    }
    .resource-nav__toggle-icon.icon {
        transition: all 0.2s ease-in-out;
        display: block;
    }
    &[aria-expanded='true']{
        .resource-nav__toggle-icon-close {
            display: block;
        }
        .resource-nav__toggle-icon.icon {
            display: none;
        }
    }

}

.resource-nav__toggle--full-width {
    display: block;
    width: 100%;
    text-align: left;
    padding-left: 0;
    padding-right: 0;

    font-family: $base-headline-font;
    color: $color-text;
    font-size: 1.286rem;

    position: relative;
    top: 0;
    right: 0 !important;

    .icon {
        top: 0.35rem !important;
        margin-right: 0;

        @include desktop {
            right: -0.45rem !important;
        }
    }
}

.resource-nav__subnav-icon {
    position: absolute;
    left: -2rem;
    top: 0.35rem;
    height: 1rem;
    width: 1rem;
    fill: $color-grey-light;
}

.content__panel.video-generator {
    padding: 2rem !important;
}

.generate-video__wrapper .content__panel.video-generator.video-examples {
    min-height: 20rem;
}

.video-examples__grid {
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    img {
        max-width: calc(50% - 1.5rem);
        min-width: calc(25% - 1.5rem);
        margin: 0 auto 2.5rem;
        @include mobile {
            margin: 0 0 2.5rem;
        }
    }
    @include mobile {
        img:nth-of-type(odd){
            margin-right: 1.25rem
        }
        img:nth-of-type(even){
            margin-left: 1.25rem;
        }
    }
}
.menu-grid {
    @include mobile {
        display: flex;
        flex-flow: row wrap;
        padding-bottom: 2.5rem;
    }
}

.menu-intro {
    margin-bottom: 2.1875rem;
}

.menu-grid__item {
    padding-bottom: 2.1875rem;

    margin-right: 2.25rem;

    @include mobile {
        width: calc(50% - 1.125rem);
    }

    .image-container {
        position: relative;
        display: block;

        img {
            display: block;
            width: 100%;
            object-fit: cover;
        }
    }

    .action {
        position: absolute;
        bottom:0;
        right: 0;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
          .icon {
              width: 1.5rem;
              height: 1.5rem;
              fill: #4A4A4A;
          }
    }

    h3 {
        font-size: 1.4375rem;
        margin-top: 0.75rem;
    }
}

.menu-grid__item:nth-of-type(2n) {
    margin-right: 0;

    &:not(.menu-grid__item--christmas) {
        @include mobile {
            margin-right: 2.25rem;
        }
    }
}

.menu-grid__item {
    &:not(.menu-grid__item--christmas) {
        &:first-of-type {
            @include mobile {
                width: 100%;
                display: flex;
                flex-flow: row nowrap;
                margin-bottom: 3.25rem;

                h3 {
                    font-size: 2.25rem;
                    margin-top: 0;
                }

                .menu-grid__item-image, .menu-grid__item-text  {
                    width: calc(50% - 1.125rem);
                }

                .menu-grid__item-image + .menu-grid__item-text {
                    margin-left: 2.25rem;
                }
            }
        }
    }
}

.mobile-header {
    display: block;
    margin: 1rem;

    @include desktop {
        display: none;
    }
}
.unsplash {
    label[for=id_query]{
       @include visually-hidden;
    }
    form {
        display: flex;
        margin: 0 0 0.5rem 0;
        padding-top: 0;

        .buttonHolder{
            position: relative;

            .icon {
                position: absolute;
                left: 0.8rem;
                right: 0;
                top: 0.8rem;
                bottom: 0;
                width: 2rem;
                height: 2rem;
                color: white;
                pointer-events: none;
            }
        }
        div:first-child{
            width: calc(100% - 2.5rem);
            margin-right: 0.5rem;
        }
        #div_id_query{
            width: 100%;
        }
    }
    .resource-grid-item {
        border: none;
        background: none;
    }
    .resource-grid-item__text{
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
            font-size: 0.75rem;
            line-height: 1.2;
            text-align:left;
        }

        .icon {
            height: 1.5em;
            width: 1.5em;
            margin-top: -0.5rem;
            color: $color-grey-light;
        }
    }
    .resource-grid {
        display: grid;
        grid-gap: 1.5vw;
        margin: 0 0 1rem 0;
        grid-template-columns: repeat(auto-fit, minmax(200px, 4fr));

        .js-grid-gutter {
            @include mobile {
                width: $width-gap;
            }
        }
        .js-grid-sizer {
            width: 100%;

            @include mobile {
                width: calc(50% - #{$width-gap/2});
            }
        }
    }

    .resource-grid-item {
        width: 100%;

        @include mobile {
            width: calc(25% - #{$width-gap/2});
        }
    }
    .content__divider {
        margin: 2rem -1rem;
    }
}
