.big-survey-chart {
    width: 100%;
    height: 25rem;
    padding: 0 0 2rem;
}

.big-survey-chart--height--tall {
    height: 37.5rem;
}

.big-survey-chart--height--very-tall {
    height: 52.5rem;
}

.big-survey-table {
    width: 100%;
    /* Adapted from Highcharts styles */
    font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif !important;
    margin: 0.5rem 0;
    padding: 0 0 2rem;
    text-align: center;
    &> h1, &>.header {
        color: #333333;
        font-family: inherit;
        font-size: 18px;
    }

    small {
        color: #666666;
        font-size: 12px;
    }

    table {
        text-align: left;
        width: 100%;
        margin: 0 auto;
        border-spacing: 1rem;
        td {
        }
        .big-survey-table__headers {
            th {
                &:nth-child(even) {
                    border-bottom: 1px solid $color-primary-dark;
                }
                &:nth-child(odd) {
                    border-bottom: 1px solid $color-grey-light;
                }
                &:nth-child(1) {
                    width: 70%;
                    border-bottom: none;
                }
            }
            &.no-labels {
                th {
                    border-bottom: 1px solid $color-primary-dark;
                    &:nth-child(1) {
                        width: 50%;
                    }
                }
            }
        }
        .big-survey-table__data {
            &:nth-child(odd) {
                td {
                    &:nth-child(2) {
                        outline: 0.5rem solid $color-primary-light;
                        background-color: $color-primary-light;
                    }
                    &:nth-child(3) {
                        outline: 0.5rem solid $color-grey;
                        background-color: $color-grey;
                    }
                }
            }
        }
    }
    @include desktop {
        table, td, .big-survey-table__headers th {
            padding: 0 1rem;
        }
    }
}
