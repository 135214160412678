.notification__number {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    border-radius: 50%;
    background: $color-error;
    color: #fff;
    text-align: center;
    font-size: 1rem;
    margin-left: $width-gap/2;
}
