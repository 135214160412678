body.lightbox-open {
    overflow: hidden;
}

#lightbox {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0; right: 0; bottom: 0; left: 0;
    align-items: center;
    justify-content: center;

    #lightbox_backdrop {
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: -10;
        background: rgba(#fff, 0.95);
        cursor: pointer;
    }

    #lightbox_close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 2rem;
        color: $color-text;
        text-decoration: none;
        user-select: none;
        pointer-events: none;
        &.lightbox_close--event {
            .lightbox_close_mobile {
                display: none;
            }
            @include smaller-than-desktop {
                top: 0.5rem;
                right: unset;
                left: $width-gap;
                pointer-events: initial;
                z-index: 10;
                .lightbox_close_mobile {
                    display: inline;
                    font-size: 0.875rem;
                    .icon {
                        margin: -0.125rem 0.5rem 0 0;
                        vertical-align: middle;
                    }
                }
                .lightbox_close_desktop {
                    display: none;
                }
            }
        }
    }

    .button__holder {
        z-index: 2000 !important; // must have higher z-index than lightbox
    }
}

#lightbox_content {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    overflow-y: scroll;
    background: #fff;

    .button-sticky {
        display: none;
    }

    @include desktop {
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        max-width: 37.5rem;
        width: calc(100vw - #{$width-gap*3});
        height: auto;
        max-height: calc(100vh - #{$width-gap*8});
        border: 1px solid $color-grey;
        box-shadow: 0px 0px 1.5rem 0.5rem $color-grey;
        overflow-y: unset;

        .button__holder.mobile {
            display: none !important;
        }

        .button-sticky {
            display: inline-block;
        }
    }

}

#lightbox_append_target {
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: $width-gap;

    @include desktop {
        padding: $width-gap*2;
    }

    .lightbox_content--event > & {
        padding: 0;
        @include smaller-than-desktop {
            height: 100%;
        }
    }
}
