.search__bar {
    display: flex;
    flex-flow: row nowrap;
    border: 3px solid $color-action;
    width: 100%;

    .icon {
        width: 1.5em;
        height: 1.5em;
        flex-shrink: 0;
    }

    > .icon {
        height: 3rem;
        width: 3rem;
        padding: 0.75rem;
    }
}

.search__form-holder {
    position: relative;
}

.search__form {
    small {
        margin-top: $width-gap/2;
        display: block;
        line-height: 1.5;
    }

    input,
    button {
        width: 100%;
        border: none;
        appearance: none;
        width: 100%;
        padding: $width-gap * 0.75 $width-gap $width-gap * 0.75 0;
        vertical-align: top;
        font-size: 16px;
        font-family: inherit;
        -webkit-font-smoothing: antialiased;
        line-height: 1;
        border-radius: 0;

        &:focus {
            outline: none;
        }
    }
}

.search__form-section {
    width: 100%;
}

.search__form-section + .search__form-section {
    margin-top: $width-gap;
}

@media all and (min-width: 541px) {
    .search__form-section {
        width: calc(50% - 2rem);
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: $width-gap/4;

        + .search__form-section {
            margin-top: 0;
        }

        &.tags-disabled {
            width: calc(100% - 4rem);
        }
    }
}

@media all and (min-width: 981px) {
    .side-content {
        .search__form-header {
            display: none;
        }

        .search__form-body {
            padding-right: $width-gap;
            flex-flow: row wrap;
        }

        .search__form-section {
            margin-bottom: $width-gap;
            width: 100%;
        }

        .search__form-section,
        .search__form-button {
            button {
                display: flex;
                width: 100%;
                justify-content: center;

                span {
                    display: block;
                }
            }
        }

        .search__form-button {
            position: relative;
            padding-right: $width-gap;
        }
    }
}

.search__form-button {
    flex-shrink: 1;
    flex-grow: 0;
    margin-top: $width-gap;

    button {
        width: 100%;
        border: none;
        appearance: none;
        width: 100%;
        vertical-align: top;
        font-size: 16px;
        font-family: inherit;
        -webkit-font-smoothing: antialiased;
        line-height: 1;
        border-radius: 0;
        text-align: center;
        padding: calc(#{$width-gap} + 2px) 0;

        span {
            margin-right: $width-gap/2;
        }

        .icon {
            vertical-align: middle;
            margin-right: 0.25rem;
        }

        &:focus {
            outline: none;
        }
    }

    @media all and (min-width: 541px) {
        margin-top: 0;
        position: absolute;
        right: 0.125rem;
        bottom: 0;

        span {
            display: none;
        }

        button {
            width: 3.5rem;
        }
    }
}

.search__form--empty .search__form-button {
    @media all and (min-width: 541px) {
        top: 6rem;
    }
    @media all and (min-width: 569px) {
        top: 3.2rem;
    }
}

.search__form-header {
    display: none;

    @media all and (min-width: 541px) {
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: $width-gap/2;

        h3 {
            width: calc(50% - 2.5rem);
            margin: 0;

            + h3 {
                margin-left: $width-gap/4;
            }

            &.tags-disabled {
                width: calc(100% - 2.5rem);
                margin-left: $width-gap/4;
            }
        }
    }
}

.home .search__form-header {
    display: block;
}

.search__form-subtitle {
    margin: 1rem 0.5rem 1.5rem 0.5rem;

    @include desktop {
        margin-bottom: 1rem;
    }
}

.search__tags-holder {
    position: relative;
}

.search__form-body {
    display: flex;
    flex-flow: row wrap;

    @media all and (min-width: 541px) {
        flex-flow: row nowrap;
    }
}

@media all and (max-width: 540px) {
    body.home .search__form-body {
        .search__form-section {
            display: none;

            &.tags-disabled {
                display: block
            }

            + .search__form-section {
                display: block;
            }
        }
    }
}

.ui-autocomplete {
    list-style: none;
    padding: 0;
    margin: 0;
    background: $color-line;
    position: absolute;
    top: 100%;
    left: -3px !important;
    width: calc(100% + 6px) !important;
    top: calc(100% + 3px) !important;
    font-family: $base-font;
    font-size: 13px;
    color: black;
    font-weight: bold;
    max-height: 12.875rem;
    overflow: auto;
    z-index: 200;

    li {
        padding-left: calc(#{$width-gap} + 3px);
        cursor: pointer;
        line-height: 30px;

        &:hover,
        &:focus {
            background: #fafafa;
        }

        + li {
            border-top: 3px solid $color-grey;
        }
    }
}

#acny_search_tags {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-right: $width-gap/2;

    .taggle_placeholder {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        color: rgba($color-text, 0.8);
    }

    .taggle_list {
        width: 100%;
        display: flex;
        flex-shrink: 0;
        flex-flow: row wrap;
        min-width: 0;
        overflow: hidden;
        list-style: none;
        margin: 0;
        padding: 0;
        align-items: flex-start;
        padding: $width-gap * 0.625 $width-gap 0.25rem 0;
    }

    input {
        display: inline-block;
        float: left;
        padding: $width-gap/4 0;
        margin-bottom: $width-gap/4;
        line-height: 1;
        width: 10px !important;

        &:focus {
            width: auto !important;
        }
    }

    .taggle_sizer {
        pointer-events: none;
        opacity: 0;
        position: absolute;
    }

    li,
    .taggle {
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: $width-gap/4;
        vertical-align: top;
        overflow: hidden;
    }

    .taggle {
        padding: $width-gap/6 $width-gap/2;
        margin-bottom: $width-gap/4;
    }

    .taggle {
        background: $color-primary;
        color: #fff;

        &.custom {
            background: $color-grey;
            color: $color-text;

            button {
                color: inherit;
            }
        }

        button:hover {
            color: $color-error;
        }

        &.taggle_hot {
            background: $color-grey-light;

            button {
                color: $color-error;
            }
        }
    }

    .close {
        padding: 0;
        background: none;
        width: auto;
        color: #fff;
        margin-left: $width-gap/2;
    }
}

.ui-helper-hidden-accessible {
    display: none;
}

.search__form-row {
    display: flex;
    flex-flow: row nowrap;

    button {
        border: 3px solid $color-action;
    }
}

.legend__bar {
    padding: $width-gap/2 $width-gap * 2;
    background: #fff;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-bottom: 3px solid $color-line;
    line-height: 1;

    .church__legend,
    .school__legend {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-right: $width-gap * 2;
        span {
            display: inline-block;
        }
        &:last-child {
            margin-right: 0;
        }
        @media all and (min-width: 980px) and (max-width: 1080px) {
            font-size: 0.75rem;
            margin-right: $width-gap * 1.5;
        }
        @media all and (max-width: 540px) {
            font-size: 0.75rem;
            margin-right: $width-gap/2;
        }
    }

    .map__marker.legend {
        position: relative;
        transform: none;
        margin-right: $width-gap * 1.25;
        z-index: 50;

        &:before {
            width: 0.75rem;
            height: 0.75rem;
        }

        &:after {
            font-size: 0.875rem;
        }
    }

    .map__marker.pin {
        position: relative;
        transform: none;
        margin-right: $width-gap * 1.25;
        z-index: 50;
        &:before {
            width: 0.75rem;
            height: 0.75rem;
        }
        &:after {
            font-size: 0.875rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }

    .list__count {
        margin: 0 $width-gap/4 0 0;
        padding: 0;
        position: relative;
        &:before {
            position: static;
            width: 0.675rem;
            height: 0.675rem;
            transform: rotate(45deg);
        }
        &:after {
            content: "";
            position: static;
            background: url(../icons/svg/cross.svg) center/contain no-repeat;
            width: 1.75rem;
            height: 1.75rem;
            padding-left: 4px;
        }
        &.school {
            background: none;
            &:before {
                background: $color-tertiary;
                border: 3px solid $color-tertiary;
            }
            &:after {
                background: url(../icons/svg/school.svg) center/contain
                    no-repeat;
                width: 1.75rem;
                height: 1.75rem;
                padding-left: 4px;
            }
        }
    }
}

.search__list #search_detail_holder {
    display: none;
}

#search_detail {
    .button + .button {
        margin-top: 1rem;
    }
}

.search__result {
    .list__count {
        margin: 0;
    }
    .list__count-holder {
        margin: 0;
        width: 2.75rem;
        margin-right: $width-gap * 0.75;
        text-align: center;
        flex-shrink: 0;
    }
    &.result__school {
        .school-report-btn {
            display: block !important;
            width: 14rem;
            margin: 0.5rem 0;
            text-align: center;
        }
    }
}

.side-content__results {
    padding: $width-gap 0;
    border-top: 3px solid $color-line;

    h3,
    .h3 {
        margin-bottom: 0;
    }

    @media all and (min-width: 768px) {
        padding: $width-gap $width-gap $width-gap 0;
    }
}

.filter__list {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    margin-top: $width-gap/4;
    font-family: $base-headline-font;
}

.filter,
.add__filter {
    flex-shrink: 0;
    display: inline-block;
    padding: 0 $width-gap/2;
    background: $color-action;
    margin: 0 2px 2px 0;
    color: #fff;
}

.add__filter {
    background: $color-action-light;
    color: $color-text;
}
