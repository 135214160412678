.custom__radio {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: 0.15rem solid $color-grey;
    overflow: visible;
    margin-right: 1.5rem;
    flex-shrink: 0;
}

input[type=radio]:checked ~ label{
     .custom__radio:after{
        content: " ";
        display: block;
        width: 0.8rem;
        height: 0.8rem;
        background: $color-active;
        margin: 16% auto;
        border-radius: 50%;
    }
}

