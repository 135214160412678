.page__logo {
    width: 15rem;
    height: 3.125rem;
    background: url('../img/logo.jpg') center center/contain no-repeat;
    body.admin-view & {
        width: 13.125rem;
        height: 3rem;
    }
}

.acny__logo {
    width: 8rem;
    height: 3.125rem;
    background: url('../img/acny_logo.svg') center center/contain no-repeat;
}

.church__logo {
    width: 15rem;
    height: 3.125rem;
}

.logo {
    margin: 1rem 0;
}

.acny-as-homepage__logo {
    width: 4rem;
    height: 4.5rem;
}

.acny-as-homepage__main-logo {
    display: flex;
    width: 15rem;
    height: 3.125rem;
    background: url('../img/logo.jpg') center center/contain no-repeat;
    margin: 1rem auto;
}
.acny-as-homepage__logo-holder{
    background: $color-white;

}
