//// CONTAINERS

.container {
    width: 100%;
    max-width: $width-container;
    margin: auto;
    padding: 0 1rem;

    &.wide {
        max-width: 60rem;
    }

    &.narrow {
        max-width: 37.5rem !important;

        &.awesomness {
            max-width: 50rem;
        }
    }

    &.padded {
        padding: $width-gap * 1.5 1rem !important;
    }

    .extra-padded {
        padding: $width-gap * 2 !important;
        @include desktop{
            padding: $width-gap * 5 !important;
        }
    }

    .no-padding {
        padding: 0 !important;
    }

    &.flex-on-mobile {
        display: flex;
        flex-flow: column nowrap;

        @include desktop {
            display: block;
        }
    }
}

.flatpage {
    max-width: 860px;
    padding: 0 1rem 5rem 1rem;

    .pebble-trail {
        color: $color-text;
    }
}

.page-layout {
    display: flex;
    flex-flow: column nowrap;
    padding-top: $width-gap;
    position: relative;

    @media all and (min-width: 981px) {
        flex-flow: row nowrap;
        padding: 0 $page-layout-desktop-padding-right 0 0;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            background: #fff;
            left: calc(0.5 * (-100vw + #{$width-container}));
            width: calc(0.5 * (100vw - #{$width-container}));
        }
        body.admin-view &,
        .acny-as-homepage__wrapper & {
            &:after{
                content: none;
            }
        }
    }

    &.loading {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
            width: 100vw;
            background: #fff;
            align-items: center;
            justify-content: center;
            z-index: 10000;
        }

        &:after {
            content: "";
            display: inline-block;
            z-index: 15000;
            width: 8rem;
            height: 8rem;
            background: url(../img/loader.svg) center center/contain no-repeat;
            position: absolute;
            top: 40vh;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.acny-as-homepage__container {
        padding: 0;
    }
}

//// LAYOUT

.page__wrapper {
    width: 100%;
    overflow: hidden;
}

.church-page__wrapper {
    background: $color-primary-dark;

    &.acny-as-homepage__wrapper {
        background: $color-background-lighter-grey;
        padding: 1.25rem 2.25rem 1.375rem;
    }

    body.admin-view & {
        background: $color-grey;
    }
}

.side-content {
    position: relative;
}

.page-content {
    padding-bottom: 1rem;
}

@media all and (min-width: 981px) {
    .side-content {
        width: $width-side-content;
        flex-shrink: 0;
        padding: 0 0 $width-gap $width-gap;
        background: #fff;

        &.acny-as-homepage {
            margin-top: 20rem;
        }
        .admin-view & {
            padding:0;
            background: none;
            width: 23.75rem;
        }
    }

    .search-layout .side-content {
        width: $width-side-content * 1.375;
    }
}

.two__column {
    margin-top: $width-gap * 2;

    .two__column-column:first-of-type {
        min-height: 15rem;
        margin-bottom: $width-gap * 1.5;
    }

    @media all and (min-width: 768px) {
        display: flex;
        flex-flow: row nowrap;
        width: calc(100% + 1.5rem);
        margin: 2rem 0 0 -0.75rem;

        .two__column-column {
            width: 50%;
            margin: 0 $width-gap * 0.75;

            &:first-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.main-content__intro {
    margin-top: $width-gap * 1.5;
}

.main-content {
    padding-bottom: $width-gap * 1.5;

    > div:first-child {
        margin-top: $width-gap * 2;

        @media all and (min-width: 981px) {
            margin-top: 0;
        }
    }

    .accounts & {
        > div:first-child {
            margin-top: 0;
        }
    }

    @media all and (max-width: 540px) {
        .church-detail .has-church-image & {
            .billboard__panel + .panel__grid {
                padding-top: 0;
                margin-top: $width-gap * 2;

                .event__panel .panel__content {
                    padding-top: $width-gap * 7;
                }
            }
        }
    }

    &.church-page__main {
        padding: 0;
        margin-bottom: 6rem;
        @media all and (min-width: 981px) {
            padding: 1rem 0 1rem 1rem;
        }
    }
}

@include desktop {
    .main-content {
        width: 100%;
        padding: $width-gap 0 $width-gap $width-gap;
        position: relative;
        min-width: 0;

        &.acny-as-homepage__main {
            padding: 0 0 0 1.25rem;
        }
    }
    .acny-as-homepage__main-content-wrapper {
        display: flex;
        width: 100%;

        .acny-as-homepage__side-content-wrapper {
            display: flex;
            width: 100%;

            .side-content {
                width: 24rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }

        .acny-as-homepage__mobile {
            @include desktop {
                display: none;
            }
        }
    }
}

.m-0 {
    margin: 0 !important;
}

.h-100 {
    height: 100%;
}

.content__divider {
    height: 2rem;
    background: rgb(228, 228, 228);
}

//// PANELS

.content__panel {
    background: $color-background-light;
    margin-top: $width-gap;
    padding: 0;

    @include desktop {
        padding: 1rem !important;

        &#content-panel__deleteform {
            padding: 2rem !important;
        }

        &#content-panel__galleryform {
            padding: 2rem !important;
        }

        &#content-panel__findusform {
            padding: 2rem !important;
        }

        &#content-panel__event-detail {
            padding: 0 !important;
            background: none;
        }
    }

    .church-page__main &,
    .church-page__main &:first-child {
        @media (max-width: 980px) {
            margin-top: 0;
        }
    }

    + .content__panel {
        margin-top: $width-gap;
    }

    + .content__panel.bordered {
        margin-top: 0;
        border-top: $width-gap solid $color-grey;
        padding: 1rem;

        body.admin-view &,
        .acny-as-homepage__wrapper & {
            border-top: $width-gap solid $color-background-lighter-grey;
        }
    }

    .full__map {
        margin-left: -$width-gap * 2;
        width: calc(100% + #{$width-gap * 4});
    }

    &.intro__map {
        padding: 0 0 0 0 !important;
    }

    &.analytics {
        margin: 0;
        width: auto;
        .analytics__container {
            border-bottom: 3px solid $color-status-orange;
            padding-bottom: 0.5rem;

            @media all and (min-width: 500px) {
                display: inline-block;
                border-bottom: 3px solid $color-status-orange;
                div {
                    margin-right: 1.5rem;
                    display: inline-block;
                }
            }

            @media all and (min-width: 768px) {
                float: right;
                margin-bottom: 1rem;
            }
        }

        p {
            clear: both;
        }

        h2 {
            font-size: 1.4375rem;
        }
    }

    &.church__description {
        padding: $width-gap * 2 !important;
        font-size: 1.5rem;
        line-height: 1.5;
        overflow-wrap: break-word;

        hr {
            margin-bottom: 2.5rem;
            border-color: $color-line;
        }
    }

    .headline a {
        text-decoration: none;
    }

    @include desktop {
        padding: $width-gap * 2;
        margin-top: 0;

        + .content__panel {
            margin-top: $width-gap;
        }
    }
    &--benefice-form {
        padding: 0 !important;
    }
}

.stats__panel {
    margin-top: $width-gap;

    + .content__panel {
        margin-top: $width-gap;
    }

    @media all and (min-width: 768px) {
        margin-top: 0;
        padding: $width-gap $width-gap * 2;
        background: $color-primary;

        + .content__panel {
            margin-top: $width-gap;
        }
    }
}

.event_list__filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include smaller-than-desktop {
        padding: 0 0 $width-gap $width-gap;
    }
}

.event_list__select-events {
    .button.action {
        line-height: 1.5rem;
        color: #4a4a4a;
        display: flex;
        align-items: center;
        .icon {
            margin-left: 0.5rem;
            margin-right: 0;
        }
    }
    .button.action.delete {
        background-color: $color-error;
        background: $color-error;
        color: $color-white;
        line-height: 1.5rem;
        display: flex;
        align-items: center;
        &:hover {
            border-color: $color-error;
        }
    }
}
.event_list__single-action.button-row,
.event_list__multiple-action.button-row {
    margin-top: 0;
}

.card__edit--delete-event {
    width: auto;
    margin-top: 0rem;
    .custom__checkbox {
        margin-right: 0;

        .icon {
            fill: $color-text;
        }
        &:hover {
            border-color: $color-primary;
        }
    }
    input[type='checkbox']:focus + label {
        .custom__checkbox {
            border-color: $color-primary;
        }
    }
    input {
        position: absolute;
        left: -99999px;
    }
}

.event__panel {
    .panel__image {
        width: 100%;
        height: 12.5rem;
        margin-bottom: 0;
    }

    .headline {
        margin-top: $width-gap;
    }

    .panel__content {
        background: $color-background-light;
        padding: $width-gap $width-gap * 2 $width-gap * 2;
    }
}

.panel__column > * {
    margin-bottom: 1rem !important;
}

.action__panel {
    position: relative;
    margin-bottom: 1rem;
    background: $color-secondary;
    color: $color-background;
    padding: 2rem 1rem 2rem 2rem;

    h3 {
        margin: 0;
    }
    &-form {
        padding: 0!important;
    }

    .donate__description {
        display: flex;
        justify-content: space-between;
        margin-top: 0.5rem;

        .btn {
            margin-left: 1rem;
            flex-shrink: 0;
        }
    }
    .donate__donations {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        list-style-type: none;
        padding: 0;
        * + * {
            margin-left: 0.5rem;
        }
    }
    .donate__amount {
        list-style: none;
        background-color: $color-white;
        color: $color-text;
        padding: 1rem;
        position: relative;
        width: 50px;
        height: 40px;
        display: block;
        position: relative;
        margin-bottom: 1rem;
        input[type="radio"]:checked + label {
            background-color: $color-status-orange;
            top: 0px;
            line-height: 40px;
            color: white;
        }
        &:hover {
            background-color: $color-status-orange;
            cursor: pointer;
            color: white;
        }
    }
    .donate__amount label,
    .donate__amount input {
        display: block;
        position: absolute;
        top: 6px;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        font-family: "Open Sans";
        font-size: 16px;
        line-height: 26px;
        text-transform: none;
        text-align: center;
    }
    .donate__amount input[type="radio"] {
        opacity: 0.01;
        z-index: 100;
    }
    .donate__amount-custom {
        width: 160px;
        position: relative;
        label {
            visibility: hidden;
            height: 0;
        }
        input[type=number] {
            width: 130px;
            appearance: textfield !important;
            color: $color-text !important;
            height: 40px;
            border: none !important;
            position: relative;
            padding-left: 10px !important;
            &::placeholder {
                color: $color-text !important;
            }
        }
        /* Re-applies the controls on :hover and :focus */
        input[type="number"]:hover,
        input[type="number"]:focus {
            appearance: number-input !important;
        }
        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: auto !important;
        }
    }
}

.everydayfaith__panel {
    background: $color-primary url('../img/everydayfaith_cta.png') center/cover no-repeat;

    @include desktop-large {
        p {
            // This is to make the Donations and Everyday Faith panels the same size,
            // will need to be adjusted if length of text changes
            margin-bottom: 0.15rem;
        }
    }
}

.calendar__panel,
.calendar {
    background: $color-background-light;

    .calendar__header {
        padding: $width-gap * 2 $width-gap * 2 0 $width-gap * 2;
    }
}

.media__panel {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
}

.map__panel {
    position: relative;
    width: 100%;
    min-height: 17.5rem;
    margin-top: $width-gap;

    @media all and (min-width: 768px) {
        height: 100%;
        min-height: 100%;
        margin-top: 0;
    }
}

.panel__grid {
    margin-top: $width-gap * 2;

    .content__panel + & {
        margin-top: $width-gap;
    }

    @media all and (min-width: 768px) {
        display: flex;
        flex-flow: row wrap;
        margin-top: $width-gap;

        > div,
        > a {
            display: block;
            margin: 0 $width-gap $width-gap 0;
            width: calc(50% - #{$width-gap/2});

            &:nth-of-type(2n) {
                margin-right: 0;
            }
        }

        .media__panel {
            padding-bottom: calc(50% - #{$width-gap});
        }
    }

    @media all and (min-width: 981px) {
        margin-top: 0;

        .church-detail &,
        .event-detail & {
            margin-top: $width-gap;
        }
    }
}

.panel__split-holder {
    width: 100%;
    background: #fff;
    margin-top: $width-gap;
    overflow: hidden;

    .panel__split-small,
    .panel__split-large {
        padding: $width-gap * 2 $width-gap * 2 $width-gap * 3;
    }

    .panel__split {
        margin-top: 0;

        > div {
            margin-top: 0;
        }
    }

    &.border-panel {
        border-top: $width-gap solid $color-background-lighter-grey;
        margin-top: 0;

        .panel__split-small,
        .panel__split-large {
            margin-bottom: 0;
        }
    }

    @media all and (min-width: 1240px) {
        .panel__split {
            > div:first-of-type {
                border-right: 3px solid $color-line;
            }
        }

        .panel__split-small {
            width: 30%;
        }
        .panel__split-large {
            width: 70%;
        }
    }
}
.btn.next.panel__icon-btn {
    width: auto;
    .icon {
        right: 0;
        left: auto;
    }
}
.panel__icon-btn-text {
    font-size: 1rem;
    padding-right: 2rem;
    display: block;
}

.btn.panel__action-btn {
    color: $color-text !important;
}

.panel__collage {
    .panel__collage-column {
        display: flex;
        width: 100%;
    }

    .panel {
        min-height: 0;
        width: 100%;
        overflow: hidden;
        &__no-overlay {
            min-height: 15rem;
            @media all and (min-width: 768px) {
                min-height: auto;
            }
            .panel__image {
                &:after {
                    display: none;
                }
            }
        }
    }

    @media all and (min-width: 768px) {
        display: grid;
    }

    &.collage__1 {
        @media all and (min-width: 600px) {
            grid-template-columns: 33% 34% 33%;
            grid-template-rows: auto auto;
        }
    }

    &.collage__2 {
        @media all and (min-width: 768px) {
            grid-template-columns: 50% 50%;
            grid-template-rows: auto auto auto;
        }

        @media all and (min-width: 1241px) {
            grid-template-columns: 33.3% 33.3% 33.3%;
            grid-template-rows: auto auto auto;

        }
        @media all and (min-width: 1241px) {
            grid-template-columns: 20% 33% 23.5% 23.5%;
            grid-template-rows: auto auto auto;

            .panel__1 {
                grid-column: 1 / span 2;
                grid-row: 1 / 2;
            }
            .panel__2 {
                grid-column: 3 / 4;
                grid-row: 1 / 2;
            }
            .panel__3 {
                grid-column: 4 / 5;
                grid-row: 1 / 2;
            }
            .panel__4 {
                grid-column: 1 / 3;
                grid-row: 2 / span 2;
            }
            .panel__5 {
                grid-column: 2 / 3;
                grid-row: 2 / span 2;
            }
            .panel__6 {
                grid-column: 3 / 4;
                grid-row: 2 / span 2;
            }
            .panel__7 {
                grid-column: 4 / 5;
                grid-row: 2 / 3;
            }
            .panel__8 {
                grid-column: 4 / 4;
                grid-row: 2 / span 2;
            }
            .panel__9 {
                grid-column: 1 / 5;
                grid-row: 4 / span 4;
            }
        }
    }

    // Randomize feel of colours
    .panel .panel__image:after {
        background: rgba($color-primary, 0.85);
    }
    .panel:nth-child(5n + 2) .panel__image:after {
        background: rgba($color-tertiary-light, 0.85);
    }
    .panel:nth-child(5n + 3) .panel__image:after {
        background: rgba($color-secondary, 0.85);
    }
    .panel:nth-child(5n + 4) .panel__image:after {
        background: rgba($color-tertiary, 0.85);
    }
    .panel:nth-child(5n + 5) .panel__image:after {
        background: rgba($color-primary-dark, 0.85);
    }
    .panel:nth-child(5n + 7) .panel__image:after {
        background: rgba($color-primary-dark, 0.85);
    }

    .panel__collage-column {
        .panel .panel__image:after {
            background: rgba($color-tertiary, 0.85);
        }
        .panel:nth-of-type(5n + 2) .panel__image:after {
            background: rgba($color-primary-dark, 0.85);
        }
        .panel:nth-of-type(5n + 3) .panel__image:after {
            background: rgba($color-secondary, 0.85);
        }
        .panel:nth-of-type(5n + 4) .panel__image:after {
            background: rgba($color-primary, 0.85);
        }
        .panel:nth-of-type(5n + 5) .panel__image:after {
            background: rgba($color-tertiary-light, 0.85);
        }
    }
}

.panel.darkblack .panel__image:after {
    background: rgba(0, 0, 0, 0.6);
}
.panel.black .panel__image:after {
    background: rgba(0, 0, 0, 0.4);
}
.panel.noback .panel__image:after {
    background: none !important;
}

.panel__split {
    width: 100%;

    > div {
        margin-top: $width-gap * 2;
    }

    @media all and (min-width: 1240px) {
        display: flex;
        flex-flow: row nowrap;
        margin-top: $width-gap;

        > div {
            display: block;
            margin: 0 $width-gap $width-gap 0;
        }

        .panel__split-small {
            width: 30%;
            flex-shrink: 0;
            margin-right: 0;
        }

        .panel__split-large {
            width: calc(70% - #{$width-gap});
            flex-shrink: 0;
        }
    }
}

.panel {
    text-decoration: none;
    display: block;

    .panel__image,
    > .icon {
        display: none;
    }

    background: $color-primary;
    color: $color-background-light;
    position: relative;

    .panel__image {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($color-secondary, 0.85);
        }
    }

    .panel__content {
        padding: $width-gap * 1.5 $width-gap * 4 $width-gap * 1.5 $width-gap * 2;
        position: relative;
    }

    > .icon {
        display: inline-block;
        position: absolute;
        bottom: $width-gap;
        right: $width-gap;
        border: 3px solid $color-action;
        padding: $width-gap/4;
        width: $width-gap * 2;
        height: $width-gap * 2;
    }
}

.billboard__panel {
    display: none;
    position: relative;
    margin-bottom: $width-gap;
    color: $color-text;

    .billboard__content {
        position: relative;
    }

    .billboard__content--page-detail {
        color: $color-background-light;
    }

    padding: $width-gap * 2 $width-gap $width-gap * 2;

    @media all and (min-width: 981px) {
        display: block;
        color: $color-background-light;
        min-height: $width-side-content - $width-gap * 2;
        padding: $width-gap * 3 $width-gap * 2 $width-gap * 3 $width-gap * 2;
        margin-top: -$width-gap !important;

        &.acny-as-homepage {
            margin-top: 0 !important;
            min-height: 30rem;

            .headline {
                margin-top: 10rem;
                width: 35rem;
            }

            .billboard__content {
                width: 32rem;
            }
        }
    }

    &.acny-as-homepage {
        display: block;
        color: $color-white;
        background-color: $color-primary-dark;

        .location-intro__image {
            display: block;
        }
    }

    &.billboard__panel--purple {
        background-color: rgba($color-primary-dark, 0.625);
        color: $color-white;
    }

    .pebble-trail {
        padding-left: 0;
    }
}

.billboard__panel--about-page {
    display: block;
}

.billboard__panel--benefice, .billboard__panel--page-detail {
    margin-bottom: 0;
    display: block;
    color: $color-white;
    background-color: $color-primary-dark;

    .location-intro__image {
        display: block;
    }
}

.billboard__panel--benefice {
    display: none;
    @include desktop {
        display: block;
        margin-top: 0;
    }
}

.billboard__panel--page-detail {
    margin-top: #{$width-gap * -1} !important;
}

.billboard__side {
    position: relative;
    padding: $width-gap * 2 $width-gap $width-gap * 4;
    margin: -$width-gap -$width-gap 0 -$width-gap;
    color: $color-background-light;

    .page-layout:not(.has-church-image) & {
        padding-bottom: $width-gap * 4;

        &.has__image {
            padding-bottom: $width-gap * 8;
        }
    }

    @media all and (min-width: 981px) {
        padding-bottom: $width-gap * 8;
        display: none;
    }

    @media all and (min-width: 541px) and (max-width: 980px) {
        .has-church-image &,
        .church-detail .has-church-image & {
            padding: $width-gap * 1.5 $width-gap $width-gap * 4 $width-gap;
            min-height: $width-gap * 17;

            .headline {
                padding-left: $width-gap * 11;

                &:after {
                    left: $width-gap * 11;
                }
            }
        }
    }

    body.admin-view & {
        color: $color-text;
    }
}

.billboard__side--church-detail {
    .location-intro__image {
        display: block !important;
        margin: #{$width-gap * 2} $width-gap 0;
    }
    .billboard__content {
        margin: #{$width-gap * 3} $width-gap 0;
    }
}

.billboard__side--page-detail {
    @media all and (max-width: 980px) {
        display: none !important;
    }
}

.donate__panel-form {
    padding-bottom: 0 !important;
}

.get-in-touch {
    position: relative;
}

.get-in-touch-content {
    margin-top: 1rem;
}

.resource-grid {
    display: grid;
    grid-gap: 1.5vw;
    margin: 0 0 1rem 0;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}


.resource-grid-item {
    text-align: center;
    display: grid;
    grid-template-rows: 1fr auto;
    background: #f5f5f5;
    border: 1px solid #e4e4e4;
    padding: 1rem 1rem 0.5rem 1rem;
    width: 100%;
    text-decoration: none;
    &:hover {
        background: darken(#f5f5f5, 1%);
    }
    img {
        margin-bottom: 0.5rem;
        align-self: center;
        height: auto;
    }
    span {
        align-self: end;
    }
}
.resource-grid.image-select-gallery {
    display: none;
}
#image-select-toggle:checked ~ .resource-grid.image-select-gallery {
    display: grid;
}
#image-select-toggle:checked ~ .image-select__toggle  {
    .open{
        display: none;
    }
    .close {
        display: block;
    }
}
.image-select__toggle.button {
    display: inline-block;
    font-size: 1rem;
    width: auto;
}

.pcc__charity-number {
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 24px;
    color: $color-white;
}

.survey-admin__section {
    h4 {
        font-size: 2rem;
        margin-bottom: 1.125rem;
    }
    hr {
        background-color: $color-grey;
        height: 1px;
        border: 0;
        margin: 1.125rem 0 2rem 0;
    }
    .button {
        font-weight: 700;
        padding: 0.3125rem 0.8125rem;
        margin-right: 1rem;
        margin-bottom: 0.5rem;
    }
    &:last-child {
        ul {
            padding: 0 2rem 0 0;
            li:last-child {
                padding: 0;
            }
        }
    }
    ul {
        padding: 0 2rem 2rem 0;
        li {
            list-style-type: none;
            padding: 0 0 1rem;
            h5 {
                padding: 0 0 0.5rem;
            }
        }
    }
}
