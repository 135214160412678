.image__fieldset-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-shrink: 0;
    width: 100%;
    height: 10rem;
    border: 3px solid $color-grey;
    margin-right: $width-gap*1.5;
    cursor: pointer;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;

    span {
        display: block;
        opacity: 0;
        pointer-events: none;
        z-index: 10;
        text-transform: uppercase;
        background: #fff;
        padding: $width-gap/2 $width-gap*0.75;
    }

    img {
        image-orientation: from-image;
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }

    &.no__preview {
        width: 100%;
        height: 12rem;
        position: relative;

        @include desktop {
            width: 15rem;
            height: 7.65rem;
        }

        &:before, &:after {
            position: absolute;
            top: 50%; left: 50%;
            content: ' ';
            height: 3rem;
            width: 3px;
            background-color: $color-grey;
        }
        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &.file__over {
        background-image: none !important;
        border-color: $color-status-green;

        span { opacity: 1; }
    }

    &:hover {
        border-color: $color-action;

        span {
            opacity: 1;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0; right: 0; bottom: 0; left: 0;
                background: rgba(#fff, 0.75);
                z-index: -5;
            }
        }
    }

    &.cleared {
        border-color: $color-error;

        span {
            opacity: 1;
            font-size: 0;
            padding: 0;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0; right: 0; bottom: 0; left: 0;
                background: rgba($color-error, 0.5);
                z-index: -5;
            }
        }


        &:hover span:before {

        }
    }

    @include desktop {
        width: 15rem;
        height: 15rem;
    }
 }

 .image__fieldset {

    .custom__image-field .help-block {
        padding-top: 0;
        border-top: 0;
        margin-top: $width-gap;
        color: inherit;
        font-size: 1rem;
        width: 30%;
        flex-shrink: 0;
        margin-top: $width-gap*1.5;
        padding-top: $width-gap/2;
        border-top: 3px solid $color-line;

        @media all and (min-width: 768px) {
            margin: 0 0 0 $width-gap*2;
            padding-top: 0;
            border: none;
        }
    }

    @media all and (max-width: 768px) {
        .custom__image-wrapper,
        .custom__image-field .help-block {
            width: 100%;
            max-width: none;
            flex-shrink: 0;
            flex-direction: column;

            @include desktop {
                flex-direction: row;
            }
        }
    }
 }



.custom__image-upload {
    min-width: 0;
    width: auto;
    text-align: center;
    overflow: hidden;

    @include desktop {
        margin-top: $width-gap;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }
    .delete { margin-right: $width-gap/2; }

    button {
        outline: none;
    }

    @media all and (min-width: 481px) {
        margin-top: 0;
    }

    .upload-placeholder {
        color: $color-active;
        align-self: flex-start;

        @include desktop {
            color: $color-text;
        }
    }

    .mobile__edit-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

.error ~ .custom__image-error {

    .help-inline {
        background: $color-error;
        color: #fff;
        padding: $width-gap/2;
        margin-top: $width-gap;
    }
}

.custom__image-upload-title {
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    width: 100%;
    margin-top: 0.25rem;

    .icon {
        width: 1.5rem;
        height: 1.5rem;
        color: $color-grey-light;
        margin-right: $width-gap*0.75;
        flex-shrink: 0;
    }

    p {
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        width: auto;
    }

    + button + button {
        margin-left: $width-gap*0.75;
    }

    + .button {
        margin-top: $width-gap*1.5;
    }
    @include desktop {
        justify-content: flex-start;
    }
}

.custom__image-wrapper {
    min-width: 0;

    @include desktop-large {
        display: flex;
        flex-flow: row nowrap;
    }

    @media all and (min-width: 768px) {
        flex-grow: 1;
        min-width: 30%;
    }
}

.custom__image-field {
    margin: 0;
    display: flex;
    flex-flow: row wrap;

    @include desktop {
        margin: $width-gap*2 0 0 0;
    }

    @media all and (min-width: 768px) {
        flex-flow: row nowrap;
    }

    .custom__image-errors {
        @include desktop {
            width: 30%;
        }

        .help-block {
            display: block;
            border-top: 0;
            width: 100%;
            margin-left: 0;
            margin-bottom: 1rem;
        }
    }

    .help-block {

        @include desktop {
            display: block;
        }
    }
}

#imagecreateform .image__fieldset .control-group,
#imageupdateform .image__fieldset .control-group {
    margin-top: -1rem;

    @include desktop {
        margin-top: 1rem;
    }
}
