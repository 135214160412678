.main-content form,
#lightbox {
    input[type='submit'],
    button {
        display: inline-block;
        padding: $width-gap / 2 $width-gap;
        border: 3px solid $color-action;
        appearance: none;
        background: none;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 0;
        font-family: inherit;
        color: inherit;

        &:disabled {
            cursor: not-allowed;
        }

        &:hover {
            background: $color-action-light;
        }
        &.action-arrow{
            background: $color-action;
            color: #fff;
            background-position: center center;
            background-size: 2rem;
            background-repeat: no-repeat;
            padding: 1rem;
            width: 3.5rem;
            height: 3.4rem;

            &:hover {
                background: darken($color-action, 5%);
                border-color: darken($color-action, 5%);
            }

            &:disabled:hover {
                cursor: not-allowed;
                background: $color-action;
                border-color: $color-action;
            }
        }
    }

    .button__holder {
        display: flex;
        justify-content: flex-start;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        background-color: $color-primary-dark;
        color: $color-white;
        z-index: 100; // position above any other elements on the page

        &--space-between {
            @include desktop {
                justify-content: space-between !important;
            }
        }

        &.desktop-only {
            display: none;
        }
        input, .save {
            font-size: 1.143rem;
            background-color: $color-action;

            @include desktop {
                font-size: 1rem;
                background-color: $color-white;
            }
        }

        #div_id_published {
            .controls {
                display: flex;
            }
        }

        button, a {
            white-space: nowrap;
        }

        .custom__checkbox {
            border-color: $color-primary;

            .icon {
                color: $color-white;
            }

            @include desktop {
                border-color: $color-grey;

                .icon {
                    color: $color-primary;
                }
            }
        }

        .custom__checkbox-label {
            font-size: 1.143rem;
        }

        .button--hide, .button--show-more {
            font-size: 0rem;
            display: flex;
            align-items: center;
            padding: 1rem;

            .icon {
                width: 1rem;
                height: 1rem;
            }

                @include mobile {
                font-size: 1.143rem;
            }
        }

        .button--hide {
            background-color: $color-primary-dark;

            .icon {
                margin-right: 1rem;
                color: $color-white !important;
            }
        }

        .button--show-more {
            display: flex;
            border-right: 3px solid $color-primary-darker;

            .icon {
                margin-right: 0.5rem;
                color: $color-primary;
            }
        }

        .button--extra, .button--hide {
            display: none;
        }

        #div_id_published {
            .controls {
                display: flex;
            }
        }
        // styles for when button bar has 'more' options expanded
        &.expanded {
            flex-direction: column;
            justify-content: flex-end;

            &:before {
                content: '';
                position: fixed;
                width: 100%;
                height: 100%;
                background-color: $color-white;
                opacity: 0.75;
                z-index: -1; // puts it below the button bar
            }

            .button--extra, #div_id_is_published, #div_id_published {
                display: flex;
                margin: 0;
                padding: 1rem;

                background-color: $color-white !important;
                color: $color-text;
                border: 0 !important;
                border-bottom: 3px solid $color-grey !important;

                text-transform: none;
                text-align: left;
                font-size: 1.143rem;

                .checkbox, .checkbox .icon {
                    padding: 0;
                    margin: 0;
                }
            }

            .button--extra .icon {
                color: $color-grey-light;
                display: inline-block;
                margin-right: 0.75rem;
                height: 1.5rem;
                width: 1.5rem;
                flex-shrink: 0;
            }

            .custom__checkbox .icon {
                color: $color-grey-light;
            }

            .button--hide {
                display: flex;
            }

            .button--show-more {
                display: none;
            }

            // change ordering of buttons for mobile
            #div_id_is_published, #div_id_published {
                order: 1;
                .controls {
                    display: block;
                }
            }

            .submit {
                order: 2;
            }

            label[for=id_published] {
                color: $color-text;
            }
        }

        @include desktop {
            flex-flow: row nowrap;
            justify-content: flex-start;
            margin-top: $width-gap * 2;
            padding-top: $width-gap * 2.5;
            position: relative;
            align-items: center;
            background-color: $color-white;
            color: $color-text;
            height: initial;
            z-index: 0;

            &.desktop-only {
                display: flex;
            }
            input {
                background-color: $color-white;
            }

            .button--extra {
                display: inline-block;
                &:not(.urgent-delete) {
                    .icon {
                        display: none;
                    }
                }
            }

            .button--show-more {
                display: none;
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 1rem;
                width: calc(100% + #{$width-gap * 2});
                background: $color-grey;

                .narrow.padded & {
                    width: calc(100% + #{$width-gap * 2});

                    @media all and (min-width: 768px) {
                        width: calc(100% + #{$width-gap * 3});
                        left: calc(50% - 0.5rem);
                    }
                }
                body.admin-view & {
                    background: $color-background-lighter-grey;
                }

                @include desktop {
                    width: calc(100% + #{$width-gap * 4});
                }
            }
        }

        .delete {
            margin-right: $width-gap;
            line-height: 1.15;
        }

        .copy {
            line-height: 1.15;
            margin-right: $width-gap;
        }

        .control-group {
            &#div_id_published {
                .checkbox {
                    margin-left: 0;
                    margin-right: 1rem;
                }
            }
            @include desktop {
                width: 100%;
                &#div_id_published {
                    .checkbox {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.number_of_candidates {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .control-group {
        width: 100%;
        input {
            border-left: none !important;
            border-right: none !important;
            text-align: center;
        }
    }
}

// Image help button
#image-help {
    background-color: $color-primary;
    color: $color-white;
    font-size: 1rem;
    font-family: $base-font;
    font-weight: 700;
    text-transform: none;
    border-radius: 2rem;
    border-color: $color-primary;
    padding: 0.5rem 1rem 0.5rem 2.5rem;
    margin-left: 1rem;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" xml:space="preserve" aria-hidden="true" fill="%23ffffff"><g><g><g><path d="M11,12.3V13c0,0-1.8,0-2,0v-0.6c0-0.6,0.1-1.4,0.8-2.1c0.7-0.7,1.6-1.2,1.6-2.1c0-0.9-0.7-1.4-1.4-1.4 c-1.3,0-1.4,1.4-1.5,1.7H6.6C6.6,7.1,7.2,5,10,5c2.4,0,3.4,1.6,3.4,3C13.4,10.4,11,10.8,11,12.3z"></path><circle cx="10" cy="15" r="1"></circle></g><path d="M10,2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.6,2,10,2 M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0 L10,0z"></path></g></g></svg>');
    background-repeat: no-repeat;
    background-position: 10px 5.5px;

    @media (min-width: 768px) {
        background-position: 13px 7.5px;
        margin-left: 1.5rem;
    }

    &:hover {
        background-color: $color-active;
        border-color: $color-active;
    }
}

.image-help-container {
    display: flex;
    align-items: center;
}
