.acny-as-homepage__mobile-share {
    padding: 1rem 2rem;
    background: $color-background-light;

    .navlist__item {
        align-items: flex-start;
        display: flex;
        flex-flow: row nowrap;
        padding: .75rem .5rem .75rem 0;
        position: relative;

        &.share {
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                height: 1.5rem;
                margin-top: .3rem;
                width: 2rem;
                flex-shrink: 0;
                color: $color-grey-light;
                margin-right: 1.3rem;
            }

            .navlist__item-content {
                width: 100%;

                .navlist__item-title {
                    font-size: 1.125rem;
                }
            }

            span .navlist__item-content {
                width: auto;
            }

            ul {
                align-items: center;
                bottom: 0;
                display: flex;
                flex-flow: row nowrap;
                list-style: none;
                margin: -.75rem 0 -.75rem auto;
                right: 0;
                top: 0;
            }

            .navlist__item-wrapper {
                display: flex;
                align-items: center;
                width: 100%;
                text-decoration: none;
            }
        }
    }
}
