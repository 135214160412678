.benefice__header {
    background-color: $color-white;
    padding: $width-gap * 2.5;
    margin-top: $width-gap;

    .headline {
        max-width: 50rem;
    }
}

.benefice__description {
    margin-bottom: 1rem;
}

.benefice__image {
    border-top: $width-gap * 1.25 $color-grey solid;
}

.benefice__container {
    background-color: $color-white;
    margin-bottom: $width-gap * 6;
    padding: $width-gap * 2.5 $width-gap;

    @media all and (min-width: 450px) {
        padding: $width-gap * 2.5;
    }
}

.benefice__container--form {
    padding: $width-gap * 2.5 $width-gap * 2;
    border: 0;
}

.benefice__edit-button {
    margin-top: $width-gap;
}

.benefice__content {
    padding: 2rem !important;
    @include desktop {
        padding: 1rem 2rem 2rem !important;
    }
}

.benefice__map {
    padding: 0 !important;
}

.tabs__holder--benefice {
    margin-bottom: 0;
}

.headline--benefice-form {
    margin: 1rem 0 0 1rem;
    .content__panel--benefice-form & {
        margin: 0 0 0 #{$width-gap * 2};
        padding-top: $width-gap * 2;
    }
}

.admin-benefice__header {
    padding: #{$width-gap * 2} #{$width-gap * 2} $width-gap;
    margin-bottom: 1rem;
    background-color: $color-white;

    .headline--admin-benefice {
        font-size: 2.25rem;
        line-height: 2.7rem;
        margin-bottom: 1.5rem;
    }
}
