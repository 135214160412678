// Styles for gallery sidebar view
.gallery-preview__container-holder {
    position: relative;
    // only show gallery in side navigation in desktop view
    @media (min-width: 981px) {
        display: none;
    }

    .mobile__navigation & {
        display: none;
    }

    &.gallery__has-imgs {
        border-top: 3px solid $color-grey;
        border-bottom: 3px solid $color-grey;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }

    // no borders for 'ACNY as homepage' style layout
    .acny-as-homepage__wrapper & {
        border: none !important;
        padding-top: 1rem;
        padding-bottom: 0;
    }

    // prevent double line when first nav item active
    + .side__navigation :first-child,
    + .side__navigation .active:first-child {
        border-top-width: 0;
    }

    &:not(.gallery__has-imgs) .gallery__has-imgs-mobile {
        @media (min-width: 980px) {
            border-bottom: 3px solid $color-line;
        }

        @media (max-width: 540px) {
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;
            border-bottom: 3px solid $color-line;
        }
    }

    .location-image__preview + &:not(.gallery__has-imgs) {
        padding-top: 0;
        margin-top: 0.5rem;
    }

    &.gallery__hide-mobile {
        @media (max-width: 979px) {
            display: none;
        }
    }

    @media (min-width: 981px) {
        &.gallery__has-imgs {
            border-top: 3px solid $color-grey;
        }
    }

    @media (max-width: 980px) {
        background-color: #fff;

        .navlist__item-wrapper {
            padding-left: 0.5rem;
        }

        .gallery-preview__image-wrapper:first-child {
            margin-left: 0.5rem;
        }
    }

    .card__edit {
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        margin-top: 0.5rem;
        background-color: #fff;
        color: $color-text;
        right: 0;

        &:hover {
            color: $color-primary !important;
        }

        &.empty {
            margin-top: 0 !important;
            ~ a {
                text-decoration: none;
            }
        }

        svg {
            margin: 0.3rem;
        }
    }

    .gallery__has-imgs-mobile:not(.gallery__has-imgs) + .card__edit {
        @media (min-width: 541px) {
            display: none;
        }
    }

    .gallery-preview__logo {
        @media (min-width: 541px) {
            display: none;
        }
    }
    //don't duplicate gallery on tablet view
    .side-content &{
        display: block;
        @media (max-width: 981px) {
            display: none;
        }
    }
}

.gallery-preview__container {
    display: flex;
    position: relative;
    flex-flow: row nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none !important;
    cursor: pointer;
    font-family: $base-headline-font;

    .navlist__mobile-hidden:last-of-type {
        @media (min-width: 980px) {
            border-bottom-width: 0px;
        }

        @media (max-width: 540px) {
            display: none;
        }
    }

    &:not(.gallery__has-imgs) .navlist__item-wrapper {
        font-size: 1.25rem;
    }

    a {
        text-decoration: none;
    }

    .navlist__item {
        width: 100%;
    }

    a.gallery-preview__image-wrapper {
        position: relative;
        height: 4.5rem;
        margin-right: 0;
        font-size: 1.25rem;

        img.gallery-preview__image {
            height: 100%;
            width: auto;
            margin-right: 0.5rem;
        }

        .icon.preview {
            position: absolute;
            bottom: 0.25rem;
            right: 1rem;
            fill: $color-background-light;
            z-index: 21;
        }

        &:focus {
            outline: none;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0.5rem;
                box-shadow: 0 0 10px #9ecaed;
                border: 3px #9ecaed solid;
            }
        }

        .acny-as-homepage__wrapper & {
            height: 11rem;
        }
    }
}

// Styles for gallery admin list view
.gallery-flex {
    display: flex;
    flex-flow: row wrap;

    .gallery-upload {
        height: 22rem;
        margin: 0 1rem $width-gap * 1.5;
        padding: $width-gap * 2 $width-gap;
        border: 3px solid $color-grey;
        text-align: center;
        p {
            &:last-child {
                position: absolute;
                bottom: $width-gap;
                left: $width-gap / 1.5;
                right: $width-gap / 1.5;
            }
        }
    }
    .gallery-card {
        display: flex;
        padding: 1rem;
        border-top: 3px solid $color-grey;

        .gallery-card__image {
            height: 10rem;
            width: 10rem;
            flex-shrink: 0;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            border: 3px solid $color-grey;
        }

        .gallery-card__filename {
            word-break: break-word;
        }

        .gallery-card__caption {
            padding: 0 $width-gap / 2;
            flex-grow: 1;
        }

        .actions-container {
            .drag-action {
                display: none;
            }

            .card__edit {
                position: relative;
                top: 0;
                right: 0;
                text-decoration: none;

                .icon {
                    color: $color-grey-light;
                    height: 1.5rem;
                    width: 1.5rem;
                }
            }
        }

        .gallery-card__ordering-buttons {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .icon {
                padding: 1.5rem 0.5rem;
                width: 2.5rem;
                height: 4.5rem;
            }
        }

        &:first-child .order-up, &:last-child .order-down {
            color: $color-grey-light;
        }

        @include desktop {
            display: block;
            margin: 0 1rem 1.5rem;
            padding: 0;
            border: 0;

            .gallery-card__image {
                height: 15rem;
                width: 100%;
                background-color: $color-text;
                border: 0;
            }

            .gallery-card__filename {
                display: none;
            }

            .gallery-card__caption {
                border: 3px solid $color-grey;
                border-top: 0;
                height: 7rem;
                overflow: scroll;
                padding: $width-gap / 2;

                p {
                    margin-top: 0;
                }
            }

            .actions-container {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                flex-direction: column;

                .drag-action {
                    display: inline;
                }

                .card__edit {
                    top: 0.5rem;
                    right: 0.5rem;

                    .icon {
                        width: 1rem;
                        height: 1rem;
                        color: $color-grey;
                    }
                }
            }

            .gallery-card__ordering-buttons {
                display: none;
            }
        }
    }

    .gallery-upload,
    .gallery-card,
    .draggable-placeholder {
        position: relative;
        width: 100%;

        @media all and (min-width: 980px) {
            width: calc(33% - 1rem);
            margin: 0 0.5rem 1.5rem;
        }
        @media all and (min-width: 1024px) {
            width: calc(33% - 2rem);
            margin: 0 1rem 1.5rem;
        }
        @media all and (min-width: 1366px) {
            width: calc(25% - 2rem);
        }
    }

    @include desktop {
        margin: 0 -1rem;

        &:before {
            width: calc(100% + 2rem) !important;
        }
    }
}

.draggable-placeholder {
  position: relative;
  border: 3px dotted $color-action;
  height: 22rem;
}
