@import "marker";

#find_us_map {
    @media (max-width: 767px) {
        margin-bottom: 1rem;
    }
}

.pac-container {
    width: calc(100% + 6px) !important;
    top: calc(100% + 3px) !important;
    left: -3px !important;
    right: -3px !important;
    box-shadow: none;
    border-top: none;
    border-radius: 0;
    font-family: $base-font;

    .pac-item {
        border-top: none;
        padding-left: calc(#{$width-gap} + 3px);
        cursor: pointer;

        + .pac-item {
            border-top: 3px solid $color-line;
        }
    }

    .pac-icon {
        margin-right: $width-gap;
        vertical-align: middle;
    }

    .pac-item-selected,
    .pac-item-hover,
    .pac-item:hover {
        background: #fff;
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #fcfcfc;
        z-index: -10;
        border-bottom: $width-gap * 1.325 solid $color-primary;
    }

    &:after {
        filter: brightness(0) invert(1);
        height: 20px;
        margin-right: 2px;
    }
}

.embed-container {
    position: relative;
    padding-bottom: 100%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.intro__map .embed-container {
    padding-bottom: 50%;
}

// Break out of the navlist and side-content containers on desktop for the
// ACNY as a homepage view (this would ideally be solved by reimplementing the
// sidebar containers, but will be hard as we're relying on styles that live
// throughout the project)
.embed-container.acny-as-homepage {
    $total-padding-left: $acny-as-homepage-navlist-padding-sides + $page-layout-desktop-padding-right;
    $total-padding-right: $acny-as-homepage-navlist-padding-sides;
    $width-increase: $total-padding-left + $total-padding-right;

    @include desktop {
        max-width: unset;
        width: calc(100% + #{$width-increase});
        margin-left: -$total-padding-left;
        padding-bottom: calc(100% + #{$width-increase});
    }
}

.embed-container embed,
.embed-container iframe,
.embed-container object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
