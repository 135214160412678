.table {
    border-collapse: collapse;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.25;
    margin: $width-gap*1.5 0;

    td, th {
        padding: $width-gap/4 $width-gap/2;

        &:first-child { padding-left: 0; }
    }

    th {
        white-space: nowrap;
        text-align: left;
        font-weight: inherit;

        .icon {
            color: $color-grey-light;
            vertical-align: middle;
            width: 1.125rem;
            height: 1.125rem;
        }
    }

    td + td,
    th + th {
        border-left: 3px solid $color-line;
    }

    tr + tr {
        border-top: 3px solid $color-line;
    }
}


