.table__edit,
.table__inbox {
    width: 2.5rem;
    text-align: center;
    .table__edit__stat {
        text-align: left;
        margin: 0;
        font-size: 0.875rem !important;
        width: 5rem;
        span{
            display: inline-block;
            width: 6.5rem !important;
            text-transform: uppercase;
            color: lighten($color-text,30%);
        }
        @media all and (min-width:400px){
            width: 10.5rem;
        }
    }
}

.table__name {
    font-size: 1.25rem;
    font-family: $base-headline-font;

    .tag__list { margin-bottom: 0; }

    a { text-decoration: none; }
}

.table__complete {
    text-align: center;
}

.table__views {
    white-space: nowrap;
    text-transform: uppercase;

    span {
        display: inline-block;
        width: 6.5rem;
        color: $color-grey-light;
        font-weight: 600;
    }
}

.table th:not(.table__name) {
    text-transform: uppercase;
    font-weight: 600;
}

.table__complete,
.table__views,
.table__events {
    display: none;
}

.table .table__inbox {
    text-align: center;

    .unread {
        background: $color-error;
        border-radius: $width-gap;
        padding: $width-gap/4 $width-gap/2;
        color: #fff;
    }
}

.table__church-name {
    display: flex;
    align-items: center;
    width: 100%;

    padding: $width-gap/4 0;

    .icon {
        flex-shrink: 0;
        color: $color-grey-light;
        margin-left: $width-gap/2;
    }
}

td.table__events {
    text-transform: uppercase;

    a {
        display: flex;
        align-items: center;
        text-decoration: none;

        &:hover { text-decoration: underline; }

        + a { margin-top: $width-gap/2; }
    }

    .icon {
        color: $color-grey-light;
        margin-right: $width-gap/2;
    }
}

@media all and (min-width: 768px) {
    .table__name {
        width: 100%;
    }
    .table__complete,
    .table__views,
    .table__events {
        display: table-cell;
    }
}
