.church-list {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -$width-gap;
    padding: 0;
}

.church-list__item {
    flex: 0 0 100%;
    list-style-type: none;

    @media all and (min-width: 600px) {
        flex: 0 0 50%;
    }

    .church-list--3-up & {
        @media all and (min-width: 1341px) {
            flex: 0 0 33.3333%;
        }
    }
}

.church-list__link {
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
    align-items: flex-start;
    text-decoration: none;
    margin: $width-gap;
    margin-bottom: $width-gap*1.5;
    min-height: 7.5rem;

    h4 { margin: 0 $width-gap*0.75 0 0 }

    p {
        font-size: 0.75rem;
        margin: $width-gap/4 $width-gap*0.75 $width-gap 0;
        line-height: 1.5;
    }

    .church-list__item-diocese {
        text-transform: uppercase;
        margin-top: 0.125rem;
        font-size: 0.875rem;
        margin-bottom: 0;
    }
}

.church-list__item-details {
    padding-right: $width-gap * 0.5;
    word-break: break-word;
    width: 100%;
}

.church-list__item-image {
    display: none;
    width: 5rem;
    height: 5rem;
    border: 3px solid $color-line;
    position: relative;
    margin: 0.5rem 0 0.5rem 0.5rem;

    @media all and (min-width: 360px) {
        display: block;
        float: right;
    }

    @media all and (min-width: 45px) {
        width: 7.5rem;
        height: 7.5rem;
    }

    // reduce image size on narrower 2 column views
    @media all and (min-width: 600px) and (max-width: 981px) {
        width: 5rem;
        height: 5rem;
    }
}

.church-list__image-placeholder{
    opacity: 0.75;
    background: url(../img/cross.svg) center center/75% no-repeat;
}
