.page-footer {
    padding: 1.25rem 1rem;
    background-color: $color-background-blue;
    color: $color-navy;
    border-top: 1px solid $color-navy;

    ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    .footer__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4.25rem;
    }

    .footer__top-links {
        margin-top: 1.25rem;

        li {
            margin: 0 0 1rem 0;
        }

        a {
            text-decoration: none;
            font-weight: 600;
        }
    }

    .footer__social {
        margin-top: 0.75rem;

        ul {
            flex-direction: row;
            align-items: center;
        }

        li {
            margin-right: 0.75rem;
        }

        .icon {
            width: 1.5rem;
            height: 1.5rem;
        }

        .icon--small {
            width: 1.25rem;
            height: 1.25rem;
        }
    }

    .footer__bottom-links {
        margin-top: 1.25rem;

        ul {
            padding-top: 1.25rem;
            border-top: 1px solid $color-navy;
            justify-content: center;
        }

        li {
            margin: 0 0 1rem 0;
            font-size: 0.875rem;
        }
    }

    @include mobile {
        .footer__links {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    @include desktop {
        padding: 3.75rem 0;

        ul {
            flex-direction: row;
        }

        .footer__top-links {
            margin-top: 0;

            ul {
                flex-wrap: wrap;
            }

            li {
                margin: 0 1rem;
            }
        }

        .footer__social {
            margin-top: 0;

            li {
                margin: 0 0.375rem;
            }
        }

        .footer__bottom-links {
            margin-top: 3.75rem;

            ul {
                padding-top: 2rem;
            }

            li {
                margin: 0 0.75rem;
                font-size: 0.875rem;
            }
        }
    }
}
