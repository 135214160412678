.controls__checkbox {
    flex-shrink: 0;
    cursor: pointer;

    label { font-size: 1rem !important; }

    .checkbox {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: inherit;

        margin-left: 1rem;
        padding-top: 0.5rem;

        @include desktop {
            margin-left: auto;
        }
    }

    label[for=id_at_venue_location].checkbox {
        margin: 0 0 2rem 0;
    }
}

.controls__checkbox--flex-grow {
    flex-grow: 1;
}

#lightbox_content .controls__checkbox {
    margin-bottom: $width-gap/8;
}

.custom__checkbox {
    width: 1.5rem;
    height: 1.5rem;
    border: 3px solid $color-grey;
    margin-right: $width-gap*0.75;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        display: none;
        color: $color-primary;
    }
}

input[type=checkbox]:checked + label,
.checkbox--checked {

    .custom__checkbox {
        .icon { display: block; }
    }
}

#getintouchform .buttonHolder, #claimeditorform .buttonHolder {
    margin-top: 1rem;
}

[for="id_terms"] .custom__checkbox-label {
    text-transform: none;
}
