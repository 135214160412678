@import "christmas_events_banner";

.main-content .content__panel.event__intro,
.main-content .content__panel.content__panel--with-tabs {
    @include desktop {
        padding: 0 1rem 1rem !important;
    }
}

.calendar__panel-intro,
.content__panel.event__intro,
.content__panel.content__panel--with-tabs {
    @media all and (max-width: 599px) {
        .tabs__holder {
            padding: 0.75rem 0.35rem 0 0.35rem;
        }

        .tab {
            padding: 0.75rem 0.75rem 0 0.75rem;
            font-size: 1.1rem;
            line-height: 1.35;
            display: block;

            span {
                display: inline-block;
            }
        }
    }

    @media all and (max-width: 399px) {
        .tab {
            flex-basis: 0;
        }
    }

    form {
        padding: 0;
    }
}

.events__header {
    padding: 2rem;
    margin-bottom: 1rem;
    background-color: $color-white;
}

.events__copy-label {
    max-width: 38rem;

    .control-label {
        font-size: 1em;
        text-transform: unset;
    }
}

.events-calendar__wrapper {
    flex-direction: column;
    display: flex;
    border: 3px solid $color-grey;

    @media (min-width: 760px) {
        flex-direction: row;
    }
}

.events-calendar {
    flex-grow: 1;
    padding: 1rem;
    border-bottom: 3px solid $color-grey;

    @media (min-width: 760px) {
        border-right: 3px solid $color-grey;
        border-bottom: 0;
    }

    // class used to highlight the selectred dates
    .ui-state-highlight {
        background-color: $color-action;

        a {
            background-color: transparent !important;
        }
    }

    .ui-datepicker-today {
        border: 2px solid $color-action;

        .ui-state-highlight {
            background-color: transparent !important;
        }
    }
}

.events-calendar__selected {
    padding: 0 1rem;

    @media (min-width: 760px) {
        flex-basis: 60%;
    }

    .form-row {
        border-bottom: 3px solid $color-grey;

        &:last-child {
            border-bottom: 0;
        }
    }

    .form-row > * {
        display: none;
    }

    .events-calendar__listing {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
    }

    .events-calendar__listing-value {
        flex: 1 0 auto;
        margin: 0;
    }

    .events-calendar__listing-delete {
        width: auto;
        margin: 0;
        cursor: pointer;
        padding: 0;
        border: 0;

        &:hover {
            background-color: transparent;
        }

        .icon {
            fill: $color-action;
        }
    }
}

.billboard__side {
    margin-bottom: 0;
}

.add-to-calendar .icon {
    margin-left: 1rem;
    width: 1.25rem;
    height: 1.25rem;
}

.feature-images-grid {
    &__item {
        max-width: 210px;
        width: 100%;
        max-height: 160px;
        height: 100%;
        object-fit: cover;
    }
}
.weekly {
    p[id*="error_"] {
        background: #DE2F62;
        color: #fff !important;
        padding: 0.5rem;
        margin-top: 0;
    }
}

.event__intro .tab.active {
    pointer-events: none;
}

.event__intro--benefice {
    gap: $width-gap;
    padding: 0 $width-gap $width-gap;
    @include desktop {
        padding: 0;
    }

    p {
        margin: 0;
    }

    #benefice-events-filter-control {
        .icon {
            margin: 0 0 0 1ch;
        }
    }
}

#benefice-events-filter form {
    #div_id_venues .controls {
        display: flex;
        flex-flow: row wrap;
        gap: $width-gap;
        padding: 0 $width-gap $width-gap;

        @include desktop {
            padding: 0;
        }

        > .controls__checkbox {
            flex: 1 1 auto;
            input[type='checkbox'], .checkbox {
                margin: 0;
            }
            .checkbox {
                padding: 0;
            }
        }
    }
    #div_id_status {
        margin: $width-gap 0 $width-gap $width-gap;
        @include desktop {
            margin: $width-gap 0 0;
        }
    }
}
