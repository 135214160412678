@import "normalize/import-now";
@import "variables";
@import "utility";
@import 'mixins/mixins';
@import "typography";
@import "accessibility";

body {
    width: 100%;
    overflow-x: hidden;
    color: $color-text;
}

* {
    box-sizing: border-box;
}

:first-child {
    margin-top: 0;
}

:last-child {
    margin-bottom: 0;
}

@import "layout/layout";
@import "accounts/accounts";
@import "elements/elements";
@import "forms/forms";
@import "locations/locations";
@import "calendar/calendar";
@import "churches/churches";
@import "search/search";
@import "map/map";
@import "events/events";
@import "news/news";
@import "resourcehub/resourcehub";
@import "tag-group-pages/tag-group-pages";
@import "videocampaigns/videocampaigns";
@import "service-register/service_register";
@import "surveys/surveys";
@import "benefice/benefice";

.hidden {
    display: none !important;
}

#search-button {
    display: none;
}

@media (max-width: 540px) {
    #search_list_holder {
        #acny_search,
        .search__form-button {
            display: none;
        }
    }
    #search-button {
        display: inline-block;
    }
}
