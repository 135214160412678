.christmas-events-banner {
    background-color: $color-dark-navy;
    color: $color-white;
    text-align: center;
    padding: 1.25rem 1.5rem;
    gap: 1rem;
    text-decoration: none;

    .christmas-logo {
        width: 7.75rem;
        margin: 0 auto;
    }

    .banner-content {
        width: 100%;
    }

    h3 {
        font-size: 2.5rem;
        line-height: 3rem;
        margin: 0;
    }

    p {
        font-size: 1.25rem;
        line-height: 1.875rem;
        margin-top: 0;

        span {
            color: $color-yellow;
        }
    }

    .button {
        background-color: $color-yellow;
        border-color: $color-yellow;
        color: $color-dark-navy;
        margin-right: auto;
        margin-left: auto;
        font-family: $base-font;
        font-weight: 700;
    }

    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="254" height="130" fill="none"><path fill="%23003A5D" d="M4.765 144.52c-62.796 9.592-90.891 72.819-152.401 56.933-56.448-14.58-87.951-51.519-103.414-107.809C-281.198-16.096-132.469-82.327-18.821-81.999c136.159.393 357.111 185.086 239.486 253.774-73.413 42.87-131.89-40.087-215.9-27.255Z"/></svg>'), url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="227" height="130" fill="none"><path fill="%23003A5D" d="M262.827 143.896c-63.536 9.693-91.963 73.583-154.197 57.53-57.114-14.732-88.988-52.059-104.634-108.94C-26.507-18.403 123.976-85.33 238.963-84.998c137.764.398 361.32 187.027 242.309 256.436-74.278 43.32-133.445-40.508-218.445-27.541Z"/></svg>');
    background-repeat: no-repeat;
    background-position: left top, right top;
    background-size: 11rem 100%;


    @include mobile {
        background-color: $color-navy;
        flex-wrap: nowrap;

        .banner-content {
            width: auto;
        }

        .christmas-logo {
            margin: 0;
        }

        .button {
            margin-right: 0;
            margin-left: 0;
        }
    }

    @include desktop {
        background-color: $color-dark-navy;
        flex-wrap: wrap;
        margin-bottom: -1rem;

        .banner-content {
            width: 100%;
        }

        .christmas-logo {
            margin: 0 auto;
        }

        .button {
            margin-right: auto;
            margin-left: auto;
        }
    }

    @media all and (min-width: 1080px) {
        background-color: $color-navy;
        flex-wrap: nowrap;

        .banner-content {
            width: auto;
        }

        .christmas-logo {
            width: 7.75rem;
            margin: 0;
        }

        .button {
            margin-right: 0;
            margin-left: 0;
        }
    }

    @include desktop-large {
        .christmas-logo {
            width: 8.75rem;
        }

        .button {
            margin-right: 1rem;
        }

        background-size: 15rem 100%;
    }
}
