.pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    a,
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        height: 2.5rem !important;
        width: 2.5rem !important;
        &.prev {
            margin-right: 0.25rem;
        }
        &.next {
            margin-left: 0.25rem;
        }
        &.next.disabled, &.prev.disabled{
            opacity: 0.5;
            cursor: auto;
        }
        + a{
            margin: 0 0.125rem;
        }
    }
    a .icon {
        position: relative !important;
        left: 0 !important;
        top: 0 !important;
        width: 1.5rem !important;
        height: 1.5rem !important;
        margin-right: 0;
    }

    a:not(.disabled) {
        .church-page__wrapper & {
            color: $color-white;
        }
    
        *[class^="acny-as-"] & {
            color: inherit;
        }
    }

    .pages {
        display: flex;
        flex-flow: row nowrap;
        #pages__current {
            background: $color-action;
            border: 3px solid $color-action !important;
            color: $color-background;
            + a{
                border-left: 3px solid inherit !important;
            }
        }
    }
}

.messages__pagination{
    .pagination{
        justify-content: flex-end;
    }
}

.benefice-page__main .pagination {
    margin-top: 2rem;
    a:not(.disabled) {
        color: $color-white;
    }
}