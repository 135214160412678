.admin-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3px;
    align-items: center;

    background-color: $color-grey;
    font-size: 1.286rem;
    font-family: $base-headline-font;

    .admin-grid__item {
        background-color: $color-white;
        min-height: 11rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-decoration: none;
            width: 100%;
            height: 100%;
            padding: 1rem;
            text-align: center;
        }

        .icon {
            width: 2.5rem;
            height: 2.5rem;
            vertical-align: middle;
            margin-bottom: 0.2rem;
        }

        p {
            font-size: 1rem;
            font-family: $base-font;
            padding: 0 1rem;
        }
    }

    .admin-grid__item--small {
        min-height: 4rem;

        a {
            flex-direction: row;
            justify-content: space-between;
        }

        .icon {
            width: 2rem;
            height: 2rem;
        }

        .admin-grid__heading {
            margin-left: 0.5rem;
        }
    }

    .admin-grid__item--title {
        grid-column: span 2;
        text-align: left !important;
    }

    .admin-grid__item--single {
        grid-column: span 2;
    }

    .admin-grid__label {
        font-size: 0.86rem;
        line-height: 1rem;
    }

    .nav__notifications {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        font-size: 1.286rem;
        line-height: 1.5rem;
    }
    body.admin-view & {
        background: none;
    }
}

.admin-grid--analytics {
    border-top: 1.25rem solid $color-background-lighter-grey;
    grid-template-columns: 1fr 1fr 1fr;

    h4 {
        margin-top: 0;
        display: flex;
        align-items: center;

        .icon {
            margin-right: 0.75rem;
            width: 1.25rem !important;
            height: 1.25rem !important;
        }
    }

    .admin-grid__item {
        display: block;
        padding: 1.5rem;
        min-width: 33%;
        text-align: center;
        min-height: auto;
        height: 100%;
    }

    .admin-grid__stat {
        font-size: 1.4375rem;
        line-height: 1.725rem;
    }

    .admin-grid__label {
        font-size: 1rem;
        line-height: 1.5rem;
        font-family: $base-font;
    }

    .admin-grid__item--title {
        grid-column: span 3;
        padding: 0.75rem 1.625rem;
    }
}

.admin__header {
    display: flex;
    align-items: center;
    border-bottom: 3px solid $color-grey;
    width: 100%;
    padding: $width-gap;

    .icon {
        width: 2rem;
        height: 2rem;
    }

    h1 {
        margin: 0 $width-gap;
        font-size: 1.286rem;
        display: flex;
        flex-direction: column;
    }

    .admin__subheader {
        font-size: 0.857rem;
        font-weight: 600;
        color: $color-primary;
    }
}

.admin__item {
    font-size: 1.286rem;
    font-family: $base-headline-font;
    background-color: $color-white;
    min-height: 11rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-decoration: none;
        width: 100%;
        height: 100%;
        padding: 1rem;
        text-align: center;
    }

    .icon {
        width: 2.5rem;
        height: 2.5rem;
        vertical-align: middle;
        margin-bottom: 0.2rem;
    }

    p {
        font-size: 1rem;
        font-family: $base-font;
        padding: 0 1rem;
    }
}

