.row {
    display: flex;
    flex-flow: row wrap;

    &--nowrap {
        flex-flow: row nowrap;
    }

    &--nowrap-medium {
        @include mobile {
            flex-flow: row nowrap;
        }
    }

    // Row alignment
    &--center {
        align-items: center;
    }

    &--bottom {
        align-items: flex-end;
    }

    &--top {
        align-items: flex-start;
    }

    &--baseline {
        align-items: baseline;
    }

    // Row justification
    &--justify-end {
        justify-content: flex-end;
    }

    &--justify-center {
        justify-content: center;
    }

    &--space-between {
        justify-content: space-between;
    }

    // Row distribution
    &.distribute {
        > * + * {
            margin-left: $width-gap;
        }
    }

    &.distribute-s {
        > * + * {
            margin-left: calc(#{$width-gap} / 2);
        }
    }

    &.distribute-l {
        > * + * {
            margin-left: $width-gap * 2;

            @include mobile {
                margin-left: $width-gap * 0.25;
            }

            @include desktop {
                margin-left: $width-gap * 2;
            }
        }
    }
}

.box {
    padding: $width-gap;

    &--small {
        padding: calc(#{$width-gap} / 2) $width-gap;
    }

    &--large {
        padding: $width-gap * 2 $width-gap;
    }
    &--mobile {
        @include smaller-than-desktop {
            padding: $width-gap;
        }
    }
}