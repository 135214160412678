.mobile-admin__tabbed-nav {

    .tabs__holder {
        width: 100%;
        margin-left: 0;
        padding-right: 0;
        padding-left: 0;
    }

    .tabs a span {
        color: $color-primary;
        border: 0;
    }

    p {
        padding: 0 1rem;
    }

    .mobile-admin__tabbed-nav-panel {
        display: none;

        &.active {
            display: block;
        }

        .mobile-admin__tabbed-nav-panel-posts {
            width: auto;
            display: flex;
            align-items: center;
            padding: 0 1rem 1rem;
            border-bottom: 3px solid $color-grey;

            .card__grid-item {
                width: auto;
                margin-bottom: 0;
            }

            .card__image {
                width: 5rem;
                height: 5rem;
                max-width: 5rem;
                padding: 3rem;
                margin-right: 1rem;
            }

            .tabbed-nav-panel_headline-and-tags {
                display: flex;
                flex-direction: column;

                .mobile-post-headline {
                    margin-top: 0rem;
                }

                .tabbed-nav-panel__tag {
                    margin-top: 1rem;
                }
            }

            .tabbed-nav-panel-edit {
                margin-left: auto;

                .icon {
                    color: $color-grey-light;
                }
            }
        }

        .mobile-admin__tabbed-nav-panel-posts + .mobile-admin__tabbed-nav-panel-posts {
            padding-top: 1rem;
        }

        .post-button {
            display: flex;
            justify-content: center;
            margin-top: 1rem;
        }

        .card__summary {
            .btn {
                margin-top: 1rem;
            }
        }
    }

    .mobile-admin__tabbed-nav-toggle:checked + .mobile-admin__tabbed-nav-panel {
        display: block;
    }
}

.mobile-admin {
    display: none;
    width: 100%;
    margin-top: 0 !important;

    .mobile-admin__navigation {
        list-style: none;
        padding: 0;
        margin: 0;
        font-family: $base-headline-font;
        font-size: 1.25rem;

        li + li {
            border-top: 3px solid $color-grey;
        }

        .mobile-admin__navigation-link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-decoration: none;
            padding: 1rem;

            .icon {
                width: 2rem;
                height: 2rem;
                margin-right: 0.5rem;
                color: $color-grey-light;

                &:last-child {
                    margin-left: auto;
                    width: 1.5rem;
                    height: 1.5rem;
                    margin-right: 0;
                }
            }
        }
    }

    .mobile-admin__section {
        padding: 1rem 0 0;
        border-top: 10px solid $color-grey;

        + .mobile-admin__section {
            border-top-width: 10px;
        }
    }

    .mobile-admin__section-title {
        display: flex;
        align-items: center;
        margin: 0 0 1rem;
        padding: 0 1rem;

        h5 {
            margin: 0;
        }

        .icon {
            width: 2rem;
            height: 2rem;
            flex-shrink: 0;
            margin-right: 0.5rem;
            color: $color-grey-light;
        }
    }

    .mobile-admin__location-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        padding: 1rem 1rem;
        font-size: 1.25rem;
        font-family: $base-headline-font;
        border-top: 3px solid $color-grey;

        span {
            display: block;
        }

        .mobile-admin__location-link-tags {
            margin-top: 0.5rem;
        }

        .icon {
            width: 1.5rem;
            height: 1.5rem;
            color: $color-grey-light;
        }
    }
}

body.admin-view {

    .mobile-admin--hide {
        display: none;

        & + p {
            margin-top: 0;
        }

        @include desktop {
            display: block;

            & + p {
                margin-top: 1rem;
            }
        }
    }

    .mobile-admin--hide, .side-content {
        display: none;

        // if a headline is the only element in a 'mobile-admin--hide' div,
        // its bottom margin is removed as it's the :last-child
        .headline {
            margin-bottom: 1.5rem;
        }

        @include desktop {
            display: block;
        }
    }

    .mobile-admin {
        display: block;

        @include desktop {
            display: none;
        }
    }

    .page__wrapper {
        @include smaller-than-desktop {
            overflow: initial;
            width: 100%;
        }
    }

    .container.not-padded-mobile-admin {
        @include smaller-than-desktop {
            padding: 0 !important;
        }
    }

    .billboard__side {
        display: none;
    }

    .church-page__wrapper {
        background-color: $color-white;

        @include desktop {
            background-color: $color-background-lighter-grey;
            padding: 1.375rem 2.25rem;
        }
    }

    .container {
        padding: 0;
    }

    @include desktop {
        .page-layout {
            padding-right: 1rem;
        }
    }

    &.home .mobile-navbar {
        display: none;
    }

    .mobile-navbar {
        background-color: $color-primary-dark;
        color: $color-white;

        display: grid;
        align-items: center;
        text-align: center;
        grid-template-columns: 3rem 1fr 3rem;

        .page__logo,
        .acny__logo {
            display: none;
        }

        .mobile__toggle {
            border-left: 3px solid $color-primary-darker;
            width: 100%;

            label {
                padding: 0;
                justify-content: center;
            }
        }

        .mobile-navbar__back-button {
            display: block;
            height: 100%;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                border-right: 3px solid $color-primary-darker;
            }
        }

        .mobile-navbar__title {
            display: inline-block;
            font-family: $base-headline-font;
            font-size: 1.286rem;
            grid-column: 2;
        }

        @media all and (min-width: 981px) {
            display: none;
        }

        .acny-as-homepage__logo-mobile,
        .acny-as-homepage__venue-name {
            display: none;
        }
    }

    .navlist .icon {
        color: $color-grey-light;

        @include desktop {
            color: $color-text;
        }
    }

    .admin-form__intro {
        padding: 1rem 1.5rem 0 1.5rem;

        @include desktop {
            padding: 0;
        }
    }
}

.mobile-admin .navlist__item {
    padding: 0.75rem 1rem;

    .icon {
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: middle;
    }
}

.tabbed-nav-panel-benefice {
    padding: $width-gap !important;
    border-top: 0.25rem solid $color-secondary;
}
