.ical-rss__downloads-holder {
  display: flex;
  justify-content: flex-end;

  .calendar__header-option-area > & {
    position: absolute;
    top: calc(#{$width-gap} * (2 / 3));
    right: 0;
    &.ical-rss__downloads-holder--admin {
      @media (max-width: 272px) {
        top: 4rem;
      }
    }
  }

  &.news__downloads-holder {
    margin: 0 1rem 0 auto;

      .ical-rss__downloads {
          margin-right: 0;
      }

      @include mobile {
        position: absolute;
        top: 0.75rem;
        right: 2rem;
      }
  }

  .ical-rss__downloads {
    position: relative;
    background-color: #fff;

    @media all and (min-width: 768px) {
      margin-right: $width-gap;
      font-size: 0.9375rem;
    }
  }

  .ical-rss__download {
    display: block;
    padding: $width-gap * 0.5;
    line-height: 1.35;
    color: $color-text;
    font-family: $base-headline-font;
    font-size: 1.25rem;
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: rgba(166, 136, 183, 0.0625);
    }

    .icon {
        fill: $color-grey-light;
        margin-right: 0.5rem;
    }
  }

  .ical__download-notice {
      display: none;
      background-color: $color-primary;
      color: #fff;
      padding: $width-gap*0.75 $width-gap;
      line-height: 1.35;

      @media (min-width: 768px) {
          font-size: 0.875rem;
      }

      &.show {
          display: block;
      }
  }

  .ical-rss__options {
    display: none;
    position: absolute;
    right: 0;
    z-index: 1;
    min-width: 20rem;
    width: 100%;
    padding: $width-gap;
    background-color: #fff;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);

    @media (max-width: 767px) {
      margin-top: 0.25rem;
    }
  }

  button {
    display: inline-block;
    width: 100%;
    padding: $width-gap * 0.75 $width-gap;
    border: none;
    border-radius: 0;
    appearance: none;
    background: none;
    cursor: pointer;
    text-transform: uppercase;
    text-align: left;
    color: $color-white;
    background-color: $color-primary;
    font-family: $base-font;

    &[aria-expanded="true"] + .ical-rss__options {
      display: block;
    }

    @media all and (min-width: 768px) {
      font-size: 1rem;
    }
  }

  &:not(.news__downloads-holder) button {
      @media all and (max-width: 1060px) {
        border: 3px solid $color-primary;
        padding: $width-gap / 4 $width-gap / 2;

        span {
          position: absolute;
          left: -99999px;
        }
      }
  }

  .icon {
    position: relative;
    top: 3px;
    margin-right: 0.25rem;
  }
}

#ical-rss-admin {
  span {
    @media (min-width: 980px) and (max-width: 1180px) {
      position: absolute !important;
      left: -99999px !important;
    }
  }
}
