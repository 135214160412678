.home__section {
    padding: $width-gap * 4 0;
    max-width: 35rem;
    margin: auto;
    text-align: center;

    .home__section-title {
        padding-bottom: $width-gap * 1.5;
        position: relative;
        margin-bottom: $width-gap * 2;

        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 6.68rem;
            left: 50%;
            bottom: 0;
            height: 3px;
            background: $color-action;
            transform: translateX(-50%);
        }
    }
}

.home__section-wider {
    max-width: 70.31rem;
}

.home__tri {
    padding: $width-gap * 4 0;

    .home__tri-section {
        h4:after {
            content: "";
            display: block;
            width: 5rem;
            height: 3px;
            background: $color-action;
            margin: $width-gap * 1.5 0;
        }

        + .home__tri-section {
            margin-top: $width-gap * 3;
        }
    }

    @media all and (min-width: 981px) {
        display: flex;

        .home__tri-section {
            width: calc(100% / 3);
            padding: 0 $width-gap * 0.75;

            + .home__tri-section {
                margin-top: 0;
            }
        }
    }
}

.home__duo {
    padding: $width-gap * 4 0;

    .home__duo-section {
        h4:after {
            content: "";
            display: block;
            width: 5rem;
            height: 3px;
            background: $color-action;
            margin: $width-gap * 1.5 0;
        }

        + .home__duo-section {
            margin-top: $width-gap * 3;
        }
    }

    @media all and (min-width: 981px) {
        display: flex;

        .home__duo-section {
            width: calc(100% / 2);
            padding: 0 $width-gap * 0.75;

            + .home__duo-section {
                margin-top: 0;
            }
        }
    }
}

.home__hero-image {
    width: 100%;
    height: calc(100vh - 5.5rem);
    background-image: url("../img/home-montage.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    display: flex;
    align-items: center;

    @include desktop {
        background-size: contain;
        background-image: url("../img/home-montage@2x.jpg");
    }

    .container {
        background-color: $color-white;
        padding: 2rem 2rem !important;
        text-align: center;
    }

    .search__bar, .search__form-header {
        margin-bottom: 1.5rem;
    }

    .search__form-button {
        top: 4.2rem;
    }
}

.christmas-branding .home__hero-image {
    @include desktop {
        margin-top: 6rem;
    }
}

.christmas-home-branding .home__hero-image {
    @include desktop {
        margin-top: 16rem;
    }

    @include desktop-large {
        margin-top: 22rem;
    }
}

.christmas-home-branding .page__wrapper {
    position: relative;
}

.easter-branding .home__hero-image {
    margin-top: 6rem;

    @include mobile {
        margin-top: 10rem;
    }
}

