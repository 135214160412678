.sortable-table__wrapper {
    margin: 0 -1rem;
    padding: 2rem 1rem 3rem;
    position: relative;

    @media (max-width: 960px) {
        overflow-x: scroll;
    }

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 1rem;
        top: 0;
        left: 0;
    }
}

.sortable-table {
    border-spacing: 0;
    width: 100%;
    background: $color-background;
    font-family: $base-headline-font;
    font-size: 0.875rem;
}

.sortable-table--stat-list td,
.sortable-table--stat-list th {
    max-width: 5rem;

    &:nth-child(2n-2) {
        border-right: 1px solid $color-white;
    }
}

.sortable-table td,
.sortable-table th {
    padding: 0.25rem 0.5rem;
    min-width: 6rem;

    &:first-child {
        min-width: 8.5rem;
        padding-left: 1.5rem;
        padding-right: 1rem;
    }

    &:last-child {
        padding-right: 1.5rem;
    }

    @media all and (min-width: 768px) {
        min-width: 6.5rem;

        &:first-child {
            min-width: 7rem;
        }
    }
}

.sortable-table tr:nth-child(even) {
    background: $color-grey;
}

.sortable-table a {
    color: $color-action;
}

.sortable-table__head {
    color: $color-white;

    td {
        background: $color-active;
        border-right: 1px solid $color-white;
        font-size: 1rem;
        padding: 0.5rem;

        &:first-child {
            background: $color-white;
        }
    }

    th {
        background: $color-primary-dark;
        text-transform: none;
        color: $color-white;
        cursor: pointer;
        font-weight: normal;
        text-align: left;
        line-height: 1.2;
        white-space: nowrap;
        padding: 1.5rem 0.5rem;
        align-items: center;

        span {
            white-space: normal;
        }

        .caret {
            margin-bottom: -0.15rem;
            margin-left: 0.125rem;
            width: 0.75rem;
            height: 0.75rem;
            fill: $color-action;
            transition: transform 0.2s ease;
        }

        &[aria-sort='descending'],
        &[aria-sort='ascending'] {
            background-color: $color-active;

            .caret {
                fill: $color-white;
            }
        }

        &[aria-sort='ascending'] {
            .caret {
                transform: rotate(-180deg);
            }
        }
    }
}

.sortable-table__tooltip {
    position: relative;

    svg {
        width: 0.75rem;
        height: 1rem;
    }
}

.sortable-table__tooltip-text {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    bottom: 150%;
    left: 0.35rem;
    width: 8rem;
    transition: opacity 0.2s ease;
    transform: translateX(-50%);
    padding: 0.5rem;
    background-color: $color-action;

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.5rem 0.5rem 0 0.5rem;
        border-color: $color-action transparent transparent transparent;
        transform: translateX(-50%);
    }
}

.sortable-table__tooltip:hover .sortable-table__tooltip-text {
    opacity: 1;
}
