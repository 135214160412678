.custom__field ~ .custom__field {
    margin-top: $width-gap/4;
}

.custom__field-icon {
    border: 3px solid $color-action;
    flex-shrink: 0;
    width: 3.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .error & {
        border-color: $color-error;
    }

    &.custom-clear {
        border-right: none;

        .icon {
            width: 1rem;
            height: 1rem;
        }
    }
}

.custom__field-input {
    position: relative;
    display: flex;
    background: #fff;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    border-radius: $width-radius;
    background: #fff;
    vertical-align: top;
    line-height: inherit;

    .icon {
        color: $color-text;
        width: 1rem;
        height: 1rem;
    }
}

.custom__field-value {
    border: 3px solid $color-grey;
    border-right: none;
    padding: $width-gap * 0.75 $width-gap;
    width: 100%;
    white-space: nowrap;

    .error & {
        border-color: $color-error;
    }

    &.time__picker {
        padding: 0;
        border: none;

        input {
            border: 3px solid $color-grey;
            border-right: none !important;
            -webkit-font-smoothing: inherit !important;

            .error & {
                border-color: $color-error;
            }
        }
    }
}

.custom__field-widget.hasDatepicker,
.custom__time {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    padding: $width-gap/4;
    z-index: 100;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.125);
    user-select: none;
}

// Time picker
.custom__time {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $width-gap * 1.5 $width-gap * 2;
}

.custom__time-wrapper {
    display: flex;
    flex-flow: column nowrap;

    input {
        appearance: none !important;
        letter-spacing: 0.15rem;
        padding: 0.5rem 0 !important;
        border: none !important;
        font-size: 1.5rem !important;
        font-weight: 600 !important;
        text-align: center !important;

        &::-webkit-clear-button {
            display: none;
        }
    }
}

.custom__time-value-up,
.custom__time-value-down {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    padding: 0 1.75rem 0 0.5rem;

    .icon {
        vertical-align: middle;
        width: $width-gap * 0.75;
        height: $width-gap * 0.75;
        color: $color-primary;
    }
}

.custom__time-value {
    padding: $width-gap;
    font-size: 1.5rem;
    font-family: $base-headline-font;
    width: 4rem;
    text-align: center;
}

// Date picker
.ui-datepicker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $width-gap/2 $width-gap;
    margin-bottom: $width-gap/2;
    border-bottom: 3px solid $color-primary;

    .ui-datepicker-next {
        order: 3;
    }

    a {
        cursor: pointer;
    }
}

.ui-datepicker-title {
    font-size: 1.25rem;
    font-family: $base-headline-font;
}

.ui-datepicker-calendar {
    width: 100%;
    text-align: center;

    a {
        cursor: pointer;
        text-decoration: none;
        color: darken($color-grey, 40%);

        &:hover {
            text-decoration: underline;
        }
    }

    table {
        border-collapse: collapse;
    }

    tbody td {
        padding: $width-gap/2 $width-gap/4;
    }

    .ui-state-active {
        font-weight: bold;
        color: $color-primary;
    }
}

.ui-timepicker-wrapper {
    overflow-y: auto;
    max-height: 150px;
    width: 8.5em;
    background: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    outline: none;
    z-index: 10052;
    margin: 0;
    padding-top: 4px;
    -webkit-font-smoothing: initial;
}

.ui-timepicker-wrapper.ui-timepicker-with-duration {
    width: 13em;
}

.ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-30,
.ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-60 {
    width: 11em;
}

.ui-timepicker-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.ui-timepicker-duration {
    margin-left: 5px;
    color: #888;
}

.ui-timepicker-list:hover .ui-timepicker-duration {
    color: #888;
}

.ui-timepicker-list li {
    padding: 3px 0 3px 10px;
    cursor: pointer;
    white-space: nowrap;
    color: #000;
    list-style: none;
    margin: 0;
}

.ui-timepicker-list:hover .ui-timepicker-selected {
    background: #fff;
    color: #000;
}

li.ui-timepicker-selected,
.ui-timepicker-list li:hover,
.ui-timepicker-list .ui-timepicker-selected:hover {
    color: #a688b7;
}

li.ui-timepicker-selected .ui-timepicker-duration,
.ui-timepicker-list li:hover .ui-timepicker-duration {
    color: #ccc;
}

.ui-timepicker-list li.ui-timepicker-disabled,
.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
    color: #888;
    cursor: default;
}

.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
    background: #f2f2f2;
}

#div_id_publish_at .controls {
    .custom__field ~ .custom__field {
        margin-top: 0.5rem;

        @include desktop {
            margin-top: 0;
            margin-left: 0.5rem;
        }
    }
    @media all and (min-width: 768px) and (max-width:980px) {
        display: flex;
        flex-flow: row nowrap;
        > div {
            width: 50%;
        }
        .custom__field ~ .custom__field {
            margin: 0 0 1rem $width-gap;
        }
        .custom__field-icon {
            width: 2.25rem !important;
        }
    }
    @media all and (min-width: 850px) {
        .custom__field-icon {
            width: 3.25rem !important;
        }
    }
    @media all and (min-width: 1175px){
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: 2rem;
        > div {
            width: 50%;
        }
        .custom__field ~ .custom__field {
            margin: 0 0 1rem $width-gap;
        }
    }
}

#div_id_delete_at .controls {
    .custom__field ~ .custom__field {
        margin-top: 0.5rem;

        @include desktop {
            margin-top: 0;
            margin-left: 0.5rem;
        }
    }
    @media all and (min-width: 768px) and (max-width:980px) {
        display: flex;
        flex-flow: row nowrap;
        > div {
            width: 50%;
        }
        .custom__field ~ .custom__field {
            margin: 0 0 1rem $width-gap;
        }
        .custom__field-icon {
            width: 2.25rem !important;
        }
    }
    @media all and (min-width: 850px) {
        .custom__field-icon {
            width: 3.25rem !important;
        }
    }
    @media all and (min-width: 1175px){
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: 2rem;
        > div {
            width: 50%;
        }
        .custom__field ~ .custom__field {
            margin: 0 0 1rem $width-gap;
        }
    }
}

#div_id_event_start {
    .custom__field {
        margin-bottom: 1.5rem;
    }
    .control-label {
        font-size: 1.25rem;
        font-family: $base-font;
        line-height: 1.5rem;
        text-transform: capitalize !important;
    }
}
