.tag__checkbox {
    .checkbox {
        margin-bottom: 0;

        > span {
            background: $color-primary;
            padding: 0 $width-gap/2;
            color: #fff;
        }
    }

    + .tag__checkbox {
        margin-top: $width-gap/4;
    }

    &.checked .checkbox .custom__checkbox-label {
        width: 100%;
    }

    .custom__checkbox-label {
        text-transform: uppercase;
        font-size: 0.875rem;
        font-family: $base-headline-font;
    }

    label {
        width: unset !important;
    }
}

.tag__checkbox-description {
    display: none;
    padding-left: $width-gap * 3.25;
    margin-bottom: $width-gap;

    .checked & {
        display: block;
    }

    @include desktop {
        padding-left: $width-gap * 2.25;
    }
}

.tags__fieldset {

    .custom__tag-field {
        width: 100%;

        @media screen and (min-width: 760px) {
            width: 70%;
            padding: 0 2.5rem 0.5rem 0;
        }
        
        .tag__list {
            margin: 1.5rem 0 1.25rem 0;
        }

        .custom__tag-upload {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 0.5rem;
            align-items: center;

            @media screen and (min-width: 760px) {
                align-items: flex-start;
            }
        }
    }

    .tag-placeholder {
        color: $color-active;
    }

    .help-block {
        position: absolute;
        right: 0;
        top: 6rem;
        font-size: 1rem !important;
        width: 30%;
        color: $color-text;
        @media all and (max-width: 768px) {
            position: static;
            width: 100%;
            max-width: none;
            flex-shrink: 0;
        }
    }
    @include desktop {
        min-height: 18rem;
    }
}

#eventform{
    .tags__fieldset{
        @include desktop {
            min-height: 24rem;
        }
    }
}
