.christmas-branding {
    &.church-base,
    &.church-detail,
    &.church-page__events,
    &.event-detail,
    &.login-view,
    &.login-view .account-page__wrapper,
    .church-page__wrapper {
        background-color: $color-dark-navy;
    }

    .location-intro__image::after {
        background: rgba($color-dark-navy, 0.625);
    }

    .event__intro,
    .news__intro {
        @include desktop {
            margin-top: 5rem !important;
        }

        @media (min-width: 1033px) {
            margin-top: 2rem !important;
        }
    }

    .church-page__wrapper {
        overflow: visible;
        background: url(../img/stars_right.svg) 90% -10rem/280px repeat-y;

        @include desktop {
            background: url(../img/stars_right.svg) 90% 4rem/340px repeat-y, url(../img/stars_left.svg) 22rem 4rem/380px repeat-y;
        }

        @include desktop-large {
            background: url(../img/stars_right.svg) 95% 4rem/340px repeat-y, url(../img/stars_left.svg) 28rem 4rem/380px repeat-y;
        }

        .side-content {
            @include desktop {
                margin-top: -5rem;
                padding-top: 14rem;
            }
        }

        .church__logo-holder {
            border-top: none;
        }

        .billboard__panel--page-detail {
            background-color: $color-dark-navy;
        }
    }

    .christmas-heading-row {
        background-color: $color-dark-navy;
    }

    .church-page__main {
        @media all and (min-width: 980px) {
            padding: 7rem 0 1rem 1rem;
        }
    }

    &.admin-view {
        .church-page__wrapper .side-content {
            margin-top: 0;
            padding-top: 0;
        }

        .church-page__main {
            @media all and (min-width: 980px) {
                padding-top: 0;
            }
        }

        .benefice-page__wrapper {
            margin-top: 0;
        }

        .mobile-navbar {
            background: $color-navy;

            .mobile__toggle {
                border-left-color: $color-dark-navy;
            }

            .mobile-navbar__back-button {
                a {
                    border-right: 3px solid $color-dark-navy;
                }
            }
        }
    }

    .benefice-page__wrapper,
    &.benefice-page .church-page__wrapper {
        background: $color-primary-dark;

        .location-intro__image::after {
            background: rgba($color-primary-dark, 0.625);
        }

        .billboard__panel--page-detail {
            background-color: $color-primary-dark;
        }

        @include desktop {
            margin-top: -3rem;
            padding-top: 5rem;
        }
    }

    .resource-page__wrapper,
    .tag-group-page .main-content,
    &.admin-analytics .page__wrapper,
    &.my-churches .page__wrapper,
    &.accounts .account-page__wrapper {
        @include desktop {
            padding-top: 6rem;
        }
    }

    &.login-view .account-page__wrapper {
        padding-top: 3rem;
        padding-bottom: 3rem;

        @include desktop {
            padding-top: 6rem;
            padding-bottom: 5rem;
            background: url(../img/stars_right.svg) 95% 4rem/340px no-repeat, url(../img/stars_left.svg) 5% 4rem/380px no-repeat;
        }

        .main-content form {
            padding-bottom: 1rem;
        }
    }

    &.event-detail .page-header {
        @include desktop {
            background-color: $color-dark-navy;
        }
    }
}


// Church page - christmas events list
.church-page__events .action-pebble .icon {
    color: $color-yellow;
    vertical-align: middle;
}

.church-page__christmas-heading {
    width: 100%;
    color: $color-white;

    h1 {
        margin-bottom: $width-gap;

        &:after {
            background: $color-yellow;
        }
    }

    .christmas-heading-row {
        display: flex;
        flex-flow: column-reverse wrap;
        align-items: center;

        @include mobile {
            flex-flow: row nowrap;
            align-items: flex-start;
        }
        @include desktop-large {
            align-items: center;
        }

        img {
            padding: 2rem;
        }
    }

    .christmas-heading {
        padding: 2rem 0 4rem 1rem;
    }

    .christmas-intro {
        font-size: 1.375rem;
        line-height: 2.03125rem;
    }
}

.christmas__content .search__form-body, .christmas__search-form-container {
    .padded-text {
        padding: 0 #{$width-gap * 2};
        @include desktop {
            padding: 0 #{$width-gap * 5};
        }
        &.search-text {
            font-family: $base-font;
        }
    }
}

.christmas-events {
    .page-header {
        .cofe__navigation ul a {
            color: $color-white;
        }

        .primary__navigation {
            margin-bottom: 0;
        }
    }

    .search__bar,
    .custom__select-toggle-text,
    .toggle-icon__holder,
    .custom__field-value,
    .custom__field-icon,
    .textInput,
    .textInput:focus {
        border-color: $color-grey;
        border-width: 3px;
        background-color: $color-white;

        .icon {
            color: $color-black;
        }
    }

    .search__bar {
        margin-bottom: 1rem;
    }

    .custom__select-toggle-text {
        padding: 0.75rem 1rem;
    }

    label[for="id_event_type"],
    label[for="id_time_0"] {
        @include visually-hidden;
    }

    #div_id_date {
        margin-top: 1rem;
    }

    #div_id_time {
        margin-top: 1rem;

        .controls {
            display: flex;
            align-items: center;
            gap: 0.75rem;
        }
    }

    #div_id_distance {
        input {
            appearance: auto;
        }

        + p {
            margin-top: -0.5rem;
            font-size: 0.75rem;
        }
    }

    #div_id_distance,
    #div_id_event_type,
    #div_id_date_range {
        margin-top: 2rem;

        label {
            font-size: 1.5rem;
            font-family: $base-headline-font;

            @include desktop {
                font-size: 2rem;
            }
        }

        .controls {
            margin-top: 0.5rem;
        }
    }

    #div_id_event_type {
        .controls {
            max-height: 8rem;
            overflow-y: scroll;

            @include desktop {
                max-height: 18rem;
            }
        }

        .controls__checkbox .checkbox {
            margin-left: 0;
        }

        input[type="checkbox"] {
            @include visually-hidden;
        }

        .custom__checkbox .icon {
            color: $color-navy;
        }
    }

    #div_id_date_range {
        .custom__field-icon:last-child {
            border-color: $color-yellow;
        }
    }

    #div_id_date,
    #div_id_groups_and_activities {
        label {
            display: none;
            margin-top: 3rem;
            font-size: 2rem;
            font-family: $base-headline-font;
            margin-bottom: 1rem;

            @include desktop {
                display: block;
            }
        }
    }

    #div_id_groups_and_activities {
        display: none;

        @include desktop {
            display: block;
        }

        .taglinkwidget {
            padding: 0.2rem 0;
            list-style: none;

            li {
                a {
                    text-decoration: none;
                }
                &.selected {
                    background-color: $color-navy;
                    color: $color-white;
                }

                &:hover {
                    background-color: $color-navy;
                    border-color: $color-navy;
                    color: $color-white;
                }
            }
        }
    }

    .side-content {
        .h3 {
            font-size: 1.5rem;

            @include desktop {
                font-size: 2.25rem;
                margin-bottom: 1rem;
            }
        }

        .submit {
            color: $color-navy;
            font-weight: 700;
            background-color: $color-yellow;
            border-color: $color-yellow;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 1.5rem 0;
            font-family: $base-font;

            &:hover {
                background-color: $color-navy;
                border-color: $color-navy;
                color: $color-white;
            }
        }

        .taglinkwidget {
            padding: 0.2rem 0;
            list-style: none;

            li {
                a {
                    text-decoration: none;
                }
                &.selected {
                    background-color: $color-navy;
                }
            }
        }
    }

    @media all and (min-width: 1930px) {
        .page-header {
            background-color: $color-background-blue;
        }
    }

}

.christmas-search-page__wrapper {
    padding-top: 2rem;

    @include desktop {
        padding-top: 5rem;
    }
}

.christmas-form-wrapper {
    position: relative;
    background-color: $color-white;
    margin: 1.5rem auto;
    border: 2px solid transparentize(#B9D8E7, 0.5);
    border-radius: 1rem;
    padding: 2rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: calc(100% - 3rem);

    @include mobile-small {
        max-width: 28rem;
    }

    @media all and (min-width: 1440px) {
        z-index: 1;
        position: absolute;
        top: 20rem;
        right: 6rem;
        border-radius: 1rem;
        border: none;
    }

    #div_id_event_type {
        display: none;
    }

    #acny_search {
        order: 0;
    }

    #acny_search_place {
        border: 1px solid #73B1CE;

        @media all and (min-width: 1440px) {
            padding-right: 4rem;
        }

    }

    .search__string-holder {
        width: 100%;
        margin: 1rem auto 0 auto;

        @include mobile-small {
            width: 19.25rem;
            max-width: 100%;
        }
    }

    #acny_search_current_location,
    .submit {
        margin: 1rem auto;
        background-color: $color-yellow;
        border-color: $color-yellow;
        color: $color-navy;
        padding: 0.5rem 1rem;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        line-height: 1.75rem;
        text-transform: none;
        font-weight: 400;
        font-family: $base-font;

        &:hover {
            background-color: $color-navy;
            border-color: $color-navy;
            color: $color-white;
        }

        .icon {
            margin-left: 0.5rem;
            margin-right: 0;
        }
    }

    .submit {
        position: absolute;
        bottom: 1rem;
        right: 1.25rem;
        height: 3.325rem;
        padding: 0.5rem 0.75rem;

        .icon {
            margin: 0;
        }

        span {
            @include visually-hidden;
        }

        @include mobile-small {
            right: 4rem;
        }

        @media all and (min-width: 768px) {
            bottom: 1rem;
            height: 3.125rem;
        }

        @include desktop {
            bottom: 1.5rem;
        }
    }

    @include desktop {
        padding: 2.5rem;
    }

    .search__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $color-navy;
        text-align: center;

        h2 {
            font-size: 1.75rem;
            line-height: 2.625rem;
            font-family: $base-font;
        }

        p {
            font-size: 1rem;
            line-height: 1.375rem;
            margin: 0 0 1rem 0;
        }

        .form-p {
            font-size: 1.25rem;
            margin: 0;
        }

        .search__bar {
            width: unset;
        }

        .search__bar,
        .custom__field-value,
        .custom__field-icon {
            border-color: $color-navy;
            border-width: 2px;
            background-color: $color-white;

            .icon {
                color: $color-navy;
            }
        }
    }
}

.christmas-video-header {
    position: relative;

    @media all and (min-width: 1440px) {
        background-color: $color-black;

        &::after {
            display: block;
            content: '';
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 100%);
            width: calc(100% - 60rem);
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .video-buttons {
        position: absolute;
        bottom: 25.5rem;
        left: calc(50% - 2.75rem);
        display: flex;

        @media all and (min-width: 347px) {
            bottom: 24rem;
        }

        @media all and (min-width: 980px) {
            bottom: 27rem;
            left: 1rem;
        }

        @media all and (min-width: 1440px) {
            bottom: 1rem;
            left: 1rem;
        }
    }

    #mute-button,
    #unmute-button,
    #pause-button,
    #play-button {
        background-color: $color-yellow;
        border: none;
        border-radius: 50%;
        padding: 0.5rem;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            width: 20px;
            height: 20px;
            margin-right: 0;
        }
    }

    #mute-button,
    #unmute-button {
        margin-left: 0.5rem;
    }
}

.christmas-video {
    width: 100%;
    height: 14rem;
    margin-top: 5rem;

    @media all and (min-width: 380px) {
        height: 16rem;
        margin-top: 6rem;
    }

    @media all and (min-width: 410px) {
        margin-top: 7rem;
    }

    @include mobile-small {
        height: 19rem;
        margin-top: 6rem;
    }

    @media all and (min-width: 520px) {
        height: 21rem;
        margin-top: 7rem;
    }

    @media all and (min-width: 560px) {
        margin-top: 8rem;
    }

    @media all and (min-width: 590px) {
        height: 24rem;
        margin-top: 9rem;
    }

    @include mobile {
        height: 26rem;
        margin-top: 10rem;
    }

    @media all and (min-width: 768px) {
        height: 29rem;
        margin-top: 11rem;
    }

    @media all and (min-width: 890px) {
        height: 32rem;
        margin-top: 13rem;
    }

    @include desktop {
        height: 35rem;
        margin-top: 5rem;
    }

    @media all and (min-width: 1120px) {
        height: 40rem;
        margin-top: 7rem;
    }

    @media all and (min-width: 1340px) {
        margin-top: 9rem;
    }

    @media all and (min-width: 1440px) {
        width: 72rem;
        height: 40.25rem;
        margin-top: 9rem;
    }
}

.christmas-events-page__header {
    background-color: $color-primary-dark;
    color: $color-white;
    padding: 2rem 3rem;
    display: flex;
    justify-content: center;

    @include desktop {
        padding: 3.375rem 2rem;
    }

    h1 {
        font-size: 1.5rem;
        line-height: 2.25rem;
        position: relative;
        padding: 0;
        width: fit-content;
        text-align: center;

        &::after {
            left: 35%;
            bottom: -8px;
            width: 30%;
        }

        @media (min-width: 520px) {
            &::after {
                left: 0;
                width: 6.5rem;
            }
        }

        @include desktop {
            text-align: left;
            font-size: 3.125rem;
            line-height: 4.6875rem;

            &::after {
                bottom: 0;
            }
        }
    }
}

.christmas-page__mobile-header {
    z-index: 100;

    .fts-logo {
        position: absolute;
        top: -1rem;
        left: 1rem;
        right: 0;
        margin: 0 auto;
        width: 8rem;
        object-fit: contain;

        img {
            object-fit: contain;
            top: 0;
        }

        @include mobile-small {
            width: 10rem;
            top: 0rem;
        }

        @media all and (min-width: 540px) {
            top: 1rem;
            left: 2rem;
        }

        @include mobile {
            width: 12rem;
            top: 4rem;
        }
    }

    img {
        object-fit: cover;
        min-height: 10rem;
        top: -2rem;
        position: relative;

        @include mobile-small {
            top: -4rem;
        }
    }

    @include desktop {
        display: none;
    }
}

.christmas-results-page__wrapper {
    position: relative;

    &::after {
        display: block;
        content: '';
        position: absolute;
        bottom: 0rem;
        height: 20rem;
        width: 100%;
        background-image: url('../img/christmas_events_bg.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: -1;

        @include desktop {
            height: 30rem;
        }

        @include desktop-large {
            height: 40rem;
        }
    }
}

.christmas-results-page__container {
    min-height: 42rem; // Need enough space for calendar dropdown to show
    padding-bottom: 3rem;

    .side-content {
        background-color: $color-white;
    }

    .results-content {
        margin: 0 -1rem;
        padding: 2rem 1rem;
    }

    @include desktop {
        padding-top: 16rem;
        display: flex;
        column-gap: 2rem;
        min-height: 44rem; // Need enough space for calendar dropdown to show

        .side-content {
            background: none;
        }

        .results-content {
            background-color: unset;
            margin: 0;
            padding: 0;
        }
    }

    @include desktop-large {
        padding-top: 22rem;
    }
}

.christmas-landing {
    .christmas-page__mobile-header {
        position: absolute;
        width: 100%;
    }

    .page__wrapper {
        position: relative;
    }

    .page__wrapper:before {
        content: '';
        display: block;
        background: url(../img/christmas_boxes_bg_left.svg);
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        width: 50rem;
        height: 22rem;
        bottom: 38rem;
        left: 0;

        @include mobile-small {
            bottom: 30rem;
        }

        @include desktop {
            width: 50rem;
            height: 22rem;
            bottom: 9rem;
            left: 0;
        }

        @include desktop-large {
            width: 50rem;
            height: 22rem;
            bottom: 6rem;
            left: 0;
        }
    }

    .page__wrapper:after {
        content: '';
        display: block;
        background: url(../img/christmas_boxes_bg_right.svg);
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        width: 50rem;
        height: 22rem;
        bottom: 3rem;
        right: -14rem;
        z-index: -1;

        @include desktop {
            width: 50rem;
            height: 22rem;
            bottom: 8rem;
            right: -12rem;
        }

        @include desktop-large {
            width: 50rem;
            height: 22rem;
            bottom: 4rem;
            right: -10rem;
        }
    }
}

.christmas-content-boxes {
    margin-bottom: 4.125rem;

    div {
        background-color: $color-white;
        border: 1px solid $color-crimson;
        margin: 1.375rem 1.375rem 4rem 1.375rem;
        padding: 3rem 3rem;
        position: relative;
    }

    @include mobile {
        padding: 2rem 3rem;

        div {
            padding: 3.75rem 5rem;
        }
    }

    @include desktop {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1.375rem;
        margin-left: 1.375rem;
        margin-right: 1.375rem;

        div {
            margin: 0;
        }
    }

    .box-one:before {
        content: '';
        display: block;
        background: url(../img/christmas_star.svg);
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        width: 8rem;
        height: 8rem;
        top: -4rem;
        left: 0rem;

        @include mobile {
            width: 11rem;
            height: 11rem;
            top: -5.5rem;
            left: 1rem;
        }
    }

    .box-two:before {
        content: '';
        display: block;
        background: url(../img/christmas_tree.svg);
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        width: 2.75rem;
        height: 4.5rem;
        top: -2.25rem;
        left: 3rem;

        @include mobile {
            width: 3.125rem;
            height: 5.125rem;
            top: -2.5rem;
            left: 5rem;
        }
    }
}

.christmas-pagination {
    padding: 0;

    @include mobile {
        justify-content: flex-end;
    }

    .pages {
        align-items: flex-end;
    }

    .btn {
        background: none;
        border: none;
        border-bottom: 2px solid transparent !important;
        color: $color-navy;
        padding: 0.875rem 1rem;
        margin: 0 0.25rem;
        text-transform: none;
        font-size: 1rem;
        width: unset !important;
        height: unset !important;

        &:hover {
            border-bottom: 2px solid $color-navy !important;
        }
    }

    .btn.next,
    .btn.prev {
        font-weight: bold;
        background-color: $color-yellow;
        color: $color-navy;
        border-bottom-color: $color-yellow !important;

        &:hover {
            border-bottom-color: $color-navy !important;
            background-color: $color-navy;
        }
    }

    .pages #pages__current {
        background: none;
        color: $color-navy;
        border: none !important;
        border-bottom: 2px solid $color-navy !important;
    }
}
