ol {
    margin: 0;
    padding: 0;
    counter-reset: custom-counter;
    list-style: none;

    li {
        position: relative;
        padding: 0 0 0 $width-gap*3;

        + li { margin-top: $width-gap/2 }

        &:before {
            display: inline-block;
            width: 3rem;
            content: counter(custom-counter)'.';
            counter-increment: custom-counter;
            position: absolute;
            left: $width-gap;
            top: 0;
            color:$color-active;
            font-weight: bold;
        }
    }
}
