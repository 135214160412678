.file__attachment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    padding-top: $width-gap;
    border-top: 3px solid $color-line;
    margin-top: $width-gap*1.5;

    h4 {
        min-width: 0;
        display: inline-flex;
        align-items: center;
        margin-bottom: 0;


        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1;
            margin-right: $width-gap*0.75;
        }

        .icon {
            width: 2rem;
            height: 2rem;
            padding: 0.125rem;
            border-radius: 50%;
            border: 3px solid $color-grey-light;
            color: $color-grey-light;
            margin-right: $width-gap*0.75;
            flex-shrink: 0;
        }
    }
}
