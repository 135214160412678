.messages {
    list-style: none;
    padding: 0.6125rem 1rem;
    margin: 0;
    background: darken($color-status-green, 15%);
    color: #fff;
    font-weight: bold;
    font-size: 0.875rem;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-100%);
    animation: show-hide 4s ease-in-out;
    line-height: 1.5;

    li {
        display: block;
    }
    &__archive-disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
    }
}

.messages__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    .messages__archive_hint {
        display: flex;

        .custom__checkbox {
            margin-left: 0.5rem;
            margin-right: 0;
            border-radius: 50%;
            border-color: $color-text;

            .icon {
                display: inline-block;
                fill: $color-text;
            }
        }
    }
    @media all and (max-width: 500px) {
        display: block;
    }
}

.messages__list {
    padding-left: 0 !important;

    li {
        display: flex;
        flex-flow: row nowrap;
        padding: 1rem 0 1rem 0.5rem;
        border-top: 2px solid $color-line;

        .controls__checkbox {
            width: auto;
            margin-top: 0rem;
            .custom__checkbox {
                margin-right: 0;
                border-radius: 50%;
                border-color: $color-text;

                .icon {
                    fill: $color-text;
                }
                &:hover {
                    border-color: $color-primary;
                }
            }
            input[type='checkbox']:focus + label {
                .custom__checkbox {
                    border-color: $color-primary;
                }
            }
            input {
                position: absolute;
                left: -99999px;
            }
        }

        &.message-read {
            .messages__icon {
                opacity: 0.2;
            }
            .messages__detail,
            .messages__meta {
                opacity: 0.4;
            }
        }

        a {
            text-decoration: none;
        }

        @media all and (max-width: 768px) {
            display: block;
            padding: 1rem 0;
            > * {
                display: none;
                &:nth-child(2) {
                    display: block;
                }
            }
            .controls__checkbox {
                margin-top: 0.25rem;
            }
        }
    }
}

.messages__icon {
    padding-right: 1rem;

    .icon {
        width: $width-gap * 1.55;
        height: $width-gap * 1.55;
    }
    @media all and (max-width: 768px) {
        display: inline-block;
        vertical-align: top;

        .icon {
            width: 2rem;
            height: 2rem;
        }
    }
}

.messages__meta {
    display: inline-block;
    min-width: 13.5rem;
    width: auto;
    margin-bottom: 1rem;

    a {
        .message__name {
            display: block;
            font-family: $base-font;
            font-size: 0.75rem;
            line-height: 1.7;
        }
    }

    .controls__checkbox {
        display: inline-block;
        margin-left: auto;
        padding-left: 0.5rem;
    }

    small {
        display: block;
        line-height: 1.7;
    }

    > .tag {
        display: none;
    }

    p {
        margin-top: 0.5rem;
    }

    .mobile-layout {
        .tag {
            margin: 0.75rem 0.25rem 0.75rem 0;
        }
        .mobile-meta-row {
            display: flex;
            h4 {
                padding-top: 0.25rem;
            }
        }
    }

    @media all and (min-width: 768px) {
        width: calc(25% - 3.8125rem);
        min-width: 12rem;
        padding-right: 2.5%;

        a {
            margin-top: 0;
            .message__name {
                margin-bottom: 0.25rem;
                font-size: 1.4375rem;
                font-family: $base-headline-font;
                line-height: 1.2;
            }
        }

        .mobile-layout {
            display: none;
        }

        .controls__checkbox {
            display: block;
            margin-left: 0rem;
        }

        > .tag {
            display: inline-block;
            width: auto;
            margin-top: 0.25rem;
        }

        small {
            display: block;
            margin-left: 0;
        }

        .mobile-detail {
            display: none;
        }
    }

    @media all and (min-width: 1300px) {
        min-width: 15.5rem;
    }
}

.messages__detail {
    flex: 1;
    padding-right: 5%;
    overflow: hidden;

    h4 {
        margin-bottom: 0.5rem;
    }

    p {
        margin-top: 0.5rem;
    }

    @media all and (min-width: 768px) {
        padding-right: 8%;
    }
}

.message__detail {
    display: flex;
    flex-flow: row nowrap;
    padding: $width-gap;

    a {
        text-decoration: none;
    }

    .mobile-layout {
        display: none;
    }

    .messages__meta_row {
        display: flex;
        padding: 0.75rem 0;
        border-bottom: 2px solid $color-line;

        .icon {
            width: $width-gap * 1.55;
            height: $width-gap * 1.55;
            margin-right: 0.5rem;
            fill: lighten($color-text, 20);
        }
        .custom__checkbox {
            border-radius: 50%;
            border-color: lighten($color-text, 20);

            .icon {
                display: inline-block;
                margin-right: 0;
                width: 1rem;
                height: 1rem;
            }
        }

        .messages__meta_text {
            flex: 1;
        }

        &:first-child {
            padding-top: 0;
        }
    }

    @media all and (min-width: 500px) and (max-width: 768px) {
        .desktop-layout {
            padding-right: 1.5rem;
        }
    }

    @media all and (max-width: 500px) {
        display: block;

        .mobile-layout {
            display: block;
        }

        .desktop-layout {
            display: none;
        }

        .messages__meta {
            width: 100%;
            margin-bottom: 1rem;
        }

        .messages__meta_row {
            padding: 0.25rem 0;
            border-bottom: 0;
            display: flex;
            align-items: center;
            span {
                height: 1.75rem;
                margin-left: 1rem;
            }
        }

        .messages__detail {
            display: none;
        }
    }

    @include desktop {
        padding: 0;
    }
}

.message__detail_buttons {
    display: flex;
    justify-content: flex-end;
    margin: $width-gap;

    input {
        height: 100%;
        margin-right: $width-gap;
    }

    form {
        padding: 0 !important;
    }

    @media all and (max-width: 500px) {
        display: block;

        input {
            margin-bottom: 1rem;
        }
    }
}

@keyframes show-hide {
    0% {
        transform: translateY(-100%);
    }
    12.5% {
        transform: translateY(0%);
    }
    87.5% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-100%);
    }
}
