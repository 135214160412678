.button-row,
.btn-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin-top: $width-gap * 1.5;

    &.center {
        justify-content: center;
    }
    &.left {
        justify-content: flex-start;
    }

    .button + .button,
    .btn + .btn {
        margin-left: $width-gap/2;
    }
}

.button,
.btn {
    display: inline-block;
    padding: $width-gap/2;
    border: 3px solid $color-action;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;

    &.left {
        margin-right: auto;
    }

    &.small {
        font-size: 0.875rem;
        padding: 0 $width-gap/2;
    }

    &.large {
        padding: 0.6875rem 2rem;
        font-weight: bold;
        line-height: 1.5;
    }

    &.full {
        width: 100%;
        text-align: center;
        max-width: 22.5rem;

        &.margin-right {
            margin-right: 0.3rem;
        }
    }

    &.wide {
        padding: $width-gap/2 $width-gap * 2;
    }

    &:hover {
        background: $color-action-light;
    }

    &:disabled {
        cursor: not-allowed;

        &:hover {
            background: none;
        }
    }

    &.primary {
        border: 3px solid $color-primary;
        color: #fff;
        background: $color-primary;

        &:hover {
            background: darken($color-primary, 5%);
        }
    }

    &.delete {
        border: 3px solid $color-error;

        &:hover,
        &.active {
            background: rgba($color-error, 0.4);
        }
    }

    &.error {
        border: 3px solid $color-error;
        color: $color-white;
        background: $color-error;

        &:hover,
        &.active {
            border-color: darken($color-error, 5%);
            background: darken($color-error, 5%);
        }
    }

    &.urgent-delete {
        border: 3px solid #b3001b;
        background: #b3001b;
        color: #fff;
        &:hover,
        &.active {
            background: rgba(#b3001b, 0.6);
        }
    }

    &.secondary {
        border: 3px solid $color-secondary;
        color: #fff;
        background: $color-secondary;

        &:hover {
            background: darken($color-secondary, 5%);
        }
    }

    &.action {
        background: $color-action;
        color: #fff;

        &:hover {
            background: darken($color-action, 5%);
            border-color: darken($color-action, 5%);
        }

        &:disabled:hover {
            cursor: not-allowed;
            background: $color-action;
            border-color: $color-action;
        }

        &.action--dark {
            color: $color-text;
        }
    }

    &.grey {
        border: 3px solid $color-grey !important;
        background: $color-grey !important;

        &:hover {
            background: darken($color-grey, 5%) !important;
        }

    }

    &.grey-outline {
        border: 3px solid #d8d8d8;
        background: $color-background;
    }

    &.back {
        position: relative;

        &:hover:before {
            color: darken($color-action, 5%);
        }

        &:before {
            color: $color-action;
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: -1.1em;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 1em 1em 1em 0;
            border-color: transparent currentColor transparent transparent;
            transform: translateY(-50%);
        }
        &.faded:hover {
            opacity: 1 !important;
        }
    }
    &.next {
        height: 2.5rem;
        width: 2.5rem;
        position: relative;

        .icon {
            position: absolute;
            width: 1.2rem;
            height: 1.2rem;
            top: 0.5rem;
            left: 0.5rem;
        }
    }
    &.align-center{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.btn .icon,
button .icon {
    display: inline-block;
    margin-right: 0.5rem;
}

.btn .icon--right {
    margin-right: 0;
    margin-left: 0.5rem;
}

.section-header-btn {
    padding: $width-gap * 1.5 $width-gap $width-gap * 1.5 $width-gap * 1.5;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 !important;
    color: $color-background;
    text-decoration: none !important;
    &.events {
        background: $color-tertiary;
    }
    &.news {
        background: #61b1b9;
    }
    &.benefice {
        background: $color-primary;
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
        .panel__icon-btn {
            width: 2.5rem;
        }
        @media all and (min-width: 768px) {
            margin-top: 0 !important;
        }
    }
}

.button-sticky {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    pointer-events: none;

    .button{
        pointer-events:all;
    }
}

.btn--live {
    display: flex !important;
    align-items: center;
    padding: 0.5rem 0.75rem;
    background-color: $color-turquoise;
    color: $color-white;
    border: none;
    cursor: pointer;

    .icon {
        fill: $color-white;
        width: 1rem;
        height: 1rem;
        margin-right: 0;
    }

    &:hover,
    &:focus {
        background-color: darken($color-turquoise, 10%);
    }
    +.btn--live{
        margin-left: 0.5rem;
    }
}
.btn-inline {
    .button {
        text-transform: none;
        width: 100%;
        max-width: 11rem;
        margin-bottom: 1rem;
        svg {
            margin-left: 0.625rem;
        }
    }
    @media all and (min-width: 1024px) {
        display: flex;
        justify-content: flex-end;
        .button {
            margin-right: 1rem;
            text-transform: none;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            width: auto;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}

.venue__panel-btn {
    position: relative;
    width: auto;
    height: auto;
    padding: #{$width-gap * 0.625} #{$width-gap * 0.675} #{$width-gap * 0.9} ;
    line-height: 1.125rem;
    span {
        font-size: 1.375rem
    }
    .icon {
        position: relative;
        width: 1.2375rem;
        height: 1.2375rem;
        margin: 0 #{$width-gap * 0.45};
        vertical-align: middle;
    }
}

.button__download {
    display: flex;
    align-items: center;
    &-xmas {
        background-color: $color-yellow !important;
        border: none;
        font-size: 1rem!important;
        color: $color-white !important;
        padding-top: 0;
        padding-bottom: 0;
        height: auto !important;
    }
}
.text-only {
    background: none;
    border: none;
    text-decoration: underline;
    color: #5c5c5c;
    font-size: 0.95rem;
    cursor: pointer;
}
