/*
 * The styles for Big Church Survey forms are encapsulated under the parent
 * class on purpose as they are slightly different from the styles in the rest
 * of the project.
 */
.big-survey {
    .alert-block {
        padding-top: 1.25rem;
        padding-bottom: 0.125rem;
        background-color: $color-error;
    }

    .error {
        color: $color-error;
    }

    .alert-error ul {
        font-size: 1rem;
        font-weight: bold;
        text-transform: none;
    }

    .help-block,
    .help-inline {
        display: block;
        width: 100%;
        margin-top: 0.375rem;
        margin-bottom: 1.25rem;
    }

    // Copied verbatim from other css files - not a great solution, but those
    // styles are nested under classes that will mess with other styles here,
    // so it's safer just to copy the styles as I don't have the time to
    // untangle all the styles and ensure no breaking changes elsewhere
    input:not([type='checkbox']):not([type='radio']):not([type='submit']),
    textarea {
        appearance: none;
        width: 100%;
        padding: $width-gap * 0.75 $width-gap;
        border-radius: $width-radius;
        background: #fff;
        vertical-align: top;
        font-size: 16px;
        font-family: inherit;
        -webkit-font-smoothing: antialiased;
        border: 3px solid $color-grey;
        color: inherit;
        line-height: inherit;
        resize: vertical;

        &:focus {
            outline: none;
            border-color: darken($color-grey, 15%);
        }
    }

    input[type='number'] {
        max-width: 10rem;
    }

    .radio-select .help-block,
    .radio-select .help-inline {
        margin-top: 0;
    }

    .controls__checkbox {
        position: relative;

        input {
            position: absolute;
            left: 0;
            opacity: 0;
        }

        label {
            margin-bottom: 0.5rem;
            padding-top: 0;
        }
    }

    .radio-select {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        label {
            margin-right: 1rem;
            margin-bottom: 1rem;
            padding: 0.3125rem 1rem;
            border: 3px solid $color-grey;
        }

        input {
            margin-right: 0.5rem;
        }

        .radio-select__group-label {
            flex-basis: 100%; // Force newline
            margin-top: 1rem;
            margin-bottom: 0.5rem;
        }
    }

    .control-label {
        display: block;
        margin-bottom: 1.25rem;
        font-size: 1.25rem;
    }

    .note {
        margin-top: 2rem;
        font-size: 1rem;
    }

    .control-group + .control-label {
        margin-top: 1rem;
    }

    .label-other .control-label {
        margin-bottom: 0.75rem;
        font-size: 1rem;
    }

    .label-subquestion .control-label {
        margin: 1rem 0;
        font-size: 1rem;
    }

    .radio-column .radio-select {
        flex-direction: column;

        .radio {
            width: fit-content;
        }
    }
}


// Styles for the optional questions editing forms

table.question-list {
    font-size: 1rem;
    margin: 1.5rem 1rem;
    max-width: 100%;

    th {
        a {
            text-decoration: none;
        }
    }

    tr th:first-child {
        width: 70%;
    }

    tr th:not(:first-child) {
        text-align: center;
    }

    .column__question {
        display: flex;
        padding: 0.5rem 0.5rem 0.5rem 0;

        @media all and (min-width: 768px) {
            font-size: 1.2rem;
        }
    }

    .column__enabled,
    .column__edit {
        text-align: center;

        .icon {
            width: 2rem;
            height: 2rem;
            margin: 0 auto;
        }
    }

    @media all and (max-width: 767px) {
        font-size: 0.8rem !important;

        th {
            white-space: normal;
            font-size: 0.75rem;
        }

        td {
            width: 30%;

            &:first-of-type {
                width: 100%;
            }

            &:last-of-type {
                width: 20%;
            }
        }
    }

    @include desktop {
        margin: 1.5rem 0;
    }
}

.edit-question-form {
    form {
        padding-top: 0;

        @include desktop {
            padding-left: 0;
        }
    }

    input[type='checkbox'] {
        margin-top: 0 !important;
        position: absolute;
    }

    .controls__checkbox .checkbox {
        margin-left: 0;
    }
}

#beneficesurveyform {
    input[type='checkbox'] {
        margin-top: 0;
    }
}
