.main-content .form__holder {
    padding-top: 0;
    margin-top: 0;

    @include desktop {
        position: relative;
        padding-top: $width-gap * 2.5;
        margin-top: $width-gap * 1.5;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 1rem;
            width: 100%;
            background: $color-background-lighter-grey;
            width: calc(100% + #{$width-gap * 4});
        }
    }

    + .form__holder {
        margin-top: $width-gap * 2;
    }

    .headline {
        font-size: 1.3125rem;
        color: $color-active;
        padding-top: 2.5rem;
        margin-top: 1.5rem;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 1rem;
            width: calc(100% + #{$width-gap * 2});
            background: $color-grey;

            @include desktop {
               background: none;
               width: calc(100% + #{$width-gap * 4});
            }
        }

        &:after {
            display: none;
        }

        .headline__meta {
            color: $color-grey-light;
        }

        @include desktop {
            font-size: 2.25rem;
            color: $color-text;
            padding-top: 0;
            margin-top: 0;

            &:after {
                display: inline-block;
            }
        }
    }
    body.admin-view & {
        &:before {
            background: $color-background-lighter-grey;
        }
    }
}

// adds padding to take into account button bar and cookie/zendesk icon on mobile
.main-content form {
    padding: 1rem 1rem 10rem;

    @include desktop {
        padding-bottom: 0;
    }
}

#deleteform {
    text-align: center;

    @include desktop {
        text-align: left;
    }
}

.form__holder.whats-on,
.form__holder.news-panel {
    .custom__checkbox {
        border-radius: 50%;
        .icon {
            display: none !important;
        }
    }
    input[type='checkbox']:checked + label .custom__checkbox {
        &:after {
            content: '';
            border-radius: 50%;
            width: 0.6rem;
            height: 0.6rem;
            background: $color-primary;
        }
    }
    .custom__select-toggle-text {
        margin-left: 2rem;
    }
    .custom__select-dropdown {
        margin-left: 2rem;
        min-width: calc(100% - 2rem);
    }
}

.form__narrow {
    max-width: 22.5rem;
}

.form__label-text {
  margin-bottom: 1.25rem;
}

#hint_id_description.help-block {
  color: $color-active;
  margin-top: 0rem;

    @include desktop {
        color: #000000;
        margin-top: 1rem;
    }
}

.main-content form,
#lightbox form {
    fieldset {
        padding: 0;
        margin: 0;
        border: none;

        legend {
            @extend .headline;

            font-size: 2.25rem;
            line-height: 1.5;
            margin-bottom: 0.66em;
            width: 100%;

            + p {
                margin-top: 0;
            }
        }

        + fieldset {
            position: relative;
            padding-top: $width-gap * 2.5;
            margin-top: $width-gap * 1.5;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 1rem;
                width: 100%;
                background: $color-grey;

                @include desktop {
                    width: calc(100% + #{$width-gap * 4});
                }
            }
        }
    }

    .wysiwyg__fieldset,
    .image__fieldset,
    .tags__fieldset {
        .control-group > label {
            font-size: 1.3125rem;
            position: relative;
            padding-bottom: 0.25em;
            line-height: 1.5;
            margin-bottom: 0.45em;
            font-family: $base-headline-font;
            text-transform: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $color-active;

            @include desktop {
                color: $color-text;
                font-size: 2.25rem;
            }

            &:after {
                display: none;

                @include desktop {
                    content: '';
                    display: inline-block;
                    width: 6.5rem;
                    height: 3px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    background: $color-primary;
                }
            }
        }

        .asteriskField {
            display: none;
        }

        .control-group {
            position: relative;
            padding-top: $width-gap * 2.5;
            margin-top: $width-gap * 1.5;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 1rem;
                width: calc(100% + #{$width-gap * 2});
                background: $color-grey;

                body.admin-view & {
                    background: $color-background-lighter-grey;
                }

                @include desktop {
                    width: calc(100% + #{$width-gap * 4});
                }
            }
        }
    }

    .form__tabs {
        margin-top: $width-gap * 1.5;
    }

    .form__conditional-fields,
    .form__section {
        margin-top: $width-gap * 1.5;
    }

    .wysiwyg__fieldset {
        .help-block {
            background: none;
            color: $color-active;

            @include desktop {
                color: $color-text;
                padding-top: 0.5rem;
            }
        }
    }

    input:not([type='checkbox']):not([type='radio']):not([type='submit']),
    textarea,
    select,
    .medium-wysiwyg {
        appearance: none;
        width: 100%;
        padding: $width-gap * 0.75 $width-gap;
        border-radius: $width-radius;
        background: #fff;
        vertical-align: top;
        font-size: 16px;
        font-family: inherit;
        -webkit-font-smoothing: antialiased;
        border: 3px solid $color-grey;
        color: inherit;
        line-height: inherit;
        resize: vertical;

        &:focus {
            outline: none;
            border-color: darken($color-grey, 15%);
        }
    }

    .medium-wysiwyg {
        border-width: 0 0 2px 0;
        padding: $width-gap * 0.75 0;
    }

    .select__holder {
        display: flex;
        flex-flow: row nowrap;
        position: relative;

        select {
            padding-right: $width-gap * 3;
        }

        .icon {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: $width-gap * 3;
            padding: $width-gap * 0.75;
            border: 3px solid $color-action;
            pointer-events: none;
        }
    }

    input[type='checkbox'] {
        opacity: 0;
        margin-top: 2rem;
    }

    label[for='id_terms'] {
        margin-top: -3rem;
    }

    .checkbox,
    .radio {
        input {
            margin-right: 0.375rem;
        }

        label {
            text-transform: none;
        }
    }

    label {
        display: block;
        width: 100%;
        margin-bottom: 0.75rem;
        user-select: none;
        text-transform: initial;

        @include desktop {
            font-size: 1.3125rem;
            color: $color-text;
            text-transform: uppercase;
        }
    }

    .control-group {
        position: relative;
    }

    small {
        color: $color-active;
    }

    small + * {
        margin-top: 0.5rem;
    }

    label[for=id_link].control-label,
    label[for=id_frequency].control-label {
        text-transform: uppercase;
    }

    label[for=id_address].control-label {
        display: flex;
        justify-content: space-between;
    }

    .form__header {
        color: $color-active;
        font-family: $base-headline-font;
        font-size: 1.3125rem;

        ~ .asteriskField {
            color: $color-active;
        }

        @include desktop {
            color: $color-text;
            font-family: $base-font;
            text-transform: unset;

            ~ .asteriskField {
                color: $color-text;
            }
        }


    }

    .control-group + .control-group,
    .form-group + .form-group,
    .control-group + .form-halves {
        margin-top: $width-gap;
    }

    .control-label .label__meta {
        font-family: Cabin;
        color: $color-grey-light;
    }

    .hint-icon {
        display: inline-block;
        cursor: pointer;
        color: $color-grey-light;
        vertical-align: middle;
        width: 1.5rem;
        height: 1.5rem;

        @include desktop {
            display: none;
        }
    }

    .hint-close {
        cursor: pointer;
        position: absolute;
        right: 1rem;
        top: 1rem;
        width: 1.5rem;
        height: 1.5rem;

        @include desktop {
            display: none;
        }
    }

    .field-info {
        position: absolute;
        right: 0;
    }

    .help-inline,
    .help-block {
        vertical-align: top;
        color: $color-active;
        font-family: $base-font;
        margin-top: 0.25rem;

        @include desktop {
            display: block;
            vertical-align: top;
            color: $color-text;
            font-size: 0.875rem;
            margin-top: 0.25rem;
        }

        &.modal-display {
            display: block !important;
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: 200; // needs to be above the save bar
            top: 0;
            left: 0;
            margin: 0;
            padding: 4rem 1rem;

            background-color: $color-primary;
            color: $color-white;
            font-size: 1rem;
        }
    }

    .hint-fieldset {
        > .control-group > .controls,
        &.custom {
            @include desktop {
                display: flex;
                flex-flow: row wrap;
                align-items: flex-start;
            }
            > input,
            > div {
                flex: 1;

            }
            .help-block,
            .help-inline {
                display: none;

                @include desktop {
                    display: block;
                    width: 100%;
                    flex-shrink: 0;
                    font-size: 1rem;
                    font-family: $base-font;
                    margin-top: 0.75rem;
                    padding-top: 0.5rem;
                    color: #8B63A1;
                }
            }

            &.caption {
                .control-label {
                    display: none;
                }
                textarea {
                    max-height: 14rem;
                    @media all and (min-width: 400px) {
                        max-height: 10rem;
                    }
                    @media all and (min-width: 1200px) {
                        max-height: 8rem;
                    }
                }
            }

            @include desktop {
                flex-flow: row wrap;
                margin-bottom: 2rem;

                .custom {
                    margin-bottom: 0.5rem;
                }

                > input, > div {
                    margin-right: 3rem;
                    .custom__field{
                        margin-right: 0rem;
                    }
                    &.control-group {
                        margin-right: 0.5rem;
                    }
                }
                .help-block,
                .help-inline {
                    margin: -0.25rem 0 0 auto !important;
                    padding-top: 0;
                    width: 30%;

                    #error_2_id_publish_at {
                        margin: 0 0 0 2rem;
                    }
                }
            }
        }
        &.donations {
            margin-bottom: 0.3rem;
        }

    }

    .error {
        input:not([type='checkbox']):not([type='radio']):not([type='submit']),
        textarea,
        select {
            border-color: $color-error;
            text-overflow: ellipsis;
        }

        .mediumeditorwidget {
            border-color: $color-error;
        }

        .help-inline {
            background: $color-error;
            color: #fff;
            padding: $width-gap/2;
            margin-top: 0;
        }
    }

    // form splitting

    .form-halves {
        + .form-halves,
        + .control-group,
        + .form-group {
            margin-top: $width-gap;
        }

        @media all and (min-width: 768px) {
            display: flex;
            flex-flow: row nowrap;

            > div {
                width: calc(50% - (#{$width-gap} / 2));
                flex-shrink: 0;
            }

            > div + div {
                margin-left: $width-gap;
            }

            .control-group + .control-group,
            .form-group + .form-group {
                margin-top: 0;
            }
        }
    }

    .form-thirds {
        + .form-thirds,
        + .control-group,
        + .form-group {
            margin-top: $width-gap;
        }

        @media all and (min-width: 768px) {
            display: flex;
            flex-flow: row nowrap;

            > div {
                width: calc(33% - (#{$width-gap} / 3));
                flex-shrink: 0;
            }

            > div + div {
                margin-left: $width-gap;
            }

            .control-group + .control-group,
            .form-group + .form-group {
                margin-top: 0;
            }
        }
    }
}

#day-week-month-selector {
    margin-bottom: 1rem;
}

#day-week-month-selector input[type='checkbox'] {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

.errorlist,
.alert-error ul {
    list-style: none;
    margin: 0 0 $width-gap 0;
    padding: 0.125rem 0.5rem;
    background: $color-error;
    color: #fff;
    font-size: 0.875rem;
    text-transform: uppercase;
}
.errorlist.nonfield {
    // TODO - hide ???
}

#lightbox form .button__holder:before {
    width: calc(100% + #{$width-gap * 2});

    @include desktop {
        width: calc(100% + #{$width-gap * 4});
    }
}

.account-page__wrapper .container .main-content form .button__holder:before {
    width: calc(100% + #{$width-gap * 2});
    left: 50%;

    @include desktop {
        width: calc(100% + #{$width-gap * 4});
    }
}

body .mediumeditorwidget {
    min-height: 10em;
    outline: none;
}

input[type=text] {
    appearance: none;
    width: 100%;
    padding: $width-gap * 0.75 $width-gap;
    border-radius: $width-radius;
    background: #fff;
    vertical-align: top;
    font-size: 16px;
    font-family: inherit;
    -webkit-font-smoothing: antialiased;
    border: 3px solid $color-grey;
    color: inherit;
    line-height: inherit;
    resize: vertical;

    &:focus {
        outline: none;
        border-color: darken($color-grey, 15%);
    }
}

// add a class to the cookie icon (with JS) to move it up when form button bar is showing
#ccc-icon.shift-up {
    bottom: 40px;

    @include desktop {
        bottom: 0;
    }
}

#getintouchform {
    .form-wrapper {
        padding-top: 10px;
    }
    .iframe-wrapper {
        display: block;
        margin-top: 20px;
        padding: 0px;
        overflow: hidden;
    }
    iframe {
        overflow: hidden;
        height: 100vh;
        width: 100%;
    }
}


#launcher {
    bottom: 3rem !important;
    left: 0 !important;
    @include desktop {
        bottom: 100px !important;
        left: unset !important;
    }
}
.benefice-page__main form {
    .control-label,
    #id_page_title_label,
    #id_page_title_label ~ .asteriskField,
    &#beneficeeventform h3.headline {
        color: $color-text !important;
        text-transform: none;
    }
    .help-block {
        color: $color-primary !important;
    }
    .field--benefice {
        &.image__fieldset {
            .control-group {
                &:before {
                    content: none;
                }
                > .control-label {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    &:after {
                        content: none;
                    }
                }
                .custom__image-field {
                    margin: 1.25rem 0 0;
                }
            }

        }
        + .field--benefice {
            margin-top: 2rem;
        }
    }

    #div_id_publish_to_venues,
    #div_id_venues_location {
        flex: 1 0 100%;
        margin-bottom: $width-gap;
        @include desktop {
            flex: 1 0 50%;
            & ~ .help-block {
                width: 30%;
                font-size: 1rem;
            }
        }
        .controls {
            display: flex;
            flex-flow: row wrap;
            gap: $width-gap;

            @include desktop {
                padding: 0;
            }

            > .controls__checkbox {
                flex: 1 1 48%;
                input[type='checkbox'], .checkbox {
                    margin: 0;
                }
                .checkbox {
                    padding: 0;
                    text-transform: capitalize;
                }
            }
        }
    }

    &#beneficeaboutusform,
    &#beneficeinfoform,
    &#beneficeeventform,
    &#beneficeeventupdateform {
        .control-label:not(#id_file_attachment), .field--benefice .control-label {
            font-family: $base-font !important;
            font-size: 1.3125rem !important;
            line-height: 24px !important;
        }
    }

    &#beneficeeventform, &#beneficeeventupdateform {
        padding: 0;
        > * {
            padding: #{$width-gap * 1.5} $width-gap;
            border-bottom: 1rem solid $color-background-lighter-grey;
            @include desktop {
                padding: #{$width-gap * 1.5} #{$width-gap * 2};
            }
        }
        h3.headline {
            @include smaller-than-desktop {
                font-family: $base-font !important;
                font-size: 1.3125rem !important;
                line-height: 24px !important;
                &:after {
                    content: none;
                }
            }

            &:not(.headline--benefice-event) {
                margin-top: 0;
                padding-top: 0;
                @include desktop {
                    margin-top: -#{$width-gap};
                }
            }
            &.headline--venues {
                margin-bottom: 0;
            }
        }
        #heading--church-locations {
            margin-bottom: 0;
        }
        #div_id_regular_event {
            & ~ .headline {
                margin-top: 0;
            }
            & ~ .form__tabs-controller {
                @include smaller-than-desktop {
                    margin-left: -#{$width-gap} !important;
                }
            }
        }
        .button__holder,
        .control-group,
        .form__holder,
        .form__holder .headline {
            &:before {
                content: none;
            }
        }
        .button__holder {
            padding: 0;
        }
        .form__holder--optional {
            padding: 0 !important;
            > small, .control-group {
                padding: #{$width-gap * 1.5} $width-gap;
                @include desktop {
                    padding: #{$width-gap * 1.5} #{$width-gap * 2};
                }
            }
            > .headline {
                margin: $width-gap $width-gap 0 !important;
                @include desktop {
                    margin: 0 #{$width-gap * 2} !important;
                    padding-top: 0 !important;
                }
            }
            #div_id_link {
                padding-bottom: 0 !important;
                & ~ small {
                    @include desktop {
                        padding-top: 0 !important;
                        font-size: 1rem;
                    }
                }
            }
        }
        #div_id_tags,
        #div_id_feature_image,
        #div_id_file_attachment {
            margin-top: 0;
            .control-label {
                display: inline;
                width: auto;
                &:after {
                    content: none !important;
                }
                .headline {
                    width: 100%;
                    @include smaller-than-desktop {
                        margin-top: 0;
                        padding-top: 0;
                    }
                }
            }
        }
        #div_id_feature_image ~ .page-content {
            @include smaller-than-desktop {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
            }
        }
        #div_id_tags,
        #div_id_feature_image {
            padding-top: 0;
        }
        #div_id_tags #hint_id_tags {
            position: relative;
            top: 0;
            width: auto;
            margin-top: $width-gap;
            @include desktop-large {
                position: absolute;
                top: $width-gap * 2;
                width: 30%;
                margin-top: 0;
            }
            .hint-close {
                display: none;
            }
        }
        .button__holder,
        #div_id_name {
            border-bottom: none;
        }
        #div_id_name {
            padding-bottom: 0;
            + .control-group {
                padding-top: 0;
            }
        }
        .form__tabs {
            margin-top: 0;
        }
        .image__fieldset.file, #div_id_name {
            border-top: 1rem solid $color-background-lighter-grey;
        }
        .field__description {
            padding-bottom: $width-gap;
            font-size: 1rem;
        }
        .button__holder {
            @include desktop {
                padding: 0 $width-gap * 2;
                margin-top: $width-gap * 1.5;
            }
        }
        .custom-location-row {
            gap: $width-gap;
            .form__conditional-fields {
                flex: 1 0 100%;
                @include desktop {
                    flex: 1 0 50%;
                }
                + .help-block {
                    flex: 1 0 11.5%
                }
            }
        }
        #heading--custom-location {
            margin: #{$width-gap * 2} 0 0;
        }
    }
}
