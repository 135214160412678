html {
    font-size: 0.875rem;

    @media all and (min-width: 768px) {
        font-size: 1rem;
    }
}

body {
    font-family: $base-font, sans-serif;
    line-height: $base-line-height;
}

//// HEADLINES

// loop through heading size variable and assign sizes
@each $heading, $font-properties in $headline-scale {
    #{$heading},
    .#{$heading} {
        font-size: nth($font-properties, 1);
        line-height: nth($font-properties, 2);
        margin-top: nth($font-properties, 3) * 1;
        margin-bottom: nth($font-properties, 4) * 1;
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: $base-headline-font;
    font-weight: normal;
}

//// COMMON

a {
    color: inherit;
}

small {
    font-size: 0.75rem;
}

//// UTILITY CLASSES

.headline {
    position: relative;
    padding-bottom: 0.25em;
    margin-bottom: $width-gap * 1.5;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
        content: "";
        display: inline-block;
        width: 6.5rem;
        height: 3px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: $color-primary;
    }
    &.school-headline:after {
        background: $color-tertiary;
    }
    &.action-headline:after {
        background: $color-action;
    }
    &.error-headline:after {
        background: $color-error;
    }
}

.section__headline {
    display: flex;
    align-items: center;
    margin-bottom: 0.4rem;

    .icon {
        margin-right: $width-gap * 0.75;
    }
}

.inverse__headline {
    position: relative;
    margin: 0 auto;
    color: $color-background;
    padding-bottom: $width-gap/2;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 1.5rem;
    font-size: 1.4rem;
    margin: 3rem 0;
    &:before {
        position: absolute;
        content: "";
        background: $color-action;
        height: 3px;
        width: 6rem;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 0;
    }
}

.headline__meta,
.label__meta {
    margin-left: $width-gap;
    color: $color-grey-light;
    font-size: 1rem;

    .icon {
        width: 1.5rem;
        height: 1.5rem;
    }

    @include desktop {
        font-size: 1.5rem;
    }
}

.type__overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.bold {
    font-weight: bold;
}

.faded {
    opacity: 0.5;
}

.uppercase {
    text-transform: uppercase;
}

.text-center {
    text-align: center;
}

.pill {
    padding: 0.25em 0.5em;
    border-radius: 1em;
    color: #fff;

    &.low {
        background: $color-error;
    }
    &.medium {
        background: $color-status-orange;
    }
    &.height {
        background: $color-status-green;
    }
}

.break-sm-mobile {
    @media (max-width: 399px) {
        display: block;
    }
}

.mobile__section-header {
    color: $color-primary;
    font-size: 1.286rem;
    padding: 1rem;
    margin: 1rem 0 0 0 !important;
}
