.statistics {

    aside{
        padding: 0 1rem;
    }
    @include mobile {
        display: flex;
        aside {
            order: 1;
            margin-top: 1rem;
            width: 40%;
            padding: 0;
        }
    }
    aside {
        font-size: 0.875rem;
        font-weight: 800;
    }
}
.statistics-table {
    display: flex;
    flex-flow:row wrap;
    width: 100%;
    padding: 0 1rem;
    hr {
        width: 100%;
    }
    dt {
        color: $color-primary;
        flex-shrink: 0;
        text-transform: uppercase;
        font-weight: 300;
    }

    dt, dd {
        width: 40%;
        margin-bottom: 0.5rem;
    }
    dd{
        a{
            word-break: break-word;
        }
    }
}



