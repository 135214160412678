.user__table-entry {
    display: grid;
    grid-template-rows: 1fr min-content;
    grid-template-columns: 1fr 1fr;
    border-top: 3px solid $color-line;
    padding: $width-gap/2;

    > div { width: 100%; }

    .icon {
        flex-shrink: 0;
    }

    &.accept__set {
        background: rgba($color-status-green, 0.125);

        > div { border-color: $color-status-green; }
    }

    &.delete__set {
        background: rgba($color-error, 0.125);

        > div { border-color: $color-error; }
    }

    &:last-of-type { border-bottom: 3px solid $color-line; }

    .user__table-level {
        grid-column: span 2;
    }

    .user__table-controls {
        width: auto;
        margin-left: auto;
    }

    .user__table-permissions {
      padding: 0;
      display: flex;
      align-items: center;

      .custom__select-holder {
        flex-grow: 1;
        max-width: 12rem;
      }

      .custom__select-toggle-text {
        border: 0px;
      }
      .toggle-icon__holder {
        border: 0px;
      }

      .icon {
        fill: $color-grey-light;
      }
    }
}

.user__table-name {
    @extend .h4;

    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .icon { margin-right: $width-gap/2; }
}

.user__table-name-email {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-column: span 2;
}

@media all and (min-width: 768px) {

    .user__table-entry {
        padding: 0;
        grid-template-columns: 1fr 190px min-content min-content;

        > div {
            width: auto;
            padding: $width-gap;
        }

        > div + div { border-left: 3px solid $color-line; }

        .user__table-level {
            width: auto;
            grid-column: span 1;
        }

        .user__table-controls {
            max-width: 6rem;
            flex-grow: 0;
        }

        .user__table-email,
        .user__table-level {
            width: 10rem;
            flex-shrink: 0;
        }

        .user__table-email {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 0.875rem;
            margin-left: auto;
        }
    }

    .user__table-name-email {
        grid-column: 1;
    }
}

// Desktop size spacing - when the admin sidebar is showing,
// on desktop sizes the space for the table becomes smaller
@include desktop {
    .user__table-entry {
        grid-template-columns: 1fr min-content;

        > div + div { border-left: 0; }

        .user__table-level {
            width: auto;
        }

        .user__table-level,
        .user__table-name-email {
            grid-column: span 2;
        }
    }
}

@include desktop-large {
    .user__table-entry {
        grid-template-columns: 1fr 190px min-content min-content;

        > div + div { border-left: 3px solid $color-line; }

        .user__table-level {
            width: 10rem;
        }

        .user__table-level,
        .user__table-name-email {
            grid-column: span 1;
        }
    }
}

.user__table-name,
.user__table-email,
.user__table-level {
    a { text-decoration: none; }
}

.user__table-name {
    white-space: nowrap;
}

.user__message {
    font-style: italic;
    font-size: 0.875rem;
}

.user__table-level {
    margin: 0;
}

.user__table-controls {
    display: flex;
    justify-content: flex-end;
}

.accept__toggle { margin-right: $width-gap*0.75; }

.accept__toggle,
.delete__toggle {
    cursor: pointer;

    .icon {
        vertical-align: middle;
        width: 1.5rem;
        height: 1.5rem;
        fill: $color-grey-light;
    }
}

.icon--editor-1 {
    fill: $color-turquoise;
}

.icon--editor-2 {
    fill: $color-action;
}

.icon--editor-3 {
    fill: $color-primary;
}

.icon--editor-4 {
    fill: $color-tertiary;
}
