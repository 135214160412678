
.register__intro {
    .headline {
        margin: 1rem 0 3rem 0;
    }
}

.desktop--hide {
    @include desktop{
        display: none;
    }
}

.service-register {
    @media all and (max-width: 980px) {
        background-color: #F1F1F1;
        .content__panel {
            padding: 1rem !important;
        }
        &__detail {
            padding: 1rem 1rem 0;
        }
    }
    .js-tabbed-nav {
        background: $color-white;
    }

    .admin-grid {
        padding-top: 1rem;
    }

    .previous-records {
        padding: 1rem;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -1rem;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            height: 1rem;
            width: calc(100%);
            background: #E4E4E4;

            body.admin-view & {
                background: $color-background-lighter-grey;
            }
        }
    }
    .previous-records__intro {
        padding: 0 1rem;
    }
    &__label{
        font-size: 1.3125rem;
        color: #4A4A4A;
        text-transform: uppercase;
    }
    &__header {
        color: #8B63A1;
        font-family: "Cabin", Arial, sans-serif;
        font-size: 1.3125rem;
    }
}

.intro{
    padding: 0 1rem;
}

.mobile-admin__tabbed-nav {
    position: relative;

    .mobile-admin__tabbed-nav-panel {
        .mobile-admin__tabbed-nav-panel-posts.hide {
            display: none;
        }
    }
}

.load-more {
    display: block;
    margin: 1rem auto;
    padding: 0.5rem;
    width: 10rem;
    text-align: center;
    font-size: 1.3rem;

    &.hide {
        display:none;
    }
}

.tabs__holder-single {
    width: 100%;
    margin-left: 0;
    padding: 1rem 0 0 0;
    background: #E4E4E4;
    margin-bottom: 1.5rem;

    .tabs {
        padding-left: 0;
        border-bottom: 0.2rem solid $color-grey;
    }

    .single {
        width: 100%;
        padding: 2rem 1rem 0.2rem 1rem;
        color: $color-active;
        cursor: default;

        @include desktop {
            padding: 1rem;
        }
    }
}


.form__fields {
   padding: 2rem 0.5rem 1rem 0.5rem;
   position: relative;

   &:first-of-type {
       &:before {
        height: 0.4375rem;
       }
   }
   &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        height: 0.625rem;
        width: calc(100% + 2rem);
        background: #E4E4E4;

        @include desktop {
            width: calc(100% + 4rem);
        }
   }

    .control-label,
    label {
        text-transform: none !important;
        margin-top: 0.75rem;
        margin-bottom: 0.25rem;
    }
    #div_id_service_types .control-label  {
        margin-bottom: 0;
    }

    .radio-select {
        margin: 0.5rem 0 1rem 0;
    }

    .persons-formset {
        label {
            text-transform: uppercase;
            margin-top: 0.75rem;
            margin-bottom: 0;
            position: relative;
        }
        label:last-of-type {
            display: none;

        }
        input:first-of-type{
            position: relative;
            width: calc(100% - 4rem) !important;
        }


        .form-row {
            position: relative;
            margin-top: 2rem;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: -1rem;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                height: 0.2rem;
                width: calc(100% + 4rem);
                background: #E4E4E4;
           }

            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: -1rem;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                height: 0.2rem;
                width: calc(100% + 4rem);
                background: #E4E4E4;
            }
        }
        .events-calendar__listing-delete {
            height: 3.6rem;
            @media all and (min-width: 768px) {
                height: 3.35rem;
            }
            .icon {
                margin-right: 0;
                height: 1.2rem;
                width: 1.2rem;
            }
        }
        .events-calendar__listing {
            position: absolute;
            top: 0.7rem;
            right: 0;

            @media all and (min-width: 768px) {
                top: 0.5rem;
            }
        }
        .person_form {
            position: relative;

            .js-delete {
                position: absolute;
                height: 3.6rem;
                right: 0;

                @media all and (min-width: 768px) {
                    height: 3.35rem;
                }

                .icon {
                    margin-right: 0;
                    height: 1.2rem;
                    width: 1.2rem;
                }
            }
        }
    }

    small {
        line-height: 0.9375rem;
    }

    .checkbox {
        margin-bottom: 1.25rem;
    }
    input[type='checkbox']#id_preacher_officiant_same {
        margin-top:0;
    }
    &.attendees{
        label {
            font-size: 1rem;
            text-transform: none !important;
            margin-bottom: 0;
        }
    }
}

.form__alert {
    padding: 1rem 0.5rem 0rem 0.5rem;
}

.select2-search--dropdown {
    margin-top: -3.5rem !important;
    height: 3.5rem !important;

    .select2-search__field {
        height: 3.5rem !important;
    }
}

.select2-container--default {
    .select2-selection--single {
        border: 3px solid $color-grey !important;
        border-radius: 0px !important;
        height: 3.5rem !important;
    }
}

.select2-container--default {
    width: 100% !important;

    .select2-search--dropdown {
        padding: 0;

        .select2-search__field {
            border: none !important;
        }
    }
}

.select2-selection__arrow {
    display: none;
}

.select2-selection__clear {
    position: absolute !important;
    top: 0;
    right: 3.25rem;
    border: 3px solid $color-status-orange;
    border-right: none;
    padding: 0.6rem 1rem;
    font-size: 2rem;
    background: $color-white;
    height: 100%;
}

.event__field {
    position: relative;
    + p {
    font-size: 0.875rem;
    margin-top: 0.25rem;
    }
}

.icon.search-icon {
    position: absolute;
    border: 3px solid $color-status-orange;
    right: 0;
    bottom: 0.475rem;
    height: 3.65rem;
    width: 3.25rem;
    padding: 0.6rem;
    background: white;

    @media all and (min-width: 768px) {
        bottom: 0.37rem;
        height: 3.35rem;
    }

    @include desktop {
        bottom: 0.36rem;
    }
}

.error + .icon.search-icon {
    bottom: 2.2rem;

    @media all and (min-width: 768px) {
        bottom: 1.9rem;
    }

    @include desktop {
        bottom: 2.9rem;
    }
}


.main-content {
    form#serviceregistercreateform,   form#serviceregisterupdateform {
        button.add-person {
            display: block;
            margin: 2.25rem auto 0.25rem auto;
            padding: 0.5rem;
            width: 12rem;
            text-align: center;
            font-size: 1.3rem;
        }

        .error {
            input,
            textarea,
            select {
                border-color: $color-error;
                text-overflow: ellipsis;
            }

            .mediumeditorwidget {
                border-color: $color-error;
            }

            .help-inline, .help-block {
                background: $color-error;
                color: #fff;
                padding: $width-gap/2;
                margin-top: 0;
            }
        }
        textarea {
            height: 6rem;
        }
    }

    .form__fields-attendees {
        background-color: $color-grey;
        display: flex;
        flex-direction: row;
        span {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-start;
            padding-left: 2rem;
            font-family: $base-font;
            font-weight: bold;
        }

        .control-group {
            width: 100%;
        }

        input[type=number] {
            display: block;
            margin: 0;
            border-right: none !important;
            border-left: none !important;
            font-size: 1.5rem !important;
            text-align: center;
            padding: 0.5rem 1rem !important;
            font-weight: 600;
        }
        #div_id_attendees_total {
            input[type=number] {
                background-color: $color-grey !important;
                text-align: right;
                padding-right: 2rem !important;
            }
        }
    }

    button.plus {
        border: 3px solid $color-status-darker-green;
        min-width: 3.75rem;
        background-color: $color-white !important;

        .icon {
            margin: 0;
        }
    }

    button.minus {
        border: 3px solid $color-grey;
        min-width: 3.75rem;
        background-color: $color-white !important;

        .icon {
            margin: 0;
        }

        &.active {
            border: 3px solid $color-error;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield !important;
    }
}

form[id=serviceregistercreateform],
form[id=serviceregisterupdateform] {

    .form__header {
        font-family: $base-headline-font;
        font-size: 1.438rem;
    }
}

#save-message:not(.modal-display) {
    display: none;
}

.offline-data-list {
    list-style: none;
    padding-left: 0;

    li {
        display: grid;
        grid-template-columns: 1fr max-content max-content;
        padding: 0.5rem 0;
        border-bottom: 1px solid $color-grey;
    }

    li:first-of-type {
        border-top: 1px solid $color-grey;
    }

    .error {
        color: $color-error;
    }
}


.dob-formsets-holder-baptism-christening, .dob-formsets-holder-thanksgiving {
    margin-top:1rem;
    p {
        margin-top: 0;
    }
    li {
        list-style: none;
        display: flex;
        flex-direction: column;
    }
    .errorlist {
        order: 2;
    }
    .errorlist li {
        font-weight: bold;
        text-transform: none;
    }
}

#div_id_confirm_duplicate_event {
    // We only want this confirmation checkbox to display if the error is attached to it.
    display: none;

    &.error {
        display: block;
        .controls__checkbox {
            display: flex;
            flex-direction: column-reverse;
            .custom__checkbox-label {
                color: $color-error;
                font-weight: bold;
            }
        }
    }
}
