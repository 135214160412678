.church__meta {

    + .church__meta {
        margin-top: $width-gap*0.75;
    }

    @media all and (min-width: 481px) {
        display: flex;
        align-items: baseline;
    }
}

.church__meta-key {
    color: $color-primary;
    width: 11rem;
    margin-right: $width-gap;
    flex-shrink: 0;
    text-transform: uppercase;
    font-weight: 300;
}

.church__meta-value {
    margin-top: -$width-gap/4;
}

.church__meta-wrapper + .church__meta-wrapper {
    padding-top: $width-gap;
    margin-top: $width-gap;
    border-top: 3px solid $color-line;
}
