.room-booking-iframe-wrapper {
    padding: 1rem;
    background-color: $color-white;

    iframe {
        min-height: 40rem;
        width: 100%;
    }

    @include desktop {
        padding: 1rem 2rem;

        iframe {
            min-height: 50rem;
        }
    }
}
