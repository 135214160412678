.meta {
    display: flex;
    flex-flow: row nowrap;

    + .meta { margin-top: $width-gap; }

    .icon {
        width: 1.5em;
        height: 1.5em;
        margin-right: $width-gap*0.75;
        flex-shrink: 0;
        color: $color-grey-light;
        vertical-align: middle;

        &.primary { color: $color-primary; }
    }
}

.meta__list {
    @media all and (min-width: 768px) {
        > .button {
            width: calc(100% - #{$width-gap*2});
            margin-left: $width-gap*2;
        }
    }
}

.meta__group {
    margin-bottom: 1rem;
}
