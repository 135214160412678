$height: 2rem;
$gray:#292929;
$light-gray:#E4E4E4;

.custom-toggle {
  user-select:none;
  width: $height * 2;
  height: $height;
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;

  label {
    width: $height * 2;
    height: $height;
    border-radius: $height/2;
    background-color: $color-grey;
    cursor: pointer;
    display: block;
    &:active:after{
      width:$height*1.5;
    }
    &:after {
      width: $height * 0.8;
      height: $height * 0.8;
      background-color: $color-white;
      content: "";
      position: absolute;
      border-radius: $height;
      top: 0;
      left: 0;
      transition: all 0.5s ;
      margin: $height * 0.1;
    }
    .text{
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: $height/2;
      width: 100%;
      height: 100%;
      user-select: none;
      .on{
        color:$color-turquoise;
      }
      .off{
        color:$gray;
      }

    }

  }
  input {
    display: none;
    &:checked {
      & + label {
        background-color: $color-turquoise;
        &:after {
            left: unset;
            background-color: $color-white;
            right: 0.1 * $height;
            transform: translateX(0.1 * $height);
        }
      }
    }
  }
}
#marketplaceform {
    display: flex;
    flex-flow:row wrap;
    justify-content: space-between;
    padding: 1rem 0;
    .grid-item {
        width: 100%;
        margin: 0.5rem 0;
        background: $color-white;
        position: relative;
        @include mobile {
            display: flex;
            flex-direction: column;
            width: calc(50% - 0.5rem);
        }
        &.iKnow {
            padding-top: 0;
        }
    }

    .grid-item__buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        a {
            margin: 0;
        }
        .right-aligned-btn {
            margin: 1rem 0 0;
        }
        @include desktop-large {
            flex-direction: row;
            justify-content: space-between;
            .right-aligned-btn {
                margin: 0;
            }
        }
    }
    .grid-item__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        @include mobile {
            height: 3.5rem;
        }
        @include desktop {
            height: 5rem;
        }
        h4, .controls {
            margin: 0;
        }
    }
    .grid-item__preview {
        min-height: 9.375rem;
        background: $color-primary;
        padding: 1.5rem 2rem;
        position: relative;
        &.survey {
            background: $color-tertiary;
            color: $color-background;
        }
        &.donate {
            background: $color-secondary;
            color: $color-background;
        }
        &.iKnow {
            background: $color-primary;
            color: $color-background;
        }
        &.everyday-faith {
            background: $color-tertiary-light;
            color: $color-background;
        }
        h3,p {
            color: $color-white;
            margin: 0;
            padding-bottom: 0.5rem;
        }
        .btn {
            position: absolute;
            right: 1rem;
            bottom: 1rem;
            cursor: auto;
            &:hover {
                background: none;
            }
        }
    }
    .grid-item__description {
        position: relative;
        display: flex;
        flex-flow: column;
        width: 100%;
        padding: 2rem 2rem;
        p {
            margin-bottom: 3rem;
        }
        a {
            align-self: flex-start;
            min-width: 7.5rem;

        }
        .button {
            text-transform: none;
            text-align: center;
        }
        @include mobile {
            flex: 1;
            justify-content: space-between;
        }
    }
}
.tiny-icon{
    fill: $color-white;
    background: $color-text;
    width: 1rem;
    height: 0.7rem;
    margin-right: 1rem;
}

#donationsconfigurationform {

    h4 {
        margin-top:0;
        margin-bottom: 1rem;
        font-size: 1rem;
        text-transform: uppercase;
    }

    p {
        margin: 0.5rem 0;
    }

    .hide-if-custom-url,
    .hide-if-not-custom-url {
        margin: 2rem 0;
    }

    .help-block {
        display: block;
        margin: 0.5rem 0;

        font-size: 1rem;
        @include desktop {
            margin: 1.5rem 0 2.5rem;
        }
        input {
            margin-top: 0.5rem;
        }
    }

    .control-group {
        .help-block {
            @include desktop {
                margin: 2rem 0 0 !important;
            }
        }
        input[type=checkbox]{
            @include visually-hidden;
        }

        .controls {
            display: flex;
            flex-flow: column;
            margin-right: 3rem;
            margin-bottom: 0;
            input {
                order:1;
            }
            p.help-block {
                margin: 0 0 0.5rem 0 !important;
                width: 100%;
                font-size: 1.25rem;
            }
        }

    }
    .hint-fieldset{
        label{
            @include visually-hidden;
        }
    }

    .control-label {
        text-transform: unset;
    }
}
.donation-error {
    color: $color-error;
}
