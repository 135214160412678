// Base sizes
$base-font-size: 1rem !default;
$base-line-height: 1.5 !default;
$base-headline-font: 'Cabin', Arial, sans-serif;
$base-font: 'Open Sans', Arial, sans-serif;


// Colors
$color-primary: #a688b7;
$color-primary-light: #D7C7DF !default;
$color-primary-dark: #382e75 !default;
$color-primary-darker: #2d255e !default;

$color-secondary: #95317A !default;
$color-tertiary: #6C93CE !default;
$color-tertiary-light: #6FBEC6 !default;

$color-action: #E8A065 !default;
$color-action-light: rgba(#E8A065, 0.4) !default;

$color-background: #F5F5F5 !default;
$color-background-lighter-grey: #F1F1F1;
$color-background-light: #FFFFFF !default;
$color-background-blue: #F1F7FA !default;

$color-text: #4A4A4A !default;
$color-active: #8B63A1 !default;
$color-error: #DE2F62 !default;

$color-grey: #E4E4E4 !default;
$color-grey-light: #A5A5A5 !default;
$color-line: #F5F5F5 !default;

$color-status-green: #A3C27F !default;
$color-status-orange: #E8A065 !default;
$color-status-purple: #95317A !default;
$color-status-darker-green: #8FAB73 !default;

// Structure colours
$color-deanery: $color-tertiary-light;
$color-diocese: $color-secondary;

$color-black: #000000;
$color-white: #ffffff;
$color-blue: #4d74b9;
$color-turquoise: #61b1b9;
$color-navy: #004874;
$color-dark-navy: #003A5D;
$color-yellow: #E7AA22;
$color-crimson: #E55445;
$color-easter-yellow: #E0A468;
$color-easter-purple: #A289B7;

// Size
$width-gap: 1rem;
$width-line: 0.125rem;
$width-container: 92.5rem !default;
$width-main-content: 60%;
$width-side-content: 21rem;
$width-radius: 0;


// Layout variables
$page-layout-desktop-padding-right: $width-gap;
$acny-as-homepage-navlist-padding-sides: 2* $width-gap;


// Responsive breakpoints
$breakpoint-mobile-small: 460px;
$breakpoint-mobile: 640px;
$breakpoint-desktop: 980px;
$breakpoint-desktop-large: 1280px;


// Headlines

///     FONT SIZE  |  LINE H  |  MARGIN T  |  MARGIN B
$headline-scale: (
    h1: (3.375rem,    1.1,       1.6785em,    0.3em),
    h2: (3.375rem,     1.2,       0.9em,       0.45em),
    h3: (2.25rem,      1.2,       1.33em,      0.66em),
    h4: (1.4375rem,     1.2,       1.2em,       0.8em),
    h5: (1.25rem,      1.1,       1.125em,     0.9em),
    h6: (1rem,        1,         1.5em,       0.5em)
) !default;


// Icon

$icon-width: 1em;
$icon-height: $icon-width;
