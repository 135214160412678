// Uses the holy albatross http://www.heydonworks.com/article/the-flexbox-holy-albatross-reincarnated
.diocese-stats {
    display: flex;
    flex-wrap: wrap;
    margin: -0.75rem;
    padding: 3.75rem 0;
}

.diocese-stats__block {
    flex-grow: 1;
    flex-basis: calc(calc(600px - 100%) * 999);
    margin: 0.75rem;
    padding: 1rem;
    padding-bottom: 1.5rem;
    border: 3px solid #E4E4E4;

    @media (min-width: 768px) {
        padding: 2rem;
        padding-bottom: 2.5rem;
    }
}

.diocese-stats__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.diocese-stats__heading {
    font-size: 2.25rem;

    &::after {
        width: 100%;
    }
}

.diocese-stats__chart-wrapper svg {
    width: 70%;
    height: 70%;
    transform: rotate(-90deg);
    margin: 3rem 15%;

    .slice--fraction {
        fill: $color-primary-dark;
    }

    .slice--remainder {
        fill: $color-active;
    }

    .slice--text {
        transform: rotate(90deg);
        fill: $color-white;
        font-family: $base-headline-font;
        font-weight: bold;
        text-align: center;
    }
}

.diocese-stats__legend {
    padding: 0;
}

.diocese-stats__legend-item {
    list-style-type: none;
    display: flex;
    align-items: flex-start;

    &::before {
        flex: 0 0 0.75rem;
        content: '';
        display: inline-block;
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 0.425rem;
        margin-top: 0.35em;
        margin-right: 1em;
    }
}

.diocese-stats__legend-item--total::before {
    background-color: $color-active;
}

.diocese-stats__legend-item--fraction::before {
    background-color: $color-primary-dark;
}

.diocese-stats__stats {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: -0.75rem;
    padding: 0;
}

.diocese-stats__stat {
    flex-grow: 1;
    flex-basis: 10rem;
    list-style-type: none;
    font-size: 1.5rem;
    line-height: 1.2em;
    margin: 0.75rem;

    span {
        display: block;
        font-size: 2.25rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
}
