// Override styles for BaguetteBox lightbox
.baguetteBox-open {
    // Stop body from scrolling when lightbox is open
    overflow: hidden;

    #baguetteBox-overlay {
        background: rgba(255, 255, 255, 0.95) !important;
        .full-image figcaption {
            font-size: 1.125rem !important;
            padding: 1rem;
            background: rgba($color-text, 0.95);
            color: $color-line;
            font-size: $base-font;
            font-weight: 300;
            line-height: 1.5;
        }
        button {
            background: rgba($color-action, 0.6);
            &:hover {
                background: rgba($color-action, 0.9);
            }
            svg *{
                stroke: $color-background-light !important;
            }
        }
    }
}
