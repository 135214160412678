.card__grid {
    margin: $width-gap 0;
}

.card__grid-item,
.js-grid-sizer {
    width: 100%;

    @media all and (min-width: 768px) {
        width: calc(50% - #{$width-gap/2});
    }
}

.js-grid-gutter {
    @media all and (min-width: 768px) {
        width: $width-gap;
    }
}

.card__grid-item {
    display: block;
    text-decoration: none;
    margin-bottom: $width-gap;
}

.card__banner {
    margin: 0;
    background: $color-secondary;
    color: $color-white;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    z-index: 1;
}

.card__image {
    width: 100%;
    // Keep image height ratio consistent
    height: 0;
    padding-top: 50%;

    #lightbox & {
        width: calc(100% + #{$width-gap * 4});
        margin-top: -$width-gap * 2;
        margin-left: -$width-gap * 2;
        margin-bottom: $width-gap * 1.5;
    }
}

.card__title {
    margin-bottom: $width-gap * 1.5;

    h3 a {
        text-decoration: none;
    }
}

.card__days {
    margin-bottom: $width-gap/2;
}

.card__summary {
    padding: $width-gap * 2;
    background: #fff;
    position: relative;

    .social {
        li:first-of-type {
            margin-right: $width-gap;
        }
        li + li {
            margin-left: 0;
        }
    }

    #lightbox & {
        position: relative;
        padding: 0;
    }

    .btn-primary {
        width: 100%;
        margin: 1.5rem 0 1rem 0;
        text-align: center;
        justify-content: center;
    }
}

.card__edit {
    position: absolute;
    top: $width-gap/2;
    right: $width-gap/2;
    color: darken($color-grey, 10%);

    &:hover {
        color: $color-primary;
    }

    &.delete:hover {
        color: $color-error;
    }

    &.update:hover {
      color: $color-tertiary;
    }

    #lightbox & {
        top: -$width-gap;
        right: -$width-gap;
    }

    .with__card-image & {
        background: #fff;
        padding: $width-gap/4 $width-gap/2;
        top: 0;
        right: 0;
    }
}

.card__button-row {
    margin-top: $width-gap * 1.5;
    padding-left: $width-gap * 2;

    &--panel {
        margin-top: 0;
    }
    &.next {
        text-align: right;
    }

    &.full {
        padding-left: 0;

        a {
            max-width: 100%;
        }
    }
}

.card__flex {
    padding: 0rem 2rem 3rem 2rem;
    max-width: 100rem;
    margin: 3rem auto 0rem auto;
    display: flex;
    flex-flow: row wrap;

    .card__grid-item {
        width: 100%;
        margin: 0.5rem;
        display: flex;
        flex-direction: column;

        .card__image {
            height: 12rem;
        }

        .card__summary {
            padding: $width-gap $width-gap $width-gap * 3;
            height: 100%;
            max-height: 25rem;
            overflow: scroll;
            position: relative;

            .btn {
                position: absolute;
                bottom: $width-gap;
                right: $width-gap;
                justify-content: center;
                align-items: center;
                display: flex;
                background: white;

                .icon {
                    margin-right: 0;
                }
            }
        }

        @media (min-width: 541px) and (max-width: 1279px) {
            margin: 2%;
            width: 46%;
        }

        @media (min-width: 1280px) {
            width: auto;
            flex: 1;
        }
    }
}

.card--event {
    position: relative;
    dd {
        margin-inline: 0;
    }
    .meta__venue {
        > * {
            margin: 0;
            word-wrap: break-word;
        }
        .venue {
            text-decoration: underline;
        }
        .address {
            font-size: 0.75rem;
            font-style: normal;
        }
        + .meta__venue {
            margin-top: 0.5rem;
        }
    }
    .card__title {
        padding-right: $width-gap;

        a {
            text-decoration: none;
        }
    }
    .meta__event-description {
        display: block;

        .read-more {
            padding-left: 0;
            margin-top: 1rem;
        }
    }
    .card__event-description {
        word-break: break-word;
    }
    .card__button-row, .card__button-stack {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        gap: $width-gap;
        padding-left: 0;
    }
    .card__button-stack {
        flex-flow: column nowrap;
    }
    .card__button, .card__tab {
        flex: 1 0 auto;
        padding: 0.875rem !important;
        color: $color-text;
        text-align: center;
        .icon {
            width: 1.25rem;
            height: 1.25rem;
            margin-top: -0.25rem;
            margin-right: 0;
            color: darken($color-grey, 10%);
            vertical-align: middle;
        }
    }
    .card__button--lowercase {
        background: $color-white !important;
        font-family: $base-font;
        text-transform: none !important;
        &:not(:disabled):hover, &:not(:disabled).active {
            .icon {
                color: darken($color-action, 10%) !important;
            }
        }
        .icon {
            margin-left: 0.25rem;
        }
    }
    .card__tabs {
        display: flex;
        flex-flow: row wrap;
        gap: 3px;
        width: 100%;
        border-top: $color-grey solid 3px;
        background: $color-grey;
        .card__tab {
            border-width: 0 !important;
            background: $color-white !important;
            text-transform: none !important;
            font-family: $base-font !important;
            line-height: 1.5rem !important;
            &:hover, &:focus-visible, &.active {
                margin-top: -3px;
                color: $color-primary-dark !important;
                .icon {
                    color: $color-primary !important;
                }
            }
            &.active {
                pointer-events: none;
            }
            &:first-of-type {
                .icon {
                    margin-left: 0.25rem;
                }
            }
            .icon + .icon {
                margin-right: 0.25rem;
            }
            &--share.active {
                order: -1;
                @media (min-width: 366px) {
                    order: initial;
                }
            }
        }
    }
    .card__panel {
        position: relative;
        padding: $width-gap * 2;
        background: $color-white;
        color: $color-text !important;

        .panel-header {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: flex-start;

            .panel-close {
                position: absolute;
                top: $width-gap;
                right: 0;
                border: none !important;
                background: none !important;
                color: darken($color-grey, 10%) !important;
                &:hover, &:focus-visible {
                    color: $color-primary !important;
                }
                .icon {
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }
        }

        .panel-content {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: $width-gap * -4;
                right: 0;
                left: 0;
                padding-top: $width-gap * 4;
                background: linear-gradient(180deg, transparent 0%, $color-white 100%);
            }
            &--share:before {
                top: $width-gap * -5;
                padding-top: $width-gap * 5;
            }
            .calendar-form {
                margin: #{$width-gap * 2} 0 0;
                padding-bottom: 0;
            }
            .card__panel-heading {
                margin: #{$width-gap * 2.5} 0 0;
                color: $color-primary;
                font-size: 1.5rem;
                line-height: 1.75rem;
            }
            .card__panel-subheading {
                font-size: 1.25rem;
                line-height: 1.5rem;
            }
            .card__radio-row {
                fieldset {
                    display: flex;
                    flex-flow: row wrap;
                    gap: $width-gap * 2;
                    width: 100%;
                    margin: #{$width-gap * 1.5} 0 $width-gap;
                }
                legend {
                    @include visually-hidden;
                    &:after {
                        content: none;
                    }
                }
                label {
                    font-size: 1rem;
                    .custom__radio {
                        display: inline-block;
                        margin-top: -0.125rem;
                        margin-right: 0.5rem;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}

.card--event-detail {
    padding: 0;
    .card__summary, .card__panel {
        padding: $width-gap * 3;
    }
}

#lightbox .card--event-detail {
    .card__summary {
        padding: $width-gap * 2;
    }

    @include smaller-than-desktop {
        height: 100%;
        .card__banner {
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
        }
        .card__summary, .card__panel {
            padding: $width-gap * 4;
        }
        .card__tab {
            width: 100%;
            &--share.active {
                order: -1;
            }
        }
        &.card--event-benefice {
            margin-bottom: 3.5rem;
        }
    }
}

.card__grid--benefice {
    display: flex;
    flex-flow: row wrap;
    gap: $width-gap;
    padding: $width-gap 0 0;
    .card__grid-item {
        flex: 1 0 32%;
    }
}

.card--event-updated {
    margin: 1.875rem 0;
    background-color: $color-white;

    &.with__card-image {
        .card__image {
            width: calc(100% + 2px);
            margin-top: -1px;
            margin-left: -1px;
            margin-right: -1px;
        }

        @include desktop-large {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 2rem;

            .card__image {
                height: calc(100% + 2px);
                margin-top: -1px;
                margin-left: -1px;
                margin-bottom: -1px;
            }

            .card__summary,
            .card__panel {
                padding: 2.5rem 2rem 2.5rem 0;
            }
        }
    }

    h3 {
        font-size: 1.5rem;
    }

    .meta .icon {
        color: $color-text;
        width: 1.25rem;
        height: 1.25rem;
        margin-top: 0.125rem;
    }

    .add-to-calendar-button {
        background-color: $color-yellow;
        border-color: $color-yellow;
        color: $color-navy;
        padding: 0.75rem 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1.5rem 0;
        font-family: $base-font;

        .icon {
            width: 1.25rem;
            height: 1.25rem;
        }

        &:hover {
            background-color: $color-navy;
            border-color: $color-navy;
            color: $color-white;
        }
    }

    .share-event-button {
        position: absolute;
        top: 0;
        right: 0;
        border-bottom-left-radius: 10px;
        border: none;
        background-color: #B9D8E7;
        color: $color-navy;
        font-size: 0.75rem;
        font-family: $base-font;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 10px;

        .icon {
            width: 1.125rem;
            height: 1.125rem;
            margin-right: 0;
            margin-left: 0.25rem;
        }

        &:hover {
            background-color: $color-navy;
            border-color: $color-navy;
            color: $color-white;
        }
    }

    .see-all-events {
        display: flex;
        align-items: center;
        margin: 1.5rem 0;
        color: $color-navy;
        text-decoration: none;

        .icon {
            margin-left: 0.5rem;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
