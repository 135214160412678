.content-hint {
    color: $color-active;

    p {
        margin-top: 0.25rem;
    }

    @include desktop {
        color: $color-text;
        display: flex;
        justify-content: space-between;
    }

    :first-child {
        margin-top: 1rem;
        margin-left: auto;
    }
}

textarea#id_description {
    height: 6.5rem;
}

.news-edit__buttons {

    .controls.controls__checkbox {
        display: flex;
        @include desktop {
            padding-left: 1rem;
        }
    }
    #div_id_is_published .checkbox {
        padding-right: 1.5rem;
    }

    .urgent-delete {
        min-width: 6.5rem;
    }

    @media all and (max-width: 520px) {
        // justify-content: space-between !important;

        #div_id_is_published .checkbox {
            width: 8rem;
            padding-right: 0;
        }
    }

    @media (min-width: 980px) and (max-width: 1100px) {
        flex-flow: row wrap !important;
    }

    #id_is_published {
        display: none;
    }
}

#error_1_id_publish_at.help-inline {
    position: absolute;
    width: calc(60% - 0.5rem);
    top: 6rem;
}

// Grid list views
.news__intro {
    position: relative;
    margin-top: 0rem !important;
    padding-bottom: 1rem;
    padding-top: 0rem !important;

    .headline {
        width: calc(100% - 4rem);
        max-width: 40rem;
        margin: 1rem 0 3rem 0;
        font-size: 2.25rem;
    }

    @include desktop {
        padding: 2rem 2rem 0 2rem;
        .headline {
            font-size: 3.375rem;
        }
    }

    body.admin-view & {
        color: $color-text;
    }
}

.news__editor-links {
    .active {
        color: $color-primary;
    }

    a {
        text-decoration: none;
    }

    .icon {
        width: 2rem;
        height: 2rem;
    }

    div {
        display: inline;
    }
}

.card__grid__create-link {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    padding: 0.5rem 0 0.5rem 1rem;
    background: $color-primary;
    color: $color-background;
    text-decoration: none;

    .btn {
        display: flex;
        float: right;
        justify-content: center;
        align-items: center;
        margin-top: -0.5rem;
        padding-top: 0.6rem;

        .icon {
            margin: 0 auto;
        }
    }

    &:only-child {
        margin-bottom: 0rem;
    }
}

// Admin list views

.news__list__new-link {
    text-align: center;

    a {
        padding: 0.75rem 2.5rem;
    }
}

table.news-list {
    font-size: 1rem;
    margin: 1.5rem 1rem;

    th {
        a {
            text-decoration: none;
        }

        .tag {
            text-decoration: none;
            padding: 0.25rem 0.5rem;
        }
    }

    tr th:first-child {
        width: 60%;
    }

    .table__name {
        display: flex;
        padding: 0.5rem 0;

        .image {
            display: none;
            min-width: 7rem;
            min-height: 4.5rem;
            max-height: 6rem;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }

        a {
            display: block;
            font-size: 1.25rem !important;
        }

        .image__fieldset-preview {
            width: 7rem;
            height: 4.5rem;
            margin-right: 0;
        }

        .tag__list {
            margin: 1rem -1rem 0 0;
            span {
                margin-right: 0.5rem;
            }
        }

        &.notice {
            display: block;

            a {
                font-size: 1.2rem !important;
                padding-left: 0;
                margin-bottom: 0.25rem;

                &.table__notice-link {
                    display: inline;
                    font-weight: 500;
                    font-size: 0.8rem !important;
                    border-bottom: 1px solid $color-action;
                }
            }
        }

        @media all and (min-width: 768px) {
            font-size: 1.2rem;

            .image {
                display: block;
            }

            a {
                font-size: 1.1rem !important;
                padding-left: 1rem;
            }

            .tag__list {
                margin: 1rem -1rem 0 1rem;
            }

            &.notice {
                a {
                    font-size: 1.5rem !important;
                    &.table__notice-link {
                        font-size: 1rem !important;
                    }
                }
            }
        }
    }

    .table__date {
        color: $color-grey-light;
    }

    .table__published {
        text-align: center;

        .icon {
            width: 2rem;
            height: 2rem;
            margin: 0 auto;
        }
    }

    @media all and (max-width: 767px) {
        font-size: 0.8rem !important;

        th {
            white-space: normal;
            font-size: 0.75rem;
        }

        td {
            width: 30%;

            &:first-of-type {
                width: 100%;
            }

            &:last-of-type {
                width: 20%;
            }
        }
        .table__name a {
            font-size: 0.85rem !important;
        }
    }

    @include desktop {
        margin: 1.5rem 0;
    }
}

// detail views
.card__summary.news-post {
    display: flex;
    flex-flow: column nowrap;
    .content {
        width: 100%;
        word-break: break-word;
        @media all and (min-width: 768px) {
            flex-grow: 1;
        }
    }
    .news__meta {
        min-width: 100%;
        order: 1;
        padding-top: 2rem;
        .social {
            li {
                margin-right: 0.5rem;
            }

            .icon {
                width: 1.5rem;
                height: 1.5rem;
                margin-right: 0;
            }
        }

        .meta__description {
            position: relative;
            width: 100%;
            padding: 0.75rem 0 0.75rem 2rem;
            word-wrap: break-word;

            &:not(:first-child) {
                border-top: 3px solid $color-line;
            }

            .btn {
                width: calc(100% - 1.5rem);
                margin-top: 0.5rem;
                text-align: center;
            }

            > .icon:first-child {
                position: absolute;
                height: 1.5rem;
                width: 1.5rem;
                left: 0;
            }

            .tag__list {
                margin-bottom: 0;

                span {
                    margin-right: 0.25rem;
                }
            }
        }
        @media all and (min-width: 768px) {
            min-width: 30%;
            padding-left: 1.5rem;
            padding-top: 0;
        }
    }
    .card__image {
        height: 15rem;
        @media all and (min-width: 550px) {
            height: 22rem;
        }
    }
    @media all and (min-width: 768px) {
        flex-flow: row nowrap;
    }
}

.news__copy-label {
    max-width: 38rem;

    .control-label {
        font-size: 1em;
        text-transform: unset;
    }
}
