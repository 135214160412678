@charset "UTF-8";
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.mt0 {
  margin-top: 0; }

.mt1 {
  margin-top: 1rem; }

.mb0 {
  margin-bottom: 0; }

.mb1 {
  margin-bottom: 1rem; }

.mt1 {
  margin-top: 1rem; }

.mr1 {
  margin-right: 1rem; }

.height-auto {
  height: auto !important; }

.thicker-border-bottom {
  border-bottom: 10px solid #E4E4E4 !important; }

.thicker-border-top {
  border-top: 10px solid #E4E4E4 !important; }

.no-scroll {
  overflow: hidden; }

.visually-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.m-bottom {
  margin-bottom: 2rem; }

html {
  font-size: 0.875rem; }
  @media all and (min-width: 768px) {
    html {
      font-size: 1rem; } }

body {
  font-family: "Open Sans", Arial, sans-serif, sans-serif;
  line-height: 24px; }

h1,
.h1 {
  font-size: 3.375rem;
  line-height: 1.1;
  margin-top: 1.6785em;
  margin-bottom: 0.3em; }

h2,
.h2 {
  font-size: 3.375rem;
  line-height: 1.2;
  margin-top: 0.9em;
  margin-bottom: 0.45em; }

h3,
.h3 {
  font-size: 2.25rem;
  line-height: 1.2;
  margin-top: 1.33em;
  margin-bottom: 0.66em; }

h4,
.h4,
.user__table-name {
  font-size: 1.4375rem;
  line-height: 1.2;
  margin-top: 1.2em;
  margin-bottom: 0.8em; }

h5,
.h5 {
  font-size: 1.25rem;
  line-height: 1.1;
  margin-top: 1.125em;
  margin-bottom: 0.9em; }

h6,
.h6 {
  font-size: 1rem;
  line-height: 1;
  margin-top: 1.5em;
  margin-bottom: 0.5em; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
.user__table-name,
h5,
.h5,
h6,
.h6 {
  font-family: "Cabin", Arial, sans-serif;
  font-weight: normal; }

a {
  color: inherit; }

small {
  font-size: 0.75rem; }

.headline, .main-content form fieldset legend,
#lightbox form fieldset legend {
  position: relative;
  padding-bottom: 0.25em;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .headline:after, .main-content form fieldset legend:after,
  #lightbox form fieldset legend:after {
    content: "";
    display: inline-block;
    width: 6.5rem;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #a688b7; }
  .headline.school-headline:after, .main-content form fieldset legend.school-headline:after,
  #lightbox form fieldset legend.school-headline:after {
    background: #6C93CE; }
  .headline.action-headline:after, .main-content form fieldset legend.action-headline:after,
  #lightbox form fieldset legend.action-headline:after {
    background: #E8A065; }
  .headline.error-headline:after, .main-content form fieldset legend.error-headline:after,
  #lightbox form fieldset legend.error-headline:after {
    background: #DE2F62; }

.section__headline {
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem; }
  .section__headline .icon {
    margin-right: 0.75rem; }

.inverse__headline {
  position: relative;
  margin: 0 auto;
  color: #F5F5F5;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 1.5rem;
  font-size: 1.4rem;
  margin: 3rem 0; }
  .inverse__headline:before {
    position: absolute;
    content: "";
    background: #E8A065;
    height: 3px;
    width: 6rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0; }

.headline__meta,
.label__meta {
  margin-left: 1rem;
  color: #A5A5A5;
  font-size: 1rem; }
  .headline__meta .icon,
  .label__meta .icon {
    width: 1.5rem;
    height: 1.5rem; }
  @media (min-width: 980px) {
    .headline__meta,
    .label__meta {
      font-size: 1.5rem; } }

.type__overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.bold {
  font-weight: bold; }

.faded {
  opacity: 0.5; }

.uppercase {
  text-transform: uppercase; }

.text-center {
  text-align: center; }

.pill {
  padding: 0.25em 0.5em;
  border-radius: 1em;
  color: #fff; }
  .pill.low {
    background: #DE2F62; }
  .pill.medium {
    background: #E8A065; }
  .pill.height {
    background: #A3C27F; }

@media (max-width: 399px) {
  .break-sm-mobile {
    display: block; } }

.mobile__section-header {
  color: #a688b7;
  font-size: 1.286rem;
  padding: 1rem;
  margin: 1rem 0 0 0 !important; }

.sr__text,
.sr__input,
.sr__label {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.focus-visible {
  position: absolute !important;
  z-index: 2;
  background-color: #fff;
  padding: 0.5rem; }
  .focus-visible:not(:focus) {
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    opacity: 0; }

body {
  width: 100%;
  overflow-x: hidden;
  color: #4A4A4A; }

* {
  box-sizing: border-box; }

:first-child {
  margin-top: 0; }

:last-child {
  margin-bottom: 0; }

.row {
  display: flex;
  flex-flow: row wrap; }
  .row--nowrap {
    flex-flow: row nowrap; }
  @media (min-width: 640px) {
    .row--nowrap-medium {
      flex-flow: row nowrap; } }
  .row--center {
    align-items: center; }
  .row--bottom {
    align-items: flex-end; }
  .row--top {
    align-items: flex-start; }
  .row--baseline {
    align-items: baseline; }
  .row--justify-end {
    justify-content: flex-end; }
  .row--justify-center {
    justify-content: center; }
  .row--space-between {
    justify-content: space-between; }
  .row.distribute > * + * {
    margin-left: 1rem; }
  .row.distribute-s > * + * {
    margin-left: calc(1rem / 2); }
  .row.distribute-l > * + * {
    margin-left: 2rem; }
    @media (min-width: 640px) {
      .row.distribute-l > * + * {
        margin-left: 0.25rem; } }
    @media (min-width: 980px) {
      .row.distribute-l > * + * {
        margin-left: 2rem; } }

.box {
  padding: 1rem; }
  .box--small {
    padding: calc(1rem / 2) 1rem; }
  .box--large {
    padding: 2rem 1rem; }
  @media (max-width: 979px) {
    .box--mobile {
      padding: 1rem; } }

.demo-announcement,
.saved-data-notification {
  padding: 0.75rem 0;
  background: #000;
  color: #fff;
  text-align: center; }
  @media (max-width: 980px) {
    .demo-announcement,
    .saved-data-notification {
      top: 130px; } }
  @media (max-width: 767px) {
    .demo-announcement,
    .saved-data-notification {
      top: 118px; } }

.saved-data-notification {
  display: none;
  background: #95317A; }

.page-header {
  background: #fff; }
  @media all and (max-width: 979px) {
    .page-header {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      background: #FFFFFF; }
      .page-header .primary__navigation {
        display: none; }
      .page-header .navbar__holder {
        flex-flow: row wrap; } }

.cofe__toggle {
  cursor: pointer;
  background: #F5F5F5;
  color: #4A4A4A;
  padding: 0.75rem;
  flex-shrink: 0;
  user-select: none;
  width: 6rem;
  text-align: center; }
  .cofe__toggle .icon {
    vertical-align: text-top; }
  .cofe__toggle .close {
    display: none; }
  @media all and (min-width: 980px) {
    .cofe__toggle {
      display: none; } }
  @media all and (max-width: 980px) {
    .cofe__toggle {
      width: fit-content;
      flex: auto;
      align-self: normal; } }

#cofe_toggle:checked ~ .page-header .cofe__bar {
  display: flex; }
  #cofe_toggle:checked ~ .page-header .cofe__bar .open {
    display: none; }
  #cofe_toggle:checked ~ .page-header .cofe__bar .close {
    display: inline; }
  #cofe_toggle:checked ~ .page-header .cofe__bar .navbar li {
    display: block; }

#cofe_toggle:checked ~ .page-header .primary__navigation {
  padding: 1rem 0 0 0; }

.header__toggle,
.mobile__toggle {
  margin-left: auto;
  font-size: 1em;
  position: relative; }
  .header__toggle label,
  .mobile__toggle label {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer; }
  .header__toggle .close,
  .mobile__toggle .close {
    display: none; }
  @media all and (min-width: 980px) {
    .header__toggle,
    .mobile__toggle {
      display: none !important; } }

.page-header__buttons {
  margin-left: auto; }

.cofe__bar {
  background: #a688b7;
  font-family: "Cabin", Arial, sans-serif;
  letter-spacing: 0.025em;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  font-size: 0.825rem;
  letter-spacing: 0.05em; }
  .cofe__bar a {
    padding: 0.5rem; }
  @media all and (min-width: 980px) {
    .cofe__bar .navbar li {
      border-color: #fff; }
      .cofe__bar .navbar li a:hover {
        color: #fff; }
      .cofe__bar .navbar li.active a {
        background: #FFFFFF;
        color: #4A4A4A; }
      .cofe__bar .navbar li .hidden-tab {
        display: none; } }
  @media all and (max-width: 980px) {
    .cofe__bar {
      text-align: center;
      letter-spacing: 0.02em;
      font-size: 0.75rem;
      letter-spacing: 0.02em; }
      .cofe__bar .container {
        margin-top: 0; }
      .cofe__bar .navbar {
        max-width: 20rem;
        margin: auto; }
        .cofe__bar .navbar li {
          border-color: rgba(255, 255, 255, 0.125);
          display: none;
          align-items: center; }
          .cofe__bar .navbar li.active {
            order: -1;
            display: flex !important;
            justify-content: space-between;
            border-top: none;
            color: #4A4A4A; }
            .cofe__bar .navbar li.active a {
              background: #fff;
              width: fit-content;
              padding: 0.75rem 1rem;
              align-self: normal; }
            .cofe__bar .navbar li.active .hidden-tab {
              background: #F5F5F5; }
            .cofe__bar .navbar li.active .icon {
              transform: scale(0.5); } }

.primary__navigation {
  width: 100%;
  padding: 0.5rem 0 0.35rem 0; }
  @media all and (min-width: 980px) {
    .primary__navigation {
      padding: 0.75rem 0 0.75rem 1rem; }
      .primary__navigation.acny-as-homepage {
        padding: 1rem 0 1rem 1rem; }
        .primary__navigation.acny-as-homepage .container {
          max-width: unset; }
        .primary__navigation.acny-as-homepage .navbar {
          flex-wrap: wrap; } }
  .primary__navigation .navbar li {
    font-family: "Cabin", Arial, sans-serif;
    padding: 1rem 0;
    border-left: none;
    text-transform: none; }
    @media all and (min-width: 980px) {
      .primary__navigation .navbar li {
        font-size: 1.25rem; } }
    .primary__navigation .navbar li.single__item {
      border: none;
      padding: 0.5rem 0; }
    .primary__navigation .navbar li a {
      padding: 0;
      display: flex;
      align-items: center; }
    .primary__navigation .navbar li .icon {
      margin-right: 1rem;
      width: 1rem;
      height: 1rem;
      fill: #A5A5A5;
      flex-shrink: 0; }
    .primary__navigation .navbar li .navbar__item-holder {
      border-right: 2px solid #E4E4E4;
      padding-right: 2rem; }
      .primary__navigation .navbar li .navbar__item-holder p {
        font-size: 0.875rem; }
    .primary__navigation .navbar li:last-of-type {
      border: none; }
  .primary__navigation .navbar li + li {
    margin-left: 2rem; }
  .primary__navigation .navlist__item.active {
    color: #a688b7; }

.navbar__brand {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  font-family: "Cabin", Arial, sans-serif;
  text-transform: uppercase;
  color: #382e75;
  text-decoration: none;
  font-size: 1.3125rem; }
  .navbar__brand .icon {
    color: #a688b7;
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5rem;
    flex-shrink: 0; }

.cofe__navigation {
  font-family: "Cabin", Arial, sans-serif;
  position: relative;
  display: none;
  border-top: 1px solid #004874;
  padding: 0 1rem !important; }
  .cofe__navigation ul {
    list-style: none;
    padding: 0;
    padding-top: 2rem; }
    .cofe__navigation ul li {
      margin: 0 0 2rem 0; }
    .cofe__navigation ul a {
      text-decoration: none;
      color: #004874;
      display: flex;
      align-items: center; }
      .cofe__navigation ul a .icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.25rem; }
  @media (min-width: 980px) {
    .cofe__navigation {
      display: block;
      border-top: none; }
      .cofe__navigation ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding: 0.5rem; }
        .cofe__navigation ul li {
          margin: 0.5rem 1rem; } }
  .admin-view .cofe__navigation {
    display: none; }

.church__navigation + .cofe__navigation {
  border-top: none; }

.page-header .primary__navigation {
  padding: 0;
  position: relative; }
  .page-header .primary__navigation .container {
    padding: 0; }
  .page-header .primary__navigation .navbar__right {
    margin-right: 0; }
  .page-header .primary__navigation .navbar {
    width: 100%; }
  .page-header .primary__navigation .navbar li a {
    display: flex; }
  .page-header .primary__navigation .navlist__dropdown--churches {
    list-style: none;
    padding-left: 1rem;
    top: 5.5rem;
    left: 1rem; }
    .page-header .primary__navigation .navlist__dropdown--churches .dropdown__item {
      display: flex; }
      .page-header .primary__navigation .navlist__dropdown--churches .dropdown__item .navlist__item-wrapper {
        margin-right: 1rem; }
  .page-header .primary__navigation .navbar li {
    padding: 0.75rem 1rem;
    width: 100%; }
    .page-header .primary__navigation .navbar li.active {
      background-color: #BBD0FF; }
  .page-header .primary__navigation .navbar li + li {
    margin-left: 0;
    border-top: 0; }
  .page-header .primary__navigation a {
    color: #000000; }
  .page-header .primary__navigation .acny__logo {
    display: none; }
  @media (min-width: 980px) {
    .page-header .primary__navigation {
      margin-bottom: 2rem; }
      .admin-view .page-header .primary__navigation {
        margin-bottom: 0; }
      .page-header .primary__navigation .container {
        padding: 0 1rem; }
      .page-header .primary__navigation .navbar__holder {
        border: 1px solid #A289B7;
        border-radius: 20px;
        padding: 0 1.875rem;
        background-color: #ffffff; }
        .admin-view .page-header .primary__navigation .navbar__holder {
          border: none;
          padding: 0; }
      .page-header .primary__navigation .acny__logo {
        display: block; }
      .page-header .primary__navigation .navbar {
        width: auto; }
      .page-header .primary__navigation .navbar li {
        padding: 1.875rem 0;
        width: auto; }
        .page-header .primary__navigation .navbar li.active {
          background-color: unset; }
      .page-header .primary__navigation .navbar li + li {
        margin-left: 1rem; }
      .page-header .primary__navigation .navbar li .icon {
        display: none; }
      .page-header .primary__navigation .navlist__dropdown--churches li + li {
        margin-left: 0 !important; } }
  @media (min-width: 1130px) {
    .page-header .primary__navigation .navbar li .icon {
      display: block; } }
  @media (min-width: 1195px) {
    .page-header .primary__navigation .navbar li + li {
      margin-left: 2rem; } }
  .page-header .primary__navigation--christmas .navbar__holder {
    border: 1px solid #6C93CE; }

.page-header .mobile__navigation .primary__navigation--updated,
.mobile__navigation .cofe__navigation {
  display: block; }
  @media (min-width: 980px) {
    .page-header .mobile__navigation .primary__navigation--updated,
    .mobile__navigation .cofe__navigation {
      display: none; } }

.header-background {
  display: none; }

.christmas-branding .page-header {
  position: relative;
  z-index: 10; }
  .christmas-branding .page-header .navbar li a {
    display: flex; }
  .christmas-branding .page-header .navlist__dropdown--churches {
    list-style: none;
    padding-left: 1rem;
    top: 5.5rem;
    left: 1rem; }
  @media (min-width: 980px) {
    .christmas-branding .page-header {
      background-color: #004874; } }
  @media (min-width: 1930px) {
    .christmas-branding .page-header {
      background-color: #ffffff; } }

.christmas-branding.admin-view .page-header .navlist__dropdown--churches {
  top: 4.5rem; }

@media (min-width: 980px) {
  .christmas-branding.admin-view .page-header {
    background-color: #ffffff; } }

@media (min-width: 980px) {
  .christmas-branding.accounts .cofe__navigation {
    display: block; } }

.christmas-branding.accounts .navbar__holder {
  padding: 0 1.875rem !important; }

@media (min-width: 980px) {
  .christmas-branding .cofe__navigation ul li a {
    color: #ffffff; } }

.christmas-branding .primary__navigation {
  margin-bottom: 0;
  z-index: 100; }

.christmas-branding .header-background {
  z-index: -1;
  width: 100%;
  position: absolute;
  top: 0; }
  @media (min-width: 980px) {
    .christmas-branding .header-background {
      display: block; } }
  @media (min-width: 1033px) {
    .christmas-branding .header-background {
      min-height: 17rem; } }
  @media (min-width: 1280px) {
    .christmas-branding .header-background {
      max-height: 17rem; } }

.christmas-branding .header-background--events {
  width: calc(100% + 4rem);
  margin-left: -1rem;
  top: 4rem; }
  @media (min-width: 980px) {
    .christmas-branding .header-background--events {
      max-height: 20rem; } }
  @media (min-width: 1280px) {
    .christmas-branding .header-background--events {
      max-height: unset; } }
  @media (min-width: 1500px) {
    .christmas-branding .header-background--events {
      top: 0; } }
  @media (min-width: 1730px) {
    .christmas-branding .header-background--events {
      max-height: 27rem; } }
  .christmas-branding .header-background--events + .fts-logo {
    display: none;
    position: absolute;
    z-index: 10;
    top: 9rem;
    width: 10rem;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0; }
    @media (min-width: 980px) {
      .christmas-branding .header-background--events + .fts-logo {
        display: block;
        top: 11rem; } }
    @media (min-width: 1080px) {
      .christmas-branding .header-background--events + .fts-logo {
        width: 14rem; } }
    @media (min-width: 1280px) {
      .christmas-branding .header-background--events + .fts-logo {
        top: 12rem; } }

.christmas-branding .header-background--landing {
  width: calc(100% + 4rem);
  margin-left: -1rem;
  top: 4rem; }
  @media (min-width: 980px) {
    .christmas-branding .header-background--landing {
      max-height: 20rem; } }
  @media (min-width: 1280px) {
    .christmas-branding .header-background--landing {
      max-height: unset; } }
  @media (min-width: 1500px) {
    .christmas-branding .header-background--landing {
      top: 0; } }
  @media (min-width: 1730px) {
    .christmas-branding .header-background--landing {
      max-height: 27rem; } }
  .christmas-branding .header-background--landing + .fts-logo {
    display: none;
    position: absolute;
    z-index: 10;
    top: 9rem;
    width: 10rem;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0; }
    @media (min-width: 980px) {
      .christmas-branding .header-background--landing + .fts-logo {
        display: block;
        top: 11rem; } }
    @media (min-width: 1080px) {
      .christmas-branding .header-background--landing + .fts-logo {
        width: 14rem; } }
    @media (min-width: 1280px) {
      .christmas-branding .header-background--landing + .fts-logo {
        top: 12rem; } }

.easter-branding .page-header {
  position: relative;
  z-index: 10; }
  .easter-branding .page-header .navbar li a {
    display: flex; }
  .easter-branding .page-header .navlist__dropdown--churches {
    list-style: none;
    padding-left: 1rem;
    top: 5.5rem;
    left: 1rem; }

.easter-branding.admin-view .header-background {
  display: none; }

.easter-branding.admin-view .page-header .navlist__dropdown--churches {
  top: 4.5rem; }

@media (min-width: 980px) {
  .easter-branding.admin-view .page-header {
    background-color: #ffffff; } }

@media (min-width: 980px) {
  .easter-branding.accounts .cofe__navigation,
  .easter-branding.accounts .header-background, .easter-branding.my-churches .cofe__navigation,
  .easter-branding.my-churches .header-background {
    display: block; } }

.easter-branding.accounts .navbar__holder, .easter-branding.my-churches .navbar__holder {
  padding: 0 1.875rem !important; }

@media (min-width: 980px) {
  .easter-branding .cofe__navigation ul li a {
    color: #ffffff; } }

.easter-branding .primary__navigation {
  margin-bottom: 0;
  z-index: 100; }

.easter-branding .header-background {
  z-index: -1;
  width: 100%;
  position: absolute;
  top: 4rem; }
  @media (min-width: 980px) {
    .easter-branding .header-background {
      display: block;
      top: 0;
      min-height: 15rem; } }
  @media (min-width: 1280px) {
    .easter-branding .header-background {
      max-height: 17rem; } }

.easter-branding.home .header-background {
  display: block; }

.container {
  width: 100%;
  max-width: 92.5rem;
  margin: auto;
  padding: 0 1rem; }
  .container.wide {
    max-width: 60rem; }
  .container.narrow {
    max-width: 37.5rem !important; }
    .container.narrow.awesomness {
      max-width: 50rem; }
  .container.padded {
    padding: 1.5rem 1rem !important; }
  .container .extra-padded {
    padding: 2rem !important; }
    @media (min-width: 980px) {
      .container .extra-padded {
        padding: 5rem !important; } }
  .container .no-padding {
    padding: 0 !important; }
  .container.flex-on-mobile {
    display: flex;
    flex-flow: column nowrap; }
    @media (min-width: 980px) {
      .container.flex-on-mobile {
        display: block; } }

.flatpage {
  max-width: 860px;
  padding: 0 1rem 5rem 1rem; }
  .flatpage .pebble-trail {
    color: #4A4A4A; }

.page-layout {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 1rem;
  position: relative; }
  @media all and (min-width: 981px) {
    .page-layout {
      flex-flow: row nowrap;
      padding: 0 1rem 0 0; }
      .page-layout:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background: #fff;
        left: calc(0.5 * (-100vw + 92.5rem));
        width: calc(0.5 * (100vw - 92.5rem)); }
      body.admin-view .page-layout:after,
      .acny-as-homepage__wrapper .page-layout:after {
        content: none; } }
  .page-layout.loading {
    position: relative; }
    .page-layout.loading:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      width: 100vw;
      background: #fff;
      align-items: center;
      justify-content: center;
      z-index: 10000; }
    .page-layout.loading:after {
      content: "";
      display: inline-block;
      z-index: 15000;
      width: 8rem;
      height: 8rem;
      background: url(../img/loader.svg) center center/contain no-repeat;
      position: absolute;
      top: 40vh;
      left: 50%;
      transform: translate(-50%, -50%); }
  .page-layout.acny-as-homepage__container {
    padding: 0; }

.page__wrapper {
  width: 100%;
  overflow: hidden; }

.church-page__wrapper {
  background: #382e75; }
  .church-page__wrapper.acny-as-homepage__wrapper {
    background: #F1F1F1;
    padding: 1.25rem 2.25rem 1.375rem; }
  body.admin-view .church-page__wrapper {
    background: #E4E4E4; }

.side-content {
  position: relative; }

.page-content {
  padding-bottom: 1rem; }

@media all and (min-width: 981px) {
  .side-content {
    width: 21rem;
    flex-shrink: 0;
    padding: 0 0 1rem 1rem;
    background: #fff; }
    .side-content.acny-as-homepage {
      margin-top: 20rem; }
    .admin-view .side-content {
      padding: 0;
      background: none;
      width: 23.75rem; }
  .search-layout .side-content {
    width: 28.875rem; } }

.two__column {
  margin-top: 2rem; }
  .two__column .two__column-column:first-of-type {
    min-height: 15rem;
    margin-bottom: 1.5rem; }
  @media all and (min-width: 768px) {
    .two__column {
      display: flex;
      flex-flow: row nowrap;
      width: calc(100% + 1.5rem);
      margin: 2rem 0 0 -0.75rem; }
      .two__column .two__column-column {
        width: 50%;
        margin: 0 0.75rem; }
        .two__column .two__column-column:first-of-type {
          margin-bottom: 0; } }

.main-content__intro {
  margin-top: 1.5rem; }

.main-content {
  padding-bottom: 1.5rem; }
  .main-content > div:first-child {
    margin-top: 2rem; }
    @media all and (min-width: 981px) {
      .main-content > div:first-child {
        margin-top: 0; } }
  .accounts .main-content > div:first-child {
    margin-top: 0; }
  @media all and (max-width: 540px) {
    .church-detail .has-church-image .main-content .billboard__panel + .panel__grid {
      padding-top: 0;
      margin-top: 2rem; }
      .church-detail .has-church-image .main-content .billboard__panel + .panel__grid .event__panel .panel__content {
        padding-top: 7rem; } }
  .main-content.church-page__main {
    padding: 0;
    margin-bottom: 6rem; }
    @media all and (min-width: 981px) {
      .main-content.church-page__main {
        padding: 1rem 0 1rem 1rem; } }

@media (min-width: 980px) {
  .main-content {
    width: 100%;
    padding: 1rem 0 1rem 1rem;
    position: relative;
    min-width: 0; }
    .main-content.acny-as-homepage__main {
      padding: 0 0 0 1.25rem; }
  .acny-as-homepage__main-content-wrapper {
    display: flex;
    width: 100%; }
    .acny-as-homepage__main-content-wrapper .acny-as-homepage__side-content-wrapper {
      display: flex;
      width: 100%; }
      .acny-as-homepage__main-content-wrapper .acny-as-homepage__side-content-wrapper .side-content {
        width: 24rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between; } }
  @media (min-width: 980px) and (min-width: 980px) {
    .acny-as-homepage__main-content-wrapper .acny-as-homepage__mobile {
      display: none; } }

.m-0 {
  margin: 0 !important; }

.h-100 {
  height: 100%; }

.content__divider {
  height: 2rem;
  background: #e4e4e4; }

.content__panel {
  background: #FFFFFF;
  margin-top: 1rem;
  padding: 0; }
  @media (min-width: 980px) {
    .content__panel {
      padding: 1rem !important; }
      .content__panel#content-panel__deleteform {
        padding: 2rem !important; }
      .content__panel#content-panel__galleryform {
        padding: 2rem !important; }
      .content__panel#content-panel__findusform {
        padding: 2rem !important; }
      .content__panel#content-panel__event-detail {
        padding: 0 !important;
        background: none; } }
  @media (max-width: 980px) {
    .church-page__main .content__panel,
    .church-page__main .content__panel:first-child {
      margin-top: 0; } }
  .content__panel + .content__panel {
    margin-top: 1rem; }
  .content__panel + .content__panel.bordered {
    margin-top: 0;
    border-top: 1rem solid #E4E4E4;
    padding: 1rem; }
    body.admin-view .content__panel + .content__panel.bordered,
    .acny-as-homepage__wrapper .content__panel + .content__panel.bordered {
      border-top: 1rem solid #F1F1F1; }
  .content__panel .full__map {
    margin-left: -2rem;
    width: calc(100% + 4rem); }
  .content__panel.intro__map {
    padding: 0 0 0 0 !important; }
  .content__panel.analytics {
    margin: 0;
    width: auto; }
    .content__panel.analytics .analytics__container {
      border-bottom: 3px solid #E8A065;
      padding-bottom: 0.5rem; }
      @media all and (min-width: 500px) {
        .content__panel.analytics .analytics__container {
          display: inline-block;
          border-bottom: 3px solid #E8A065; }
          .content__panel.analytics .analytics__container div {
            margin-right: 1.5rem;
            display: inline-block; } }
      @media all and (min-width: 768px) {
        .content__panel.analytics .analytics__container {
          float: right;
          margin-bottom: 1rem; } }
    .content__panel.analytics p {
      clear: both; }
    .content__panel.analytics h2 {
      font-size: 1.4375rem; }
  .content__panel.church__description {
    padding: 2rem !important;
    font-size: 1.5rem;
    line-height: 1.5;
    overflow-wrap: break-word; }
    .content__panel.church__description hr {
      margin-bottom: 2.5rem;
      border-color: #F5F5F5; }
  .content__panel .headline a, .content__panel .main-content form fieldset legend a, .main-content form fieldset .content__panel legend a, .content__panel #lightbox form fieldset legend a, #lightbox form fieldset .content__panel legend a {
    text-decoration: none; }
  @media (min-width: 980px) {
    .content__panel {
      padding: 2rem;
      margin-top: 0; }
      .content__panel + .content__panel {
        margin-top: 1rem; } }
  .content__panel--benefice-form {
    padding: 0 !important; }

.stats__panel {
  margin-top: 1rem; }
  .stats__panel + .content__panel {
    margin-top: 1rem; }
  @media all and (min-width: 768px) {
    .stats__panel {
      margin-top: 0;
      padding: 1rem 2rem;
      background: #a688b7; }
      .stats__panel + .content__panel {
        margin-top: 1rem; } }

.event_list__filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 979px) {
    .event_list__filter-container {
      padding: 0 0 1rem 1rem; } }

.event_list__select-events .button.action {
  line-height: 1.5rem;
  color: #4a4a4a;
  display: flex;
  align-items: center; }
  .event_list__select-events .button.action .icon {
    margin-left: 0.5rem;
    margin-right: 0; }

.event_list__select-events .button.action.delete {
  background-color: #DE2F62;
  background: #DE2F62;
  color: #ffffff;
  line-height: 1.5rem;
  display: flex;
  align-items: center; }
  .event_list__select-events .button.action.delete:hover {
    border-color: #DE2F62; }

.event_list__single-action.button-row,
.event_list__multiple-action.button-row {
  margin-top: 0; }

.card__edit--delete-event {
  width: auto;
  margin-top: 0rem; }
  .card__edit--delete-event .custom__checkbox {
    margin-right: 0; }
    .card__edit--delete-event .custom__checkbox .icon {
      fill: #4A4A4A; }
    .card__edit--delete-event .custom__checkbox:hover {
      border-color: #a688b7; }
  .card__edit--delete-event input[type='checkbox']:focus + label .custom__checkbox {
    border-color: #a688b7; }
  .card__edit--delete-event input {
    position: absolute;
    left: -99999px; }

.event__panel .panel__image {
  width: 100%;
  height: 12.5rem;
  margin-bottom: 0; }

.event__panel .headline, .event__panel .main-content form fieldset legend, .main-content form fieldset .event__panel legend,
.event__panel #lightbox form fieldset legend, #lightbox form fieldset .event__panel legend {
  margin-top: 1rem; }

.event__panel .panel__content {
  background: #FFFFFF;
  padding: 1rem 2rem 2rem; }

.panel__column > * {
  margin-bottom: 1rem !important; }

.action__panel {
  position: relative;
  margin-bottom: 1rem;
  background: #95317A;
  color: #F5F5F5;
  padding: 2rem 1rem 2rem 2rem; }
  .action__panel h3 {
    margin: 0; }
  .action__panel-form {
    padding: 0 !important; }
  .action__panel .donate__description {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem; }
    .action__panel .donate__description .btn {
      margin-left: 1rem;
      flex-shrink: 0; }
  .action__panel .donate__donations {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0; }
    .action__panel .donate__donations * + * {
      margin-left: 0.5rem; }
  .action__panel .donate__amount {
    list-style: none;
    background-color: #ffffff;
    color: #4A4A4A;
    padding: 1rem;
    position: relative;
    width: 50px;
    height: 40px;
    display: block;
    position: relative;
    margin-bottom: 1rem; }
    .action__panel .donate__amount input[type="radio"]:checked + label {
      background-color: #E8A065;
      top: 0px;
      line-height: 40px;
      color: white; }
    .action__panel .donate__amount:hover {
      background-color: #E8A065;
      cursor: pointer;
      color: white; }
  .action__panel .donate__amount label,
  .action__panel .donate__amount input {
    display: block;
    position: absolute;
    top: 6px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 26px;
    text-transform: none;
    text-align: center; }
  .action__panel .donate__amount input[type="radio"] {
    opacity: 0.01;
    z-index: 100; }
  .action__panel .donate__amount-custom {
    width: 160px;
    position: relative;
    /* Re-applies the controls on :hover and :focus */ }
    .action__panel .donate__amount-custom label {
      visibility: hidden;
      height: 0; }
    .action__panel .donate__amount-custom input[type=number] {
      width: 130px;
      appearance: textfield !important;
      color: #4A4A4A !important;
      height: 40px;
      border: none !important;
      position: relative;
      padding-left: 10px !important; }
      .action__panel .donate__amount-custom input[type=number]::placeholder {
        color: #4A4A4A !important; }
    .action__panel .donate__amount-custom input[type="number"]:hover,
    .action__panel .donate__amount-custom input[type="number"]:focus {
      appearance: number-input !important; }
    .action__panel .donate__amount-custom input[type=number]::-webkit-inner-spin-button,
    .action__panel .donate__amount-custom input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: auto !important; }

.everydayfaith__panel {
  background: #a688b7 url("../img/everydayfaith_cta.png") center/cover no-repeat; }
  @media (min-width: 1280px) {
    .everydayfaith__panel p {
      margin-bottom: 0.15rem; } }

.calendar__panel,
.calendar {
  background: #FFFFFF; }
  .calendar__panel .calendar__header,
  .calendar .calendar__header {
    padding: 2rem 2rem 0 2rem; }

.media__panel {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative; }

.map__panel {
  position: relative;
  width: 100%;
  min-height: 17.5rem;
  margin-top: 1rem; }
  @media all and (min-width: 768px) {
    .map__panel {
      height: 100%;
      min-height: 100%;
      margin-top: 0; } }

.panel__grid {
  margin-top: 2rem; }
  .content__panel + .panel__grid {
    margin-top: 1rem; }
  @media all and (min-width: 768px) {
    .panel__grid {
      display: flex;
      flex-flow: row wrap;
      margin-top: 1rem; }
      .panel__grid > div,
      .panel__grid > a {
        display: block;
        margin: 0 1rem 1rem 0;
        width: calc(50% - 0.5rem); }
        .panel__grid > div:nth-of-type(2n),
        .panel__grid > a:nth-of-type(2n) {
          margin-right: 0; }
      .panel__grid .media__panel {
        padding-bottom: calc(50% - 1rem); } }
  @media all and (min-width: 981px) {
    .panel__grid {
      margin-top: 0; }
      .church-detail .panel__grid,
      .event-detail .panel__grid {
        margin-top: 1rem; } }

.panel__split-holder {
  width: 100%;
  background: #fff;
  margin-top: 1rem;
  overflow: hidden; }
  .panel__split-holder .panel__split-small,
  .panel__split-holder .panel__split-large {
    padding: 2rem 2rem 3rem; }
  .panel__split-holder .panel__split {
    margin-top: 0; }
    .panel__split-holder .panel__split > div {
      margin-top: 0; }
  .panel__split-holder.border-panel {
    border-top: 1rem solid #F1F1F1;
    margin-top: 0; }
    .panel__split-holder.border-panel .panel__split-small,
    .panel__split-holder.border-panel .panel__split-large {
      margin-bottom: 0; }
  @media all and (min-width: 1240px) {
    .panel__split-holder .panel__split > div:first-of-type {
      border-right: 3px solid #F5F5F5; }
    .panel__split-holder .panel__split-small {
      width: 30%; }
    .panel__split-holder .panel__split-large {
      width: 70%; } }

.btn.next.panel__icon-btn {
  width: auto; }
  .btn.next.panel__icon-btn .icon {
    right: 0;
    left: auto; }

.panel__icon-btn-text {
  font-size: 1rem;
  padding-right: 2rem;
  display: block; }

.btn.panel__action-btn {
  color: #4A4A4A !important; }

.panel__collage .panel__collage-column {
  display: flex;
  width: 100%; }

.panel__collage .panel {
  min-height: 0;
  width: 100%;
  overflow: hidden; }
  .panel__collage .panel__no-overlay {
    min-height: 15rem; }
    @media all and (min-width: 768px) {
      .panel__collage .panel__no-overlay {
        min-height: auto; } }
    .panel__collage .panel__no-overlay .panel__image:after {
      display: none; }

@media all and (min-width: 768px) {
  .panel__collage {
    display: grid; } }

@media all and (min-width: 600px) {
  .panel__collage.collage__1 {
    grid-template-columns: 33% 34% 33%;
    grid-template-rows: auto auto; } }

@media all and (min-width: 768px) {
  .panel__collage.collage__2 {
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto auto; } }

@media all and (min-width: 1241px) {
  .panel__collage.collage__2 {
    grid-template-columns: 33.3% 33.3% 33.3%;
    grid-template-rows: auto auto auto; } }

@media all and (min-width: 1241px) {
  .panel__collage.collage__2 {
    grid-template-columns: 20% 33% 23.5% 23.5%;
    grid-template-rows: auto auto auto; }
    .panel__collage.collage__2 .panel__1 {
      grid-column: 1 / span 2;
      grid-row: 1 / 2; }
    .panel__collage.collage__2 .panel__2 {
      grid-column: 3 / 4;
      grid-row: 1 / 2; }
    .panel__collage.collage__2 .panel__3 {
      grid-column: 4 / 5;
      grid-row: 1 / 2; }
    .panel__collage.collage__2 .panel__4 {
      grid-column: 1 / 3;
      grid-row: 2 / span 2; }
    .panel__collage.collage__2 .panel__5 {
      grid-column: 2 / 3;
      grid-row: 2 / span 2; }
    .panel__collage.collage__2 .panel__6 {
      grid-column: 3 / 4;
      grid-row: 2 / span 2; }
    .panel__collage.collage__2 .panel__7 {
      grid-column: 4 / 5;
      grid-row: 2 / 3; }
    .panel__collage.collage__2 .panel__8 {
      grid-column: 4 / 4;
      grid-row: 2 / span 2; }
    .panel__collage.collage__2 .panel__9 {
      grid-column: 1 / 5;
      grid-row: 4 / span 4; } }

.panel__collage .panel .panel__image:after {
  background: rgba(166, 136, 183, 0.85); }

.panel__collage .panel:nth-child(5n + 2) .panel__image:after {
  background: rgba(111, 190, 198, 0.85); }

.panel__collage .panel:nth-child(5n + 3) .panel__image:after {
  background: rgba(149, 49, 122, 0.85); }

.panel__collage .panel:nth-child(5n + 4) .panel__image:after {
  background: rgba(108, 147, 206, 0.85); }

.panel__collage .panel:nth-child(5n + 5) .panel__image:after {
  background: rgba(56, 46, 117, 0.85); }

.panel__collage .panel:nth-child(5n + 7) .panel__image:after {
  background: rgba(56, 46, 117, 0.85); }

.panel__collage .panel__collage-column .panel .panel__image:after {
  background: rgba(108, 147, 206, 0.85); }

.panel__collage .panel__collage-column .panel:nth-of-type(5n + 2) .panel__image:after {
  background: rgba(56, 46, 117, 0.85); }

.panel__collage .panel__collage-column .panel:nth-of-type(5n + 3) .panel__image:after {
  background: rgba(149, 49, 122, 0.85); }

.panel__collage .panel__collage-column .panel:nth-of-type(5n + 4) .panel__image:after {
  background: rgba(166, 136, 183, 0.85); }

.panel__collage .panel__collage-column .panel:nth-of-type(5n + 5) .panel__image:after {
  background: rgba(111, 190, 198, 0.85); }

.panel.darkblack .panel__image:after {
  background: rgba(0, 0, 0, 0.6); }

.panel.black .panel__image:after {
  background: rgba(0, 0, 0, 0.4); }

.panel.noback .panel__image:after {
  background: none !important; }

.panel__split {
  width: 100%; }
  .panel__split > div {
    margin-top: 2rem; }
  @media all and (min-width: 1240px) {
    .panel__split {
      display: flex;
      flex-flow: row nowrap;
      margin-top: 1rem; }
      .panel__split > div {
        display: block;
        margin: 0 1rem 1rem 0; }
      .panel__split .panel__split-small {
        width: 30%;
        flex-shrink: 0;
        margin-right: 0; }
      .panel__split .panel__split-large {
        width: calc(70% - 1rem);
        flex-shrink: 0; } }

.panel {
  text-decoration: none;
  display: block;
  background: #a688b7;
  color: #FFFFFF;
  position: relative; }
  .panel .panel__image,
  .panel > .icon {
    display: none; }
  .panel .panel__image {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    .panel .panel__image:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(149, 49, 122, 0.85); }
  .panel .panel__content {
    padding: 1.5rem 4rem 1.5rem 2rem;
    position: relative; }
  .panel > .icon {
    display: inline-block;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    border: 3px solid #E8A065;
    padding: 0.25rem;
    width: 2rem;
    height: 2rem; }

.billboard__panel {
  display: none;
  position: relative;
  margin-bottom: 1rem;
  color: #4A4A4A;
  padding: 2rem 1rem 2rem; }
  .billboard__panel .billboard__content {
    position: relative; }
  .billboard__panel .billboard__content--page-detail {
    color: #FFFFFF; }
  @media all and (min-width: 981px) {
    .billboard__panel {
      display: block;
      color: #FFFFFF;
      min-height: 19rem;
      padding: 3rem 2rem 3rem 2rem;
      margin-top: -1rem !important; }
      .billboard__panel.acny-as-homepage {
        margin-top: 0 !important;
        min-height: 30rem; }
        .billboard__panel.acny-as-homepage .headline, .billboard__panel.acny-as-homepage .main-content form fieldset legend, .main-content form fieldset .billboard__panel.acny-as-homepage legend,
        .billboard__panel.acny-as-homepage #lightbox form fieldset legend, #lightbox form fieldset .billboard__panel.acny-as-homepage legend {
          margin-top: 10rem;
          width: 35rem; }
        .billboard__panel.acny-as-homepage .billboard__content {
          width: 32rem; } }
  .billboard__panel.acny-as-homepage {
    display: block;
    color: #ffffff;
    background-color: #382e75; }
    .billboard__panel.acny-as-homepage .location-intro__image {
      display: block; }
  .billboard__panel.billboard__panel--purple {
    background-color: rgba(56, 46, 117, 0.625);
    color: #ffffff; }
  .billboard__panel .pebble-trail {
    padding-left: 0; }

.billboard__panel--about-page {
  display: block; }

.billboard__panel--benefice, .billboard__panel--page-detail {
  margin-bottom: 0;
  display: block;
  color: #ffffff;
  background-color: #382e75; }
  .billboard__panel--benefice .location-intro__image, .billboard__panel--page-detail .location-intro__image {
    display: block; }

.billboard__panel--benefice {
  display: none; }
  @media (min-width: 980px) {
    .billboard__panel--benefice {
      display: block;
      margin-top: 0; } }

.billboard__panel--page-detail {
  margin-top: -1rem !important; }

.billboard__side {
  position: relative;
  padding: 2rem 1rem 4rem;
  margin: -2rem -1rem;
  color: #FFFFFF; }
  .page-layout:not(.has-church-image) .billboard__side {
    padding-bottom: 4rem; }
    .page-layout:not(.has-church-image) .billboard__side.has__image {
      padding-bottom: 8rem; }
  @media all and (min-width: 981px) {
    .billboard__side {
      padding-bottom: 8rem;
      display: none; } }
  @media all and (min-width: 541px) and (max-width: 980px) {
    .has-church-image .billboard__side,
    .church-detail .has-church-image .billboard__side {
      padding: 1.5rem 1rem 4rem 1rem;
      min-height: 17rem; }
      .has-church-image .billboard__side .headline, .has-church-image .billboard__side .main-content form fieldset legend, .main-content form fieldset .has-church-image .billboard__side legend,
      .has-church-image .billboard__side #lightbox form fieldset legend, #lightbox form fieldset .has-church-image .billboard__side legend,
      .church-detail .has-church-image .billboard__side .headline,
      .church-detail .has-church-image .billboard__side .main-content form fieldset legend,
      .main-content form fieldset .church-detail .has-church-image .billboard__side legend,
      .church-detail .has-church-image .billboard__side #lightbox form fieldset legend,
      #lightbox form fieldset .church-detail .has-church-image .billboard__side legend {
        padding-left: 11rem; }
        .has-church-image .billboard__side .headline:after, .has-church-image .billboard__side .main-content form fieldset legend:after, .main-content form fieldset .has-church-image .billboard__side legend:after,
        .has-church-image .billboard__side #lightbox form fieldset legend:after, #lightbox form fieldset .has-church-image .billboard__side legend:after,
        .church-detail .has-church-image .billboard__side .headline:after,
        .church-detail .has-church-image .billboard__side .main-content form fieldset legend:after,
        .main-content form fieldset .church-detail .has-church-image .billboard__side legend:after,
        .church-detail .has-church-image .billboard__side #lightbox form fieldset legend:after,
        #lightbox form fieldset .church-detail .has-church-image .billboard__side legend:after {
          left: 11rem; } }
  body.admin-view .billboard__side {
    color: #4A4A4A; }

.billboard__side--church-detail .location-intro__image {
  display: block !important;
  margin: 2rem 1rem 0; }

.billboard__side--church-detail .billboard__content {
  margin: 3rem 1rem 0; }

@media all and (max-width: 980px) {
  .billboard__side--page-detail {
    display: none !important; } }

.donate__panel-form {
  padding-bottom: 0 !important; }

.get-in-touch {
  position: relative; }

.get-in-touch-content {
  margin-top: 1rem; }

.resource-grid {
  display: grid;
  grid-gap: 1.5vw;
  margin: 0 0 1rem 0;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); }

.resource-grid-item {
  text-align: center;
  display: grid;
  grid-template-rows: 1fr auto;
  background: #f5f5f5;
  border: 1px solid #e4e4e4;
  padding: 1rem 1rem 0.5rem 1rem;
  width: 100%;
  text-decoration: none; }
  .resource-grid-item:hover {
    background: #f2f2f2; }
  .resource-grid-item img {
    margin-bottom: 0.5rem;
    align-self: center;
    height: auto; }
  .resource-grid-item span {
    align-self: end; }

.resource-grid.image-select-gallery {
  display: none; }

#image-select-toggle:checked ~ .resource-grid.image-select-gallery {
  display: grid; }

#image-select-toggle:checked ~ .image-select__toggle .open {
  display: none; }

#image-select-toggle:checked ~ .image-select__toggle .close {
  display: block; }

.image-select__toggle.button {
  display: inline-block;
  font-size: 1rem;
  width: auto; }

.pcc__charity-number {
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 24px;
  color: #ffffff; }

.survey-admin__section h4 {
  font-size: 2rem;
  margin-bottom: 1.125rem; }

.survey-admin__section hr {
  background-color: #E4E4E4;
  height: 1px;
  border: 0;
  margin: 1.125rem 0 2rem 0; }

.survey-admin__section .button {
  font-weight: 700;
  padding: 0.3125rem 0.8125rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem; }

.survey-admin__section:last-child ul {
  padding: 0 2rem 0 0; }
  .survey-admin__section:last-child ul li:last-child {
    padding: 0; }

.survey-admin__section ul {
  padding: 0 2rem 2rem 0; }
  .survey-admin__section ul li {
    list-style-type: none;
    padding: 0 0 1rem; }
    .survey-admin__section ul li h5 {
      padding: 0 0 0.5rem; }

.page-footer {
  padding: 1.25rem 1rem;
  background-color: #F1F7FA;
  color: #004874;
  border-top: 1px solid #004874; }
  .page-footer ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column; }
  .page-footer .footer__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.25rem; }
  .page-footer .footer__top-links {
    margin-top: 1.25rem; }
    .page-footer .footer__top-links li {
      margin: 0 0 1rem 0; }
    .page-footer .footer__top-links a {
      text-decoration: none;
      font-weight: 600; }
  .page-footer .footer__social {
    margin-top: 0.75rem; }
    .page-footer .footer__social ul {
      flex-direction: row;
      align-items: center; }
    .page-footer .footer__social li {
      margin-right: 0.75rem; }
    .page-footer .footer__social .icon {
      width: 1.5rem;
      height: 1.5rem; }
    .page-footer .footer__social .icon--small {
      width: 1.25rem;
      height: 1.25rem; }
  .page-footer .footer__bottom-links {
    margin-top: 1.25rem; }
    .page-footer .footer__bottom-links ul {
      padding-top: 1.25rem;
      border-top: 1px solid #004874;
      justify-content: center; }
    .page-footer .footer__bottom-links li {
      margin: 0 0 1rem 0;
      font-size: 0.875rem; }
  @media (min-width: 640px) {
    .page-footer .footer__links {
      display: flex;
      justify-content: space-between;
      align-items: center; } }
  @media (min-width: 980px) {
    .page-footer {
      padding: 3.75rem 0; }
      .page-footer ul {
        flex-direction: row; }
      .page-footer .footer__top-links {
        margin-top: 0; }
        .page-footer .footer__top-links ul {
          flex-wrap: wrap; }
        .page-footer .footer__top-links li {
          margin: 0 1rem; }
      .page-footer .footer__social {
        margin-top: 0; }
        .page-footer .footer__social li {
          margin: 0 0.375rem; }
      .page-footer .footer__bottom-links {
        margin-top: 3.75rem; }
        .page-footer .footer__bottom-links ul {
          padding-top: 2rem; }
        .page-footer .footer__bottom-links li {
          margin: 0 0.75rem;
          font-size: 0.875rem; } }

.navbar a,
.navlist a {
  text-decoration: none; }

.navbar__holder,
.navbar {
  display: flex;
  flex-flow: row wrap; }
  @media all and (min-width: 980px) {
    .navbar__holder,
    .navbar {
      align-items: center; }
      .navbar__holder .acny-as-homepage__logo-holder,
      .navbar .acny-as-homepage__logo-holder {
        text-decoration: none;
        display: flex;
        align-items: center;
        font-size: 1.3rem;
        margin-left: 1rem; }
        .navbar__holder .acny-as-homepage__logo-holder .acny-as-homepage__venue-name,
        .navbar .acny-as-homepage__logo-holder .acny-as-homepage__venue-name {
          margin-left: 1rem; } }

.navbar {
  list-style: none;
  padding: 0;
  margin: 0; }
  @media all and (min-width: 980px) {
    .navbar li {
      border-left: 1px solid #E4E4E4;
      position: relative; }
      .navbar li a {
        display: inline-block;
        padding: 0.5rem 0.75rem; }
      .navbar li.active a,
      .navbar li a:active,
      .navbar li a:hover {
        color: #8B63A1; }
      .navbar li:last-of-type {
        border-right: 1px solid #E4E4E4; }
      .navbar li .navlist__dropdown {
        display: none;
        position: absolute;
        top: 3.5rem;
        background: #F5F5F5;
        padding: 1rem;
        z-index: 30;
        width: 18rem;
        list-style: none;
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2); }
        .navbar li .navlist__dropdown .dropdown__item {
          display: flex;
          justify-content: space-between;
          margin: 0;
          padding: 0.5rem 0; }
          .navbar li .navlist__dropdown .dropdown__item .icon--live {
            margin: 0 0 0 1rem; }
            .navbar li .navlist__dropdown .dropdown__item .icon--live:hover, .navbar li .navlist__dropdown .dropdown__item .icon--live:active {
              fill: #a688b7; }
      .navbar li .navlist__dropdown--churches {
        top: 4.5rem;
        left: 2rem;
        width: 26rem; }
      .navbar li:hover .navlist__dropdown {
        display: block; } }
  @media all and (max-width: 979px) {
    .navbar {
      flex-flow: column nowrap; }
      .navbar li + li {
        border-top: 1px solid #F5F5F5; }
      .page-header .navbar li a {
        display: block;
        padding: 0.5rem 0; } }

@media all and (min-width: 980px) {
  .navbar__right {
    width: 100%;
    width: auto;
    margin-left: auto;
    margin-right: 2.5rem; } }

.navlist .navlist__item,
.gallery-preview__container .navlist__item {
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  padding: 0.75rem 0.5rem 0.75rem 0;
  border-top: 3px solid #E4E4E4;
  align-items: flex-start; }
  .navlist .navlist__item > .icon,
  .gallery-preview__container .navlist__item > .icon {
    flex-shrink: 0;
    margin-right: 0.75rem;
    color: #A5A5A5; }
  .navlist .navlist__item:last-of-type,
  .gallery-preview__container .navlist__item:last-of-type {
    border-bottom: 3px solid #E4E4E4; }
  .navlist .navlist__item.active,
  .navlist .navlist__item.active .icon,
  .gallery-preview__container .navlist__item.active,
  .gallery-preview__container .navlist__item.active .icon {
    color: #a688b7; }
  .navlist .navlist__item a:hover,
  .navlist .navlist__item a:active,
  .gallery-preview__container .navlist__item a:hover,
  .gallery-preview__container .navlist__item a:active {
    color: #a688b7; }
  .navlist .navlist__item.active .navlist__item-wrapper .icon,
  .gallery-preview__container .navlist__item.active .navlist__item-wrapper .icon {
    color: currentColor; }
  .navlist .navlist__item.navlist__item--no-border,
  .gallery-preview__container .navlist__item.navlist__item--no-border {
    border-top: 0;
    border-bottom: 0; }
  .navlist .navlist__item .navlist__item-wrapper,
  .gallery-preview__container .navlist__item .navlist__item-wrapper {
    display: inline-flex;
    flex-shrink: 1;
    align-items: flex-start; }
    .navlist .navlist__item .navlist__item-wrapper > .icon,
    .gallery-preview__container .navlist__item .navlist__item-wrapper > .icon {
      flex-shrink: 0;
      margin-right: 1.125rem;
      color: #A5A5A5; }
  .navlist .navlist__item.indented__nav,
  .gallery-preview__container .navlist__item.indented__nav {
    margin-left: 2.25rem; }
  .navlist .navlist__item a.navlist__item-wrapper:hover .icon,
  .gallery-preview__container .navlist__item a.navlist__item-wrapper:hover .icon {
    color: inherit; }
  .navlist .navlist__item .navlist__item-content,
  .gallery-preview__container .navlist__item .navlist__item-content {
    width: 100%; }
  .navlist .navlist__item .nav__notifications,
  .gallery-preview__container .navlist__item .nav__notifications {
    position: absolute;
    right: 0.75rem;
    top: 0.825rem; }
  .navlist .navlist__item .navlist__item-content span,
  .gallery-preview__container .navlist__item .navlist__item-content span {
    display: block;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 0.875rem;
    opacity: 0.875;
    line-height: 1.5; }
  .navlist .navlist__item .navlist__item-title,
  .gallery-preview__container .navlist__item .navlist__item-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 1.286rem; }
    .navlist .navlist__item .navlist__item-title a.active,
    .gallery-preview__container .navlist__item .navlist__item-title a.active {
      color: #a688b7; }
  .navlist .navlist__item.nesting-nav__item label,
  .gallery-preview__container .navlist__item.nesting-nav__item label {
    cursor: pointer; }
    .navlist .navlist__item.nesting-nav__item label .icon,
    .gallery-preview__container .navlist__item.nesting-nav__item label .icon {
      width: 1rem;
      height: 1rem; }
    .navlist .navlist__item.nesting-nav__item label:hover,
    .gallery-preview__container .navlist__item.nesting-nav__item label:hover {
      color: #a688b7; }
  .navlist .navlist__item.nesting-nav__item .navlist__item-content,
  .navlist .navlist__item.nesting-nav__item .navlist__item-title,
  .gallery-preview__container .navlist__item.nesting-nav__item .navlist__item-content,
  .gallery-preview__container .navlist__item.nesting-nav__item .navlist__item-title {
    width: 100%; }
  .navlist .navlist__item .resourcehub_child,
  .gallery-preview__container .navlist__item .resourcehub_child {
    margin-left: 1rem; }
    .navlist .navlist__item .resourcehub_child:first-of-type,
    .gallery-preview__container .navlist__item .resourcehub_child:first-of-type {
      margin-top: 0.75rem; }
    .navlist .navlist__item .resourcehub_child:last-child,
    .gallery-preview__container .navlist__item .resourcehub_child:last-child {
      border-bottom: 0; }
  .navlist .navlist__item .edit__icon,
  .gallery-preview__container .navlist__item .edit__icon {
    opacity: 0.25; }
    .navlist .navlist__item .edit__icon:hover,
    .gallery-preview__container .navlist__item .edit__icon:hover {
      opacity: 1; }
  .navlist .navlist__item .navlist__item-content small,
  .gallery-preview__container .navlist__item .navlist__item-content small {
    display: block;
    font-family: "Open Sans", Arial, sans-serif;
    line-height: 1.5;
    margin-top: 0.125rem; }
  .navlist .navlist__item.school .navlist__item-content span,
  .gallery-preview__container .navlist__item.school .navlist__item-content span {
    color: #6C93CE; }
  .navlist .navlist__item.with__preview,
  .gallery-preview__container .navlist__item.with__preview {
    padding-top: 1.5rem; }
    .navlist .navlist__item.with__preview .navlist__item-content span,
    .gallery-preview__container .navlist__item.with__preview .navlist__item-content span {
      color: #a688b7;
      font-family: inherit;
      text-transform: uppercase;
      margin-top: 0.125rem; }
  .navlist .navlist__item.in__parish .navlist__item-content span,
  .gallery-preview__container .navlist__item.in__parish .navlist__item-content span {
    color: #E8A065; }
  .navlist .navlist__item.share,
  .gallery-preview__container .navlist__item.share {
    position: relative; }
    @media (min-width: 980px) {
      .navlist .navlist__item.share,
      .gallery-preview__container .navlist__item.share {
        border-bottom: none; } }
    .navlist .navlist__item.share li a,
    .gallery-preview__container .navlist__item.share li a {
      padding: 0 !important; }
    .navlist .navlist__item.share .icon,
    .gallery-preview__container .navlist__item.share .icon {
      margin-right: 1.25rem; }
    .navlist .navlist__item.share span.navlist__item-content,
    .gallery-preview__container .navlist__item.share span.navlist__item-content {
      width: auto; }
    .navlist .navlist__item.share ul,
    .gallery-preview__container .navlist__item.share ul {
      margin: -0.75rem -0.5rem -0.75rem auto;
      right: 0;
      top: 0;
      bottom: 0;
      list-style: none;
      padding: 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: center; }
      .navlist .navlist__item.share ul .icon,
      .gallery-preview__container .navlist__item.share ul .icon {
        margin: auto;
        align-self: center;
        opacity: 0.5; }
      .navlist .navlist__item.share ul li,
      .gallery-preview__container .navlist__item.share ul li {
        border-left: 3px solid #E4E4E4;
        height: 100%;
        min-height: 100%;
        display: flex;
        align-items: center;
        padding: 0.75rem 0.75rem 0.5rem; }
      .navlist .navlist__item.share ul a:hover .icon,
      .gallery-preview__container .navlist__item.share ul a:hover .icon {
        color: #a688b7;
        opacity: 1; }
  .navlist .navlist__item.split-item a:nth-of-type(2),
  .gallery-preview__container .navlist__item.split-item a:nth-of-type(2) {
    border-left: 3px solid #E4E4E4;
    margin-left: 1rem;
    padding-left: 1rem; }
  .navlist .navlist__item.split-item .navlist__item-wrapper,
  .gallery-preview__container .navlist__item.split-item .navlist__item-wrapper {
    flex-shrink: 0; }

.navlist .nested__list,
.gallery-preview__container .nested__list {
  color: #4A4A4A; }
  .navlist .nested__list.active,
  .navlist .nested__list.active .icon,
  .gallery-preview__container .nested__list.active,
  .gallery-preview__container .nested__list.active .icon {
    color: #a688b7; }
  .navlist .nested__list a:hover,
  .navlist .nested__list a:active,
  .gallery-preview__container .nested__list a:hover,
  .gallery-preview__container .nested__list a:active {
    color: #a688b7; }
  .navlist .nested__list.active .navlist__item-wrapper .icon,
  .gallery-preview__container .nested__list.active .navlist__item-wrapper .icon {
    color: currentColor; }

.navlist.admin-nav .navlist__item,
.gallery-preview__container.admin-nav .navlist__item {
  background: #6C93CE;
  color: #ffffff;
  padding: 0.75rem 1.625rem;
  border-bottom: 10px solid #F1F1F1 !important;
  border-top: 0; }
  .navlist.admin-nav .navlist__item.active,
  .gallery-preview__container.admin-nav .navlist__item.active {
    background: #a688b7; }
    .navlist.admin-nav .navlist__item.active:after,
    .gallery-preview__container.admin-nav .navlist__item.active:after {
      border-left: 0.5rem solid #a688b7; }
    .navlist.admin-nav .navlist__item.active:hover,
    .gallery-preview__container.admin-nav .navlist__item.active:hover {
      background: #8e68a4; }
  .navlist.admin-nav .navlist__item:hover, .navlist.admin-nav .navlist__item:focus,
  .gallery-preview__container.admin-nav .navlist__item:hover,
  .gallery-preview__container.admin-nav .navlist__item:focus {
    background: #4677c1; }
    .navlist.admin-nav .navlist__item:hover a, .navlist.admin-nav .navlist__item:focus a,
    .gallery-preview__container.admin-nav .navlist__item:hover a,
    .gallery-preview__container.admin-nav .navlist__item:focus a {
      color: #ffffff; }
  .navlist.admin-nav .navlist__item .icon,
  .gallery-preview__container.admin-nav .navlist__item .icon {
    fill: #ffffff !important; }
  .navlist.admin-nav .navlist__item:last-child,
  .gallery-preview__container.admin-nav .navlist__item:last-child {
    border-bottom: none !important; }
  .navlist.admin-nav .navlist__item.admin-nav__header,
  .gallery-preview__container.admin-nav .navlist__item.admin-nav__header {
    border-bottom: none !important; }
    .navlist.admin-nav .navlist__item.admin-nav__header .icon,
    .gallery-preview__container.admin-nav .navlist__item.admin-nav__header .icon {
      margin-right: 1.25rem; }
    .navlist.admin-nav .navlist__item.admin-nav__header:hover,
    .gallery-preview__container.admin-nav .navlist__item.admin-nav__header:hover {
      background: #6C93CE; }
  .navlist.admin-nav .navlist__item.active--benefice,
  .gallery-preview__container.admin-nav .navlist__item.active--benefice {
    padding: 0.75rem 1.625rem !important;
    pointer-events: none; }
    .navlist.admin-nav .navlist__item.active--benefice:after,
    .gallery-preview__container.admin-nav .navlist__item.active--benefice:after {
      content: none; }

.navlist.admin-nav .navlist__item--live,
.gallery-preview__container.admin-nav .navlist__item--live {
  background: #61b1b9; }
  .navlist.admin-nav .navlist__item--live:hover, .navlist.admin-nav .navlist__item--live:focus,
  .gallery-preview__container.admin-nav .navlist__item--live:hover,
  .gallery-preview__container.admin-nav .navlist__item--live:focus {
    background: #4798a0; }

.navlist.admin-nav .navlist__subnav,
.gallery-preview__container.admin-nav .navlist__subnav {
  padding: 1rem 0;
  background: #ffffff; }
  .navlist.admin-nav .navlist__subnav .navlist__item,
  .gallery-preview__container.admin-nav .navlist__subnav .navlist__item {
    background: #ffffff;
    color: #4A4A4A;
    border-bottom: none !important;
    padding: 0.75rem 0.75rem 0.75rem 1.625rem; }
    .navlist.admin-nav .navlist__subnav .navlist__item .icon,
    .gallery-preview__container.admin-nav .navlist__subnav .navlist__item .icon {
      fill: #A5A5A5 !important; }
    .navlist.admin-nav .navlist__subnav .navlist__item .navlist__item-title,
    .gallery-preview__container.admin-nav .navlist__subnav .navlist__item .navlist__item-title {
      font-size: 1.25rem; }
    .navlist.admin-nav .navlist__subnav .navlist__item:last-of-type,
    .gallery-preview__container.admin-nav .navlist__subnav .navlist__item:last-of-type {
      border-bottom: none !important; }
    .navlist.admin-nav .navlist__subnav .navlist__item:hover a,
    .gallery-preview__container.admin-nav .navlist__subnav .navlist__item:hover a {
      color: #a688b7; }
    .navlist.admin-nav .navlist__subnav .navlist__item.active,
    .gallery-preview__container.admin-nav .navlist__subnav .navlist__item.active {
      color: #a688b7;
      background: #ffffff; }

.navlist.admin-nav .navlist__subnav + .navlist__item--benefice,
.gallery-preview__container.admin-nav .navlist__subnav + .navlist__item--benefice {
  border: 10px solid #F1F1F1;
  border-width: 10px 0; }

.navlist {
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: "Cabin", Arial, sans-serif; }
  .side-content .navlist {
    font-size: 1.25rem; }
    .side-content .navlist li.active {
      position: relative; }
      .side-content .navlist li.active:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 100%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-color: transparent transparent transparent #FFFFFF;
        transform: translateY(-50%); }
  .acny-as-homepage__wrapper .navlist {
    padding: 1rem 2rem; }
    .acny-as-homepage__wrapper .navlist .icon {
      width: 1.2rem;
      height: 1.2rem;
      margin-top: 0.125rem; }
    .acny-as-homepage__wrapper .navlist .navlist__item {
      border: none; }
      .acny-as-homepage__wrapper .navlist .navlist__item .navlist__item-content ul li {
        border: none; }
    .acny-as-homepage__wrapper .navlist .navlist__item-title {
      font-size: 1.125rem; }

.navlist--secondary {
  border-top: 10px solid #E4E4E4;
  padding: 1rem 0; }
  .navlist--secondary .navlist__item:first-child {
    border-top: 0; }
  .navlist--secondary .navlist__item:last-child {
    border-bottom: 0; }

.side__navigation .icon {
  width: 1.5rem;
  height: 1.5rem; }

@media all and (min-width: 980px) {
  .side__navigation .navlist > .active {
    position: relative; }
    .side__navigation .navlist > .active:after {
      content: '';
      display: block;
      position: absolute;
      top: 1.5rem;
      right: -0.5rem;
      transform: translateY(-50%);
      border-top: 0.5rem solid transparent;
      border-bottom: 0.5rem solid transparent;
      border-left: 0.5rem solid #fff; } }

@media all and (max-width: 980px) {
  .side__navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #FFFFFF;
    z-index: 100;
    box-shadow: 0px 0.16667rem 1rem rgba(0, 0, 0, 0.175); }
    .side__navigation .navlist > .active {
      position: relative; }
      .side__navigation .navlist > .active:after {
        content: '';
        display: block;
        position: absolute;
        top: -0.5rem;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
        border-bottom: 0.5rem solid black; }
    .side__navigation .navlist {
      flex-flow: row nowrap;
      width: 100%; }
      .side__navigation .navlist .navlist__item {
        border-top: none;
        padding: 0.75rem;
        position: relative;
        text-align: center;
        width: 100%;
        justify-content: center; }
        .side__navigation .navlist .navlist__item.share {
          display: none; }
        .side__navigation .navlist .navlist__item.active:after {
          left: 50%;
          top: auto;
          bottom: 100%;
          transform: translateX(-50%);
          border-width: 0 0.5rem 0.5rem 0.5rem;
          border-color: transparent transparent #FFFFFF transparent; }
        .side__navigation .navlist .navlist__item:hover, .side__navigation .navlist .navlist__item:active {
          color: #a688b7; }
          .side__navigation .navlist .navlist__item:hover .icon, .side__navigation .navlist .navlist__item:active .icon {
            color: inherit; }
          .side__navigation .navlist .navlist__item:hover .nested__list, .side__navigation .navlist .navlist__item:active .nested__list {
            color: #4A4A4A; }
        .side__navigation .navlist .navlist__item .icon {
          margin: 0.2rem auto; }
        .side__navigation .navlist .navlist__item:last-of-type {
          border-bottom: none; }
        .side__navigation .navlist .navlist__item a span {
          font-size: 0; }
      .side__navigation .navlist .navlist__item:not(.mobile__toggle) {
        display: none; }
      .side__navigation .navlist .icon {
        margin-right: 0; }
    .side__navigation .hidden__mobile {
      display: none !important; } }

.mobile__navigation {
  display: none;
  width: 100%;
  background-color: #ffffff; }
  .mobile__navigation .hidden__mobile {
    display: none !important; }
  .mobile__navigation .navlist .navlist__item {
    padding: 0; }
    .mobile__navigation .navlist .navlist__item a, .mobile__navigation .navlist .navlist__item.share {
      padding: 0.75rem;
      width: 100%; }
    .mobile__navigation .navlist .navlist__item.admin-nav__header {
      padding: 0.75rem; }
    .mobile__navigation .navlist .navlist__item.indented__nav {
      margin-left: 3rem; }
    .mobile__navigation .navlist .navlist__item .navlist__item-content span {
      display: none; }
  .mobile__navigation .navlist__subnav .navlist__item {
    padding: 0 !important; }
    .mobile__navigation .navlist__subnav .navlist__item a {
      padding: 0.75rem 0.75rem 0.75rem 1.625rem; }
  .mobile__navigation .icon {
    width: 1.5rem;
    height: 1.5rem; }
  .mobile__navigation .location-image__preview {
    display: none; }

.mobile-navbar {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center; }
  .mobile-navbar .page__logo {
    display: inline-block;
    margin-left: 1rem; }
  .mobile-navbar .mobile__toggle {
    height: 100%; }
    .mobile-navbar .mobile__toggle label {
      padding: 0 1rem;
      height: 100%;
      justify-content: left;
      position: static;
      display: flex;
      width: 100%;
      align-items: center; }
  .mobile-navbar .mobile__toggle-title {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px); }
  .mobile-navbar .mobile__toggle-item {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    border: none !important; }
  .mobile-navbar .mobile-navbar__back-button, .mobile-navbar .mobile-navbar__title {
    display: none; }
  @media all and (min-width: 980px) {
    .mobile-navbar {
      display: none; } }
  .mobile-navbar .acny-as-homepage__logo-mobile {
    width: 2.5rem;
    height: 3rem;
    margin: 0.8rem 1rem 0.8rem 1.5rem; }
  .mobile-navbar .acny-as-homepage__venue-name {
    font-size: 1.3rem; }

.close {
  display: none; }

@media all and (max-width: 979px) {
  #mobile_toggle:checked ~ .page-header .mobile__navigation {
    display: block; }
  #mobile_toggle:checked ~ .page-header .mobile__toggle-item {
    display: none; }
  #mobile_toggle:checked ~ .page-header .mobile__toggle .close {
    display: inline-block; }
  #mobile_toggle:checked ~ .page__wrapper,
  #mobile_toggle:checked ~ .page-header .admin__header,
  #mobile_toggle:checked ~ .billboard__panel.acny-as-homepage {
    display: none; } }

.pebble-trail {
  display: none;
  position: relative;
  padding-top: 1rem;
  padding-left: 1rem;
  color: #ffffff; }
  .pebble-trail a {
    text-decoration: none; }
  .pebble-trail__analytics-page {
    color: #382e75; }
  @media all and (min-width: 481px) {
    .pebble-trail {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 100%;
      max-width: 92.5rem;
      margin-bottom: 1.125rem;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.2; }
      .pebble-trail .icon {
        color: #a688b7;
        width: 0.75rem;
        margin: 0 0.5rem; } }
  @media all and (min-width: 481px) {
    .pebble-trail.pebble-trail-include {
      justify-content: space-between; }
      .pebble-trail.pebble-trail-include span,
      .pebble-trail.pebble-trail-include a {
        display: inline-block; } }
  @media all and (max-width: 980px) {
    .main-content .pebble-trail {
      display: none; } }
  .pebble-trail.action-pebble .icon {
    color: #E8A065; }
  body.admin-view .pebble-trail {
    color: #4A4A4A;
    padding: 0 2rem 0 0; }
    @media all and (max-width: 980px) {
      body.admin-view .pebble-trail {
        display: none; } }
  .acny-as-homepage__wrapper .pebble-trail {
    color: #4A4A4A; }
  .pebble-trail .pebble-trail__button-container {
    display: flex; }

.tabs__holder {
  padding: 1rem 1rem 0 0;
  background: #E4E4E4;
  margin-bottom: 1.5rem; }
  .calendar__panel .tabs__holder {
    width: calc(100% + 4rem);
    margin-left: -2rem; }
  .tabs__holder .headline__meta {
    margin: 0 1rem 0 auto; }
  .tabs__holder .tabs {
    position: relative; }
  .form__tabs-holder .tabs__holder {
    width: calc(100% + 2rem); }
  @media (min-width: 980px) {
    .tabs__holder {
      width: calc(100% + 2rem);
      margin-left: -1rem; }
      .form__tabs-holder .tabs__holder {
        width: calc(100% + 4rem);
        margin-left: -2rem; } }

.tabs__message {
  max-width: 40em;
  margin-left: 2rem; }
  @media (min-width: 980px) {
    .tabs__message {
      margin-left: 1rem; } }

.tab__meta {
  font-family: "Cabin", Arial, sans-serif;
  margin-left: auto;
  padding: 1rem 1rem 0 0;
  opacity: 0.5; }
  @media (min-width: 980px) {
    .tab__meta {
      font-size: 1.5rem; } }

.tabs {
  padding-left: 1rem;
  display: flex;
  flex-flow: row nowrap; }
  @media (min-width: 980px) {
    .tabs {
      display: flex;
      flex-flow: row nowrap; } }

.tab {
  padding: 1rem 1rem 0 1rem;
  font-family: "Cabin", Arial, sans-serif;
  font-size: 1.5rem;
  user-select: none;
  cursor: pointer;
  text-decoration: none; }
  .tab > span {
    display: inline-flex;
    align-items: center;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid transparent; }
  .tab.active {
    background: #FFFFFF; }
    .tab.active > span {
      border-color: #a688b7; }
  @media all and (max-width: 440px) {
    .tab.christmas {
      display: none; } }

.nav__notifications {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background: #DE2F62;
  font-size: 1rem;
  text-align: center;
  line-height: 1.2rem;
  color: #FFFFFF; }

.mobile-admin__tabbed-nav .tabs__holder {
  width: 100%;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.mobile-admin__tabbed-nav .tabs a span {
  color: #a688b7;
  border: 0; }

.mobile-admin__tabbed-nav p {
  padding: 0 1rem; }

.mobile-admin__tabbed-nav .mobile-admin__tabbed-nav-panel {
  display: none; }
  .mobile-admin__tabbed-nav .mobile-admin__tabbed-nav-panel.active {
    display: block; }
  .mobile-admin__tabbed-nav .mobile-admin__tabbed-nav-panel .mobile-admin__tabbed-nav-panel-posts {
    width: auto;
    display: flex;
    align-items: center;
    padding: 0 1rem 1rem;
    border-bottom: 3px solid #E4E4E4; }
    .mobile-admin__tabbed-nav .mobile-admin__tabbed-nav-panel .mobile-admin__tabbed-nav-panel-posts .card__grid-item {
      width: auto;
      margin-bottom: 0; }
    .mobile-admin__tabbed-nav .mobile-admin__tabbed-nav-panel .mobile-admin__tabbed-nav-panel-posts .card__image {
      width: 5rem;
      height: 5rem;
      max-width: 5rem;
      padding: 3rem;
      margin-right: 1rem; }
    .mobile-admin__tabbed-nav .mobile-admin__tabbed-nav-panel .mobile-admin__tabbed-nav-panel-posts .tabbed-nav-panel_headline-and-tags {
      display: flex;
      flex-direction: column; }
      .mobile-admin__tabbed-nav .mobile-admin__tabbed-nav-panel .mobile-admin__tabbed-nav-panel-posts .tabbed-nav-panel_headline-and-tags .mobile-post-headline {
        margin-top: 0rem; }
      .mobile-admin__tabbed-nav .mobile-admin__tabbed-nav-panel .mobile-admin__tabbed-nav-panel-posts .tabbed-nav-panel_headline-and-tags .tabbed-nav-panel__tag {
        margin-top: 1rem; }
    .mobile-admin__tabbed-nav .mobile-admin__tabbed-nav-panel .mobile-admin__tabbed-nav-panel-posts .tabbed-nav-panel-edit {
      margin-left: auto; }
      .mobile-admin__tabbed-nav .mobile-admin__tabbed-nav-panel .mobile-admin__tabbed-nav-panel-posts .tabbed-nav-panel-edit .icon {
        color: #A5A5A5; }
  .mobile-admin__tabbed-nav .mobile-admin__tabbed-nav-panel .mobile-admin__tabbed-nav-panel-posts + .mobile-admin__tabbed-nav-panel-posts {
    padding-top: 1rem; }
  .mobile-admin__tabbed-nav .mobile-admin__tabbed-nav-panel .post-button {
    display: flex;
    justify-content: center;
    margin-top: 1rem; }
  .mobile-admin__tabbed-nav .mobile-admin__tabbed-nav-panel .card__summary .btn {
    margin-top: 1rem; }

.mobile-admin__tabbed-nav .mobile-admin__tabbed-nav-toggle:checked + .mobile-admin__tabbed-nav-panel {
  display: block; }

.mobile-admin {
  display: none;
  width: 100%;
  margin-top: 0 !important; }
  .mobile-admin .mobile-admin__navigation {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: "Cabin", Arial, sans-serif;
    font-size: 1.25rem; }
    .mobile-admin .mobile-admin__navigation li + li {
      border-top: 3px solid #E4E4E4; }
    .mobile-admin .mobile-admin__navigation .mobile-admin__navigation-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      padding: 1rem; }
      .mobile-admin .mobile-admin__navigation .mobile-admin__navigation-link .icon {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
        color: #A5A5A5; }
        .mobile-admin .mobile-admin__navigation .mobile-admin__navigation-link .icon:last-child {
          margin-left: auto;
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 0; }
  .mobile-admin .mobile-admin__section {
    padding: 1rem 0 0;
    border-top: 10px solid #E4E4E4; }
    .mobile-admin .mobile-admin__section + .mobile-admin__section {
      border-top-width: 10px; }
  .mobile-admin .mobile-admin__section-title {
    display: flex;
    align-items: center;
    margin: 0 0 1rem;
    padding: 0 1rem; }
    .mobile-admin .mobile-admin__section-title h5 {
      margin: 0; }
    .mobile-admin .mobile-admin__section-title .icon {
      width: 2rem;
      height: 2rem;
      flex-shrink: 0;
      margin-right: 0.5rem;
      color: #A5A5A5; }
  .mobile-admin .mobile-admin__location-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    padding: 1rem 1rem;
    font-size: 1.25rem;
    font-family: "Cabin", Arial, sans-serif;
    border-top: 3px solid #E4E4E4; }
    .mobile-admin .mobile-admin__location-link span {
      display: block; }
    .mobile-admin .mobile-admin__location-link .mobile-admin__location-link-tags {
      margin-top: 0.5rem; }
    .mobile-admin .mobile-admin__location-link .icon {
      width: 1.5rem;
      height: 1.5rem;
      color: #A5A5A5; }

body.admin-view .mobile-admin--hide {
  display: none; }
  body.admin-view .mobile-admin--hide + p {
    margin-top: 0; }
  @media (min-width: 980px) {
    body.admin-view .mobile-admin--hide {
      display: block; }
      body.admin-view .mobile-admin--hide + p {
        margin-top: 1rem; } }

body.admin-view .mobile-admin--hide, body.admin-view .side-content {
  display: none; }
  body.admin-view .mobile-admin--hide .headline, body.admin-view .mobile-admin--hide .main-content form fieldset legend, .main-content form fieldset body.admin-view .mobile-admin--hide legend,
  body.admin-view .mobile-admin--hide #lightbox form fieldset legend, #lightbox form fieldset body.admin-view .mobile-admin--hide legend, body.admin-view .side-content .headline, body.admin-view .side-content .main-content form fieldset legend, .main-content form fieldset body.admin-view .side-content legend,
  body.admin-view .side-content #lightbox form fieldset legend, #lightbox form fieldset body.admin-view .side-content legend {
    margin-bottom: 1.5rem; }
  @media (min-width: 980px) {
    body.admin-view .mobile-admin--hide, body.admin-view .side-content {
      display: block; } }

body.admin-view .mobile-admin {
  display: block; }
  @media (min-width: 980px) {
    body.admin-view .mobile-admin {
      display: none; } }

@media (max-width: 979px) {
  body.admin-view .page__wrapper {
    overflow: initial;
    width: 100%; } }

@media (max-width: 979px) {
  body.admin-view .container.not-padded-mobile-admin {
    padding: 0 !important; } }

body.admin-view .billboard__side {
  display: none; }

body.admin-view .church-page__wrapper {
  background-color: #ffffff; }
  @media (min-width: 980px) {
    body.admin-view .church-page__wrapper {
      background-color: #F1F1F1;
      padding: 1.375rem 2.25rem; } }

body.admin-view .container {
  padding: 0; }

@media (min-width: 980px) {
  body.admin-view .page-layout {
    padding-right: 1rem; } }

body.admin-view.home .mobile-navbar {
  display: none; }

body.admin-view .mobile-navbar {
  background-color: #382e75;
  color: #ffffff;
  display: grid;
  align-items: center;
  text-align: center;
  grid-template-columns: 3rem 1fr 3rem; }
  body.admin-view .mobile-navbar .page__logo,
  body.admin-view .mobile-navbar .acny__logo {
    display: none; }
  body.admin-view .mobile-navbar .mobile__toggle {
    border-left: 3px solid #2d255e;
    width: 100%; }
    body.admin-view .mobile-navbar .mobile__toggle label {
      padding: 0;
      justify-content: center; }
  body.admin-view .mobile-navbar .mobile-navbar__back-button {
    display: block;
    height: 100%; }
    body.admin-view .mobile-navbar .mobile-navbar__back-button a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      border-right: 3px solid #2d255e; }
  body.admin-view .mobile-navbar .mobile-navbar__title {
    display: inline-block;
    font-family: "Cabin", Arial, sans-serif;
    font-size: 1.286rem;
    grid-column: 2; }
  @media all and (min-width: 981px) {
    body.admin-view .mobile-navbar {
      display: none; } }
  body.admin-view .mobile-navbar .acny-as-homepage__logo-mobile,
  body.admin-view .mobile-navbar .acny-as-homepage__venue-name {
    display: none; }

body.admin-view .navlist .icon {
  color: #A5A5A5; }
  @media (min-width: 980px) {
    body.admin-view .navlist .icon {
      color: #4A4A4A; } }

body.admin-view .admin-form__intro {
  padding: 1rem 1.5rem 0 1.5rem; }
  @media (min-width: 980px) {
    body.admin-view .admin-form__intro {
      padding: 0; } }

.mobile-admin .navlist__item {
  padding: 0.75rem 1rem; }
  .mobile-admin .navlist__item .icon {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle; }

.tabbed-nav-panel-benefice {
  padding: 1rem !important;
  border-top: 0.25rem solid #95317A; }

.admin-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3px;
  align-items: center;
  background-color: #E4E4E4;
  font-size: 1.286rem;
  font-family: "Cabin", Arial, sans-serif; }
  .admin-grid .admin-grid__item {
    background-color: #ffffff;
    min-height: 11rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .admin-grid .admin-grid__item a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-decoration: none;
      width: 100%;
      height: 100%;
      padding: 1rem;
      text-align: center; }
    .admin-grid .admin-grid__item .icon {
      width: 2.5rem;
      height: 2.5rem;
      vertical-align: middle;
      margin-bottom: 0.2rem; }
    .admin-grid .admin-grid__item p {
      font-size: 1rem;
      font-family: "Open Sans", Arial, sans-serif;
      padding: 0 1rem; }
  .admin-grid .admin-grid__item--small {
    min-height: 4rem; }
    .admin-grid .admin-grid__item--small a {
      flex-direction: row;
      justify-content: space-between; }
    .admin-grid .admin-grid__item--small .icon {
      width: 2rem;
      height: 2rem; }
    .admin-grid .admin-grid__item--small .admin-grid__heading {
      margin-left: 0.5rem; }
  .admin-grid .admin-grid__item--title {
    grid-column: span 2;
    text-align: left !important; }
  .admin-grid .admin-grid__item--single {
    grid-column: span 2; }
  .admin-grid .admin-grid__label {
    font-size: 0.86rem;
    line-height: 1rem; }
  .admin-grid .nav__notifications {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.286rem;
    line-height: 1.5rem; }
  body.admin-view .admin-grid {
    background: none; }

.admin-grid--analytics {
  border-top: 1.25rem solid #F1F1F1;
  grid-template-columns: 1fr 1fr 1fr; }
  .admin-grid--analytics h4 {
    margin-top: 0;
    display: flex;
    align-items: center; }
    .admin-grid--analytics h4 .icon {
      margin-right: 0.75rem;
      width: 1.25rem !important;
      height: 1.25rem !important; }
  .admin-grid--analytics .admin-grid__item {
    display: block;
    padding: 1.5rem;
    min-width: 33%;
    text-align: center;
    min-height: auto;
    height: 100%; }
  .admin-grid--analytics .admin-grid__stat {
    font-size: 1.4375rem;
    line-height: 1.725rem; }
  .admin-grid--analytics .admin-grid__label {
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: "Open Sans", Arial, sans-serif; }
  .admin-grid--analytics .admin-grid__item--title {
    grid-column: span 3;
    padding: 0.75rem 1.625rem; }

.admin__header {
  display: flex;
  align-items: center;
  border-bottom: 3px solid #E4E4E4;
  width: 100%;
  padding: 1rem; }
  .admin__header .icon {
    width: 2rem;
    height: 2rem; }
  .admin__header h1 {
    margin: 0 1rem;
    font-size: 1.286rem;
    display: flex;
    flex-direction: column; }
  .admin__header .admin__subheader {
    font-size: 0.857rem;
    font-weight: 600;
    color: #a688b7; }

.admin__item {
  font-size: 1.286rem;
  font-family: "Cabin", Arial, sans-serif;
  background-color: #ffffff;
  min-height: 11rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .admin__item a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    width: 100%;
    height: 100%;
    padding: 1rem;
    text-align: center; }
  .admin__item .icon {
    width: 2.5rem;
    height: 2.5rem;
    vertical-align: middle;
    margin-bottom: 0.2rem; }
  .admin__item p {
    font-size: 1rem;
    font-family: "Open Sans", Arial, sans-serif;
    padding: 0 1rem; }

body.lightbox-open {
  overflow: hidden; }

#lightbox {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center; }
  #lightbox #lightbox_backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -10;
    background: rgba(255, 255, 255, 0.95);
    cursor: pointer; }
  #lightbox #lightbox_close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    color: #4A4A4A;
    text-decoration: none;
    user-select: none;
    pointer-events: none; }
    #lightbox #lightbox_close.lightbox_close--event .lightbox_close_mobile {
      display: none; }
    @media (max-width: 979px) {
      #lightbox #lightbox_close.lightbox_close--event {
        top: 0.5rem;
        right: unset;
        left: 1rem;
        pointer-events: initial;
        z-index: 10; }
        #lightbox #lightbox_close.lightbox_close--event .lightbox_close_mobile {
          display: inline;
          font-size: 0.875rem; }
          #lightbox #lightbox_close.lightbox_close--event .lightbox_close_mobile .icon {
            margin: -0.125rem 0.5rem 0 0;
            vertical-align: middle; }
        #lightbox #lightbox_close.lightbox_close--event .lightbox_close_desktop {
          display: none; } }
  #lightbox .button__holder {
    z-index: 2000 !important; }

#lightbox_content {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  background: #fff; }
  #lightbox_content .button-sticky {
    display: none; }
  @media (min-width: 980px) {
    #lightbox_content {
      display: flex;
      flex-flow: column nowrap;
      position: relative;
      max-width: 37.5rem;
      width: calc(100vw - 3rem);
      height: auto;
      max-height: calc(100vh - 8rem);
      border: 1px solid #E4E4E4;
      box-shadow: 0px 0px 1.5rem 0.5rem #E4E4E4;
      overflow-y: unset; }
      #lightbox_content .button__holder.mobile {
        display: none !important; }
      #lightbox_content .button-sticky {
        display: inline-block; } }

#lightbox_append_target {
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem; }
  @media (min-width: 980px) {
    #lightbox_append_target {
      padding: 2rem; } }
  .lightbox_content--event > #lightbox_append_target {
    padding: 0; }
    @media (max-width: 979px) {
      .lightbox_content--event > #lightbox_append_target {
        height: 100%; } }

.home__section {
  padding: 4rem 0;
  max-width: 35rem;
  margin: auto;
  text-align: center; }
  .home__section .home__section-title {
    padding-bottom: 1.5rem;
    position: relative;
    margin-bottom: 2rem; }
    .home__section .home__section-title:after {
      content: "";
      display: block;
      position: absolute;
      width: 6.68rem;
      left: 50%;
      bottom: 0;
      height: 3px;
      background: #E8A065;
      transform: translateX(-50%); }

.home__section-wider {
  max-width: 70.31rem; }

.home__tri {
  padding: 4rem 0; }
  .home__tri .home__tri-section h4:after {
    content: "";
    display: block;
    width: 5rem;
    height: 3px;
    background: #E8A065;
    margin: 1.5rem 0; }
  .home__tri .home__tri-section + .home__tri-section {
    margin-top: 3rem; }
  @media all and (min-width: 981px) {
    .home__tri {
      display: flex; }
      .home__tri .home__tri-section {
        width: calc(100% / 3);
        padding: 0 0.75rem; }
        .home__tri .home__tri-section + .home__tri-section {
          margin-top: 0; } }

.home__duo {
  padding: 4rem 0; }
  .home__duo .home__duo-section h4:after {
    content: "";
    display: block;
    width: 5rem;
    height: 3px;
    background: #E8A065;
    margin: 1.5rem 0; }
  .home__duo .home__duo-section + .home__duo-section {
    margin-top: 3rem; }
  @media all and (min-width: 981px) {
    .home__duo {
      display: flex; }
      .home__duo .home__duo-section {
        width: calc(100% / 2);
        padding: 0 0.75rem; }
        .home__duo .home__duo-section + .home__duo-section {
          margin-top: 0; } }

.home__hero-image {
  width: 100%;
  height: calc(100vh - 5.5rem);
  background-image: url("../img/home-montage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center; }
  @media (min-width: 980px) {
    .home__hero-image {
      background-size: contain;
      background-image: url("../img/home-montage@2x.jpg"); } }
  .home__hero-image .container {
    background-color: #ffffff;
    padding: 2rem 2rem !important;
    text-align: center; }
  .home__hero-image .search__bar, .home__hero-image .search__form-header {
    margin-bottom: 1.5rem; }
  .home__hero-image .search__form-button {
    top: 4.2rem; }

@media (min-width: 980px) {
  .christmas-branding .home__hero-image {
    margin-top: 6rem; } }

@media (min-width: 980px) {
  .christmas-home-branding .home__hero-image {
    margin-top: 16rem; } }

@media (min-width: 1280px) {
  .christmas-home-branding .home__hero-image {
    margin-top: 22rem; } }

.christmas-home-branding .page__wrapper {
  position: relative; }

.easter-branding .home__hero-image {
  margin-top: 6rem; }
  @media (min-width: 640px) {
    .easter-branding .home__hero-image {
      margin-top: 10rem; } }

.card__grid {
  margin: 1rem 0; }

.card__grid-item,
.js-grid-sizer {
  width: 100%; }
  @media all and (min-width: 768px) {
    .card__grid-item,
    .js-grid-sizer {
      width: calc(50% - 0.5rem); } }

@media all and (min-width: 768px) {
  .js-grid-gutter {
    width: 1rem; } }

.card__grid-item {
  display: block;
  text-decoration: none;
  margin-bottom: 1rem; }

.card__banner {
  margin: 0;
  background: #95317A;
  color: #ffffff;
  font-size: 0.875rem;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  z-index: 1; }

.card__image {
  width: 100%;
  height: 0;
  padding-top: 50%; }
  #lightbox .card__image {
    width: calc(100% + 4rem);
    margin-top: -2rem;
    margin-left: -2rem;
    margin-bottom: 1.5rem; }

.card__title {
  margin-bottom: 1.5rem; }
  .card__title h3 a {
    text-decoration: none; }

.card__days {
  margin-bottom: 0.5rem; }

.card__summary {
  padding: 2rem;
  background: #fff;
  position: relative; }
  .card__summary .social li:first-of-type {
    margin-right: 1rem; }
  .card__summary .social li + li {
    margin-left: 0; }
  #lightbox .card__summary {
    position: relative;
    padding: 0; }
  .card__summary .btn-primary {
    width: 100%;
    margin: 1.5rem 0 1rem 0;
    text-align: center;
    justify-content: center; }

.card__edit {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: #cbcbcb; }
  .card__edit:hover {
    color: #a688b7; }
  .card__edit.delete:hover {
    color: #DE2F62; }
  .card__edit.update:hover {
    color: #6C93CE; }
  #lightbox .card__edit {
    top: -1rem;
    right: -1rem; }
  .with__card-image .card__edit {
    background: #fff;
    padding: 0.25rem 0.5rem;
    top: 0;
    right: 0; }

.card__button-row {
  margin-top: 1.5rem;
  padding-left: 2rem; }
  .card__button-row--panel {
    margin-top: 0; }
  .card__button-row.next {
    text-align: right; }
  .card__button-row.full {
    padding-left: 0; }
    .card__button-row.full a {
      max-width: 100%; }

.card__flex {
  padding: 0rem 2rem 3rem 2rem;
  max-width: 100rem;
  margin: 3rem auto 0rem auto;
  display: flex;
  flex-flow: row wrap; }
  .card__flex .card__grid-item {
    width: 100%;
    margin: 0.5rem;
    display: flex;
    flex-direction: column; }
    .card__flex .card__grid-item .card__image {
      height: 12rem; }
    .card__flex .card__grid-item .card__summary {
      padding: 1rem 1rem 3rem;
      height: 100%;
      max-height: 25rem;
      overflow: scroll;
      position: relative; }
      .card__flex .card__grid-item .card__summary .btn {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        justify-content: center;
        align-items: center;
        display: flex;
        background: white; }
        .card__flex .card__grid-item .card__summary .btn .icon {
          margin-right: 0; }
    @media (min-width: 541px) and (max-width: 1279px) {
      .card__flex .card__grid-item {
        margin: 2%;
        width: 46%; } }
    @media (min-width: 1280px) {
      .card__flex .card__grid-item {
        width: auto;
        flex: 1; } }

.card--event {
  position: relative; }
  .card--event dd {
    margin-inline: 0; }
  .card--event .meta__venue > * {
    margin: 0;
    word-wrap: break-word; }
  .card--event .meta__venue .venue {
    text-decoration: underline; }
  .card--event .meta__venue .address {
    font-size: 0.75rem;
    font-style: normal; }
  .card--event .meta__venue + .meta__venue {
    margin-top: 0.5rem; }
  .card--event .card__title {
    padding-right: 1rem; }
    .card--event .card__title a {
      text-decoration: none; }
  .card--event .meta__event-description {
    display: block; }
    .card--event .meta__event-description .read-more {
      padding-left: 0;
      margin-top: 1rem; }
  .card--event .card__event-description {
    word-break: break-word; }
  .card--event .card__button-row, .card--event .card__button-stack {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    gap: 1rem;
    padding-left: 0; }
  .card--event .card__button-stack {
    flex-flow: column nowrap; }
  .card--event .card__button, .card--event .card__tab {
    flex: 1 0 auto;
    padding: 0.875rem !important;
    color: #4A4A4A;
    text-align: center; }
    .card--event .card__button .icon, .card--event .card__tab .icon {
      width: 1.25rem;
      height: 1.25rem;
      margin-top: -0.25rem;
      margin-right: 0;
      color: #cbcbcb;
      vertical-align: middle; }
  .card--event .card__button--lowercase {
    background: #ffffff !important;
    font-family: "Open Sans", Arial, sans-serif;
    text-transform: none !important; }
    .card--event .card__button--lowercase:not(:disabled):hover .icon, .card--event .card__button--lowercase:not(:disabled).active .icon {
      color: #e18539 !important; }
    .card--event .card__button--lowercase .icon {
      margin-left: 0.25rem; }
  .card--event .card__tabs {
    display: flex;
    flex-flow: row wrap;
    gap: 3px;
    width: 100%;
    border-top: #E4E4E4 solid 3px;
    background: #E4E4E4; }
    .card--event .card__tabs .card__tab {
      border-width: 0 !important;
      background: #ffffff !important;
      text-transform: none !important;
      font-family: "Open Sans", Arial, sans-serif !important;
      line-height: 1.5rem !important; }
      .card--event .card__tabs .card__tab:hover, .card--event .card__tabs .card__tab:focus-visible, .card--event .card__tabs .card__tab.active {
        margin-top: -3px;
        color: #382e75 !important; }
        .card--event .card__tabs .card__tab:hover .icon, .card--event .card__tabs .card__tab:focus-visible .icon, .card--event .card__tabs .card__tab.active .icon {
          color: #a688b7 !important; }
      .card--event .card__tabs .card__tab.active {
        pointer-events: none; }
      .card--event .card__tabs .card__tab:first-of-type .icon {
        margin-left: 0.25rem; }
      .card--event .card__tabs .card__tab .icon + .icon {
        margin-right: 0.25rem; }
      .card--event .card__tabs .card__tab--share.active {
        order: -1; }
        @media (min-width: 366px) {
          .card--event .card__tabs .card__tab--share.active {
            order: initial; } }
  .card--event .card__panel {
    position: relative;
    padding: 2rem;
    background: #ffffff;
    color: #4A4A4A !important; }
    .card--event .card__panel .panel-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start; }
      .card--event .card__panel .panel-header .panel-close {
        position: absolute;
        top: 1rem;
        right: 0;
        border: none !important;
        background: none !important;
        color: #cbcbcb !important; }
        .card--event .card__panel .panel-header .panel-close:hover, .card--event .card__panel .panel-header .panel-close:focus-visible {
          color: #a688b7 !important; }
        .card--event .card__panel .panel-header .panel-close .icon {
          width: 1.5rem;
          height: 1.5rem; }
    .card--event .card__panel .panel-content {
      position: relative; }
      .card--event .card__panel .panel-content:before {
        content: "";
        position: absolute;
        top: -4rem;
        right: 0;
        left: 0;
        padding-top: 4rem;
        background: linear-gradient(180deg, transparent 0%, #ffffff 100%); }
      .card--event .card__panel .panel-content--share:before {
        top: -5rem;
        padding-top: 5rem; }
      .card--event .card__panel .panel-content .calendar-form {
        margin: 2rem 0 0;
        padding-bottom: 0; }
      .card--event .card__panel .panel-content .card__panel-heading {
        margin: 2.5rem 0 0;
        color: #a688b7;
        font-size: 1.5rem;
        line-height: 1.75rem; }
      .card--event .card__panel .panel-content .card__panel-subheading {
        font-size: 1.25rem;
        line-height: 1.5rem; }
      .card--event .card__panel .panel-content .card__radio-row fieldset {
        display: flex;
        flex-flow: row wrap;
        gap: 2rem;
        width: 100%;
        margin: 1.5rem 0 1rem; }
      .card--event .card__panel .panel-content .card__radio-row legend {
        position: absolute;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px); }
        .card--event .card__panel .panel-content .card__radio-row legend:after {
          content: none; }
      .card--event .card__panel .panel-content .card__radio-row label {
        font-size: 1rem; }
        .card--event .card__panel .panel-content .card__radio-row label .custom__radio {
          display: inline-block;
          margin-top: -0.125rem;
          margin-right: 0.5rem;
          vertical-align: middle; }

.card--event-detail {
  padding: 0; }
  .card--event-detail .card__summary, .card--event-detail .card__panel {
    padding: 3rem; }

#lightbox .card--event-detail .card__summary {
  padding: 2rem; }

@media (max-width: 979px) {
  #lightbox .card--event-detail {
    height: 100%; }
    #lightbox .card--event-detail .card__banner {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0; }
    #lightbox .card--event-detail .card__summary, #lightbox .card--event-detail .card__panel {
      padding: 4rem; }
    #lightbox .card--event-detail .card__tab {
      width: 100%; }
      #lightbox .card--event-detail .card__tab--share.active {
        order: -1; }
    #lightbox .card--event-detail.card--event-benefice {
      margin-bottom: 3.5rem; } }

.card__grid--benefice {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  padding: 1rem 0 0; }
  .card__grid--benefice .card__grid-item {
    flex: 1 0 32%; }

.card--event-updated {
  margin: 1.875rem 0;
  background-color: #ffffff; }
  .card--event-updated.with__card-image .card__image {
    width: calc(100% + 2px);
    margin-top: -1px;
    margin-left: -1px;
    margin-right: -1px; }
  @media (min-width: 1280px) {
    .card--event-updated.with__card-image {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem; }
      .card--event-updated.with__card-image .card__image {
        height: calc(100% + 2px);
        margin-top: -1px;
        margin-left: -1px;
        margin-bottom: -1px; }
      .card--event-updated.with__card-image .card__summary,
      .card--event-updated.with__card-image .card__panel {
        padding: 2.5rem 2rem 2.5rem 0; } }
  .card--event-updated h3 {
    font-size: 1.5rem; }
  .card--event-updated .meta .icon {
    color: #4A4A4A;
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.125rem; }
  .card--event-updated .add-to-calendar-button {
    background-color: #E7AA22;
    border-color: #E7AA22;
    color: #004874;
    padding: 0.75rem 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 0;
    font-family: "Open Sans", Arial, sans-serif; }
    .card--event-updated .add-to-calendar-button .icon {
      width: 1.25rem;
      height: 1.25rem; }
    .card--event-updated .add-to-calendar-button:hover {
      background-color: #004874;
      border-color: #004874;
      color: #ffffff; }
  .card--event-updated .share-event-button {
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-left-radius: 10px;
    border: none;
    background-color: #B9D8E7;
    color: #004874;
    font-size: 0.75rem;
    font-family: "Open Sans", Arial, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px; }
    .card--event-updated .share-event-button .icon {
      width: 1.125rem;
      height: 1.125rem;
      margin-right: 0;
      margin-left: 0.25rem; }
    .card--event-updated .share-event-button:hover {
      background-color: #004874;
      border-color: #004874;
      color: #ffffff; }
  .card--event-updated .see-all-events {
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
    color: #004874;
    text-decoration: none; }
    .card--event-updated .see-all-events .icon {
      margin-left: 0.5rem; }
    .card--event-updated .see-all-events:hover {
      text-decoration: underline; }

.account-page__wrapper {
  padding: 1rem;
  background: #F1F1F1; }
  .account-page__wrapper > .container--white {
    background-color: #ffffff; }
  .account-page__wrapper .main-content {
    padding: 0 1rem 1.5rem 1rem; }
  .account-page__wrapper + .page-footer {
    margin-top: 0; }

.icon {
  width: 1em;
  height: 1em;
  fill: currentColor; }

.social {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row nowrap; }
  .social > li + li {
    margin-left: 1rem; }

.button-row,
.btn-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  margin-top: 1.5rem; }
  .button-row.center,
  .btn-row.center {
    justify-content: center; }
  .button-row.left,
  .btn-row.left {
    justify-content: flex-start; }
  .button-row .button + .button,
  .button-row .btn + .btn,
  .btn-row .button + .button,
  .btn-row .btn + .btn {
    margin-left: 0.5rem; }

.button,
.btn {
  display: inline-block;
  padding: 0.5rem;
  border: 3px solid #E8A065;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0; }
  .button.left,
  .btn.left {
    margin-right: auto; }
  .button.small,
  .btn.small {
    font-size: 0.875rem;
    padding: 0 0.5rem; }
  .button.large,
  .btn.large {
    padding: 0.6875rem 2rem;
    font-weight: bold;
    line-height: 1.5; }
  .button.full,
  .btn.full {
    width: 100%;
    text-align: center;
    max-width: 22.5rem; }
    .button.full.margin-right,
    .btn.full.margin-right {
      margin-right: 0.3rem; }
  .button.wide,
  .btn.wide {
    padding: 0.5rem 2rem; }
  .button:hover,
  .btn:hover {
    background: rgba(232, 160, 101, 0.4); }
  .button:disabled,
  .btn:disabled {
    cursor: not-allowed; }
    .button:disabled:hover,
    .btn:disabled:hover {
      background: none; }
  .button.primary,
  .btn.primary {
    border: 3px solid #a688b7;
    color: #fff;
    background: #a688b7; }
    .button.primary:hover,
    .btn.primary:hover {
      background: #9a78ad; }
  .button.delete,
  .btn.delete {
    border: 3px solid #DE2F62; }
    .button.delete:hover, .button.delete.active,
    .btn.delete:hover,
    .btn.delete.active {
      background: rgba(222, 47, 98, 0.4); }
  .button.error,
  .btn.error {
    border: 3px solid #DE2F62;
    color: #ffffff;
    background: #DE2F62; }
    .button.error:hover, .button.error.active,
    .btn.error:hover,
    .btn.error.active {
      border-color: #d22155;
      background: #d22155; }
  .button.urgent-delete,
  .btn.urgent-delete {
    border: 3px solid #b3001b;
    background: #b3001b;
    color: #fff; }
    .button.urgent-delete:hover, .button.urgent-delete.active,
    .btn.urgent-delete:hover,
    .btn.urgent-delete.active {
      background: rgba(179, 0, 27, 0.6); }
  .button.secondary,
  .btn.secondary {
    border: 3px solid #95317A;
    color: #fff;
    background: #95317A; }
    .button.secondary:hover,
    .btn.secondary:hover {
      background: #822b6a; }
  .button.action,
  .btn.action {
    background: #E8A065;
    color: #fff; }
    .button.action:hover,
    .btn.action:hover {
      background: #e5924f;
      border-color: #e5924f; }
    .button.action:disabled:hover,
    .btn.action:disabled:hover {
      cursor: not-allowed;
      background: #E8A065;
      border-color: #E8A065; }
    .button.action.action--dark,
    .btn.action.action--dark {
      color: #4A4A4A; }
  .button.grey,
  .btn.grey {
    border: 3px solid #E4E4E4 !important;
    background: #E4E4E4 !important; }
    .button.grey:hover,
    .btn.grey:hover {
      background: #d7d7d7 !important; }
  .button.grey-outline,
  .btn.grey-outline {
    border: 3px solid #d8d8d8;
    background: #F5F5F5; }
  .button.back,
  .btn.back {
    position: relative; }
    .button.back:hover:before,
    .btn.back:hover:before {
      color: #e5924f; }
    .button.back:before,
    .btn.back:before {
      color: #E8A065;
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: -1.1em;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 1em 1em 1em 0;
      border-color: transparent currentColor transparent transparent;
      transform: translateY(-50%); }
    .button.back.faded:hover,
    .btn.back.faded:hover {
      opacity: 1 !important; }
  .button.next,
  .btn.next {
    height: 2.5rem;
    width: 2.5rem;
    position: relative; }
    .button.next .icon,
    .btn.next .icon {
      position: absolute;
      width: 1.2rem;
      height: 1.2rem;
      top: 0.5rem;
      left: 0.5rem; }
  .button.align-center,
  .btn.align-center {
    display: flex;
    justify-content: center;
    align-items: center; }

.btn .icon,
button .icon {
  display: inline-block;
  margin-right: 0.5rem; }

.btn .icon--right {
  margin-right: 0;
  margin-left: 0.5rem; }

.section-header-btn {
  padding: 1.5rem 1rem 1.5rem 1.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  color: #F5F5F5;
  text-decoration: none !important; }
  .section-header-btn.events {
    background: #6C93CE; }
  .section-header-btn.news {
    background: #61b1b9; }
  .section-header-btn.benefice {
    background: #a688b7;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
    .section-header-btn.benefice .panel__icon-btn {
      width: 2.5rem; }
    @media all and (min-width: 768px) {
      .section-header-btn.benefice {
        margin-top: 0 !important; } }

.button-sticky {
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  pointer-events: none; }
  .button-sticky .button {
    pointer-events: all; }

.btn--live {
  display: flex !important;
  align-items: center;
  padding: 0.5rem 0.75rem;
  background-color: #61b1b9;
  color: #ffffff;
  border: none;
  cursor: pointer; }
  .btn--live .icon {
    fill: #ffffff;
    width: 1rem;
    height: 1rem;
    margin-right: 0; }
  .btn--live:hover, .btn--live:focus {
    background-color: #4798a0; }
  .btn--live + .btn--live {
    margin-left: 0.5rem; }

.btn-inline .button {
  text-transform: none;
  width: 100%;
  max-width: 11rem;
  margin-bottom: 1rem; }
  .btn-inline .button svg {
    margin-left: 0.625rem; }

@media all and (min-width: 1024px) {
  .btn-inline {
    display: flex;
    justify-content: flex-end; }
    .btn-inline .button {
      margin-right: 1rem;
      text-transform: none;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      width: auto; }
      .btn-inline .button:last-of-type {
        margin-right: 0; } }

.venue__panel-btn {
  position: relative;
  width: auto;
  height: auto;
  padding: 0.625rem 0.675rem 0.9rem;
  line-height: 1.125rem; }
  .venue__panel-btn span {
    font-size: 1.375rem; }
  .venue__panel-btn .icon {
    position: relative;
    width: 1.2375rem;
    height: 1.2375rem;
    margin: 0 0.45rem;
    vertical-align: middle; }

.button__download {
  display: flex;
  align-items: center; }
  .button__download-xmas {
    background-color: #E7AA22 !important;
    border: none;
    font-size: 1rem !important;
    color: #ffffff !important;
    padding-top: 0;
    padding-bottom: 0;
    height: auto !important; }

.text-only {
  background: none;
  border: none;
  text-decoration: underline;
  color: #5c5c5c;
  font-size: 0.95rem;
  cursor: pointer; }

.page__logo {
  width: 15rem;
  height: 3.125rem;
  background: url("../img/logo.jpg") center center/contain no-repeat; }
  body.admin-view .page__logo {
    width: 13.125rem;
    height: 3rem; }

.acny__logo {
  width: 8rem;
  height: 3.125rem;
  background: url("../img/acny_logo.svg") center center/contain no-repeat; }

.church__logo {
  width: 15rem;
  height: 3.125rem; }

.logo {
  margin: 1rem 0; }

.acny-as-homepage__logo {
  width: 4rem;
  height: 4.5rem; }

.acny-as-homepage__main-logo {
  display: flex;
  width: 15rem;
  height: 3.125rem;
  background: url("../img/logo.jpg") center center/contain no-repeat;
  margin: 1rem auto; }

.acny-as-homepage__logo-holder {
  background: #ffffff; }

ol {
  margin: 0;
  padding: 0;
  counter-reset: custom-counter;
  list-style: none; }
  ol li {
    position: relative;
    padding: 0 0 0 3rem; }
    ol li + li {
      margin-top: 0.5rem; }
    ol li:before {
      display: inline-block;
      width: 3rem;
      content: counter(custom-counter) ".";
      counter-increment: custom-counter;
      position: absolute;
      left: 1rem;
      top: 0;
      color: #8B63A1;
      font-weight: bold; }

img {
  width: 100%;
  height: auto;
  vertical-align: top;
  image-orientation: from-image; }

.tag__list {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  margin-bottom: 1rem;
  padding: 0; }

.tag {
  flex-shrink: 0;
  display: inline-block;
  padding: 0 0.5rem;
  background: lightgray;
  margin: 0px 0px 4px 0px;
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-family: "Cabin", Arial, sans-serif;
  vertical-align: top; }
  .tag.facilities {
    background: #a688b7; }
  .tag.features {
    background: #95317A; }
  .tag.location, .tag.action {
    background: #E8A065; }
  .tag.news-tags, .tag.tertiary {
    background: #6C93CE; }
  .tag.communion, .tag.tertiary-light {
    background: #6FBEC6; }
  .tag.diocese {
    background: #95317A; }
  .tag.deanery {
    background: #6FBEC6; }
  .tag.my-church {
    background: #a688b7; }
  .tag.my-benefice {
    background: #95317A; }
  .tag.status-green {
    background: #A3C27F; }
  .tag.with__description {
    padding: 0;
    display: inline-flex;
    flex-flow: column nowrap; }
    .tag.with__description .tag__name {
      padding: 0 0 0 0.5rem; }
      .tag.with__description .tag__name label {
        width: 100%;
        display: flex;
        justify-content: space-between; }
  .tag.h4, .tag.user__table-name {
    font-size: 1.5rem;
    padding: 0.4rem 0.4rem !important; }

div[class*="tag__group"] ~ div[class*="tag__group"],
div[class*="tag__group"] + div[class*="tag__group"] {
  margin-top: 2.5rem; }

.tag__name {
  display: inline-flex;
  user-select: none;
  justify-content: space-between; }
  .tag__name label {
    display: flex; }

.tag__control {
  display: inline-block;
  margin-left: 0.5rem;
  width: 1.5rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.4); }
  .tag__control .close {
    display: none; }

.tag__description {
  display: block;
  text-transform: none;
  padding: 0.75rem 1rem;
  border: 3px solid #E4E4E4;
  border-top: none;
  color: #4A4A4A;
  background: white; }

input[type="checkbox"]:checked + .tag__name .tag__control {
  background: none; }
  input[type="checkbox"]:checked + .tag__name .tag__control .open {
    display: none; }
  input[type="checkbox"]:checked + .tag__name .tag__control .close {
    display: block; }

input[type="checkbox"]:checked ~ .tag__description {
  display: block; }

.tag__holder {
  width: 100%; }
  @media all and (min-width: 768px) {
    .tag__holder {
      width: calc(50% - 0.5rem); }
      .tag__holder:nth-of-type(2n) {
        margin-left: 1rem; } }

._event-types, .group-services-and-events {
  color: #6FBEC6; }

.services-and-events,
.advent,
.allages,
.allsaints,
.allsaintsandsouls,
.allsouls,
.ashwednesday,
.baptismchristening,
.bcp,
.biblesunday,
.brexitprayers,
.candlemass,
.carols,
.choirevent,
.christingle,
.christmaseve,
.christmas,
.coffee,
.communion,
.compline,
.confirmationservice,
.coronation,
.cribnativity,
.cw,
.dedication,
.easter,
.easterday,
.epiphany,
.episcopalservice,
.eveningworship,
.fam_fri,
.fathersday,
.followthestar,
.freshexpression,
.funeral,
.goodfriday,
.harvest,
.healing,
.holyweek,
.hotcrossbuns,
.hymnsevent,
.incense,
.lent,
.marriage,
.maundythursday,
.memorialservice,
.messychurch,
.midnightmass,
.mince_pies,
.morningworship,
.motheringsunday,
.mulled_wine,
.musicevent,
.nightprayer,
.ordinations,
.palmsunday,
.patronalfestival,
.pentecost,
.petservice,
.ploughsunday,
.prayers,
.queen,
.remembrance,
.rogationday,
.saintsdays,
.schoolservice,
.shrovetuesday,
.stationscross,
.signing,
.singingevent,
.tellservegive19,
.thanksgiving,
.thykingdomcome,
.wedding {
  background: #6FBEC6; }

._event-locations, .group-event-locations {
  color: #E8A065; }

.event-locations,
.beach,
.cathedral,
.chapel,
.church,
.hall,
.shop,
.space,
.green,
.hired,
.nursing,
.others,
.private,
.pub,
.school,
.street,
.town,
.vicarage {
  background: #E8A065; }

._groups-courses-and-activities, .group-groups-and-activities {
  color: #95317A; }

.groups-and-activities,
.addiction,
.afterschool,
.alpha,
.asylum,
.beavers,
.biblestudy,
.boysbrigade,
.brass,
.brownies,
.christianityexplored,
.coffemorning,
.communityevent,
.communitygroup,
.counselling,
.creche,
.cubs,
.debt,
.discipleship,
.employment,
.exercise,
.girlsbrigade,
.guides,
.healthclub,
.holidayclub,
.homesless,
.lentcourse,
.mentalheath,
.marriagecourse,
.mentoring,
.moneyadvice,
.mothersunion,
.nightshelter,
.nurture,
.over60s,
.parenting,
.parentstoddlers,
.pilgrim,
.playgroup,
.preschool,
.rainbows,
.refugees,
.rehab,
.scouts,
.seascouts,
.socialaction,
.streetpastor,
.sunday_school,
.voluntarygroup,
.youth,
.youthwork {
  background: #95317A; }

._our-building {
  color: #8fab73; }

.listed,
.postoffice {
  background: #8fab73; }

._accessibility {
  color: #a688b7; }

.toilets,
.baby,
.parish_church,
.parking,
.access_lav,
.access_phone,
.autism_aware,
.access_park,
.defibrilator,
.wheel,
.hearing,
.facilities-form .signing,
.braile,
.largep,
.access_dogs,
.dementia_aware {
  background: #a688b7; }

._music-and-worship {
  color: #6FBEC6; }

.bells,
.music,
.organ,
.bcp_services,
.choir {
  background: #6FBEC6; }

._help-for-visitors, .group-news-tags {
  color: #6C93CE; }

.wifi,
.tours,
.guide,
.cafe,
.facilities-form .shop,
.church_open {
  background: #6C93CE; }

._other-features {
  color: #382e75; }

.fairtrade,
.foodbank,
.audio,
.conservation,
.upa,
.credit_union,
.backtochurch,
.facilities-form .hall {
  background: #382e75; }

.action_required {
  background: #DE2F62; }
  .action_required.icon {
    background: none;
    fill: #DE2F62; }

.contact_form {
  background: #a688b7; }
  .contact_form.icon {
    background: none;
    fill: #a688b7; }

.tags__list-more {
  display: none; }

.tags__list-initial {
  display: initial; }

.christmas-results-page__container .tag,
.card--event-updated .tag {
  color: #4A4A4A;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  padding: 0.25rem 0.5rem;
  margin: 0 0.375rem 0.625rem 0; }

.christmas-results-page__container .services-and-events,
.christmas-results-page__container .advent,
.christmas-results-page__container .allages,
.christmas-results-page__container .allsaints,
.christmas-results-page__container .allsaintsandsouls,
.christmas-results-page__container .allsouls,
.christmas-results-page__container .ashwednesday,
.christmas-results-page__container .baptismchristening,
.christmas-results-page__container .bcp,
.christmas-results-page__container .biblesunday,
.christmas-results-page__container .brexitprayers,
.christmas-results-page__container .candlemass,
.christmas-results-page__container .carols,
.christmas-results-page__container .choirevent,
.christmas-results-page__container .christingle,
.christmas-results-page__container .christmaseve,
.christmas-results-page__container .christmas,
.christmas-results-page__container .coffee,
.christmas-results-page__container .communion,
.christmas-results-page__container .compline,
.christmas-results-page__container .confirmationservice,
.christmas-results-page__container .coronation,
.christmas-results-page__container .cribnativity,
.christmas-results-page__container .cw,
.christmas-results-page__container .dedication,
.christmas-results-page__container .easter,
.christmas-results-page__container .easterday,
.christmas-results-page__container .epiphany,
.christmas-results-page__container .episcopalservice,
.christmas-results-page__container .eveningworship,
.christmas-results-page__container .fam_fri,
.christmas-results-page__container .fathersday,
.christmas-results-page__container .followthestar,
.christmas-results-page__container .freshexpression,
.christmas-results-page__container .funeral,
.christmas-results-page__container .goodfriday,
.christmas-results-page__container .harvest,
.christmas-results-page__container .healing,
.christmas-results-page__container .holyweek,
.christmas-results-page__container .hotcrossbuns,
.christmas-results-page__container .hymnsevent,
.christmas-results-page__container .incense,
.christmas-results-page__container .lent,
.christmas-results-page__container .marriage,
.christmas-results-page__container .maundythursday,
.christmas-results-page__container .memorialservice,
.christmas-results-page__container .messychurch,
.christmas-results-page__container .midnightmass,
.christmas-results-page__container .mince_pies,
.christmas-results-page__container .morningworship,
.christmas-results-page__container .motheringsunday,
.christmas-results-page__container .mulled_wine,
.christmas-results-page__container .musicevent,
.christmas-results-page__container .nightprayer,
.christmas-results-page__container .ordinations,
.christmas-results-page__container .palmsunday,
.christmas-results-page__container .patronalfestival,
.christmas-results-page__container .pentecost,
.christmas-results-page__container .petservice,
.christmas-results-page__container .ploughsunday,
.christmas-results-page__container .prayers,
.christmas-results-page__container .queen,
.christmas-results-page__container .remembrance,
.christmas-results-page__container .rogationday,
.christmas-results-page__container .saintsdays,
.christmas-results-page__container .schoolservice,
.christmas-results-page__container .shrovetuesday,
.christmas-results-page__container .stationscross,
.christmas-results-page__container .signing,
.christmas-results-page__container .singingevent,
.christmas-results-page__container .tellservegive19,
.christmas-results-page__container .thanksgiving,
.christmas-results-page__container .thykingdomcome,
.christmas-results-page__container .wedding,
.card--event-updated .services-and-events,
.card--event-updated .advent,
.card--event-updated .allages,
.card--event-updated .allsaints,
.card--event-updated .allsaintsandsouls,
.card--event-updated .allsouls,
.card--event-updated .ashwednesday,
.card--event-updated .baptismchristening,
.card--event-updated .bcp,
.card--event-updated .biblesunday,
.card--event-updated .brexitprayers,
.card--event-updated .candlemass,
.card--event-updated .carols,
.card--event-updated .choirevent,
.card--event-updated .christingle,
.card--event-updated .christmaseve,
.card--event-updated .christmas,
.card--event-updated .coffee,
.card--event-updated .communion,
.card--event-updated .compline,
.card--event-updated .confirmationservice,
.card--event-updated .coronation,
.card--event-updated .cribnativity,
.card--event-updated .cw,
.card--event-updated .dedication,
.card--event-updated .easter,
.card--event-updated .easterday,
.card--event-updated .epiphany,
.card--event-updated .episcopalservice,
.card--event-updated .eveningworship,
.card--event-updated .fam_fri,
.card--event-updated .fathersday,
.card--event-updated .followthestar,
.card--event-updated .freshexpression,
.card--event-updated .funeral,
.card--event-updated .goodfriday,
.card--event-updated .harvest,
.card--event-updated .healing,
.card--event-updated .holyweek,
.card--event-updated .hotcrossbuns,
.card--event-updated .hymnsevent,
.card--event-updated .incense,
.card--event-updated .lent,
.card--event-updated .marriage,
.card--event-updated .maundythursday,
.card--event-updated .memorialservice,
.card--event-updated .messychurch,
.card--event-updated .midnightmass,
.card--event-updated .mince_pies,
.card--event-updated .morningworship,
.card--event-updated .motheringsunday,
.card--event-updated .mulled_wine,
.card--event-updated .musicevent,
.card--event-updated .nightprayer,
.card--event-updated .ordinations,
.card--event-updated .palmsunday,
.card--event-updated .patronalfestival,
.card--event-updated .pentecost,
.card--event-updated .petservice,
.card--event-updated .ploughsunday,
.card--event-updated .prayers,
.card--event-updated .queen,
.card--event-updated .remembrance,
.card--event-updated .rogationday,
.card--event-updated .saintsdays,
.card--event-updated .schoolservice,
.card--event-updated .shrovetuesday,
.card--event-updated .stationscross,
.card--event-updated .signing,
.card--event-updated .singingevent,
.card--event-updated .tellservegive19,
.card--event-updated .thanksgiving,
.card--event-updated .thykingdomcome,
.card--event-updated .wedding {
  background: #ACD5B8;
  color: #15361F; }

.christmas-results-page__container .event-locations,
.christmas-results-page__container .beach,
.christmas-results-page__container .cathedral,
.christmas-results-page__container .chapel,
.christmas-results-page__container .church,
.christmas-results-page__container .hall,
.christmas-results-page__container .shop,
.christmas-results-page__container .space,
.christmas-results-page__container .green,
.christmas-results-page__container .hired,
.christmas-results-page__container .nursing,
.christmas-results-page__container .others,
.christmas-results-page__container .private,
.christmas-results-page__container .pub,
.christmas-results-page__container .school,
.christmas-results-page__container .street,
.christmas-results-page__container .town,
.christmas-results-page__container .vicarage,
.card--event-updated .event-locations,
.card--event-updated .beach,
.card--event-updated .cathedral,
.card--event-updated .chapel,
.card--event-updated .church,
.card--event-updated .hall,
.card--event-updated .shop,
.card--event-updated .space,
.card--event-updated .green,
.card--event-updated .hired,
.card--event-updated .nursing,
.card--event-updated .others,
.card--event-updated .private,
.card--event-updated .pub,
.card--event-updated .school,
.card--event-updated .street,
.card--event-updated .town,
.card--event-updated .vicarage {
  background: #F3D591;
  color: #5F4100; }

.christmas-results-page__container .groups-and-activities,
.christmas-results-page__container .addiction,
.christmas-results-page__container .afterschool,
.christmas-results-page__container .alpha,
.christmas-results-page__container .asylum,
.christmas-results-page__container .beavers,
.christmas-results-page__container .biblestudy,
.christmas-results-page__container .boysbrigade,
.christmas-results-page__container .brass,
.christmas-results-page__container .brownies,
.christmas-results-page__container .christianityexplored,
.christmas-results-page__container .coffemorning,
.christmas-results-page__container .communityevent,
.christmas-results-page__container .communitygroup,
.christmas-results-page__container .counselling,
.christmas-results-page__container .creche,
.christmas-results-page__container .cubs,
.christmas-results-page__container .debt,
.christmas-results-page__container .discipleship,
.christmas-results-page__container .employment,
.christmas-results-page__container .exercise,
.christmas-results-page__container .girlsbrigade,
.christmas-results-page__container .guides,
.christmas-results-page__container .healthclub,
.christmas-results-page__container .holidayclub,
.christmas-results-page__container .homesless,
.christmas-results-page__container .lentcourse,
.christmas-results-page__container .mentalheath,
.christmas-results-page__container .marriagecourse,
.christmas-results-page__container .mentoring,
.christmas-results-page__container .moneyadvice,
.christmas-results-page__container .mothersunion,
.christmas-results-page__container .nightshelter,
.christmas-results-page__container .nurture,
.christmas-results-page__container .over60s,
.christmas-results-page__container .parenting,
.christmas-results-page__container .parentstoddlers,
.christmas-results-page__container .pilgrim,
.christmas-results-page__container .playgroup,
.christmas-results-page__container .preschool,
.christmas-results-page__container .rainbows,
.christmas-results-page__container .refugees,
.christmas-results-page__container .rehab,
.christmas-results-page__container .scouts,
.christmas-results-page__container .seascouts,
.christmas-results-page__container .socialaction,
.christmas-results-page__container .streetpastor,
.christmas-results-page__container .sunday_school,
.christmas-results-page__container .voluntarygroup,
.christmas-results-page__container .youth,
.christmas-results-page__container .youthwork,
.card--event-updated .groups-and-activities,
.card--event-updated .addiction,
.card--event-updated .afterschool,
.card--event-updated .alpha,
.card--event-updated .asylum,
.card--event-updated .beavers,
.card--event-updated .biblestudy,
.card--event-updated .boysbrigade,
.card--event-updated .brass,
.card--event-updated .brownies,
.card--event-updated .christianityexplored,
.card--event-updated .coffemorning,
.card--event-updated .communityevent,
.card--event-updated .communitygroup,
.card--event-updated .counselling,
.card--event-updated .creche,
.card--event-updated .cubs,
.card--event-updated .debt,
.card--event-updated .discipleship,
.card--event-updated .employment,
.card--event-updated .exercise,
.card--event-updated .girlsbrigade,
.card--event-updated .guides,
.card--event-updated .healthclub,
.card--event-updated .holidayclub,
.card--event-updated .homesless,
.card--event-updated .lentcourse,
.card--event-updated .mentalheath,
.card--event-updated .marriagecourse,
.card--event-updated .mentoring,
.card--event-updated .moneyadvice,
.card--event-updated .mothersunion,
.card--event-updated .nightshelter,
.card--event-updated .nurture,
.card--event-updated .over60s,
.card--event-updated .parenting,
.card--event-updated .parentstoddlers,
.card--event-updated .pilgrim,
.card--event-updated .playgroup,
.card--event-updated .preschool,
.card--event-updated .rainbows,
.card--event-updated .refugees,
.card--event-updated .rehab,
.card--event-updated .scouts,
.card--event-updated .seascouts,
.card--event-updated .socialaction,
.card--event-updated .streetpastor,
.card--event-updated .sunday_school,
.card--event-updated .voluntarygroup,
.card--event-updated .youth,
.card--event-updated .youthwork {
  background: #B9D8E7;
  color: #004874; }

.search__bar {
  display: flex;
  flex-flow: row nowrap;
  border: 3px solid #E8A065;
  width: 100%; }
  .search__bar .icon {
    width: 1.5em;
    height: 1.5em;
    flex-shrink: 0; }
  .search__bar > .icon {
    height: 3rem;
    width: 3rem;
    padding: 0.75rem; }

.search__form-holder {
  position: relative; }

.search__form small {
  margin-top: 0.5rem;
  display: block;
  line-height: 1.5; }

.search__form input,
.search__form button {
  width: 100%;
  border: none;
  appearance: none;
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 0;
  vertical-align: top;
  font-size: 16px;
  font-family: inherit;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  border-radius: 0; }
  .search__form input:focus,
  .search__form button:focus {
    outline: none; }

.search__form-section {
  width: 100%; }

.search__form-section + .search__form-section {
  margin-top: 1rem; }

@media all and (min-width: 541px) {
  .search__form-section {
    width: calc(50% - 2rem);
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 0.25rem; }
    .search__form-section + .search__form-section {
      margin-top: 0; }
    .search__form-section.tags-disabled {
      width: calc(100% - 4rem); } }

@media all and (min-width: 981px) {
  .side-content .search__form-header {
    display: none; }
  .side-content .search__form-body {
    padding-right: 1rem;
    flex-flow: row wrap; }
  .side-content .search__form-section {
    margin-bottom: 1rem;
    width: 100%; }
  .side-content .search__form-section button,
  .side-content .search__form-button button {
    display: flex;
    width: 100%;
    justify-content: center; }
    .side-content .search__form-section button span,
    .side-content .search__form-button button span {
      display: block; }
  .side-content .search__form-button {
    position: relative;
    padding-right: 1rem; } }

.search__form-button {
  flex-shrink: 1;
  flex-grow: 0;
  margin-top: 1rem; }
  .search__form-button button {
    width: 100%;
    border: none;
    appearance: none;
    width: 100%;
    vertical-align: top;
    font-size: 16px;
    font-family: inherit;
    -webkit-font-smoothing: antialiased;
    line-height: 1;
    border-radius: 0;
    text-align: center;
    padding: calc(1rem + 2px) 0; }
    .search__form-button button span {
      margin-right: 0.5rem; }
    .search__form-button button .icon {
      vertical-align: middle;
      margin-right: 0.25rem; }
    .search__form-button button:focus {
      outline: none; }
  @media all and (min-width: 541px) {
    .search__form-button {
      margin-top: 0;
      position: absolute;
      right: 0.125rem;
      bottom: 0; }
      .search__form-button span {
        display: none; }
      .search__form-button button {
        width: 3.5rem; } }

@media all and (min-width: 541px) {
  .search__form--empty .search__form-button {
    top: 6rem; } }

@media all and (min-width: 569px) {
  .search__form--empty .search__form-button {
    top: 3.2rem; } }

.search__form-header {
  display: none; }
  @media all and (min-width: 541px) {
    .search__form-header {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: 0.5rem; }
      .search__form-header h3 {
        width: calc(50% - 2.5rem);
        margin: 0; }
        .search__form-header h3 + h3 {
          margin-left: 0.25rem; }
        .search__form-header h3.tags-disabled {
          width: calc(100% - 2.5rem);
          margin-left: 0.25rem; } }

.home .search__form-header {
  display: block; }

.search__form-subtitle {
  margin: 1rem 0.5rem 1.5rem 0.5rem; }
  @media (min-width: 980px) {
    .search__form-subtitle {
      margin-bottom: 1rem; } }

.search__tags-holder {
  position: relative; }

.search__form-body {
  display: flex;
  flex-flow: row wrap; }
  @media all and (min-width: 541px) {
    .search__form-body {
      flex-flow: row nowrap; } }

@media all and (max-width: 540px) {
  body.home .search__form-body .search__form-section {
    display: none; }
    body.home .search__form-body .search__form-section.tags-disabled {
      display: block; }
    body.home .search__form-body .search__form-section + .search__form-section {
      display: block; } }

.ui-autocomplete {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #F5F5F5;
  position: absolute;
  top: 100%;
  left: -3px !important;
  width: calc(100% + 6px) !important;
  top: calc(100% + 3px) !important;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 13px;
  color: black;
  font-weight: bold;
  max-height: 12.875rem;
  overflow: auto;
  z-index: 200; }
  .ui-autocomplete li {
    padding-left: calc(1rem + 3px);
    cursor: pointer;
    line-height: 30px; }
    .ui-autocomplete li:hover, .ui-autocomplete li:focus {
      background: #fafafa; }
    .ui-autocomplete li + li {
      border-top: 3px solid #E4E4E4; }

#acny_search_tags {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-right: 0.5rem; }
  #acny_search_tags .taggle_placeholder {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: rgba(74, 74, 74, 0.8); }
  #acny_search_tags .taggle_list {
    width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-flow: row wrap;
    min-width: 0;
    overflow: hidden;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: flex-start;
    padding: 0.625rem 1rem 0.25rem 0; }
  #acny_search_tags input {
    display: inline-block;
    float: left;
    padding: 0.25rem 0;
    margin-bottom: 0.25rem;
    line-height: 1;
    width: 10px !important; }
    #acny_search_tags input:focus {
      width: auto !important; }
  #acny_search_tags .taggle_sizer {
    pointer-events: none;
    opacity: 0;
    position: absolute; }
  #acny_search_tags li,
  #acny_search_tags .taggle {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 0.25rem;
    vertical-align: top;
    overflow: hidden; }
  #acny_search_tags .taggle {
    padding: 0.16667rem 0.5rem;
    margin-bottom: 0.25rem; }
  #acny_search_tags .taggle {
    background: #a688b7;
    color: #fff; }
    #acny_search_tags .taggle.custom {
      background: #E4E4E4;
      color: #4A4A4A; }
      #acny_search_tags .taggle.custom button {
        color: inherit; }
    #acny_search_tags .taggle button:hover {
      color: #DE2F62; }
    #acny_search_tags .taggle.taggle_hot {
      background: #A5A5A5; }
      #acny_search_tags .taggle.taggle_hot button {
        color: #DE2F62; }
  #acny_search_tags .close {
    padding: 0;
    background: none;
    width: auto;
    color: #fff;
    margin-left: 0.5rem; }

.ui-helper-hidden-accessible {
  display: none; }

.search__form-row {
  display: flex;
  flex-flow: row nowrap; }
  .search__form-row button {
    border: 3px solid #E8A065; }

.legend__bar {
  padding: 0.5rem 2rem;
  background: #fff;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: 3px solid #F5F5F5;
  line-height: 1; }
  .legend__bar .church__legend,
  .legend__bar .school__legend {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-right: 2rem; }
    .legend__bar .church__legend span,
    .legend__bar .school__legend span {
      display: inline-block; }
    .legend__bar .church__legend:last-child,
    .legend__bar .school__legend:last-child {
      margin-right: 0; }
    @media all and (min-width: 980px) and (max-width: 1080px) {
      .legend__bar .church__legend,
      .legend__bar .school__legend {
        font-size: 0.75rem;
        margin-right: 1.5rem; } }
    @media all and (max-width: 540px) {
      .legend__bar .church__legend,
      .legend__bar .school__legend {
        font-size: 0.75rem;
        margin-right: 0.5rem; } }
  .legend__bar .map__marker.legend {
    position: relative;
    transform: none;
    margin-right: 1.25rem;
    z-index: 50; }
    .legend__bar .map__marker.legend:before {
      width: 0.75rem;
      height: 0.75rem; }
    .legend__bar .map__marker.legend:after {
      font-size: 0.875rem; }
  .legend__bar .map__marker.pin {
    position: relative;
    transform: none;
    margin-right: 1.25rem;
    z-index: 50; }
    .legend__bar .map__marker.pin:before {
      width: 0.75rem;
      height: 0.75rem; }
    .legend__bar .map__marker.pin:after {
      font-size: 0.875rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg); }
  .legend__bar .list__count {
    margin: 0 0.25rem 0 0;
    padding: 0;
    position: relative; }
    .legend__bar .list__count:before {
      position: static;
      width: 0.675rem;
      height: 0.675rem;
      transform: rotate(45deg); }
    .legend__bar .list__count:after {
      content: "";
      position: static;
      background: url(../icons/svg/cross.svg) center/contain no-repeat;
      width: 1.75rem;
      height: 1.75rem;
      padding-left: 4px; }
    .legend__bar .list__count.school {
      background: none; }
      .legend__bar .list__count.school:before {
        background: #6C93CE;
        border: 3px solid #6C93CE; }
      .legend__bar .list__count.school:after {
        background: url(../icons/svg/school.svg) center/contain no-repeat;
        width: 1.75rem;
        height: 1.75rem;
        padding-left: 4px; }

.search__list #search_detail_holder {
  display: none; }

#search_detail .button + .button {
  margin-top: 1rem; }

.search__result .list__count {
  margin: 0; }

.search__result .list__count-holder {
  margin: 0;
  width: 2.75rem;
  margin-right: 0.75rem;
  text-align: center;
  flex-shrink: 0; }

.search__result.result__school .school-report-btn {
  display: block !important;
  width: 14rem;
  margin: 0.5rem 0;
  text-align: center; }

.side-content__results {
  padding: 1rem 0;
  border-top: 3px solid #F5F5F5; }
  .side-content__results h3,
  .side-content__results .h3 {
    margin-bottom: 0; }
  @media all and (min-width: 768px) {
    .side-content__results {
      padding: 1rem 1rem 1rem 0; } }

.filter__list {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  margin-top: 0.25rem;
  font-family: "Cabin", Arial, sans-serif; }

.filter,
.add__filter {
  flex-shrink: 0;
  display: inline-block;
  padding: 0 0.5rem;
  background: #E8A065;
  margin: 0 2px 2px 0;
  color: #fff; }

.add__filter {
  background: rgba(232, 160, 101, 0.4);
  color: #4A4A4A; }

.meta {
  display: flex;
  flex-flow: row nowrap; }
  .meta + .meta {
    margin-top: 1rem; }
  .meta .icon {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.75rem;
    flex-shrink: 0;
    color: #A5A5A5;
    vertical-align: middle; }
    .meta .icon.primary {
      color: #a688b7; }

@media all and (min-width: 768px) {
  .meta__list > .button {
    width: calc(100% - 2rem);
    margin-left: 2rem; } }

.meta__group {
  margin-bottom: 1rem; }

.messages {
  list-style: none;
  padding: 0.6125rem 1rem;
  margin: 0;
  background: #7da64f;
  color: #fff;
  font-weight: bold;
  font-size: 0.875rem;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-100%);
  animation: show-hide 4s ease-in-out;
  line-height: 1.5; }
  .messages li {
    display: block; }
  .messages__archive-disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }

.messages__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between; }
  .messages__header .messages__archive_hint {
    display: flex; }
    .messages__header .messages__archive_hint .custom__checkbox {
      margin-left: 0.5rem;
      margin-right: 0;
      border-radius: 50%;
      border-color: #4A4A4A; }
      .messages__header .messages__archive_hint .custom__checkbox .icon {
        display: inline-block;
        fill: #4A4A4A; }
  @media all and (max-width: 500px) {
    .messages__header {
      display: block; } }

.messages__list {
  padding-left: 0 !important; }
  .messages__list li {
    display: flex;
    flex-flow: row nowrap;
    padding: 1rem 0 1rem 0.5rem;
    border-top: 2px solid #F5F5F5; }
    .messages__list li .controls__checkbox {
      width: auto;
      margin-top: 0rem; }
      .messages__list li .controls__checkbox .custom__checkbox {
        margin-right: 0;
        border-radius: 50%;
        border-color: #4A4A4A; }
        .messages__list li .controls__checkbox .custom__checkbox .icon {
          fill: #4A4A4A; }
        .messages__list li .controls__checkbox .custom__checkbox:hover {
          border-color: #a688b7; }
      .messages__list li .controls__checkbox input[type='checkbox']:focus + label .custom__checkbox {
        border-color: #a688b7; }
      .messages__list li .controls__checkbox input {
        position: absolute;
        left: -99999px; }
    .messages__list li.message-read .messages__icon {
      opacity: 0.2; }
    .messages__list li.message-read .messages__detail,
    .messages__list li.message-read .messages__meta {
      opacity: 0.4; }
    .messages__list li a {
      text-decoration: none; }
    @media all and (max-width: 768px) {
      .messages__list li {
        display: block;
        padding: 1rem 0; }
        .messages__list li > * {
          display: none; }
          .messages__list li > *:nth-child(2) {
            display: block; }
        .messages__list li .controls__checkbox {
          margin-top: 0.25rem; } }

.messages__icon {
  padding-right: 1rem; }
  .messages__icon .icon {
    width: 1.55rem;
    height: 1.55rem; }
  @media all and (max-width: 768px) {
    .messages__icon {
      display: inline-block;
      vertical-align: top; }
      .messages__icon .icon {
        width: 2rem;
        height: 2rem; } }

.messages__meta {
  display: inline-block;
  min-width: 13.5rem;
  width: auto;
  margin-bottom: 1rem; }
  .messages__meta a .message__name {
    display: block;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 0.75rem;
    line-height: 1.7; }
  .messages__meta .controls__checkbox {
    display: inline-block;
    margin-left: auto;
    padding-left: 0.5rem; }
  .messages__meta small {
    display: block;
    line-height: 1.7; }
  .messages__meta > .tag {
    display: none; }
  .messages__meta p {
    margin-top: 0.5rem; }
  .messages__meta .mobile-layout .tag {
    margin: 0.75rem 0.25rem 0.75rem 0; }
  .messages__meta .mobile-layout .mobile-meta-row {
    display: flex; }
    .messages__meta .mobile-layout .mobile-meta-row h4 {
      padding-top: 0.25rem; }
  @media all and (min-width: 768px) {
    .messages__meta {
      width: calc(25% - 3.8125rem);
      min-width: 12rem;
      padding-right: 2.5%; }
      .messages__meta a {
        margin-top: 0; }
        .messages__meta a .message__name {
          margin-bottom: 0.25rem;
          font-size: 1.4375rem;
          font-family: "Cabin", Arial, sans-serif;
          line-height: 1.2; }
      .messages__meta .mobile-layout {
        display: none; }
      .messages__meta .controls__checkbox {
        display: block;
        margin-left: 0rem; }
      .messages__meta > .tag {
        display: inline-block;
        width: auto;
        margin-top: 0.25rem; }
      .messages__meta small {
        display: block;
        margin-left: 0; }
      .messages__meta .mobile-detail {
        display: none; } }
  @media all and (min-width: 1300px) {
    .messages__meta {
      min-width: 15.5rem; } }

.messages__detail {
  flex: 1;
  padding-right: 5%;
  overflow: hidden; }
  .messages__detail h4 {
    margin-bottom: 0.5rem; }
  .messages__detail p {
    margin-top: 0.5rem; }
  @media all and (min-width: 768px) {
    .messages__detail {
      padding-right: 8%; } }

.message__detail {
  display: flex;
  flex-flow: row nowrap;
  padding: 1rem; }
  .message__detail a {
    text-decoration: none; }
  .message__detail .mobile-layout {
    display: none; }
  .message__detail .messages__meta_row {
    display: flex;
    padding: 0.75rem 0;
    border-bottom: 2px solid #F5F5F5; }
    .message__detail .messages__meta_row .icon {
      width: 1.55rem;
      height: 1.55rem;
      margin-right: 0.5rem;
      fill: #7d7d7d; }
    .message__detail .messages__meta_row .custom__checkbox {
      border-radius: 50%;
      border-color: #7d7d7d; }
      .message__detail .messages__meta_row .custom__checkbox .icon {
        display: inline-block;
        margin-right: 0;
        width: 1rem;
        height: 1rem; }
    .message__detail .messages__meta_row .messages__meta_text {
      flex: 1; }
    .message__detail .messages__meta_row:first-child {
      padding-top: 0; }
  @media all and (min-width: 500px) and (max-width: 768px) {
    .message__detail .desktop-layout {
      padding-right: 1.5rem; } }
  @media all and (max-width: 500px) {
    .message__detail {
      display: block; }
      .message__detail .mobile-layout {
        display: block; }
      .message__detail .desktop-layout {
        display: none; }
      .message__detail .messages__meta {
        width: 100%;
        margin-bottom: 1rem; }
      .message__detail .messages__meta_row {
        padding: 0.25rem 0;
        border-bottom: 0;
        display: flex;
        align-items: center; }
        .message__detail .messages__meta_row span {
          height: 1.75rem;
          margin-left: 1rem; }
      .message__detail .messages__detail {
        display: none; } }
  @media (min-width: 980px) {
    .message__detail {
      padding: 0; } }

.message__detail_buttons {
  display: flex;
  justify-content: flex-end;
  margin: 1rem; }
  .message__detail_buttons input {
    height: 100%;
    margin-right: 1rem; }
  .message__detail_buttons form {
    padding: 0 !important; }
  @media all and (max-width: 500px) {
    .message__detail_buttons {
      display: block; }
      .message__detail_buttons input {
        margin-bottom: 1rem; } }

@keyframes show-hide {
  0% {
    transform: translateY(-100%); }
  12.5% {
    transform: translateY(0%); }
  87.5% {
    transform: translateY(0%); }
  100% {
    transform: translateY(-100%); } }

.table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25;
  margin: 1.5rem 0; }
  .table td, .table th {
    padding: 0.25rem 0.5rem; }
    .table td:first-child, .table th:first-child {
      padding-left: 0; }
  .table th {
    white-space: nowrap;
    text-align: left;
    font-weight: inherit; }
    .table th .icon {
      color: #A5A5A5;
      vertical-align: middle;
      width: 1.125rem;
      height: 1.125rem; }
  .table td + td,
  .table th + th {
    border-left: 3px solid #F5F5F5; }
  .table tr + tr {
    border-top: 3px solid #F5F5F5; }

.notification__number {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  border-radius: 50%;
  background: #DE2F62;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  margin-left: 0.5rem; }

.pagination {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem; }
  .pagination a,
  .pagination span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    height: 2.5rem !important;
    width: 2.5rem !important; }
    .pagination a.prev,
    .pagination span.prev {
      margin-right: 0.25rem; }
    .pagination a.next,
    .pagination span.next {
      margin-left: 0.25rem; }
    .pagination a.next.disabled, .pagination a.prev.disabled,
    .pagination span.next.disabled,
    .pagination span.prev.disabled {
      opacity: 0.5;
      cursor: auto; }
    .pagination a + a,
    .pagination span + a {
      margin: 0 0.125rem; }
  .pagination a .icon {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
    margin-right: 0; }
  .church-page__wrapper .pagination a:not(.disabled) {
    color: #ffffff; }
  *[class^="acny-as-"] .pagination a:not(.disabled) {
    color: inherit; }
  .pagination .pages {
    display: flex;
    flex-flow: row nowrap; }
    .pagination .pages #pages__current {
      background: #E8A065;
      border: 3px solid #E8A065 !important;
      color: #F5F5F5; }
      .pagination .pages #pages__current + a {
        border-left: 3px solid inherit !important; }

.messages__pagination .pagination {
  justify-content: flex-end; }

.benefice-page__main .pagination {
  margin-top: 2rem; }
  .benefice-page__main .pagination a:not(.disabled) {
    color: #ffffff; }

.video__wrapper {
  position: relative;
  padding-bottom: 50.25%;
  /* 16:9 */
  padding-top: 30px;
  height: 0;
  overflow: hidden; }
  .video__wrapper iframe,
  .video__wrapper object,
  .video__wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.baguetteBox-open {
  overflow: hidden; }
  .baguetteBox-open #baguetteBox-overlay {
    background: rgba(255, 255, 255, 0.95) !important; }
    .baguetteBox-open #baguetteBox-overlay .full-image figcaption {
      font-size: 1.125rem !important;
      padding: 1rem;
      background: rgba(74, 74, 74, 0.95);
      color: #F5F5F5;
      font-size: "Open Sans", Arial, sans-serif;
      font-weight: 300;
      line-height: 1.5; }
    .baguetteBox-open #baguetteBox-overlay button {
      background: rgba(232, 160, 101, 0.6); }
      .baguetteBox-open #baguetteBox-overlay button:hover {
        background: rgba(232, 160, 101, 0.9); }
      .baguetteBox-open #baguetteBox-overlay button svg * {
        stroke: #FFFFFF !important; }

/*!
 * baguetteBox.js
 * @author  feimosi
 * @version %%INJECT_VERSION%%
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity .5s ease; }
  #baguetteBox-overlay.visible {
    opacity: 1; }
  #baguetteBox-overlay .full-image {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center; }
    #baguetteBox-overlay .full-image figure {
      display: inline;
      margin: 0;
      height: 100%; }
    #baguetteBox-overlay .full-image img {
      display: inline-block;
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 100%;
      vertical-align: middle;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); }
    #baguetteBox-overlay .full-image figcaption {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      line-height: 1.8;
      white-space: normal;
      color: #ccc;
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.6);
      font-family: sans-serif; }
    #baguetteBox-overlay .full-image:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px; }

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  transition: left .4s ease, transform .4s ease; }
  #baguetteBox-slider.bounce-from-right {
    animation: bounceFromRight .4s ease-out; }
  #baguetteBox-slider.bounce-from-left {
    animation: bounceFromLeft .4s ease-out; }

@keyframes bounceFromRight {
  0% {
    margin-left: 0; }
  50% {
    margin-left: -30px; }
  100% {
    margin-left: 0; } }

@keyframes bounceFromLeft {
  0% {
    margin-left: 0; }
  50% {
    margin-left: 30px; }
  100% {
    margin-left: 0; } }

.baguetteBox-button#next-button, .baguetteBox-button#previous-button {
  top: 50%;
  top: calc(50% - 30px);
  width: 44px;
  height: 60px; }

.baguetteBox-button {
  position: absolute;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 15%;
  background-color: #323232;
  background-color: rgba(50, 50, 50, 0.5);
  color: #ddd;
  font: 1.6em sans-serif;
  transition: background-color .4s ease; }
  .baguetteBox-button:focus, .baguetteBox-button:hover {
    background-color: rgba(50, 50, 50, 0.9); }
  .baguetteBox-button#next-button {
    right: 2%; }
  .baguetteBox-button#previous-button {
    left: 2%; }
  .baguetteBox-button#close-button {
    top: 20px;
    right: 2%;
    right: calc(2% + 6px);
    width: 30px;
    height: 30px; }
  .baguetteBox-button svg {
    position: absolute;
    left: 0;
    top: 0; }

/*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/
.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px; }

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2s infinite ease-in-out; }

.baguetteBox-double-bounce2 {
  animation-delay: -1s; }

@keyframes bounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

.ical-rss__downloads-holder {
  display: flex;
  justify-content: flex-end; }
  .calendar__header-option-area > .ical-rss__downloads-holder {
    position: absolute;
    top: calc(1rem * (2 / 3));
    right: 0; }
    @media (max-width: 272px) {
      .calendar__header-option-area > .ical-rss__downloads-holder.ical-rss__downloads-holder--admin {
        top: 4rem; } }
  .ical-rss__downloads-holder.news__downloads-holder {
    margin: 0 1rem 0 auto; }
    .ical-rss__downloads-holder.news__downloads-holder .ical-rss__downloads {
      margin-right: 0; }
    @media (min-width: 640px) {
      .ical-rss__downloads-holder.news__downloads-holder {
        position: absolute;
        top: 0.75rem;
        right: 2rem; } }
  .ical-rss__downloads-holder .ical-rss__downloads {
    position: relative;
    background-color: #fff; }
    @media all and (min-width: 768px) {
      .ical-rss__downloads-holder .ical-rss__downloads {
        margin-right: 1rem;
        font-size: 0.9375rem; } }
  .ical-rss__downloads-holder .ical-rss__download {
    display: block;
    padding: 0.5rem;
    line-height: 1.35;
    color: #4A4A4A;
    font-family: "Cabin", Arial, sans-serif;
    font-size: 1.25rem;
    text-decoration: none; }
    .ical-rss__downloads-holder .ical-rss__download:hover, .ical-rss__downloads-holder .ical-rss__download:focus {
      background-color: rgba(166, 136, 183, 0.0625); }
    .ical-rss__downloads-holder .ical-rss__download .icon {
      fill: #A5A5A5;
      margin-right: 0.5rem; }
  .ical-rss__downloads-holder .ical__download-notice {
    display: none;
    background-color: #a688b7;
    color: #fff;
    padding: 0.75rem 1rem;
    line-height: 1.35; }
    @media (min-width: 768px) {
      .ical-rss__downloads-holder .ical__download-notice {
        font-size: 0.875rem; } }
    .ical-rss__downloads-holder .ical__download-notice.show {
      display: block; }
  .ical-rss__downloads-holder .ical-rss__options {
    display: none;
    position: absolute;
    right: 0;
    z-index: 1;
    min-width: 20rem;
    width: 100%;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2); }
    @media (max-width: 767px) {
      .ical-rss__downloads-holder .ical-rss__options {
        margin-top: 0.25rem; } }
  .ical-rss__downloads-holder button {
    display: inline-block;
    width: 100%;
    padding: 0.75rem 1rem;
    border: none;
    border-radius: 0;
    appearance: none;
    background: none;
    cursor: pointer;
    text-transform: uppercase;
    text-align: left;
    color: #ffffff;
    background-color: #a688b7;
    font-family: "Open Sans", Arial, sans-serif; }
    .ical-rss__downloads-holder button[aria-expanded="true"] + .ical-rss__options {
      display: block; }
    @media all and (min-width: 768px) {
      .ical-rss__downloads-holder button {
        font-size: 1rem; } }
  @media all and (max-width: 1060px) {
    .ical-rss__downloads-holder:not(.news__downloads-holder) button {
      border: 3px solid #a688b7;
      padding: 0.25rem 0.5rem; }
      .ical-rss__downloads-holder:not(.news__downloads-holder) button span {
        position: absolute;
        left: -99999px; } }
  .ical-rss__downloads-holder .icon {
    position: relative;
    top: 3px;
    margin-right: 0.25rem; }

@media (min-width: 980px) and (max-width: 1180px) {
  #ical-rss-admin span {
    position: absolute !important;
    left: -99999px !important; } }

.benefice__header {
  background-color: #ffffff;
  padding: 2.5rem;
  margin-top: 1rem; }
  .benefice__header .headline, .benefice__header .main-content form fieldset legend, .main-content form fieldset .benefice__header legend,
  .benefice__header #lightbox form fieldset legend, #lightbox form fieldset .benefice__header legend {
    max-width: 50rem; }

.benefice__description {
  margin-bottom: 1rem; }

.benefice__image {
  border-top: 1.25rem #E4E4E4 solid; }

.benefice__container {
  background-color: #ffffff;
  margin-bottom: 6rem;
  padding: 2.5rem 1rem; }
  @media all and (min-width: 450px) {
    .benefice__container {
      padding: 2.5rem; } }

.benefice__container--form {
  padding: 2.5rem 2rem;
  border: 0; }

.benefice__edit-button {
  margin-top: 1rem; }

.benefice__content {
  padding: 2rem !important; }
  @media (min-width: 980px) {
    .benefice__content {
      padding: 1rem 2rem 2rem !important; } }

.benefice__map {
  padding: 0 !important; }

.tabs__holder--benefice {
  margin-bottom: 0; }

.headline--benefice-form {
  margin: 1rem 0 0 1rem; }
  .content__panel--benefice-form .headline--benefice-form {
    margin: 0 0 0 2rem;
    padding-top: 2rem; }

.admin-benefice__header {
  padding: 2rem 2rem 1rem;
  margin-bottom: 1rem;
  background-color: #ffffff; }
  .admin-benefice__header .headline--admin-benefice {
    font-size: 2.25rem;
    line-height: 2.7rem;
    margin-bottom: 1.5rem; }

.diocese-stats {
  display: flex;
  flex-wrap: wrap;
  margin: -0.75rem;
  padding: 3.75rem 0; }

.diocese-stats__block {
  flex-grow: 1;
  flex-basis: calc(calc(600px - 100%) * 999);
  margin: 0.75rem;
  padding: 1rem;
  padding-bottom: 1.5rem;
  border: 3px solid #E4E4E4; }
  @media (min-width: 768px) {
    .diocese-stats__block {
      padding: 2rem;
      padding-bottom: 2.5rem; } }

.diocese-stats__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

.diocese-stats__heading {
  font-size: 2.25rem; }
  .diocese-stats__heading::after {
    width: 100%; }

.diocese-stats__chart-wrapper svg {
  width: 70%;
  height: 70%;
  transform: rotate(-90deg);
  margin: 3rem 15%; }
  .diocese-stats__chart-wrapper svg .slice--fraction {
    fill: #382e75; }
  .diocese-stats__chart-wrapper svg .slice--remainder {
    fill: #8B63A1; }
  .diocese-stats__chart-wrapper svg .slice--text {
    transform: rotate(90deg);
    fill: #ffffff;
    font-family: "Cabin", Arial, sans-serif;
    font-weight: bold;
    text-align: center; }

.diocese-stats__legend {
  padding: 0; }

.diocese-stats__legend-item {
  list-style-type: none;
  display: flex;
  align-items: flex-start; }
  .diocese-stats__legend-item::before {
    flex: 0 0 0.75rem;
    content: '';
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 0.425rem;
    margin-top: 0.35em;
    margin-right: 1em; }

.diocese-stats__legend-item--total::before {
  background-color: #8B63A1; }

.diocese-stats__legend-item--fraction::before {
  background-color: #382e75; }

.diocese-stats__stats {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: -0.75rem;
  padding: 0; }

.diocese-stats__stat {
  flex-grow: 1;
  flex-basis: 10rem;
  list-style-type: none;
  font-size: 1.5rem;
  line-height: 1.2em;
  margin: 0.75rem; }
  .diocese-stats__stat span {
    display: block;
    font-size: 2.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem; }

.sortable-table__wrapper {
  margin: 0 -1rem;
  padding: 2rem 1rem 3rem;
  position: relative; }
  @media (max-width: 960px) {
    .sortable-table__wrapper {
      overflow-x: scroll; } }
  .sortable-table__wrapper::before, .sortable-table__wrapper::after {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 1rem;
    top: 0;
    left: 0; }

.sortable-table {
  border-spacing: 0;
  width: 100%;
  background: #F5F5F5;
  font-family: "Cabin", Arial, sans-serif;
  font-size: 0.875rem; }

.sortable-table--stat-list td,
.sortable-table--stat-list th {
  max-width: 5rem; }
  .sortable-table--stat-list td:nth-child(2n-2),
  .sortable-table--stat-list th:nth-child(2n-2) {
    border-right: 1px solid #ffffff; }

.sortable-table td,
.sortable-table th {
  padding: 0.25rem 0.5rem;
  min-width: 6rem; }
  .sortable-table td:first-child,
  .sortable-table th:first-child {
    min-width: 8.5rem;
    padding-left: 1.5rem;
    padding-right: 1rem; }
  .sortable-table td:last-child,
  .sortable-table th:last-child {
    padding-right: 1.5rem; }
  @media all and (min-width: 768px) {
    .sortable-table td,
    .sortable-table th {
      min-width: 6.5rem; }
      .sortable-table td:first-child,
      .sortable-table th:first-child {
        min-width: 7rem; } }

.sortable-table tr:nth-child(even) {
  background: #E4E4E4; }

.sortable-table a {
  color: #E8A065; }

.sortable-table__head {
  color: #ffffff; }
  .sortable-table__head td {
    background: #8B63A1;
    border-right: 1px solid #ffffff;
    font-size: 1rem;
    padding: 0.5rem; }
    .sortable-table__head td:first-child {
      background: #ffffff; }
  .sortable-table__head th {
    background: #382e75;
    text-transform: none;
    color: #ffffff;
    cursor: pointer;
    font-weight: normal;
    text-align: left;
    line-height: 1.2;
    white-space: nowrap;
    padding: 1.5rem 0.5rem;
    align-items: center; }
    .sortable-table__head th span {
      white-space: normal; }
    .sortable-table__head th .caret {
      margin-bottom: -0.15rem;
      margin-left: 0.125rem;
      width: 0.75rem;
      height: 0.75rem;
      fill: #E8A065;
      transition: transform 0.2s ease; }
    .sortable-table__head th[aria-sort='descending'], .sortable-table__head th[aria-sort='ascending'] {
      background-color: #8B63A1; }
      .sortable-table__head th[aria-sort='descending'] .caret, .sortable-table__head th[aria-sort='ascending'] .caret {
        fill: #ffffff; }
    .sortable-table__head th[aria-sort='ascending'] .caret {
      transform: rotate(-180deg); }

.sortable-table__tooltip {
  position: relative; }
  .sortable-table__tooltip svg {
    width: 0.75rem;
    height: 1rem; }

.sortable-table__tooltip-text {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: 150%;
  left: 0.35rem;
  width: 8rem;
  transition: opacity 0.2s ease;
  transform: translateX(-50%);
  padding: 0.5rem;
  background-color: #E8A065; }
  .sortable-table__tooltip-text:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.5rem 0.5rem 0 0.5rem;
    border-color: #E8A065 transparent transparent transparent;
    transform: translateX(-50%); }

.sortable-table__tooltip:hover .sortable-table__tooltip-text {
  opacity: 1; }

.acny-as-homepage__mobile-share {
  padding: 1rem 2rem;
  background: #FFFFFF; }
  .acny-as-homepage__mobile-share .navlist__item {
    align-items: flex-start;
    display: flex;
    flex-flow: row nowrap;
    padding: .75rem .5rem .75rem 0;
    position: relative; }
    .acny-as-homepage__mobile-share .navlist__item.share {
      display: flex;
      align-items: center;
      justify-content: center; }
      .acny-as-homepage__mobile-share .navlist__item.share .icon {
        height: 1.5rem;
        margin-top: .3rem;
        width: 2rem;
        flex-shrink: 0;
        color: #A5A5A5;
        margin-right: 1.3rem; }
      .acny-as-homepage__mobile-share .navlist__item.share .navlist__item-content {
        width: 100%; }
        .acny-as-homepage__mobile-share .navlist__item.share .navlist__item-content .navlist__item-title {
          font-size: 1.125rem; }
      .acny-as-homepage__mobile-share .navlist__item.share span .navlist__item-content {
        width: auto; }
      .acny-as-homepage__mobile-share .navlist__item.share ul {
        align-items: center;
        bottom: 0;
        display: flex;
        flex-flow: row nowrap;
        list-style: none;
        margin: -.75rem 0 -.75rem auto;
        right: 0;
        top: 0; }
      .acny-as-homepage__mobile-share .navlist__item.share .navlist__item-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        text-decoration: none; }

.room-booking-iframe-wrapper {
  padding: 1rem;
  background-color: #ffffff; }
  .room-booking-iframe-wrapper iframe {
    min-height: 40rem;
    width: 100%; }
  @media (min-width: 980px) {
    .room-booking-iframe-wrapper {
      padding: 1rem 2rem; }
      .room-booking-iframe-wrapper iframe {
        min-height: 50rem; } }

.main-content .form__holder {
  padding-top: 0;
  margin-top: 0; }
  @media (min-width: 980px) {
    .main-content .form__holder {
      position: relative;
      padding-top: 2.5rem;
      margin-top: 1.5rem; }
      .main-content .form__holder:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 1rem;
        width: 100%;
        background: #F1F1F1;
        width: calc(100% + 4rem); } }
  .main-content .form__holder + .form__holder {
    margin-top: 2rem; }
  .main-content .form__holder .headline, .main-content .form__holder form fieldset legend, .main-content form fieldset .form__holder legend,
  .main-content .form__holder #lightbox form fieldset legend, #lightbox form fieldset .main-content .form__holder legend {
    font-size: 1.3125rem;
    color: #8B63A1;
    padding-top: 2.5rem;
    margin-top: 1.5rem; }
    .main-content .form__holder .headline:before, .main-content .form__holder form fieldset legend:before, .main-content form fieldset .form__holder legend:before,
    .main-content .form__holder #lightbox form fieldset legend:before, #lightbox form fieldset .main-content .form__holder legend:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 1rem;
      width: calc(100% + 2rem);
      background: #E4E4E4; }
      @media (min-width: 980px) {
        .main-content .form__holder .headline:before, .main-content .form__holder form fieldset legend:before, .main-content form fieldset .form__holder legend:before,
        .main-content .form__holder #lightbox form fieldset legend:before, #lightbox form fieldset .main-content .form__holder legend:before {
          background: none;
          width: calc(100% + 4rem); } }
    .main-content .form__holder .headline:after, .main-content .form__holder form fieldset legend:after, .main-content form fieldset .form__holder legend:after,
    .main-content .form__holder #lightbox form fieldset legend:after, #lightbox form fieldset .main-content .form__holder legend:after {
      display: none; }
    .main-content .form__holder .headline .headline__meta, .main-content .form__holder form fieldset legend .headline__meta, .main-content form fieldset .form__holder legend .headline__meta, .main-content .form__holder #lightbox form fieldset legend .headline__meta, #lightbox form fieldset .main-content .form__holder legend .headline__meta {
      color: #A5A5A5; }
    @media (min-width: 980px) {
      .main-content .form__holder .headline, .main-content .form__holder form fieldset legend, .main-content form fieldset .form__holder legend,
      .main-content .form__holder #lightbox form fieldset legend, #lightbox form fieldset .main-content .form__holder legend {
        font-size: 2.25rem;
        color: #4A4A4A;
        padding-top: 0;
        margin-top: 0; }
        .main-content .form__holder .headline:after, .main-content .form__holder form fieldset legend:after, .main-content form fieldset .form__holder legend:after,
        .main-content .form__holder #lightbox form fieldset legend:after, #lightbox form fieldset .main-content .form__holder legend:after {
          display: inline-block; } }
  body.admin-view .main-content .form__holder:before {
    background: #F1F1F1; }

.main-content form {
  padding: 1rem 1rem 10rem; }
  @media (min-width: 980px) {
    .main-content form {
      padding-bottom: 0; } }

#deleteform {
  text-align: center; }
  @media (min-width: 980px) {
    #deleteform {
      text-align: left; } }

.form__holder.whats-on .custom__checkbox,
.form__holder.news-panel .custom__checkbox {
  border-radius: 50%; }
  .form__holder.whats-on .custom__checkbox .icon,
  .form__holder.news-panel .custom__checkbox .icon {
    display: none !important; }

.form__holder.whats-on input[type='checkbox']:checked + label .custom__checkbox:after,
.form__holder.news-panel input[type='checkbox']:checked + label .custom__checkbox:after {
  content: '';
  border-radius: 50%;
  width: 0.6rem;
  height: 0.6rem;
  background: #a688b7; }

.form__holder.whats-on .custom__select-toggle-text,
.form__holder.news-panel .custom__select-toggle-text {
  margin-left: 2rem; }

.form__holder.whats-on .custom__select-dropdown,
.form__holder.news-panel .custom__select-dropdown {
  margin-left: 2rem;
  min-width: calc(100% - 2rem); }

.form__narrow {
  max-width: 22.5rem; }

.form__label-text {
  margin-bottom: 1.25rem; }

#hint_id_description.help-block {
  color: #8B63A1;
  margin-top: 0rem; }
  @media (min-width: 980px) {
    #hint_id_description.help-block {
      color: #000000;
      margin-top: 1rem; } }

.main-content form fieldset,
#lightbox form fieldset {
  padding: 0;
  margin: 0;
  border: none; }
  .main-content form fieldset legend,
  #lightbox form fieldset legend {
    font-size: 2.25rem;
    line-height: 1.5;
    margin-bottom: 0.66em;
    width: 100%; }
    .main-content form fieldset legend + p,
    #lightbox form fieldset legend + p {
      margin-top: 0; }
  .main-content form fieldset + fieldset,
  #lightbox form fieldset + fieldset {
    position: relative;
    padding-top: 2.5rem;
    margin-top: 1.5rem; }
    .main-content form fieldset + fieldset:before,
    #lightbox form fieldset + fieldset:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 1rem;
      width: 100%;
      background: #E4E4E4; }
      @media (min-width: 980px) {
        .main-content form fieldset + fieldset:before,
        #lightbox form fieldset + fieldset:before {
          width: calc(100% + 4rem); } }

.main-content form .wysiwyg__fieldset .control-group > label,
.main-content form .image__fieldset .control-group > label,
.main-content form .tags__fieldset .control-group > label,
#lightbox form .wysiwyg__fieldset .control-group > label,
#lightbox form .image__fieldset .control-group > label,
#lightbox form .tags__fieldset .control-group > label {
  font-size: 1.3125rem;
  position: relative;
  padding-bottom: 0.25em;
  line-height: 1.5;
  margin-bottom: 0.45em;
  font-family: "Cabin", Arial, sans-serif;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #8B63A1; }
  @media (min-width: 980px) {
    .main-content form .wysiwyg__fieldset .control-group > label,
    .main-content form .image__fieldset .control-group > label,
    .main-content form .tags__fieldset .control-group > label,
    #lightbox form .wysiwyg__fieldset .control-group > label,
    #lightbox form .image__fieldset .control-group > label,
    #lightbox form .tags__fieldset .control-group > label {
      color: #4A4A4A;
      font-size: 2.25rem; } }
  .main-content form .wysiwyg__fieldset .control-group > label:after,
  .main-content form .image__fieldset .control-group > label:after,
  .main-content form .tags__fieldset .control-group > label:after,
  #lightbox form .wysiwyg__fieldset .control-group > label:after,
  #lightbox form .image__fieldset .control-group > label:after,
  #lightbox form .tags__fieldset .control-group > label:after {
    display: none; }
    @media (min-width: 980px) {
      .main-content form .wysiwyg__fieldset .control-group > label:after,
      .main-content form .image__fieldset .control-group > label:after,
      .main-content form .tags__fieldset .control-group > label:after,
      #lightbox form .wysiwyg__fieldset .control-group > label:after,
      #lightbox form .image__fieldset .control-group > label:after,
      #lightbox form .tags__fieldset .control-group > label:after {
        content: '';
        display: inline-block;
        width: 6.5rem;
        height: 3px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #a688b7; } }

.main-content form .wysiwyg__fieldset .asteriskField,
.main-content form .image__fieldset .asteriskField,
.main-content form .tags__fieldset .asteriskField,
#lightbox form .wysiwyg__fieldset .asteriskField,
#lightbox form .image__fieldset .asteriskField,
#lightbox form .tags__fieldset .asteriskField {
  display: none; }

.main-content form .wysiwyg__fieldset .control-group,
.main-content form .image__fieldset .control-group,
.main-content form .tags__fieldset .control-group,
#lightbox form .wysiwyg__fieldset .control-group,
#lightbox form .image__fieldset .control-group,
#lightbox form .tags__fieldset .control-group {
  position: relative;
  padding-top: 2.5rem;
  margin-top: 1.5rem; }
  .main-content form .wysiwyg__fieldset .control-group:before,
  .main-content form .image__fieldset .control-group:before,
  .main-content form .tags__fieldset .control-group:before,
  #lightbox form .wysiwyg__fieldset .control-group:before,
  #lightbox form .image__fieldset .control-group:before,
  #lightbox form .tags__fieldset .control-group:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1rem;
    width: calc(100% + 2rem);
    background: #E4E4E4; }
    body.admin-view .main-content form .wysiwyg__fieldset .control-group:before, body.admin-view
    .main-content form .image__fieldset .control-group:before, body.admin-view
    .main-content form .tags__fieldset .control-group:before, body.admin-view
    #lightbox form .wysiwyg__fieldset .control-group:before, body.admin-view
    #lightbox form .image__fieldset .control-group:before, body.admin-view
    #lightbox form .tags__fieldset .control-group:before {
      background: #F1F1F1; }
    @media (min-width: 980px) {
      .main-content form .wysiwyg__fieldset .control-group:before,
      .main-content form .image__fieldset .control-group:before,
      .main-content form .tags__fieldset .control-group:before,
      #lightbox form .wysiwyg__fieldset .control-group:before,
      #lightbox form .image__fieldset .control-group:before,
      #lightbox form .tags__fieldset .control-group:before {
        width: calc(100% + 4rem); } }

.main-content form .form__tabs,
#lightbox form .form__tabs {
  margin-top: 1.5rem; }

.main-content form .form__conditional-fields,
.main-content form .form__section,
#lightbox form .form__conditional-fields,
#lightbox form .form__section {
  margin-top: 1.5rem; }

.main-content form .wysiwyg__fieldset .help-block,
#lightbox form .wysiwyg__fieldset .help-block {
  background: none;
  color: #8B63A1; }
  @media (min-width: 980px) {
    .main-content form .wysiwyg__fieldset .help-block,
    #lightbox form .wysiwyg__fieldset .help-block {
      color: #4A4A4A;
      padding-top: 0.5rem; } }

.main-content form input:not([type='checkbox']):not([type='radio']):not([type='submit']),
.main-content form textarea,
.main-content form select,
.main-content form .medium-wysiwyg,
#lightbox form input:not([type='checkbox']):not([type='radio']):not([type='submit']),
#lightbox form textarea,
#lightbox form select,
#lightbox form .medium-wysiwyg {
  appearance: none;
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 0;
  background: #fff;
  vertical-align: top;
  font-size: 16px;
  font-family: inherit;
  -webkit-font-smoothing: antialiased;
  border: 3px solid #E4E4E4;
  color: inherit;
  line-height: inherit;
  resize: vertical; }
  .main-content form input:not([type='checkbox']):not([type='radio']):not([type='submit']):focus,
  .main-content form textarea:focus,
  .main-content form select:focus,
  .main-content form .medium-wysiwyg:focus,
  #lightbox form input:not([type='checkbox']):not([type='radio']):not([type='submit']):focus,
  #lightbox form textarea:focus,
  #lightbox form select:focus,
  #lightbox form .medium-wysiwyg:focus {
    outline: none;
    border-color: #bebebe; }

.main-content form .medium-wysiwyg,
#lightbox form .medium-wysiwyg {
  border-width: 0 0 2px 0;
  padding: 0.75rem 0; }

.main-content form .select__holder,
#lightbox form .select__holder {
  display: flex;
  flex-flow: row nowrap;
  position: relative; }
  .main-content form .select__holder select,
  #lightbox form .select__holder select {
    padding-right: 3rem; }
  .main-content form .select__holder .icon,
  #lightbox form .select__holder .icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 3rem;
    padding: 0.75rem;
    border: 3px solid #E8A065;
    pointer-events: none; }

.main-content form input[type='checkbox'],
#lightbox form input[type='checkbox'] {
  opacity: 0;
  margin-top: 2rem; }

.main-content form label[for='id_terms'],
#lightbox form label[for='id_terms'] {
  margin-top: -3rem; }

.main-content form .checkbox input,
.main-content form .radio input,
#lightbox form .checkbox input,
#lightbox form .radio input {
  margin-right: 0.375rem; }

.main-content form .checkbox label,
.main-content form .radio label,
#lightbox form .checkbox label,
#lightbox form .radio label {
  text-transform: none; }

.main-content form label,
#lightbox form label {
  display: block;
  width: 100%;
  margin-bottom: 0.75rem;
  user-select: none;
  text-transform: initial; }
  @media (min-width: 980px) {
    .main-content form label,
    #lightbox form label {
      font-size: 1.3125rem;
      color: #4A4A4A;
      text-transform: uppercase; } }

.main-content form .control-group,
#lightbox form .control-group {
  position: relative; }

.main-content form small,
#lightbox form small {
  color: #8B63A1; }

.main-content form small + *,
#lightbox form small + * {
  margin-top: 0.5rem; }

.main-content form label[for=id_link].control-label,
.main-content form label[for=id_frequency].control-label,
#lightbox form label[for=id_link].control-label,
#lightbox form label[for=id_frequency].control-label {
  text-transform: uppercase; }

.main-content form label[for=id_address].control-label,
#lightbox form label[for=id_address].control-label {
  display: flex;
  justify-content: space-between; }

.main-content form .form__header,
#lightbox form .form__header {
  color: #8B63A1;
  font-family: "Cabin", Arial, sans-serif;
  font-size: 1.3125rem; }
  .main-content form .form__header ~ .asteriskField,
  #lightbox form .form__header ~ .asteriskField {
    color: #8B63A1; }
  @media (min-width: 980px) {
    .main-content form .form__header,
    #lightbox form .form__header {
      color: #4A4A4A;
      font-family: "Open Sans", Arial, sans-serif;
      text-transform: unset; }
      .main-content form .form__header ~ .asteriskField,
      #lightbox form .form__header ~ .asteriskField {
        color: #4A4A4A; } }

.main-content form .control-group + .control-group,
.main-content form .form-group + .form-group,
.main-content form .control-group + .form-halves,
#lightbox form .control-group + .control-group,
#lightbox form .form-group + .form-group,
#lightbox form .control-group + .form-halves {
  margin-top: 1rem; }

.main-content form .control-label .label__meta,
#lightbox form .control-label .label__meta {
  font-family: Cabin;
  color: #A5A5A5; }

.main-content form .hint-icon,
#lightbox form .hint-icon {
  display: inline-block;
  cursor: pointer;
  color: #A5A5A5;
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem; }
  @media (min-width: 980px) {
    .main-content form .hint-icon,
    #lightbox form .hint-icon {
      display: none; } }

.main-content form .hint-close,
#lightbox form .hint-close {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 1.5rem;
  height: 1.5rem; }
  @media (min-width: 980px) {
    .main-content form .hint-close,
    #lightbox form .hint-close {
      display: none; } }

.main-content form .field-info,
#lightbox form .field-info {
  position: absolute;
  right: 0; }

.main-content form .help-inline,
.main-content form .help-block,
#lightbox form .help-inline,
#lightbox form .help-block {
  vertical-align: top;
  color: #8B63A1;
  font-family: "Open Sans", Arial, sans-serif;
  margin-top: 0.25rem; }
  @media (min-width: 980px) {
    .main-content form .help-inline,
    .main-content form .help-block,
    #lightbox form .help-inline,
    #lightbox form .help-block {
      display: block;
      vertical-align: top;
      color: #4A4A4A;
      font-size: 0.875rem;
      margin-top: 0.25rem; } }
  .main-content form .help-inline.modal-display,
  .main-content form .help-block.modal-display,
  #lightbox form .help-inline.modal-display,
  #lightbox form .help-block.modal-display {
    display: block !important;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 200;
    top: 0;
    left: 0;
    margin: 0;
    padding: 4rem 1rem;
    background-color: #a688b7;
    color: #ffffff;
    font-size: 1rem; }

@media (min-width: 980px) {
  .main-content form .hint-fieldset > .control-group > .controls, .main-content form .hint-fieldset.custom,
  #lightbox form .hint-fieldset > .control-group > .controls,
  #lightbox form .hint-fieldset.custom {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start; } }

.main-content form .hint-fieldset > .control-group > .controls > input,
.main-content form .hint-fieldset > .control-group > .controls > div, .main-content form .hint-fieldset.custom > input,
.main-content form .hint-fieldset.custom > div,
#lightbox form .hint-fieldset > .control-group > .controls > input,
#lightbox form .hint-fieldset > .control-group > .controls > div,
#lightbox form .hint-fieldset.custom > input,
#lightbox form .hint-fieldset.custom > div {
  flex: 1; }

.main-content form .hint-fieldset > .control-group > .controls .help-block,
.main-content form .hint-fieldset > .control-group > .controls .help-inline, .main-content form .hint-fieldset.custom .help-block,
.main-content form .hint-fieldset.custom .help-inline,
#lightbox form .hint-fieldset > .control-group > .controls .help-block,
#lightbox form .hint-fieldset > .control-group > .controls .help-inline,
#lightbox form .hint-fieldset.custom .help-block,
#lightbox form .hint-fieldset.custom .help-inline {
  display: none; }
  @media (min-width: 980px) {
    .main-content form .hint-fieldset > .control-group > .controls .help-block,
    .main-content form .hint-fieldset > .control-group > .controls .help-inline, .main-content form .hint-fieldset.custom .help-block,
    .main-content form .hint-fieldset.custom .help-inline,
    #lightbox form .hint-fieldset > .control-group > .controls .help-block,
    #lightbox form .hint-fieldset > .control-group > .controls .help-inline,
    #lightbox form .hint-fieldset.custom .help-block,
    #lightbox form .hint-fieldset.custom .help-inline {
      display: block;
      width: 100%;
      flex-shrink: 0;
      font-size: 1rem;
      font-family: "Open Sans", Arial, sans-serif;
      margin-top: 0.75rem;
      padding-top: 0.5rem;
      color: #8B63A1; } }

.main-content form .hint-fieldset > .control-group > .controls.caption .control-label, .main-content form .hint-fieldset.custom.caption .control-label,
#lightbox form .hint-fieldset > .control-group > .controls.caption .control-label,
#lightbox form .hint-fieldset.custom.caption .control-label {
  display: none; }

.main-content form .hint-fieldset > .control-group > .controls.caption textarea, .main-content form .hint-fieldset.custom.caption textarea,
#lightbox form .hint-fieldset > .control-group > .controls.caption textarea,
#lightbox form .hint-fieldset.custom.caption textarea {
  max-height: 14rem; }
  @media all and (min-width: 400px) {
    .main-content form .hint-fieldset > .control-group > .controls.caption textarea, .main-content form .hint-fieldset.custom.caption textarea,
    #lightbox form .hint-fieldset > .control-group > .controls.caption textarea,
    #lightbox form .hint-fieldset.custom.caption textarea {
      max-height: 10rem; } }
  @media all and (min-width: 1200px) {
    .main-content form .hint-fieldset > .control-group > .controls.caption textarea, .main-content form .hint-fieldset.custom.caption textarea,
    #lightbox form .hint-fieldset > .control-group > .controls.caption textarea,
    #lightbox form .hint-fieldset.custom.caption textarea {
      max-height: 8rem; } }

@media (min-width: 980px) {
  .main-content form .hint-fieldset > .control-group > .controls, .main-content form .hint-fieldset.custom,
  #lightbox form .hint-fieldset > .control-group > .controls,
  #lightbox form .hint-fieldset.custom {
    flex-flow: row wrap;
    margin-bottom: 2rem; }
    .main-content form .hint-fieldset > .control-group > .controls .custom, .main-content form .hint-fieldset.custom .custom,
    #lightbox form .hint-fieldset > .control-group > .controls .custom,
    #lightbox form .hint-fieldset.custom .custom {
      margin-bottom: 0.5rem; }
    .main-content form .hint-fieldset > .control-group > .controls > input,
    .main-content form .hint-fieldset > .control-group > .controls > div, .main-content form .hint-fieldset.custom > input,
    .main-content form .hint-fieldset.custom > div,
    #lightbox form .hint-fieldset > .control-group > .controls > input,
    #lightbox form .hint-fieldset > .control-group > .controls > div,
    #lightbox form .hint-fieldset.custom > input,
    #lightbox form .hint-fieldset.custom > div {
      margin-right: 3rem; }
      .main-content form .hint-fieldset > .control-group > .controls > input .custom__field, .main-content form .hint-fieldset > .control-group > .controls > div .custom__field, .main-content form .hint-fieldset.custom > input .custom__field, .main-content form .hint-fieldset.custom > div .custom__field,
      #lightbox form .hint-fieldset > .control-group > .controls > input .custom__field,
      #lightbox form .hint-fieldset > .control-group > .controls > div .custom__field,
      #lightbox form .hint-fieldset.custom > input .custom__field,
      #lightbox form .hint-fieldset.custom > div .custom__field {
        margin-right: 0rem; }
      .main-content form .hint-fieldset > .control-group > .controls > input.control-group, .main-content form .hint-fieldset > .control-group > .controls > div.control-group, .main-content form .hint-fieldset.custom > input.control-group, .main-content form .hint-fieldset.custom > div.control-group,
      #lightbox form .hint-fieldset > .control-group > .controls > input.control-group,
      #lightbox form .hint-fieldset > .control-group > .controls > div.control-group,
      #lightbox form .hint-fieldset.custom > input.control-group,
      #lightbox form .hint-fieldset.custom > div.control-group {
        margin-right: 0.5rem; }
    .main-content form .hint-fieldset > .control-group > .controls .help-block,
    .main-content form .hint-fieldset > .control-group > .controls .help-inline, .main-content form .hint-fieldset.custom .help-block,
    .main-content form .hint-fieldset.custom .help-inline,
    #lightbox form .hint-fieldset > .control-group > .controls .help-block,
    #lightbox form .hint-fieldset > .control-group > .controls .help-inline,
    #lightbox form .hint-fieldset.custom .help-block,
    #lightbox form .hint-fieldset.custom .help-inline {
      margin: -0.25rem 0 0 auto !important;
      padding-top: 0;
      width: 30%; }
      .main-content form .hint-fieldset > .control-group > .controls .help-block #error_2_id_publish_at,
      .main-content form .hint-fieldset > .control-group > .controls .help-inline #error_2_id_publish_at, .main-content form .hint-fieldset.custom .help-block #error_2_id_publish_at,
      .main-content form .hint-fieldset.custom .help-inline #error_2_id_publish_at,
      #lightbox form .hint-fieldset > .control-group > .controls .help-block #error_2_id_publish_at,
      #lightbox form .hint-fieldset > .control-group > .controls .help-inline #error_2_id_publish_at,
      #lightbox form .hint-fieldset.custom .help-block #error_2_id_publish_at,
      #lightbox form .hint-fieldset.custom .help-inline #error_2_id_publish_at {
        margin: 0 0 0 2rem; } }

.main-content form .hint-fieldset.donations,
#lightbox form .hint-fieldset.donations {
  margin-bottom: 0.3rem; }

.main-content form .error input:not([type='checkbox']):not([type='radio']):not([type='submit']),
.main-content form .error textarea,
.main-content form .error select,
#lightbox form .error input:not([type='checkbox']):not([type='radio']):not([type='submit']),
#lightbox form .error textarea,
#lightbox form .error select {
  border-color: #DE2F62;
  text-overflow: ellipsis; }

.main-content form .error .mediumeditorwidget,
#lightbox form .error .mediumeditorwidget {
  border-color: #DE2F62; }

.main-content form .error .help-inline,
#lightbox form .error .help-inline {
  background: #DE2F62;
  color: #fff;
  padding: 0.5rem;
  margin-top: 0; }

.main-content form .form-halves + .form-halves,
.main-content form .form-halves + .control-group,
.main-content form .form-halves + .form-group,
#lightbox form .form-halves + .form-halves,
#lightbox form .form-halves + .control-group,
#lightbox form .form-halves + .form-group {
  margin-top: 1rem; }

@media all and (min-width: 768px) {
  .main-content form .form-halves,
  #lightbox form .form-halves {
    display: flex;
    flex-flow: row nowrap; }
    .main-content form .form-halves > div,
    #lightbox form .form-halves > div {
      width: calc(50% - (1rem / 2));
      flex-shrink: 0; }
    .main-content form .form-halves > div + div,
    #lightbox form .form-halves > div + div {
      margin-left: 1rem; }
    .main-content form .form-halves .control-group + .control-group,
    .main-content form .form-halves .form-group + .form-group,
    #lightbox form .form-halves .control-group + .control-group,
    #lightbox form .form-halves .form-group + .form-group {
      margin-top: 0; } }

.main-content form .form-thirds + .form-thirds,
.main-content form .form-thirds + .control-group,
.main-content form .form-thirds + .form-group,
#lightbox form .form-thirds + .form-thirds,
#lightbox form .form-thirds + .control-group,
#lightbox form .form-thirds + .form-group {
  margin-top: 1rem; }

@media all and (min-width: 768px) {
  .main-content form .form-thirds,
  #lightbox form .form-thirds {
    display: flex;
    flex-flow: row nowrap; }
    .main-content form .form-thirds > div,
    #lightbox form .form-thirds > div {
      width: calc(33% - (1rem / 3));
      flex-shrink: 0; }
    .main-content form .form-thirds > div + div,
    #lightbox form .form-thirds > div + div {
      margin-left: 1rem; }
    .main-content form .form-thirds .control-group + .control-group,
    .main-content form .form-thirds .form-group + .form-group,
    #lightbox form .form-thirds .control-group + .control-group,
    #lightbox form .form-thirds .form-group + .form-group {
      margin-top: 0; } }

#day-week-month-selector {
  margin-bottom: 1rem; }

#day-week-month-selector input[type='checkbox'] {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.errorlist,
.alert-error ul {
  list-style: none;
  margin: 0 0 1rem 0;
  padding: 0.125rem 0.5rem;
  background: #DE2F62;
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase; }

#lightbox form .button__holder:before {
  width: calc(100% + 2rem); }
  @media (min-width: 980px) {
    #lightbox form .button__holder:before {
      width: calc(100% + 4rem); } }

.account-page__wrapper .container .main-content form .button__holder:before {
  width: calc(100% + 2rem);
  left: 50%; }
  @media (min-width: 980px) {
    .account-page__wrapper .container .main-content form .button__holder:before {
      width: calc(100% + 4rem); } }

body .mediumeditorwidget {
  min-height: 10em;
  outline: none; }

input[type=text] {
  appearance: none;
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 0;
  background: #fff;
  vertical-align: top;
  font-size: 16px;
  font-family: inherit;
  -webkit-font-smoothing: antialiased;
  border: 3px solid #E4E4E4;
  color: inherit;
  line-height: inherit;
  resize: vertical; }
  input[type=text]:focus {
    outline: none;
    border-color: #bebebe; }

#ccc-icon.shift-up {
  bottom: 40px; }
  @media (min-width: 980px) {
    #ccc-icon.shift-up {
      bottom: 0; } }

#getintouchform .form-wrapper {
  padding-top: 10px; }

#getintouchform .iframe-wrapper {
  display: block;
  margin-top: 20px;
  padding: 0px;
  overflow: hidden; }

#getintouchform iframe {
  overflow: hidden;
  height: 100vh;
  width: 100%; }

#launcher {
  bottom: 3rem !important;
  left: 0 !important; }
  @media (min-width: 980px) {
    #launcher {
      bottom: 100px !important;
      left: unset !important; } }

.benefice-page__main form .control-label,
.benefice-page__main form #id_page_title_label,
.benefice-page__main form #id_page_title_label ~ .asteriskField,
.benefice-page__main form#beneficeeventform h3.headline {
  color: #4A4A4A !important;
  text-transform: none; }

.benefice-page__main form .help-block {
  color: #a688b7 !important; }

.benefice-page__main form .field--benefice.image__fieldset .control-group:before {
  content: none; }

.benefice-page__main form .field--benefice.image__fieldset .control-group > .control-label {
  margin-bottom: 0;
  padding-bottom: 0; }
  .benefice-page__main form .field--benefice.image__fieldset .control-group > .control-label:after {
    content: none; }

.benefice-page__main form .field--benefice.image__fieldset .control-group .custom__image-field {
  margin: 1.25rem 0 0; }

.benefice-page__main form .field--benefice + .field--benefice {
  margin-top: 2rem; }

.benefice-page__main form #div_id_publish_to_venues,
.benefice-page__main form #div_id_venues_location {
  flex: 1 0 100%;
  margin-bottom: 1rem; }
  @media (min-width: 980px) {
    .benefice-page__main form #div_id_publish_to_venues,
    .benefice-page__main form #div_id_venues_location {
      flex: 1 0 50%; }
      .benefice-page__main form #div_id_publish_to_venues ~ .help-block,
      .benefice-page__main form #div_id_venues_location ~ .help-block {
        width: 30%;
        font-size: 1rem; } }
  .benefice-page__main form #div_id_publish_to_venues .controls,
  .benefice-page__main form #div_id_venues_location .controls {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem; }
    @media (min-width: 980px) {
      .benefice-page__main form #div_id_publish_to_venues .controls,
      .benefice-page__main form #div_id_venues_location .controls {
        padding: 0; } }
    .benefice-page__main form #div_id_publish_to_venues .controls > .controls__checkbox,
    .benefice-page__main form #div_id_venues_location .controls > .controls__checkbox {
      flex: 1 1 48%; }
      .benefice-page__main form #div_id_publish_to_venues .controls > .controls__checkbox input[type='checkbox'], .benefice-page__main form #div_id_publish_to_venues .controls > .controls__checkbox .checkbox,
      .benefice-page__main form #div_id_venues_location .controls > .controls__checkbox input[type='checkbox'],
      .benefice-page__main form #div_id_venues_location .controls > .controls__checkbox .checkbox {
        margin: 0; }
      .benefice-page__main form #div_id_publish_to_venues .controls > .controls__checkbox .checkbox,
      .benefice-page__main form #div_id_venues_location .controls > .controls__checkbox .checkbox {
        padding: 0;
        text-transform: capitalize; }

.benefice-page__main form#beneficeaboutusform .control-label:not(#id_file_attachment), .benefice-page__main form#beneficeaboutusform .field--benefice .control-label, .benefice-page__main form#beneficeinfoform .control-label:not(#id_file_attachment), .benefice-page__main form#beneficeinfoform .field--benefice .control-label, .benefice-page__main form#beneficeeventform .control-label:not(#id_file_attachment), .benefice-page__main form#beneficeeventform .field--benefice .control-label, .benefice-page__main form#beneficeeventupdateform .control-label:not(#id_file_attachment), .benefice-page__main form#beneficeeventupdateform .field--benefice .control-label {
  font-family: "Open Sans", Arial, sans-serif !important;
  font-size: 1.3125rem !important;
  line-height: 24px !important; }

.benefice-page__main form#beneficeeventform, .benefice-page__main form#beneficeeventupdateform {
  padding: 0; }
  .benefice-page__main form#beneficeeventform > *, .benefice-page__main form#beneficeeventupdateform > * {
    padding: 1.5rem 1rem;
    border-bottom: 1rem solid #F1F1F1; }
    @media (min-width: 980px) {
      .benefice-page__main form#beneficeeventform > *, .benefice-page__main form#beneficeeventupdateform > * {
        padding: 1.5rem 2rem; } }
  @media (max-width: 979px) {
    
    .benefice-page__main form#beneficeeventform h3.headline, .benefice-page__main form#beneficeeventupdateform h3.headline {
      font-family: "Open Sans", Arial, sans-serif !important;
      font-size: 1.3125rem !important;
      line-height: 24px !important; }
      .benefice-page__main form#beneficeeventform h3.headline:after, .benefice-page__main form#beneficeeventupdateform h3.headline:after {
        content: none; } }
  .benefice-page__main form#beneficeeventform h3.headline:not(.headline--benefice-event), .benefice-page__main form#beneficeeventupdateform h3.headline:not(.headline--benefice-event) {
    margin-top: 0;
    padding-top: 0; }
    @media (min-width: 980px) {
      .benefice-page__main form#beneficeeventform h3.headline:not(.headline--benefice-event), .benefice-page__main form#beneficeeventupdateform h3.headline:not(.headline--benefice-event) {
        margin-top: -1rem; } }
  .benefice-page__main form#beneficeeventform h3.headline.headline--venues, .benefice-page__main form#beneficeeventupdateform h3.headline.headline--venues {
    margin-bottom: 0; }
  .benefice-page__main form#beneficeeventform #heading--church-locations, .benefice-page__main form#beneficeeventupdateform #heading--church-locations {
    margin-bottom: 0; }
  .benefice-page__main form#beneficeeventform #div_id_regular_event ~ .headline, .benefice-page__main .main-content form#beneficeeventform fieldset #div_id_regular_event ~ legend, .main-content .benefice-page__main form#beneficeeventform fieldset #div_id_regular_event ~ legend,
  .benefice-page__main #lightbox form#beneficeeventform fieldset #div_id_regular_event ~ legend, #lightbox .benefice-page__main form#beneficeeventform fieldset #div_id_regular_event ~ legend, .benefice-page__main form#beneficeeventupdateform #div_id_regular_event ~ .headline, .benefice-page__main .main-content form#beneficeeventupdateform fieldset #div_id_regular_event ~ legend, .main-content .benefice-page__main form#beneficeeventupdateform fieldset #div_id_regular_event ~ legend,
  .benefice-page__main #lightbox form#beneficeeventupdateform fieldset #div_id_regular_event ~ legend, #lightbox .benefice-page__main form#beneficeeventupdateform fieldset #div_id_regular_event ~ legend {
    margin-top: 0; }
  @media (max-width: 979px) {
    .benefice-page__main form#beneficeeventform #div_id_regular_event ~ .form__tabs-controller, .benefice-page__main form#beneficeeventupdateform #div_id_regular_event ~ .form__tabs-controller {
      margin-left: -1rem !important; } }
  .benefice-page__main form#beneficeeventform .button__holder:before,
  .benefice-page__main form#beneficeeventform .control-group:before,
  .benefice-page__main form#beneficeeventform .form__holder:before,
  .benefice-page__main form#beneficeeventform .form__holder .headline:before,
  .benefice-page__main .main-content form#beneficeeventform .form__holder fieldset legend:before,
  .main-content .benefice-page__main form#beneficeeventform .form__holder fieldset legend:before,
  .benefice-page__main .main-content form#beneficeeventform fieldset .form__holder legend:before,
  .main-content .benefice-page__main form#beneficeeventform fieldset .form__holder legend:before,
  .benefice-page__main #lightbox form#beneficeeventform .form__holder fieldset legend:before,
  #lightbox .benefice-page__main form#beneficeeventform .form__holder fieldset legend:before,
  .benefice-page__main #lightbox form#beneficeeventform fieldset .form__holder legend:before,
  #lightbox .benefice-page__main form#beneficeeventform fieldset .form__holder legend:before, .benefice-page__main form#beneficeeventupdateform .button__holder:before,
  .benefice-page__main form#beneficeeventupdateform .control-group:before,
  .benefice-page__main form#beneficeeventupdateform .form__holder:before,
  .benefice-page__main form#beneficeeventupdateform .form__holder .headline:before,
  .benefice-page__main .main-content form#beneficeeventupdateform .form__holder fieldset legend:before,
  .main-content .benefice-page__main form#beneficeeventupdateform .form__holder fieldset legend:before,
  .benefice-page__main .main-content form#beneficeeventupdateform fieldset .form__holder legend:before,
  .main-content .benefice-page__main form#beneficeeventupdateform fieldset .form__holder legend:before,
  .benefice-page__main #lightbox form#beneficeeventupdateform .form__holder fieldset legend:before,
  #lightbox .benefice-page__main form#beneficeeventupdateform .form__holder fieldset legend:before,
  .benefice-page__main #lightbox form#beneficeeventupdateform fieldset .form__holder legend:before,
  #lightbox .benefice-page__main form#beneficeeventupdateform fieldset .form__holder legend:before {
    content: none; }
  .benefice-page__main form#beneficeeventform .button__holder, .benefice-page__main form#beneficeeventupdateform .button__holder {
    padding: 0; }
  .benefice-page__main form#beneficeeventform .form__holder--optional, .benefice-page__main form#beneficeeventupdateform .form__holder--optional {
    padding: 0 !important; }
    .benefice-page__main form#beneficeeventform .form__holder--optional > small, .benefice-page__main form#beneficeeventform .form__holder--optional .control-group, .benefice-page__main form#beneficeeventupdateform .form__holder--optional > small, .benefice-page__main form#beneficeeventupdateform .form__holder--optional .control-group {
      padding: 1.5rem 1rem; }
      @media (min-width: 980px) {
        .benefice-page__main form#beneficeeventform .form__holder--optional > small, .benefice-page__main form#beneficeeventform .form__holder--optional .control-group, .benefice-page__main form#beneficeeventupdateform .form__holder--optional > small, .benefice-page__main form#beneficeeventupdateform .form__holder--optional .control-group {
          padding: 1.5rem 2rem; } }
    .benefice-page__main form#beneficeeventform .form__holder--optional > .headline, .benefice-page__main .main-content form#beneficeeventform fieldset .form__holder--optional > legend, .main-content .benefice-page__main form#beneficeeventform fieldset .form__holder--optional > legend,
    .benefice-page__main #lightbox form#beneficeeventform fieldset .form__holder--optional > legend, #lightbox .benefice-page__main form#beneficeeventform fieldset .form__holder--optional > legend, .benefice-page__main form#beneficeeventupdateform .form__holder--optional > .headline, .benefice-page__main .main-content form#beneficeeventupdateform fieldset .form__holder--optional > legend, .main-content .benefice-page__main form#beneficeeventupdateform fieldset .form__holder--optional > legend,
    .benefice-page__main #lightbox form#beneficeeventupdateform fieldset .form__holder--optional > legend, #lightbox .benefice-page__main form#beneficeeventupdateform fieldset .form__holder--optional > legend {
      margin: 1rem 1rem 0 !important; }
      @media (min-width: 980px) {
        .benefice-page__main form#beneficeeventform .form__holder--optional > .headline, .benefice-page__main .main-content form#beneficeeventform fieldset .form__holder--optional > legend, .main-content .benefice-page__main form#beneficeeventform fieldset .form__holder--optional > legend,
        .benefice-page__main #lightbox form#beneficeeventform fieldset .form__holder--optional > legend, #lightbox .benefice-page__main form#beneficeeventform fieldset .form__holder--optional > legend, .benefice-page__main form#beneficeeventupdateform .form__holder--optional > .headline, .benefice-page__main .main-content form#beneficeeventupdateform fieldset .form__holder--optional > legend, .main-content .benefice-page__main form#beneficeeventupdateform fieldset .form__holder--optional > legend,
        .benefice-page__main #lightbox form#beneficeeventupdateform fieldset .form__holder--optional > legend, #lightbox .benefice-page__main form#beneficeeventupdateform fieldset .form__holder--optional > legend {
          margin: 0 2rem !important;
          padding-top: 0 !important; } }
    .benefice-page__main form#beneficeeventform .form__holder--optional #div_id_link, .benefice-page__main form#beneficeeventupdateform .form__holder--optional #div_id_link {
      padding-bottom: 0 !important; }
      @media (min-width: 980px) {
        .benefice-page__main form#beneficeeventform .form__holder--optional #div_id_link ~ small, .benefice-page__main form#beneficeeventupdateform .form__holder--optional #div_id_link ~ small {
          padding-top: 0 !important;
          font-size: 1rem; } }
  .benefice-page__main form#beneficeeventform #div_id_tags,
  .benefice-page__main form#beneficeeventform #div_id_feature_image,
  .benefice-page__main form#beneficeeventform #div_id_file_attachment, .benefice-page__main form#beneficeeventupdateform #div_id_tags,
  .benefice-page__main form#beneficeeventupdateform #div_id_feature_image,
  .benefice-page__main form#beneficeeventupdateform #div_id_file_attachment {
    margin-top: 0; }
    .benefice-page__main form#beneficeeventform #div_id_tags .control-label,
    .benefice-page__main form#beneficeeventform #div_id_feature_image .control-label,
    .benefice-page__main form#beneficeeventform #div_id_file_attachment .control-label, .benefice-page__main form#beneficeeventupdateform #div_id_tags .control-label,
    .benefice-page__main form#beneficeeventupdateform #div_id_feature_image .control-label,
    .benefice-page__main form#beneficeeventupdateform #div_id_file_attachment .control-label {
      display: inline;
      width: auto; }
      .benefice-page__main form#beneficeeventform #div_id_tags .control-label:after,
      .benefice-page__main form#beneficeeventform #div_id_feature_image .control-label:after,
      .benefice-page__main form#beneficeeventform #div_id_file_attachment .control-label:after, .benefice-page__main form#beneficeeventupdateform #div_id_tags .control-label:after,
      .benefice-page__main form#beneficeeventupdateform #div_id_feature_image .control-label:after,
      .benefice-page__main form#beneficeeventupdateform #div_id_file_attachment .control-label:after {
        content: none !important; }
      .benefice-page__main form#beneficeeventform #div_id_tags .control-label .headline, .benefice-page__main .main-content form#beneficeeventform #div_id_tags .control-label fieldset legend, .main-content .benefice-page__main form#beneficeeventform #div_id_tags .control-label fieldset legend, .benefice-page__main .main-content form#beneficeeventform fieldset #div_id_tags .control-label legend, .main-content .benefice-page__main form#beneficeeventform fieldset #div_id_tags .control-label legend,
      .benefice-page__main #lightbox form#beneficeeventform #div_id_tags .control-label fieldset legend, #lightbox .benefice-page__main form#beneficeeventform #div_id_tags .control-label fieldset legend, .benefice-page__main #lightbox form#beneficeeventform fieldset #div_id_tags .control-label legend, #lightbox .benefice-page__main form#beneficeeventform fieldset #div_id_tags .control-label legend,
      .benefice-page__main form#beneficeeventform #div_id_feature_image .control-label .headline,
      .benefice-page__main .main-content form#beneficeeventform #div_id_feature_image .control-label fieldset legend,
      .main-content .benefice-page__main form#beneficeeventform #div_id_feature_image .control-label fieldset legend,
      .benefice-page__main .main-content form#beneficeeventform fieldset #div_id_feature_image .control-label legend,
      .main-content .benefice-page__main form#beneficeeventform fieldset #div_id_feature_image .control-label legend,
      .benefice-page__main #lightbox form#beneficeeventform #div_id_feature_image .control-label fieldset legend,
      #lightbox .benefice-page__main form#beneficeeventform #div_id_feature_image .control-label fieldset legend,
      .benefice-page__main #lightbox form#beneficeeventform fieldset #div_id_feature_image .control-label legend,
      #lightbox .benefice-page__main form#beneficeeventform fieldset #div_id_feature_image .control-label legend,
      .benefice-page__main form#beneficeeventform #div_id_file_attachment .control-label .headline,
      .benefice-page__main .main-content form#beneficeeventform #div_id_file_attachment .control-label fieldset legend,
      .main-content .benefice-page__main form#beneficeeventform #div_id_file_attachment .control-label fieldset legend,
      .benefice-page__main .main-content form#beneficeeventform fieldset #div_id_file_attachment .control-label legend,
      .main-content .benefice-page__main form#beneficeeventform fieldset #div_id_file_attachment .control-label legend,
      .benefice-page__main #lightbox form#beneficeeventform #div_id_file_attachment .control-label fieldset legend,
      #lightbox .benefice-page__main form#beneficeeventform #div_id_file_attachment .control-label fieldset legend,
      .benefice-page__main #lightbox form#beneficeeventform fieldset #div_id_file_attachment .control-label legend,
      #lightbox .benefice-page__main form#beneficeeventform fieldset #div_id_file_attachment .control-label legend, .benefice-page__main form#beneficeeventupdateform #div_id_tags .control-label .headline, .benefice-page__main .main-content form#beneficeeventupdateform #div_id_tags .control-label fieldset legend, .main-content .benefice-page__main form#beneficeeventupdateform #div_id_tags .control-label fieldset legend, .benefice-page__main .main-content form#beneficeeventupdateform fieldset #div_id_tags .control-label legend, .main-content .benefice-page__main form#beneficeeventupdateform fieldset #div_id_tags .control-label legend,
      .benefice-page__main #lightbox form#beneficeeventupdateform #div_id_tags .control-label fieldset legend, #lightbox .benefice-page__main form#beneficeeventupdateform #div_id_tags .control-label fieldset legend, .benefice-page__main #lightbox form#beneficeeventupdateform fieldset #div_id_tags .control-label legend, #lightbox .benefice-page__main form#beneficeeventupdateform fieldset #div_id_tags .control-label legend,
      .benefice-page__main form#beneficeeventupdateform #div_id_feature_image .control-label .headline,
      .benefice-page__main .main-content form#beneficeeventupdateform #div_id_feature_image .control-label fieldset legend,
      .main-content .benefice-page__main form#beneficeeventupdateform #div_id_feature_image .control-label fieldset legend,
      .benefice-page__main .main-content form#beneficeeventupdateform fieldset #div_id_feature_image .control-label legend,
      .main-content .benefice-page__main form#beneficeeventupdateform fieldset #div_id_feature_image .control-label legend,
      .benefice-page__main #lightbox form#beneficeeventupdateform #div_id_feature_image .control-label fieldset legend,
      #lightbox .benefice-page__main form#beneficeeventupdateform #div_id_feature_image .control-label fieldset legend,
      .benefice-page__main #lightbox form#beneficeeventupdateform fieldset #div_id_feature_image .control-label legend,
      #lightbox .benefice-page__main form#beneficeeventupdateform fieldset #div_id_feature_image .control-label legend,
      .benefice-page__main form#beneficeeventupdateform #div_id_file_attachment .control-label .headline,
      .benefice-page__main .main-content form#beneficeeventupdateform #div_id_file_attachment .control-label fieldset legend,
      .main-content .benefice-page__main form#beneficeeventupdateform #div_id_file_attachment .control-label fieldset legend,
      .benefice-page__main .main-content form#beneficeeventupdateform fieldset #div_id_file_attachment .control-label legend,
      .main-content .benefice-page__main form#beneficeeventupdateform fieldset #div_id_file_attachment .control-label legend,
      .benefice-page__main #lightbox form#beneficeeventupdateform #div_id_file_attachment .control-label fieldset legend,
      #lightbox .benefice-page__main form#beneficeeventupdateform #div_id_file_attachment .control-label fieldset legend,
      .benefice-page__main #lightbox form#beneficeeventupdateform fieldset #div_id_file_attachment .control-label legend,
      #lightbox .benefice-page__main form#beneficeeventupdateform fieldset #div_id_file_attachment .control-label legend {
        width: 100%; }
        @media (max-width: 979px) {
          .benefice-page__main form#beneficeeventform #div_id_tags .control-label .headline, .benefice-page__main .main-content form#beneficeeventform #div_id_tags .control-label fieldset legend, .main-content .benefice-page__main form#beneficeeventform #div_id_tags .control-label fieldset legend, .benefice-page__main .main-content form#beneficeeventform fieldset #div_id_tags .control-label legend, .main-content .benefice-page__main form#beneficeeventform fieldset #div_id_tags .control-label legend,
          .benefice-page__main #lightbox form#beneficeeventform #div_id_tags .control-label fieldset legend, #lightbox .benefice-page__main form#beneficeeventform #div_id_tags .control-label fieldset legend, .benefice-page__main #lightbox form#beneficeeventform fieldset #div_id_tags .control-label legend, #lightbox .benefice-page__main form#beneficeeventform fieldset #div_id_tags .control-label legend,
          .benefice-page__main form#beneficeeventform #div_id_feature_image .control-label .headline,
          .benefice-page__main .main-content form#beneficeeventform #div_id_feature_image .control-label fieldset legend,
          .main-content .benefice-page__main form#beneficeeventform #div_id_feature_image .control-label fieldset legend,
          .benefice-page__main .main-content form#beneficeeventform fieldset #div_id_feature_image .control-label legend,
          .main-content .benefice-page__main form#beneficeeventform fieldset #div_id_feature_image .control-label legend,
          .benefice-page__main #lightbox form#beneficeeventform #div_id_feature_image .control-label fieldset legend,
          #lightbox .benefice-page__main form#beneficeeventform #div_id_feature_image .control-label fieldset legend,
          .benefice-page__main #lightbox form#beneficeeventform fieldset #div_id_feature_image .control-label legend,
          #lightbox .benefice-page__main form#beneficeeventform fieldset #div_id_feature_image .control-label legend,
          .benefice-page__main form#beneficeeventform #div_id_file_attachment .control-label .headline,
          .benefice-page__main .main-content form#beneficeeventform #div_id_file_attachment .control-label fieldset legend,
          .main-content .benefice-page__main form#beneficeeventform #div_id_file_attachment .control-label fieldset legend,
          .benefice-page__main .main-content form#beneficeeventform fieldset #div_id_file_attachment .control-label legend,
          .main-content .benefice-page__main form#beneficeeventform fieldset #div_id_file_attachment .control-label legend,
          .benefice-page__main #lightbox form#beneficeeventform #div_id_file_attachment .control-label fieldset legend,
          #lightbox .benefice-page__main form#beneficeeventform #div_id_file_attachment .control-label fieldset legend,
          .benefice-page__main #lightbox form#beneficeeventform fieldset #div_id_file_attachment .control-label legend,
          #lightbox .benefice-page__main form#beneficeeventform fieldset #div_id_file_attachment .control-label legend, .benefice-page__main form#beneficeeventupdateform #div_id_tags .control-label .headline, .benefice-page__main .main-content form#beneficeeventupdateform #div_id_tags .control-label fieldset legend, .main-content .benefice-page__main form#beneficeeventupdateform #div_id_tags .control-label fieldset legend, .benefice-page__main .main-content form#beneficeeventupdateform fieldset #div_id_tags .control-label legend, .main-content .benefice-page__main form#beneficeeventupdateform fieldset #div_id_tags .control-label legend,
          .benefice-page__main #lightbox form#beneficeeventupdateform #div_id_tags .control-label fieldset legend, #lightbox .benefice-page__main form#beneficeeventupdateform #div_id_tags .control-label fieldset legend, .benefice-page__main #lightbox form#beneficeeventupdateform fieldset #div_id_tags .control-label legend, #lightbox .benefice-page__main form#beneficeeventupdateform fieldset #div_id_tags .control-label legend,
          .benefice-page__main form#beneficeeventupdateform #div_id_feature_image .control-label .headline,
          .benefice-page__main .main-content form#beneficeeventupdateform #div_id_feature_image .control-label fieldset legend,
          .main-content .benefice-page__main form#beneficeeventupdateform #div_id_feature_image .control-label fieldset legend,
          .benefice-page__main .main-content form#beneficeeventupdateform fieldset #div_id_feature_image .control-label legend,
          .main-content .benefice-page__main form#beneficeeventupdateform fieldset #div_id_feature_image .control-label legend,
          .benefice-page__main #lightbox form#beneficeeventupdateform #div_id_feature_image .control-label fieldset legend,
          #lightbox .benefice-page__main form#beneficeeventupdateform #div_id_feature_image .control-label fieldset legend,
          .benefice-page__main #lightbox form#beneficeeventupdateform fieldset #div_id_feature_image .control-label legend,
          #lightbox .benefice-page__main form#beneficeeventupdateform fieldset #div_id_feature_image .control-label legend,
          .benefice-page__main form#beneficeeventupdateform #div_id_file_attachment .control-label .headline,
          .benefice-page__main .main-content form#beneficeeventupdateform #div_id_file_attachment .control-label fieldset legend,
          .main-content .benefice-page__main form#beneficeeventupdateform #div_id_file_attachment .control-label fieldset legend,
          .benefice-page__main .main-content form#beneficeeventupdateform fieldset #div_id_file_attachment .control-label legend,
          .main-content .benefice-page__main form#beneficeeventupdateform fieldset #div_id_file_attachment .control-label legend,
          .benefice-page__main #lightbox form#beneficeeventupdateform #div_id_file_attachment .control-label fieldset legend,
          #lightbox .benefice-page__main form#beneficeeventupdateform #div_id_file_attachment .control-label fieldset legend,
          .benefice-page__main #lightbox form#beneficeeventupdateform fieldset #div_id_file_attachment .control-label legend,
          #lightbox .benefice-page__main form#beneficeeventupdateform fieldset #div_id_file_attachment .control-label legend {
            margin-top: 0;
            padding-top: 0; } }
  @media (max-width: 979px) {
    .benefice-page__main form#beneficeeventform #div_id_feature_image ~ .page-content, .benefice-page__main form#beneficeeventupdateform #div_id_feature_image ~ .page-content {
      display: flex;
      flex-flow: column nowrap;
      align-items: center; } }
  .benefice-page__main form#beneficeeventform #div_id_tags,
  .benefice-page__main form#beneficeeventform #div_id_feature_image, .benefice-page__main form#beneficeeventupdateform #div_id_tags,
  .benefice-page__main form#beneficeeventupdateform #div_id_feature_image {
    padding-top: 0; }
  .benefice-page__main form#beneficeeventform #div_id_tags #hint_id_tags, .benefice-page__main form#beneficeeventupdateform #div_id_tags #hint_id_tags {
    position: relative;
    top: 0;
    width: auto;
    margin-top: 1rem; }
    @media (min-width: 1280px) {
      .benefice-page__main form#beneficeeventform #div_id_tags #hint_id_tags, .benefice-page__main form#beneficeeventupdateform #div_id_tags #hint_id_tags {
        position: absolute;
        top: 2rem;
        width: 30%;
        margin-top: 0; } }
    .benefice-page__main form#beneficeeventform #div_id_tags #hint_id_tags .hint-close, .benefice-page__main form#beneficeeventupdateform #div_id_tags #hint_id_tags .hint-close {
      display: none; }
  .benefice-page__main form#beneficeeventform .button__holder,
  .benefice-page__main form#beneficeeventform #div_id_name, .benefice-page__main form#beneficeeventupdateform .button__holder,
  .benefice-page__main form#beneficeeventupdateform #div_id_name {
    border-bottom: none; }
  .benefice-page__main form#beneficeeventform #div_id_name, .benefice-page__main form#beneficeeventupdateform #div_id_name {
    padding-bottom: 0; }
    .benefice-page__main form#beneficeeventform #div_id_name + .control-group, .benefice-page__main form#beneficeeventupdateform #div_id_name + .control-group {
      padding-top: 0; }
  .benefice-page__main form#beneficeeventform .form__tabs, .benefice-page__main form#beneficeeventupdateform .form__tabs {
    margin-top: 0; }
  .benefice-page__main form#beneficeeventform .image__fieldset.file, .benefice-page__main form#beneficeeventform #div_id_name, .benefice-page__main form#beneficeeventupdateform .image__fieldset.file, .benefice-page__main form#beneficeeventupdateform #div_id_name {
    border-top: 1rem solid #F1F1F1; }
  .benefice-page__main form#beneficeeventform .field__description, .benefice-page__main form#beneficeeventupdateform .field__description {
    padding-bottom: 1rem;
    font-size: 1rem; }
  @media (min-width: 980px) {
    .benefice-page__main form#beneficeeventform .button__holder, .benefice-page__main form#beneficeeventupdateform .button__holder {
      padding: 0 2rem;
      margin-top: 1.5rem; } }
  .benefice-page__main form#beneficeeventform .custom-location-row, .benefice-page__main form#beneficeeventupdateform .custom-location-row {
    gap: 1rem; }
    .benefice-page__main form#beneficeeventform .custom-location-row .form__conditional-fields, .benefice-page__main form#beneficeeventupdateform .custom-location-row .form__conditional-fields {
      flex: 1 0 100%; }
      @media (min-width: 980px) {
        .benefice-page__main form#beneficeeventform .custom-location-row .form__conditional-fields, .benefice-page__main form#beneficeeventupdateform .custom-location-row .form__conditional-fields {
          flex: 1 0 50%; } }
      .benefice-page__main form#beneficeeventform .custom-location-row .form__conditional-fields + .help-block, .benefice-page__main form#beneficeeventupdateform .custom-location-row .form__conditional-fields + .help-block {
        flex: 1 0 11.5%; }
  .benefice-page__main form#beneficeeventform #heading--custom-location, .benefice-page__main form#beneficeeventupdateform #heading--custom-location {
    margin: 2rem 0 0; }

.custom__select-holder {
  position: relative; }
  .custom__select-holder:focus {
    outline: none; }

.custom__select-dropdown {
  position: relative;
  display: none;
  background: #fdfdfd;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 20;
  max-height: 14rem;
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  z-index: 25;
  border: 3px solid #E4E4E4;
  border-top: none; }
  .custom__select-dropdown::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px; }
  .custom__select-dropdown::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }
  .custom__select-open + .custom__select-dropdown {
    display: block; }

.custom__option,
.custom__select-toggle {
  color: inherit;
  cursor: pointer;
  padding: 0.5rem 1rem;
  position: relative; }
  .custom__option + .custom__option,
  .custom__select-toggle + .custom__option {
    border-top: 1px solid #E4E4E4; }
  .custom__option:hover,
  .custom__select-toggle:hover {
    background: rgba(166, 136, 183, 0.0625); }

#tags-search + .custom__option-group {
  margin-top: 1rem; }

.custom__option-group .tag {
  margin: 0;
  flex-shrink: 1; }

.custom__option-group {
  margin-bottom: 2rem; }

.custom__select-toggle-text {
  padding: 0.5rem 1rem;
  border: 3px solid #E8A065;
  border-right: none;
  width: 100%; }

.toggle-icon__holder {
  border: 3px solid #E8A065;
  border-left: none;
  flex-shrink: 0;
  width: 3.25rem;
  display: flex;
  justify-content: center;
  align-items: center; }

.custom__tag-selected {
  margin-top: 0.5rem; }
  .custom__tag-selected .tag {
    margin-right: 5px; }

.custom__select-toggle {
  position: relative;
  display: flex;
  background: #fff;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  border-radius: 0;
  background: #fff;
  vertical-align: top;
  line-height: inherit;
  z-index: 20; }
  .custom__select-toggle:focus {
    border-color: #bebebe; }
  .custom__select-toggle .icon {
    color: #E8A065;
    width: 1.25rem;
    height: 1.25rem; }
  .custom__select-toggle .toggle-close {
    display: none; }
  .custom__select-toggle.custom__select-open {
    border-bottom-color: #bebebe; }
    .custom__select-toggle.custom__select-open .toggle-open {
      display: none; }
    .custom__select-toggle.custom__select-open .toggle-close {
      display: block; }
  .custom__select-toggle:focus {
    outline: none; }

.mediumeditorwidget {
  min-height: 10em;
  outline: none;
  width: 100%;
  padding: 0.75rem 1rem;
  -webkit-font-smoothing: antialiased;
  border: 3px solid #e4e4e4; }

.custom__select-button-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem; }
  .custom__select-button-holder .btn {
    margin-left: auto; }

.listselect2multiple .select2-selection__clear {
  display: none; }

.listselect2multiple .select2-selection__rendered {
  padding: 0 !important; }

.listselect2multiple .select2-selection__choice {
  max-width: 100%;
  white-space: normal; }

.listselect2multiple .select2-search__field {
  border-right: 0 !important; }

.select2-search.select2-search--inline {
  width: calc(100% - 3.25rem) !important;
  margin: 0.5rem 0 0 0; }

.select2-search__field {
  width: 100% !important;
  margin-top: 0 !important; }

.select2-container--default .select2-selection--multiple {
  border: 0 !important; }

.image__fieldset-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: 10rem;
  border: 3px solid #E4E4E4;
  margin-right: 1.5rem;
  cursor: pointer;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden; }
  .image__fieldset-preview span {
    display: block;
    opacity: 0;
    pointer-events: none;
    z-index: 10;
    text-transform: uppercase;
    background: #fff;
    padding: 0.5rem 0.75rem; }
  .image__fieldset-preview img {
    image-orientation: from-image;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; }
  .image__fieldset-preview.no__preview {
    width: 100%;
    height: 12rem;
    position: relative; }
    @media (min-width: 980px) {
      .image__fieldset-preview.no__preview {
        width: 15rem;
        height: 7.65rem; } }
    .image__fieldset-preview.no__preview:before, .image__fieldset-preview.no__preview:after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: ' ';
      height: 3rem;
      width: 3px;
      background-color: #E4E4E4; }
    .image__fieldset-preview.no__preview:before {
      transform: translate(-50%, -50%) rotate(45deg); }
    .image__fieldset-preview.no__preview:after {
      transform: translate(-50%, -50%) rotate(-45deg); }
  .image__fieldset-preview.file__over {
    background-image: none !important;
    border-color: #A3C27F; }
    .image__fieldset-preview.file__over span {
      opacity: 1; }
  .image__fieldset-preview:hover {
    border-color: #E8A065; }
    .image__fieldset-preview:hover span {
      opacity: 1; }
      .image__fieldset-preview:hover span:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.75);
        z-index: -5; }
  .image__fieldset-preview.cleared {
    border-color: #DE2F62; }
    .image__fieldset-preview.cleared span {
      opacity: 1;
      font-size: 0;
      padding: 0; }
      .image__fieldset-preview.cleared span:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(222, 47, 98, 0.5);
        z-index: -5; }
  @media (min-width: 980px) {
    .image__fieldset-preview {
      width: 15rem;
      height: 15rem; } }

.image__fieldset .custom__image-field .help-block {
  padding-top: 0;
  border-top: 0;
  margin-top: 1rem;
  color: inherit;
  font-size: 1rem;
  width: 30%;
  flex-shrink: 0;
  margin-top: 1.5rem;
  padding-top: 0.5rem;
  border-top: 3px solid #F5F5F5; }
  @media all and (min-width: 768px) {
    .image__fieldset .custom__image-field .help-block {
      margin: 0 0 0 2rem;
      padding-top: 0;
      border: none; } }

@media all and (max-width: 768px) {
  .image__fieldset .custom__image-wrapper,
  .image__fieldset .custom__image-field .help-block {
    width: 100%;
    max-width: none;
    flex-shrink: 0;
    flex-direction: column; } }
  @media all and (max-width: 768px) and (min-width: 980px) {
    .image__fieldset .custom__image-wrapper,
    .image__fieldset .custom__image-field .help-block {
      flex-direction: row; } }

.custom__image-upload {
  min-width: 0;
  width: auto;
  text-align: center;
  overflow: hidden; }
  @media (min-width: 980px) {
    .custom__image-upload {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left; } }
  .custom__image-upload .delete {
    margin-right: 0.5rem; }
  .custom__image-upload button {
    outline: none; }
  @media all and (min-width: 481px) {
    .custom__image-upload {
      margin-top: 0; } }
  .custom__image-upload .upload-placeholder {
    color: #8B63A1;
    align-self: flex-start; }
    @media (min-width: 980px) {
      .custom__image-upload .upload-placeholder {
        color: #4A4A4A; } }
  .custom__image-upload .mobile__edit-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center; }

.error ~ .custom__image-error .help-inline {
  background: #DE2F62;
  color: #fff;
  padding: 0.5rem;
  margin-top: 1rem; }

.custom__image-upload-title {
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  width: 100%;
  margin-top: 0.25rem; }
  .custom__image-upload-title .icon {
    width: 1.5rem;
    height: 1.5rem;
    color: #A5A5A5;
    margin-right: 0.75rem;
    flex-shrink: 0; }
  .custom__image-upload-title p {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    width: auto; }
  .custom__image-upload-title + button + button {
    margin-left: 0.75rem; }
  .custom__image-upload-title + .button {
    margin-top: 1.5rem; }
  @media (min-width: 980px) {
    .custom__image-upload-title {
      justify-content: flex-start; } }

.custom__image-wrapper {
  min-width: 0; }
  @media (min-width: 1280px) {
    .custom__image-wrapper {
      display: flex;
      flex-flow: row nowrap; } }
  @media all and (min-width: 768px) {
    .custom__image-wrapper {
      flex-grow: 1;
      min-width: 30%; } }

.custom__image-field {
  margin: 0;
  display: flex;
  flex-flow: row wrap; }
  @media (min-width: 980px) {
    .custom__image-field {
      margin: 2rem 0 0 0; } }
  @media all and (min-width: 768px) {
    .custom__image-field {
      flex-flow: row nowrap; } }
  @media (min-width: 980px) {
    .custom__image-field .custom__image-errors {
      width: 30%; } }
  .custom__image-field .custom__image-errors .help-block {
    display: block;
    border-top: 0;
    width: 100%;
    margin-left: 0;
    margin-bottom: 1rem; }
  @media (min-width: 980px) {
    .custom__image-field .help-block {
      display: block; } }

#imagecreateform .image__fieldset .control-group,
#imageupdateform .image__fieldset .control-group {
  margin-top: -1rem; }
  @media (min-width: 980px) {
    #imagecreateform .image__fieldset .control-group,
    #imageupdateform .image__fieldset .control-group {
      margin-top: 1rem; } }

.controls__checkbox {
  flex-shrink: 0;
  cursor: pointer; }
  .controls__checkbox label {
    font-size: 1rem !important; }
  .controls__checkbox .checkbox {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: inherit;
    margin-left: 1rem;
    padding-top: 0.5rem; }
    @media (min-width: 980px) {
      .controls__checkbox .checkbox {
        margin-left: auto; } }
  .controls__checkbox label[for=id_at_venue_location].checkbox {
    margin: 0 0 2rem 0; }

.controls__checkbox--flex-grow {
  flex-grow: 1; }

#lightbox_content .controls__checkbox {
  margin-bottom: 0.125rem; }

.custom__checkbox {
  width: 1.5rem;
  height: 1.5rem;
  border: 3px solid #E4E4E4;
  margin-right: 0.75rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .custom__checkbox .icon {
    display: none;
    color: #a688b7; }

input[type=checkbox]:checked + label .custom__checkbox .icon,
.checkbox--checked .custom__checkbox .icon {
  display: block; }

#getintouchform .buttonHolder, #claimeditorform .buttonHolder {
  margin-top: 1rem; }

[for="id_terms"] .custom__checkbox-label {
  text-transform: none; }

.custom__field ~ .custom__field {
  margin-top: 0.25rem; }

.custom__field-icon {
  border: 3px solid #E8A065;
  flex-shrink: 0;
  width: 3.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .error .custom__field-icon {
    border-color: #DE2F62; }
  .custom__field-icon.custom-clear {
    border-right: none; }
    .custom__field-icon.custom-clear .icon {
      width: 1rem;
      height: 1rem; }

.custom__field-input {
  position: relative;
  display: flex;
  background: #fff;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  border-radius: 0;
  background: #fff;
  vertical-align: top;
  line-height: inherit; }
  .custom__field-input .icon {
    color: #4A4A4A;
    width: 1rem;
    height: 1rem; }

.custom__field-value {
  border: 3px solid #E4E4E4;
  border-right: none;
  padding: 0.75rem 1rem;
  width: 100%;
  white-space: nowrap; }
  .error .custom__field-value {
    border-color: #DE2F62; }
  .custom__field-value.time__picker {
    padding: 0;
    border: none; }
    .custom__field-value.time__picker input {
      border: 3px solid #E4E4E4;
      border-right: none !important;
      -webkit-font-smoothing: inherit !important; }
      .error .custom__field-value.time__picker input {
        border-color: #DE2F62; }

.custom__field-widget.hasDatepicker,
.custom__time {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 0.25rem;
  z-index: 100;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.125);
  user-select: none; }

.custom__time {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 2rem; }

.custom__time-wrapper {
  display: flex;
  flex-flow: column nowrap; }
  .custom__time-wrapper input {
    appearance: none !important;
    letter-spacing: 0.15rem;
    padding: 0.5rem 0 !important;
    border: none !important;
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    text-align: center !important; }
    .custom__time-wrapper input::-webkit-clear-button {
      display: none; }

.custom__time-value-up,
.custom__time-value-down {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 1.75rem 0 0.5rem; }
  .custom__time-value-up .icon,
  .custom__time-value-down .icon {
    vertical-align: middle;
    width: 0.75rem;
    height: 0.75rem;
    color: #a688b7; }

.custom__time-value {
  padding: 1rem;
  font-size: 1.5rem;
  font-family: "Cabin", Arial, sans-serif;
  width: 4rem;
  text-align: center; }

.ui-datepicker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  border-bottom: 3px solid #a688b7; }
  .ui-datepicker-header .ui-datepicker-next {
    order: 3; }
  .ui-datepicker-header a {
    cursor: pointer; }

.ui-datepicker-title {
  font-size: 1.25rem;
  font-family: "Cabin", Arial, sans-serif; }

.ui-datepicker-calendar {
  width: 100%;
  text-align: center; }
  .ui-datepicker-calendar a {
    cursor: pointer;
    text-decoration: none;
    color: #7e7e7e; }
    .ui-datepicker-calendar a:hover {
      text-decoration: underline; }
  .ui-datepicker-calendar table {
    border-collapse: collapse; }
  .ui-datepicker-calendar tbody td {
    padding: 0.5rem 0.25rem; }
  .ui-datepicker-calendar .ui-state-active {
    font-weight: bold;
    color: #a688b7; }

.ui-timepicker-wrapper {
  overflow-y: auto;
  max-height: 150px;
  width: 8.5em;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  outline: none;
  z-index: 10052;
  margin: 0;
  padding-top: 4px;
  -webkit-font-smoothing: initial; }

.ui-timepicker-wrapper.ui-timepicker-with-duration {
  width: 13em; }

.ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-30,
.ui-timepicker-wrapper.ui-timepicker-with-duration.ui-timepicker-step-60 {
  width: 11em; }

.ui-timepicker-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.ui-timepicker-duration {
  margin-left: 5px;
  color: #888; }

.ui-timepicker-list:hover .ui-timepicker-duration {
  color: #888; }

.ui-timepicker-list li {
  padding: 3px 0 3px 10px;
  cursor: pointer;
  white-space: nowrap;
  color: #000;
  list-style: none;
  margin: 0; }

.ui-timepicker-list:hover .ui-timepicker-selected {
  background: #fff;
  color: #000; }

li.ui-timepicker-selected,
.ui-timepicker-list li:hover,
.ui-timepicker-list .ui-timepicker-selected:hover {
  color: #a688b7; }

li.ui-timepicker-selected .ui-timepicker-duration,
.ui-timepicker-list li:hover .ui-timepicker-duration {
  color: #ccc; }

.ui-timepicker-list li.ui-timepicker-disabled,
.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
  color: #888;
  cursor: default; }

.ui-timepicker-list li.ui-timepicker-disabled:hover,
.ui-timepicker-list li.ui-timepicker-selected.ui-timepicker-disabled {
  background: #f2f2f2; }

#div_id_publish_at .controls .custom__field ~ .custom__field {
  margin-top: 0.5rem; }
  @media (min-width: 980px) {
    #div_id_publish_at .controls .custom__field ~ .custom__field {
      margin-top: 0;
      margin-left: 0.5rem; } }

@media all and (min-width: 768px) and (max-width: 980px) {
  #div_id_publish_at .controls {
    display: flex;
    flex-flow: row nowrap; }
    #div_id_publish_at .controls > div {
      width: 50%; }
    #div_id_publish_at .controls .custom__field ~ .custom__field {
      margin: 0 0 1rem 1rem; }
    #div_id_publish_at .controls .custom__field-icon {
      width: 2.25rem !important; } }

@media all and (min-width: 850px) {
  #div_id_publish_at .controls .custom__field-icon {
    width: 3.25rem !important; } }

@media all and (min-width: 1175px) {
  #div_id_publish_at .controls {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 2rem; }
    #div_id_publish_at .controls > div {
      width: 50%; }
    #div_id_publish_at .controls .custom__field ~ .custom__field {
      margin: 0 0 1rem 1rem; } }

#div_id_delete_at .controls .custom__field ~ .custom__field {
  margin-top: 0.5rem; }
  @media (min-width: 980px) {
    #div_id_delete_at .controls .custom__field ~ .custom__field {
      margin-top: 0;
      margin-left: 0.5rem; } }

@media all and (min-width: 768px) and (max-width: 980px) {
  #div_id_delete_at .controls {
    display: flex;
    flex-flow: row nowrap; }
    #div_id_delete_at .controls > div {
      width: 50%; }
    #div_id_delete_at .controls .custom__field ~ .custom__field {
      margin: 0 0 1rem 1rem; }
    #div_id_delete_at .controls .custom__field-icon {
      width: 2.25rem !important; } }

@media all and (min-width: 850px) {
  #div_id_delete_at .controls .custom__field-icon {
    width: 3.25rem !important; } }

@media all and (min-width: 1175px) {
  #div_id_delete_at .controls {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 2rem; }
    #div_id_delete_at .controls > div {
      width: 50%; }
    #div_id_delete_at .controls .custom__field ~ .custom__field {
      margin: 0 0 1rem 1rem; } }

#div_id_event_start .custom__field {
  margin-bottom: 1.5rem; }

#div_id_event_start .control-label {
  font-size: 1.25rem;
  font-family: "Open Sans", Arial, sans-serif;
  line-height: 1.5rem;
  text-transform: capitalize !important; }

.tag__checkbox .checkbox {
  margin-bottom: 0; }
  .tag__checkbox .checkbox > span {
    background: #a688b7;
    padding: 0 0.5rem;
    color: #fff; }

.tag__checkbox + .tag__checkbox {
  margin-top: 0.25rem; }

.tag__checkbox.checked .checkbox .custom__checkbox-label {
  width: 100%; }

.tag__checkbox .custom__checkbox-label {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-family: "Cabin", Arial, sans-serif; }

.tag__checkbox label {
  width: unset !important; }

.tag__checkbox-description {
  display: none;
  padding-left: 3.25rem;
  margin-bottom: 1rem; }
  .checked .tag__checkbox-description {
    display: block; }
  @media (min-width: 980px) {
    .tag__checkbox-description {
      padding-left: 2.25rem; } }

.tags__fieldset .custom__tag-field {
  width: 100%; }
  @media screen and (min-width: 760px) {
    .tags__fieldset .custom__tag-field {
      width: 70%;
      padding: 0 2.5rem 0.5rem 0; } }
  .tags__fieldset .custom__tag-field .tag__list {
    margin: 1.5rem 0 1.25rem 0; }
  .tags__fieldset .custom__tag-field .custom__tag-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0.5rem;
    align-items: center; }
    @media screen and (min-width: 760px) {
      .tags__fieldset .custom__tag-field .custom__tag-upload {
        align-items: flex-start; } }

.tags__fieldset .tag-placeholder {
  color: #8B63A1; }

.tags__fieldset .help-block {
  position: absolute;
  right: 0;
  top: 6rem;
  font-size: 1rem !important;
  width: 30%;
  color: #4A4A4A; }
  @media all and (max-width: 768px) {
    .tags__fieldset .help-block {
      position: static;
      width: 100%;
      max-width: none;
      flex-shrink: 0; } }

@media (min-width: 980px) {
  .tags__fieldset {
    min-height: 18rem; } }

@media (min-width: 980px) {
  #eventform .tags__fieldset {
    min-height: 24rem; } }

.custom__radio {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 0.15rem solid #E4E4E4;
  overflow: visible;
  margin-right: 1.5rem;
  flex-shrink: 0; }

input[type=radio]:checked ~ label .custom__radio:after {
  content: " ";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  background: #8B63A1;
  margin: 16% auto;
  border-radius: 50%; }

.main-content form input[type='submit'],
.main-content form button,
#lightbox input[type='submit'],
#lightbox button {
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 3px solid #E8A065;
  appearance: none;
  background: none;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 0;
  font-family: inherit;
  color: inherit; }
  .main-content form input[type='submit']:disabled,
  .main-content form button:disabled,
  #lightbox input[type='submit']:disabled,
  #lightbox button:disabled {
    cursor: not-allowed; }
  .main-content form input[type='submit']:hover,
  .main-content form button:hover,
  #lightbox input[type='submit']:hover,
  #lightbox button:hover {
    background: rgba(232, 160, 101, 0.4); }
  .main-content form input[type='submit'].action-arrow,
  .main-content form button.action-arrow,
  #lightbox input[type='submit'].action-arrow,
  #lightbox button.action-arrow {
    background: #E8A065;
    color: #fff;
    background-position: center center;
    background-size: 2rem;
    background-repeat: no-repeat;
    padding: 1rem;
    width: 3.5rem;
    height: 3.4rem; }
    .main-content form input[type='submit'].action-arrow:hover,
    .main-content form button.action-arrow:hover,
    #lightbox input[type='submit'].action-arrow:hover,
    #lightbox button.action-arrow:hover {
      background: #e5924f;
      border-color: #e5924f; }
    .main-content form input[type='submit'].action-arrow:disabled:hover,
    .main-content form button.action-arrow:disabled:hover,
    #lightbox input[type='submit'].action-arrow:disabled:hover,
    #lightbox button.action-arrow:disabled:hover {
      cursor: not-allowed;
      background: #E8A065;
      border-color: #E8A065; }

.main-content form .button__holder,
#lightbox .button__holder {
  display: flex;
  justify-content: flex-start;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: #382e75;
  color: #ffffff;
  z-index: 100; }
  @media (min-width: 980px) {
    .main-content form .button__holder--space-between,
    #lightbox .button__holder--space-between {
      justify-content: space-between !important; } }
  .main-content form .button__holder.desktop-only,
  #lightbox .button__holder.desktop-only {
    display: none; }
  .main-content form .button__holder input, .main-content form .button__holder .save,
  #lightbox .button__holder input,
  #lightbox .button__holder .save {
    font-size: 1.143rem;
    background-color: #E8A065; }
    @media (min-width: 980px) {
      .main-content form .button__holder input, .main-content form .button__holder .save,
      #lightbox .button__holder input,
      #lightbox .button__holder .save {
        font-size: 1rem;
        background-color: #ffffff; } }
  .main-content form .button__holder #div_id_published .controls,
  #lightbox .button__holder #div_id_published .controls {
    display: flex; }
  .main-content form .button__holder button, .main-content form .button__holder a,
  #lightbox .button__holder button,
  #lightbox .button__holder a {
    white-space: nowrap; }
  .main-content form .button__holder .custom__checkbox,
  #lightbox .button__holder .custom__checkbox {
    border-color: #a688b7; }
    .main-content form .button__holder .custom__checkbox .icon,
    #lightbox .button__holder .custom__checkbox .icon {
      color: #ffffff; }
    @media (min-width: 980px) {
      .main-content form .button__holder .custom__checkbox,
      #lightbox .button__holder .custom__checkbox {
        border-color: #E4E4E4; }
        .main-content form .button__holder .custom__checkbox .icon,
        #lightbox .button__holder .custom__checkbox .icon {
          color: #a688b7; } }
  .main-content form .button__holder .custom__checkbox-label,
  #lightbox .button__holder .custom__checkbox-label {
    font-size: 1.143rem; }
  .main-content form .button__holder .button--hide, .main-content form .button__holder .button--show-more,
  #lightbox .button__holder .button--hide,
  #lightbox .button__holder .button--show-more {
    font-size: 0rem;
    display: flex;
    align-items: center;
    padding: 1rem; }
    .main-content form .button__holder .button--hide .icon, .main-content form .button__holder .button--show-more .icon,
    #lightbox .button__holder .button--hide .icon,
    #lightbox .button__holder .button--show-more .icon {
      width: 1rem;
      height: 1rem; }
    @media (min-width: 640px) {
      .main-content form .button__holder .button--hide, .main-content form .button__holder .button--show-more,
      #lightbox .button__holder .button--hide,
      #lightbox .button__holder .button--show-more {
        font-size: 1.143rem; } }
  .main-content form .button__holder .button--hide,
  #lightbox .button__holder .button--hide {
    background-color: #382e75; }
    .main-content form .button__holder .button--hide .icon,
    #lightbox .button__holder .button--hide .icon {
      margin-right: 1rem;
      color: #ffffff !important; }
  .main-content form .button__holder .button--show-more,
  #lightbox .button__holder .button--show-more {
    display: flex;
    border-right: 3px solid #2d255e; }
    .main-content form .button__holder .button--show-more .icon,
    #lightbox .button__holder .button--show-more .icon {
      margin-right: 0.5rem;
      color: #a688b7; }
  .main-content form .button__holder .button--extra, .main-content form .button__holder .button--hide,
  #lightbox .button__holder .button--extra,
  #lightbox .button__holder .button--hide {
    display: none; }
  .main-content form .button__holder #div_id_published .controls,
  #lightbox .button__holder #div_id_published .controls {
    display: flex; }
  .main-content form .button__holder.expanded,
  #lightbox .button__holder.expanded {
    flex-direction: column;
    justify-content: flex-end; }
    .main-content form .button__holder.expanded:before,
    #lightbox .button__holder.expanded:before {
      content: '';
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      opacity: 0.75;
      z-index: -1; }
    .main-content form .button__holder.expanded .button--extra, .main-content form .button__holder.expanded #div_id_is_published, .main-content form .button__holder.expanded #div_id_published,
    #lightbox .button__holder.expanded .button--extra,
    #lightbox .button__holder.expanded #div_id_is_published,
    #lightbox .button__holder.expanded #div_id_published {
      display: flex;
      margin: 0;
      padding: 1rem;
      background-color: #ffffff !important;
      color: #4A4A4A;
      border: 0 !important;
      border-bottom: 3px solid #E4E4E4 !important;
      text-transform: none;
      text-align: left;
      font-size: 1.143rem; }
      .main-content form .button__holder.expanded .button--extra .checkbox, .main-content form .button__holder.expanded .button--extra .checkbox .icon, .main-content form .button__holder.expanded #div_id_is_published .checkbox, .main-content form .button__holder.expanded #div_id_is_published .checkbox .icon, .main-content form .button__holder.expanded #div_id_published .checkbox, .main-content form .button__holder.expanded #div_id_published .checkbox .icon,
      #lightbox .button__holder.expanded .button--extra .checkbox,
      #lightbox .button__holder.expanded .button--extra .checkbox .icon,
      #lightbox .button__holder.expanded #div_id_is_published .checkbox,
      #lightbox .button__holder.expanded #div_id_is_published .checkbox .icon,
      #lightbox .button__holder.expanded #div_id_published .checkbox,
      #lightbox .button__holder.expanded #div_id_published .checkbox .icon {
        padding: 0;
        margin: 0; }
    .main-content form .button__holder.expanded .button--extra .icon,
    #lightbox .button__holder.expanded .button--extra .icon {
      color: #A5A5A5;
      display: inline-block;
      margin-right: 0.75rem;
      height: 1.5rem;
      width: 1.5rem;
      flex-shrink: 0; }
    .main-content form .button__holder.expanded .custom__checkbox .icon,
    #lightbox .button__holder.expanded .custom__checkbox .icon {
      color: #A5A5A5; }
    .main-content form .button__holder.expanded .button--hide,
    #lightbox .button__holder.expanded .button--hide {
      display: flex; }
    .main-content form .button__holder.expanded .button--show-more,
    #lightbox .button__holder.expanded .button--show-more {
      display: none; }
    .main-content form .button__holder.expanded #div_id_is_published, .main-content form .button__holder.expanded #div_id_published,
    #lightbox .button__holder.expanded #div_id_is_published,
    #lightbox .button__holder.expanded #div_id_published {
      order: 1; }
      .main-content form .button__holder.expanded #div_id_is_published .controls, .main-content form .button__holder.expanded #div_id_published .controls,
      #lightbox .button__holder.expanded #div_id_is_published .controls,
      #lightbox .button__holder.expanded #div_id_published .controls {
        display: block; }
    .main-content form .button__holder.expanded .submit,
    #lightbox .button__holder.expanded .submit {
      order: 2; }
    .main-content form .button__holder.expanded label[for=id_published],
    #lightbox .button__holder.expanded label[for=id_published] {
      color: #4A4A4A; }
  @media (min-width: 980px) {
    .main-content form .button__holder,
    #lightbox .button__holder {
      flex-flow: row nowrap;
      justify-content: flex-start;
      margin-top: 2rem;
      padding-top: 2.5rem;
      position: relative;
      align-items: center;
      background-color: #ffffff;
      color: #4A4A4A;
      height: initial;
      z-index: 0; }
      .main-content form .button__holder.desktop-only,
      #lightbox .button__holder.desktop-only {
        display: flex; }
      .main-content form .button__holder input,
      #lightbox .button__holder input {
        background-color: #ffffff; }
      .main-content form .button__holder .button--extra,
      #lightbox .button__holder .button--extra {
        display: inline-block; }
        .main-content form .button__holder .button--extra:not(.urgent-delete) .icon,
        #lightbox .button__holder .button--extra:not(.urgent-delete) .icon {
          display: none; }
      .main-content form .button__holder .button--show-more,
      #lightbox .button__holder .button--show-more {
        display: none; }
      .main-content form .button__holder:before,
      #lightbox .button__holder:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 1rem;
        width: calc(100% + 2rem);
        background: #E4E4E4; }
        .narrow.padded .main-content form .button__holder:before, .narrow.padded
        #lightbox .button__holder:before {
          width: calc(100% + 2rem); } }
      @media all and (min-width: 980px) and (min-width: 768px) {
        .narrow.padded .main-content form .button__holder:before, .narrow.padded
        #lightbox .button__holder:before {
          width: calc(100% + 3rem);
          left: calc(50% - 0.5rem); } }
  @media (min-width: 980px) {
        body.admin-view .main-content form .button__holder:before, body.admin-view
        #lightbox .button__holder:before {
          background: #F1F1F1; } }
    @media (min-width: 980px) and (min-width: 980px) {
      .main-content form .button__holder:before,
      #lightbox .button__holder:before {
        width: calc(100% + 4rem); } }
  .main-content form .button__holder .delete,
  #lightbox .button__holder .delete {
    margin-right: 1rem;
    line-height: 1.15; }
  .main-content form .button__holder .copy,
  #lightbox .button__holder .copy {
    line-height: 1.15;
    margin-right: 1rem; }
  .main-content form .button__holder .control-group#div_id_published .checkbox,
  #lightbox .button__holder .control-group#div_id_published .checkbox {
    margin-left: 0;
    margin-right: 1rem; }
  @media (min-width: 980px) {
    .main-content form .button__holder .control-group,
    #lightbox .button__holder .control-group {
      width: 100%; }
      .main-content form .button__holder .control-group#div_id_published .checkbox,
      #lightbox .button__holder .control-group#div_id_published .checkbox {
        margin-right: 0; } }

.number_of_candidates {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }
  .number_of_candidates .control-group {
    width: 100%; }
    .number_of_candidates .control-group input {
      border-left: none !important;
      border-right: none !important;
      text-align: center; }

#image-help {
  background-color: #a688b7;
  color: #ffffff;
  font-size: 1rem;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 700;
  text-transform: none;
  border-radius: 2rem;
  border-color: #a688b7;
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  margin-left: 1rem;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" xml:space="preserve" aria-hidden="true" fill="%23ffffff"><g><g><g><path d="M11,12.3V13c0,0-1.8,0-2,0v-0.6c0-0.6,0.1-1.4,0.8-2.1c0.7-0.7,1.6-1.2,1.6-2.1c0-0.9-0.7-1.4-1.4-1.4 c-1.3,0-1.4,1.4-1.5,1.7H6.6C6.6,7.1,7.2,5,10,5c2.4,0,3.4,1.6,3.4,3C13.4,10.4,11,10.8,11,12.3z"></path><circle cx="10" cy="15" r="1"></circle></g><path d="M10,2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.6,2,10,2 M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0 L10,0z"></path></g></g></svg>');
  background-repeat: no-repeat;
  background-position: 10px 5.5px; }
  @media (min-width: 768px) {
    #image-help {
      background-position: 13px 7.5px;
      margin-left: 1.5rem; } }
  #image-help:hover {
    background-color: #8B63A1;
    border-color: #8B63A1; }

.image-help-container {
  display: flex;
  align-items: center; }

.video-select {
  display: grid;
  gap: 1rem; }
  @media (min-width: 640px) {
    .video-select {
      grid-template-columns: 1fr 1fr; } }
  @media (min-width: 1280px) {
    .video-select {
      grid-template-columns: 1fr 1fr 1fr; } }

.video-select__item label {
  display: flex !important;
  text-transform: none !important;
  font-size: 1rem !important;
  margin-top: 0.75rem; }

.video-select__control {
  width: 1.5rem;
  height: 1.5rem;
  border: 3px solid #E4E4E4;
  margin-right: 0.75rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .video-select__control .icon {
    display: none;
    color: #a688b7; }

.video-select__item input:checked + .video-select__control .icon {
  display: block; }

.image-select {
  display: grid;
  gap: 1rem; }
  @media (min-width: 640px) {
    .image-select {
      grid-template-columns: 1fr 1fr; } }
  @media (min-width: 1280px) {
    .image-select {
      grid-template-columns: 1fr 1fr 1fr; } }
  .image-select img {
    max-width: 210px;
    width: 100%;
    max-height: 160px;
    height: 100%;
    object-fit: cover; }

#div_id_hcaptcha label {
  display: none; }

.location-image__preview {
  position: relative; }
  .location-image__preview .button {
    position: absolute;
    top: 0;
    left: 0; }
  @media all and (min-width: 981px) {
    .location-image__preview {
      position: relative;
      width: 100%;
      margin-left: 0; } }
  @media all and (max-width: 540px) {
    .location-image__preview {
      display: none; } }

@media all and (min-width: 541px) and (max-width: 980px) {
  .billboard__side + .location-image__preview {
    position: absolute;
    display: block;
    left: 50%;
    bottom: -7rem;
    z-index: 20;
    transform: translateX(-50%); }
    .has-church-image .billboard__side + .location-image__preview {
      position: absolute;
      top: 3rem;
      left: 1rem;
      transform: none; }
      .has-church-image .billboard__side + .location-image__preview .church__image-carousel {
        width: 9rem; }
    .has-church-image.billboard__side--page-detail-small .billboard__side + .location-image__preview {
      width: 1000px; } }

@media all and (min-width: 541px) and (max-width: 980px) {
  .has-church-image .billboard__side--page-detail + .location-image__preview {
    display: none; } }

.church__image {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  flex-shrink: 0;
  background-color: #fff;
  background-position: center center;
  background-size: 100%;
  background-repeat: no-repeat;
  box-shadow: inset 0px 0px 0px 3px rgba(0, 0, 0, 0.05); }

.church__logo-holder {
  width: 100%;
  background-color: #fff;
  padding: 4px; }
  @media all and (min-width: 981px) {
    .church__logo-holder {
      padding: 1.25rem;
      border-top: 3px solid #E4E4E4; } }

.church__logo {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center; }
  @media all and (min-width: 981px) {
    .church__logo {
      padding-bottom: 66%; } }
  .church__logo.active {
    transform: translateX(-100%); }

.church__image-carousel {
  display: none;
  width: 10rem;
  margin: auto;
  overflow: hidden;
  background: #fff;
  position: relative; }
  @media all and (min-width: 541px) {
    .church__image-carousel {
      display: flex; } }
  @media all and (min-width: 981px) {
    .church__image-carousel {
      width: 100%;
      border: none; } }

.church__image-controls {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex; }
  .church__image-controls .pip {
    width: 10px;
    height: 10px;
    margin: 0 0.33333rem;
    border: 1px solid #fff;
    transform: rotate(-45deg);
    cursor: pointer;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25), inset 0px 0px 4px 1px rgba(0, 0, 0, 0.25); }
    .church__image-controls .pip.active {
      background: #fff;
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25); }

.location-intro {
  color: #4A4A4A;
  display: block;
  position: absolute;
  height: auto;
  padding-bottom: 0;
  margin-bottom: 0; }

.location-intro__image {
  display: block;
  position: absolute;
  height: auto;
  padding-bottom: 0;
  margin-bottom: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .church-page__main .location-intro__image {
    top: 1rem; }
  .location-intro__image:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(56, 46, 117, 0.625); }
  .location-intro__image--christmas {
    max-height: 527px;
    height: 100%; }
    .location-intro__image--christmas:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(149, 49, 122, 0.625); }
  @media all and (max-width: 980px) {
    .location-intro__image {
      display: none; } }

.stats__bar {
  font-family: "Cabin", Arial, sans-serif;
  font-size: 1.25rem; }
  .stats__bar .icon {
    color: #E4E4E4;
    margin-right: 1rem; }
  @media all and (min-width: 768px) {
    .stats__bar {
      color: #E8A065; }
      .stats__bar .stats span {
        color: #FFFFFF; } }
  @media all and (min-width: 981px) {
    .stats__bar {
      display: flex;
      flex-flow: row nowrap;
      align-items: center; }
      .stats__bar .stats {
        margin: auto; }
        .stats__bar .stats span {
          font-size: 1.25em;
          vertical-align: middle; }
        .stats__bar .stats + .stats {
          padding-left: 1rem; }
      .stats__bar .stats:first-of-type {
        margin-left: 0; } }

.calendar {
  padding: 1rem; }
  .calendar .prev__month > *,
  .calendar .next__month > * {
    opacity: 0.2; }
  .calendar .event {
    width: 100%;
    padding: 0.25rem 0.5rem;
    background: #a688b7;
    color: #FFFFFF;
    display: block;
    text-decoration: none;
    line-height: 1.5; }
    .calendar .event + .event {
      margin-top: 2px; }
    .calendar .event.school {
      background: #6C93CE; }
    .calendar .event.communion {
      background: #6FBEC6; }
  @media all and (min-width: 768px) {
    .calendar {
      padding: 0; }
      .calendar .event {
        font-size: 0.75rem; } }

.calendar__days {
  display: flex;
  flex-flow: column nowrap; }
  @media all and (min-width: 768px) {
    .calendar__days {
      flex-flow: row wrap; }
      .calendar__days > div {
        display: flex;
        flex-flow: column nowrap;
        width: calc(100%/7.001);
        border-bottom: 3px solid #F5F5F5;
        border-right: 3px solid #F5F5F5;
        min-height: 5rem; }
        .calendar__days > div:nth-of-type(7n) {
          border-right: none; }
      .calendar__days .day__label {
        padding: 0.25rem 0.5rem; } }
  @media all and (max-width: 767px) {
    .calendar__days > div + div {
      margin-top: 1.5rem;
      padding-top: 1.5rem;
      border-top: 3px solid #F5F5F5; }
    .calendar__days .no__events {
      display: none; }
    .calendar__days .day__label {
      display: block;
      margin-bottom: 0.5rem; } }

.calendar__panel-intro {
  padding: 0 2rem; }

@media all and (min-width: 768px) {
  .month__label {
    display: none; } }

.calendar__labels {
  display: none;
  flex-flow: row wrap;
  font-size: 0.875rem;
  font-weight: 600;
  border-bottom: 3px solid #F5F5F5; }
  .calendar__labels > div {
    width: calc(100%/7.001);
    padding: 0.5rem; }
  @media all and (min-width: 768px) {
    .calendar__labels {
      display: flex; } }

.calendar__header-option-area {
  position: relative;
  width: 100%;
  margin: -1rem 0 1rem; }

@media all and (max-width: 1199px) {
  .calendar__header-option-flex-first {
    display: none; } }

.calendar__controls {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 1rem; }
  @media (max-width: 767px) {
    .calendar__controls {
      padding-left: 0; } }
  .calendar__controls a .icon {
    padding: 0.25rem;
    width: 2rem;
    height: 2rem;
    border: 3px solid #E8A065;
    vertical-align: top; }
  .calendar__controls .calendar__month {
    margin: 0 1rem;
    font-size: 1.5em;
    font-family: "Cabin", Arial, sans-serif; }
    @media (max-width: 399px) {
      .calendar__controls .calendar__month {
        font-size: 1.3em;
        margin: 0 0.5rem; } }

.church__meta + .church__meta {
  margin-top: 0.75rem; }

@media all and (min-width: 481px) {
  .church__meta {
    display: flex;
    align-items: baseline; } }

.church__meta-key {
  color: #a688b7;
  width: 11rem;
  margin-right: 1rem;
  flex-shrink: 0;
  text-transform: uppercase;
  font-weight: 300; }

.church__meta-value {
  margin-top: -0.25rem; }

.church__meta-wrapper + .church__meta-wrapper {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 3px solid #F5F5F5; }

.file__attachment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  padding-top: 1rem;
  border-top: 3px solid #F5F5F5;
  margin-top: 1.5rem; }
  .file__attachment h4 {
    min-width: 0;
    display: inline-flex;
    align-items: center;
    margin-bottom: 0; }
    .file__attachment h4 span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
      margin-right: 0.75rem; }
    .file__attachment h4 .icon {
      width: 2rem;
      height: 2rem;
      padding: 0.125rem;
      border-radius: 50%;
      border: 3px solid #A5A5A5;
      color: #A5A5A5;
      margin-right: 0.75rem;
      flex-shrink: 0; }

.table__edit,
.table__inbox {
  width: 2.5rem;
  text-align: center; }
  .table__edit .table__edit__stat,
  .table__inbox .table__edit__stat {
    text-align: left;
    margin: 0;
    font-size: 0.875rem !important;
    width: 5rem; }
    .table__edit .table__edit__stat span,
    .table__inbox .table__edit__stat span {
      display: inline-block;
      width: 6.5rem !important;
      text-transform: uppercase;
      color: #979797; }
    @media all and (min-width: 400px) {
      .table__edit .table__edit__stat,
      .table__inbox .table__edit__stat {
        width: 10.5rem; } }

.table__name {
  font-size: 1.25rem;
  font-family: "Cabin", Arial, sans-serif; }
  .table__name .tag__list {
    margin-bottom: 0; }
  .table__name a {
    text-decoration: none; }

.table__complete {
  text-align: center; }

.table__views {
  white-space: nowrap;
  text-transform: uppercase; }
  .table__views span {
    display: inline-block;
    width: 6.5rem;
    color: #A5A5A5;
    font-weight: 600; }

.table th:not(.table__name) {
  text-transform: uppercase;
  font-weight: 600; }

.table__complete,
.table__views,
.table__events {
  display: none; }

.table .table__inbox {
  text-align: center; }
  .table .table__inbox .unread {
    background: #DE2F62;
    border-radius: 1rem;
    padding: 0.25rem 0.5rem;
    color: #fff; }

.table__church-name {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.25rem 0; }
  .table__church-name .icon {
    flex-shrink: 0;
    color: #A5A5A5;
    margin-left: 0.5rem; }

td.table__events {
  text-transform: uppercase; }
  td.table__events a {
    display: flex;
    align-items: center;
    text-decoration: none; }
    td.table__events a:hover {
      text-decoration: underline; }
    td.table__events a + a {
      margin-top: 0.5rem; }
  td.table__events .icon {
    color: #A5A5A5;
    margin-right: 0.5rem; }

@media all and (min-width: 768px) {
  .table__name {
    width: 100%; }
  .table__complete,
  .table__views,
  .table__events {
    display: table-cell; } }

.user__table-entry {
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-template-columns: 1fr 1fr;
  border-top: 3px solid #F5F5F5;
  padding: 0.5rem; }
  .user__table-entry > div {
    width: 100%; }
  .user__table-entry .icon {
    flex-shrink: 0; }
  .user__table-entry.accept__set {
    background: rgba(163, 194, 127, 0.125); }
    .user__table-entry.accept__set > div {
      border-color: #A3C27F; }
  .user__table-entry.delete__set {
    background: rgba(222, 47, 98, 0.125); }
    .user__table-entry.delete__set > div {
      border-color: #DE2F62; }
  .user__table-entry:last-of-type {
    border-bottom: 3px solid #F5F5F5; }
  .user__table-entry .user__table-level {
    grid-column: span 2; }
  .user__table-entry .user__table-controls {
    width: auto;
    margin-left: auto; }
  .user__table-entry .user__table-permissions {
    padding: 0;
    display: flex;
    align-items: center; }
    .user__table-entry .user__table-permissions .custom__select-holder {
      flex-grow: 1;
      max-width: 12rem; }
    .user__table-entry .user__table-permissions .custom__select-toggle-text {
      border: 0px; }
    .user__table-entry .user__table-permissions .toggle-icon__holder {
      border: 0px; }
    .user__table-entry .user__table-permissions .icon {
      fill: #A5A5A5; }

.user__table-name {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
  .user__table-name .icon {
    margin-right: 0.5rem; }

.user__table-name-email {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-column: span 2; }

@media all and (min-width: 768px) {
  .user__table-entry {
    padding: 0;
    grid-template-columns: 1fr 190px min-content min-content; }
    .user__table-entry > div {
      width: auto;
      padding: 1rem; }
    .user__table-entry > div + div {
      border-left: 3px solid #F5F5F5; }
    .user__table-entry .user__table-level {
      width: auto;
      grid-column: span 1; }
    .user__table-entry .user__table-controls {
      max-width: 6rem;
      flex-grow: 0; }
    .user__table-entry .user__table-email,
    .user__table-entry .user__table-level {
      width: 10rem;
      flex-shrink: 0; }
    .user__table-entry .user__table-email {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 0.875rem;
      margin-left: auto; }
  .user__table-name-email {
    grid-column: 1; } }

@media (min-width: 980px) {
  .user__table-entry {
    grid-template-columns: 1fr min-content; }
    .user__table-entry > div + div {
      border-left: 0; }
    .user__table-entry .user__table-level {
      width: auto; }
    .user__table-entry .user__table-level,
    .user__table-entry .user__table-name-email {
      grid-column: span 2; } }

@media (min-width: 1280px) {
  .user__table-entry {
    grid-template-columns: 1fr 190px min-content min-content; }
    .user__table-entry > div + div {
      border-left: 3px solid #F5F5F5; }
    .user__table-entry .user__table-level {
      width: 10rem; }
    .user__table-entry .user__table-level,
    .user__table-entry .user__table-name-email {
      grid-column: span 1; } }

.user__table-name a,
.user__table-email a,
.user__table-level a {
  text-decoration: none; }

.user__table-name {
  white-space: nowrap; }

.user__message {
  font-style: italic;
  font-size: 0.875rem; }

.user__table-level {
  margin: 0; }

.user__table-controls {
  display: flex;
  justify-content: flex-end; }

.accept__toggle {
  margin-right: 0.75rem; }

.accept__toggle,
.delete__toggle {
  cursor: pointer; }
  .accept__toggle .icon,
  .delete__toggle .icon {
    vertical-align: middle;
    width: 1.5rem;
    height: 1.5rem;
    fill: #A5A5A5; }

.icon--editor-1 {
  fill: #61b1b9; }

.icon--editor-2 {
  fill: #E8A065; }

.icon--editor-3 {
  fill: #a688b7; }

.icon--editor-4 {
  fill: #6C93CE; }

.church-list {
  display: flex;
  flex-flow: row wrap;
  margin: -1rem;
  padding: 0; }

.church-list__item {
  flex: 0 0 100%;
  list-style-type: none; }
  @media all and (min-width: 600px) {
    .church-list__item {
      flex: 0 0 50%; } }
  @media all and (min-width: 1341px) {
    .church-list--3-up .church-list__item {
      flex: 0 0 33.3333%; } }

.church-list__link {
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  align-items: flex-start;
  text-decoration: none;
  margin: 1rem;
  margin-bottom: 1.5rem;
  min-height: 7.5rem; }
  .church-list__link h4 {
    margin: 0 0.75rem 0 0; }
  .church-list__link p {
    font-size: 0.75rem;
    margin: 0.25rem 0.75rem 1rem 0;
    line-height: 1.5; }
  .church-list__link .church-list__item-diocese {
    text-transform: uppercase;
    margin-top: 0.125rem;
    font-size: 0.875rem;
    margin-bottom: 0; }

.church-list__item-details {
  padding-right: 0.5rem;
  word-break: break-word;
  width: 100%; }

.church-list__item-image {
  display: none;
  width: 5rem;
  height: 5rem;
  border: 3px solid #F5F5F5;
  position: relative;
  margin: 0.5rem 0 0.5rem 0.5rem; }
  @media all and (min-width: 360px) {
    .church-list__item-image {
      display: block;
      float: right; } }
  @media all and (min-width: 45px) {
    .church-list__item-image {
      width: 7.5rem;
      height: 7.5rem; } }
  @media all and (min-width: 600px) and (max-width: 981px) {
    .church-list__item-image {
      width: 5rem;
      height: 5rem; } }

.church-list__image-placeholder {
  opacity: 0.75;
  background: url(../img/cross.svg) center center/75% no-repeat; }

.gallery-preview__container-holder {
  position: relative; }
  @media (min-width: 981px) {
    .gallery-preview__container-holder {
      display: none; } }
  .mobile__navigation .gallery-preview__container-holder {
    display: none; }
  .gallery-preview__container-holder.gallery__has-imgs {
    border-top: 3px solid #E4E4E4;
    border-bottom: 3px solid #E4E4E4;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem; }
  .acny-as-homepage__wrapper .gallery-preview__container-holder {
    border: none !important;
    padding-top: 1rem;
    padding-bottom: 0; }
  .gallery-preview__container-holder + .side__navigation :first-child,
  .gallery-preview__container-holder + .side__navigation .active:first-child {
    border-top-width: 0; }
  @media (min-width: 980px) {
    .gallery-preview__container-holder:not(.gallery__has-imgs) .gallery__has-imgs-mobile {
      border-bottom: 3px solid #F5F5F5; } }
  @media (max-width: 540px) {
    .gallery-preview__container-holder:not(.gallery__has-imgs) .gallery__has-imgs-mobile {
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
      border-bottom: 3px solid #F5F5F5; } }
  .location-image__preview + .gallery-preview__container-holder:not(.gallery__has-imgs) {
    padding-top: 0;
    margin-top: 0.5rem; }
  @media (max-width: 979px) {
    .gallery-preview__container-holder.gallery__hide-mobile {
      display: none; } }
  @media (min-width: 981px) {
    .gallery-preview__container-holder.gallery__has-imgs {
      border-top: 3px solid #E4E4E4; } }
  @media (max-width: 980px) {
    .gallery-preview__container-holder {
      background-color: #fff; }
      .gallery-preview__container-holder .navlist__item-wrapper {
        padding-left: 0.5rem; }
      .gallery-preview__container-holder .gallery-preview__image-wrapper:first-child {
        margin-left: 0.5rem; } }
  .gallery-preview__container-holder .card__edit {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    margin-top: 0.5rem;
    background-color: #fff;
    color: #4A4A4A;
    right: 0; }
    .gallery-preview__container-holder .card__edit:hover {
      color: #a688b7 !important; }
    .gallery-preview__container-holder .card__edit.empty {
      margin-top: 0 !important; }
      .gallery-preview__container-holder .card__edit.empty ~ a {
        text-decoration: none; }
    .gallery-preview__container-holder .card__edit svg {
      margin: 0.3rem; }
  @media (min-width: 541px) {
    .gallery-preview__container-holder .gallery__has-imgs-mobile:not(.gallery__has-imgs) + .card__edit {
      display: none; } }
  @media (min-width: 541px) {
    .gallery-preview__container-holder .gallery-preview__logo {
      display: none; } }
  .side-content .gallery-preview__container-holder {
    display: block; }
    @media (max-width: 981px) {
      .side-content .gallery-preview__container-holder {
        display: none; } }

.gallery-preview__container {
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none !important;
  cursor: pointer;
  font-family: "Cabin", Arial, sans-serif; }
  @media (min-width: 980px) {
    .gallery-preview__container .navlist__mobile-hidden:last-of-type {
      border-bottom-width: 0px; } }
  @media (max-width: 540px) {
    .gallery-preview__container .navlist__mobile-hidden:last-of-type {
      display: none; } }
  .gallery-preview__container:not(.gallery__has-imgs) .navlist__item-wrapper {
    font-size: 1.25rem; }
  .gallery-preview__container a {
    text-decoration: none; }
  .gallery-preview__container .navlist__item {
    width: 100%; }
  .gallery-preview__container a.gallery-preview__image-wrapper {
    position: relative;
    height: 4.5rem;
    margin-right: 0;
    font-size: 1.25rem; }
    .gallery-preview__container a.gallery-preview__image-wrapper img.gallery-preview__image {
      height: 100%;
      width: auto;
      margin-right: 0.5rem; }
    .gallery-preview__container a.gallery-preview__image-wrapper .icon.preview {
      position: absolute;
      bottom: 0.25rem;
      right: 1rem;
      fill: #FFFFFF;
      z-index: 21; }
    .gallery-preview__container a.gallery-preview__image-wrapper:focus {
      outline: none; }
      .gallery-preview__container a.gallery-preview__image-wrapper:focus:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0.5rem;
        box-shadow: 0 0 10px #9ecaed;
        border: 3px #9ecaed solid; }
    .acny-as-homepage__wrapper .gallery-preview__container a.gallery-preview__image-wrapper {
      height: 11rem; }

.gallery-flex {
  display: flex;
  flex-flow: row wrap; }
  .gallery-flex .gallery-upload {
    height: 22rem;
    margin: 0 1rem 1.5rem;
    padding: 2rem 1rem;
    border: 3px solid #E4E4E4;
    text-align: center; }
    .gallery-flex .gallery-upload p:last-child {
      position: absolute;
      bottom: 1rem;
      left: 0.66667rem;
      right: 0.66667rem; }
  .gallery-flex .gallery-card {
    display: flex;
    padding: 1rem;
    border-top: 3px solid #E4E4E4; }
    .gallery-flex .gallery-card .gallery-card__image {
      height: 10rem;
      width: 10rem;
      flex-shrink: 0;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      border: 3px solid #E4E4E4; }
    .gallery-flex .gallery-card .gallery-card__filename {
      word-break: break-word; }
    .gallery-flex .gallery-card .gallery-card__caption {
      padding: 0 0.5rem;
      flex-grow: 1; }
    .gallery-flex .gallery-card .actions-container .drag-action {
      display: none; }
    .gallery-flex .gallery-card .actions-container .card__edit {
      position: relative;
      top: 0;
      right: 0;
      text-decoration: none; }
      .gallery-flex .gallery-card .actions-container .card__edit .icon {
        color: #A5A5A5;
        height: 1.5rem;
        width: 1.5rem; }
    .gallery-flex .gallery-card .gallery-card__ordering-buttons {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .gallery-flex .gallery-card .gallery-card__ordering-buttons .icon {
        padding: 1.5rem 0.5rem;
        width: 2.5rem;
        height: 4.5rem; }
    .gallery-flex .gallery-card:first-child .order-up, .gallery-flex .gallery-card:last-child .order-down {
      color: #A5A5A5; }
    @media (min-width: 980px) {
      .gallery-flex .gallery-card {
        display: block;
        margin: 0 1rem 1.5rem;
        padding: 0;
        border: 0; }
        .gallery-flex .gallery-card .gallery-card__image {
          height: 15rem;
          width: 100%;
          background-color: #4A4A4A;
          border: 0; }
        .gallery-flex .gallery-card .gallery-card__filename {
          display: none; }
        .gallery-flex .gallery-card .gallery-card__caption {
          border: 3px solid #E4E4E4;
          border-top: 0;
          height: 7rem;
          overflow: scroll;
          padding: 0.5rem; }
          .gallery-flex .gallery-card .gallery-card__caption p {
            margin-top: 0; }
        .gallery-flex .gallery-card .actions-container {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          flex-direction: column; }
          .gallery-flex .gallery-card .actions-container .drag-action {
            display: inline; }
          .gallery-flex .gallery-card .actions-container .card__edit {
            top: 0.5rem;
            right: 0.5rem; }
            .gallery-flex .gallery-card .actions-container .card__edit .icon {
              width: 1rem;
              height: 1rem;
              color: #E4E4E4; }
        .gallery-flex .gallery-card .gallery-card__ordering-buttons {
          display: none; } }
  .gallery-flex .gallery-upload,
  .gallery-flex .gallery-card,
  .gallery-flex .draggable-placeholder {
    position: relative;
    width: 100%; }
    @media all and (min-width: 980px) {
      .gallery-flex .gallery-upload,
      .gallery-flex .gallery-card,
      .gallery-flex .draggable-placeholder {
        width: calc(33% - 1rem);
        margin: 0 0.5rem 1.5rem; } }
    @media all and (min-width: 1024px) {
      .gallery-flex .gallery-upload,
      .gallery-flex .gallery-card,
      .gallery-flex .draggable-placeholder {
        width: calc(33% - 2rem);
        margin: 0 1rem 1.5rem; } }
    @media all and (min-width: 1366px) {
      .gallery-flex .gallery-upload,
      .gallery-flex .gallery-card,
      .gallery-flex .draggable-placeholder {
        width: calc(25% - 2rem); } }
  @media (min-width: 980px) {
    .gallery-flex {
      margin: 0 -1rem; }
      .gallery-flex:before {
        width: calc(100% + 2rem) !important; } }

.draggable-placeholder {
  position: relative;
  border: 3px dotted #E8A065;
  height: 22rem; }

.christmas-branding.church-base, .christmas-branding.church-detail, .christmas-branding.church-page__events, .christmas-branding.event-detail, .christmas-branding.login-view,
.christmas-branding.login-view .account-page__wrapper,
.christmas-branding .church-page__wrapper {
  background-color: #003A5D; }

.christmas-branding .location-intro__image::after {
  background: rgba(0, 58, 93, 0.625); }

@media (min-width: 980px) {
  .christmas-branding .event__intro,
  .christmas-branding .news__intro {
    margin-top: 5rem !important; } }

@media (min-width: 1033px) {
  .christmas-branding .event__intro,
  .christmas-branding .news__intro {
    margin-top: 2rem !important; } }

.christmas-branding .church-page__wrapper {
  overflow: visible;
  background: url(../img/stars_right.svg) 90% -10rem/280px repeat-y; }
  @media (min-width: 980px) {
    .christmas-branding .church-page__wrapper {
      background: url(../img/stars_right.svg) 90% 4rem/340px repeat-y, url(../img/stars_left.svg) 22rem 4rem/380px repeat-y; } }
  @media (min-width: 1280px) {
    .christmas-branding .church-page__wrapper {
      background: url(../img/stars_right.svg) 95% 4rem/340px repeat-y, url(../img/stars_left.svg) 28rem 4rem/380px repeat-y; } }
  @media (min-width: 980px) {
    .christmas-branding .church-page__wrapper .side-content {
      margin-top: -5rem;
      padding-top: 14rem; } }
  .christmas-branding .church-page__wrapper .church__logo-holder {
    border-top: none; }
  .christmas-branding .church-page__wrapper .billboard__panel--page-detail {
    background-color: #003A5D; }

.christmas-branding .christmas-heading-row {
  background-color: #003A5D; }

@media all and (min-width: 980px) {
  .christmas-branding .church-page__main {
    padding: 7rem 0 1rem 1rem; } }

.christmas-branding.admin-view .church-page__wrapper .side-content {
  margin-top: 0;
  padding-top: 0; }

@media all and (min-width: 980px) {
  .christmas-branding.admin-view .church-page__main {
    padding-top: 0; } }

.christmas-branding.admin-view .benefice-page__wrapper {
  margin-top: 0; }

.christmas-branding.admin-view .mobile-navbar {
  background: #004874; }
  .christmas-branding.admin-view .mobile-navbar .mobile__toggle {
    border-left-color: #003A5D; }
  .christmas-branding.admin-view .mobile-navbar .mobile-navbar__back-button a {
    border-right: 3px solid #003A5D; }

.christmas-branding .benefice-page__wrapper,
.christmas-branding.benefice-page .church-page__wrapper {
  background: #382e75; }
  .christmas-branding .benefice-page__wrapper .location-intro__image::after,
  .christmas-branding.benefice-page .church-page__wrapper .location-intro__image::after {
    background: rgba(56, 46, 117, 0.625); }
  .christmas-branding .benefice-page__wrapper .billboard__panel--page-detail,
  .christmas-branding.benefice-page .church-page__wrapper .billboard__panel--page-detail {
    background-color: #382e75; }
  @media (min-width: 980px) {
    .christmas-branding .benefice-page__wrapper,
    .christmas-branding.benefice-page .church-page__wrapper {
      margin-top: -3rem;
      padding-top: 5rem; } }

@media (min-width: 980px) {
  .christmas-branding .resource-page__wrapper,
  .christmas-branding .tag-group-page .main-content,
  .christmas-branding.admin-analytics .page__wrapper,
  .christmas-branding.my-churches .page__wrapper,
  .christmas-branding.accounts .account-page__wrapper {
    padding-top: 6rem; } }

.christmas-branding.login-view .account-page__wrapper {
  padding-top: 3rem;
  padding-bottom: 3rem; }
  @media (min-width: 980px) {
    .christmas-branding.login-view .account-page__wrapper {
      padding-top: 6rem;
      padding-bottom: 5rem;
      background: url(../img/stars_right.svg) 95% 4rem/340px no-repeat, url(../img/stars_left.svg) 5% 4rem/380px no-repeat; } }
  .christmas-branding.login-view .account-page__wrapper .main-content form {
    padding-bottom: 1rem; }

@media (min-width: 980px) {
  .christmas-branding.event-detail .page-header {
    background-color: #003A5D; } }

.church-page__events .action-pebble .icon {
  color: #E7AA22;
  vertical-align: middle; }

.church-page__christmas-heading {
  width: 100%;
  color: #ffffff; }
  .church-page__christmas-heading h1 {
    margin-bottom: 1rem; }
    .church-page__christmas-heading h1:after {
      background: #E7AA22; }
  .church-page__christmas-heading .christmas-heading-row {
    display: flex;
    flex-flow: column-reverse wrap;
    align-items: center; }
    @media (min-width: 640px) {
      .church-page__christmas-heading .christmas-heading-row {
        flex-flow: row nowrap;
        align-items: flex-start; } }
    @media (min-width: 1280px) {
      .church-page__christmas-heading .christmas-heading-row {
        align-items: center; } }
    .church-page__christmas-heading .christmas-heading-row img {
      padding: 2rem; }
  .church-page__christmas-heading .christmas-heading {
    padding: 2rem 0 4rem 1rem; }
  .church-page__christmas-heading .christmas-intro {
    font-size: 1.375rem;
    line-height: 2.03125rem; }

.christmas__content .search__form-body .padded-text, .christmas__search-form-container .padded-text {
  padding: 0 2rem; }
  @media (min-width: 980px) {
    .christmas__content .search__form-body .padded-text, .christmas__search-form-container .padded-text {
      padding: 0 5rem; } }
  .christmas__content .search__form-body .padded-text.search-text, .christmas__search-form-container .padded-text.search-text {
    font-family: "Open Sans", Arial, sans-serif; }

.christmas-events .page-header .cofe__navigation ul a {
  color: #ffffff; }

.christmas-events .page-header .primary__navigation {
  margin-bottom: 0; }

.christmas-events .search__bar,
.christmas-events .custom__select-toggle-text,
.christmas-events .toggle-icon__holder,
.christmas-events .custom__field-value,
.christmas-events .custom__field-icon,
.christmas-events .textInput,
.christmas-events .textInput:focus {
  border-color: #E4E4E4;
  border-width: 3px;
  background-color: #ffffff; }
  .christmas-events .search__bar .icon,
  .christmas-events .custom__select-toggle-text .icon,
  .christmas-events .toggle-icon__holder .icon,
  .christmas-events .custom__field-value .icon,
  .christmas-events .custom__field-icon .icon,
  .christmas-events .textInput .icon,
  .christmas-events .textInput:focus .icon {
    color: #000000; }

.christmas-events .search__bar {
  margin-bottom: 1rem; }

.christmas-events .custom__select-toggle-text {
  padding: 0.75rem 1rem; }

.christmas-events label[for="id_event_type"],
.christmas-events label[for="id_time_0"] {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.christmas-events #div_id_date {
  margin-top: 1rem; }

.christmas-events #div_id_time {
  margin-top: 1rem; }
  .christmas-events #div_id_time .controls {
    display: flex;
    align-items: center;
    gap: 0.75rem; }

.christmas-events #div_id_distance input {
  appearance: auto; }

.christmas-events #div_id_distance + p {
  margin-top: -0.5rem;
  font-size: 0.75rem; }

.christmas-events #div_id_distance,
.christmas-events #div_id_event_type,
.christmas-events #div_id_date_range {
  margin-top: 2rem; }
  .christmas-events #div_id_distance label,
  .christmas-events #div_id_event_type label,
  .christmas-events #div_id_date_range label {
    font-size: 1.5rem;
    font-family: "Cabin", Arial, sans-serif; }
    @media (min-width: 980px) {
      .christmas-events #div_id_distance label,
      .christmas-events #div_id_event_type label,
      .christmas-events #div_id_date_range label {
        font-size: 2rem; } }
  .christmas-events #div_id_distance .controls,
  .christmas-events #div_id_event_type .controls,
  .christmas-events #div_id_date_range .controls {
    margin-top: 0.5rem; }

.christmas-events #div_id_event_type .controls {
  max-height: 8rem;
  overflow-y: scroll; }
  @media (min-width: 980px) {
    .christmas-events #div_id_event_type .controls {
      max-height: 18rem; } }

.christmas-events #div_id_event_type .controls__checkbox .checkbox {
  margin-left: 0; }

.christmas-events #div_id_event_type input[type="checkbox"] {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.christmas-events #div_id_event_type .custom__checkbox .icon {
  color: #004874; }

.christmas-events #div_id_date_range .custom__field-icon:last-child {
  border-color: #E7AA22; }

.christmas-events #div_id_date label,
.christmas-events #div_id_groups_and_activities label {
  display: none;
  margin-top: 3rem;
  font-size: 2rem;
  font-family: "Cabin", Arial, sans-serif;
  margin-bottom: 1rem; }
  @media (min-width: 980px) {
    .christmas-events #div_id_date label,
    .christmas-events #div_id_groups_and_activities label {
      display: block; } }

.christmas-events #div_id_groups_and_activities {
  display: none; }
  @media (min-width: 980px) {
    .christmas-events #div_id_groups_and_activities {
      display: block; } }
  .christmas-events #div_id_groups_and_activities .taglinkwidget {
    padding: 0.2rem 0;
    list-style: none; }
    .christmas-events #div_id_groups_and_activities .taglinkwidget li a {
      text-decoration: none; }
    .christmas-events #div_id_groups_and_activities .taglinkwidget li.selected {
      background-color: #004874;
      color: #ffffff; }
    .christmas-events #div_id_groups_and_activities .taglinkwidget li:hover {
      background-color: #004874;
      border-color: #004874;
      color: #ffffff; }

.christmas-events .side-content .h3 {
  font-size: 1.5rem; }
  @media (min-width: 980px) {
    .christmas-events .side-content .h3 {
      font-size: 2.25rem;
      margin-bottom: 1rem; } }

.christmas-events .side-content .submit {
  color: #004874;
  font-weight: 700;
  background-color: #E7AA22;
  border-color: #E7AA22;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
  font-family: "Open Sans", Arial, sans-serif; }
  .christmas-events .side-content .submit:hover {
    background-color: #004874;
    border-color: #004874;
    color: #ffffff; }

.christmas-events .side-content .taglinkwidget {
  padding: 0.2rem 0;
  list-style: none; }
  .christmas-events .side-content .taglinkwidget li a {
    text-decoration: none; }
  .christmas-events .side-content .taglinkwidget li.selected {
    background-color: #004874; }

@media all and (min-width: 1930px) {
  .christmas-events .page-header {
    background-color: #F1F7FA; } }

.christmas-search-page__wrapper {
  padding-top: 2rem; }
  @media (min-width: 980px) {
    .christmas-search-page__wrapper {
      padding-top: 5rem; } }

.christmas-form-wrapper {
  position: relative;
  background-color: #ffffff;
  margin: 1.5rem auto;
  border: 2px solid rgba(185, 216, 231, 0.5);
  border-radius: 1rem;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(100% - 3rem); }
  @media (min-width: 460px) {
    .christmas-form-wrapper {
      max-width: 28rem; } }
  @media all and (min-width: 1440px) {
    .christmas-form-wrapper {
      z-index: 1;
      position: absolute;
      top: 20rem;
      right: 6rem;
      border-radius: 1rem;
      border: none; } }
  .christmas-form-wrapper #div_id_event_type {
    display: none; }
  .christmas-form-wrapper #acny_search {
    order: 0; }
  .christmas-form-wrapper #acny_search_place {
    border: 1px solid #73B1CE; }
    @media all and (min-width: 1440px) {
      .christmas-form-wrapper #acny_search_place {
        padding-right: 4rem; } }
  .christmas-form-wrapper .search__string-holder {
    width: 100%;
    margin: 1rem auto 0 auto; }
    @media (min-width: 460px) {
      .christmas-form-wrapper .search__string-holder {
        width: 19.25rem;
        max-width: 100%; } }
  .christmas-form-wrapper #acny_search_current_location,
  .christmas-form-wrapper .submit {
    margin: 1rem auto;
    background-color: #E7AA22;
    border-color: #E7AA22;
    color: #004874;
    padding: 0.5rem 1rem;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-transform: none;
    font-weight: 400;
    font-family: "Open Sans", Arial, sans-serif; }
    .christmas-form-wrapper #acny_search_current_location:hover,
    .christmas-form-wrapper .submit:hover {
      background-color: #004874;
      border-color: #004874;
      color: #ffffff; }
    .christmas-form-wrapper #acny_search_current_location .icon,
    .christmas-form-wrapper .submit .icon {
      margin-left: 0.5rem;
      margin-right: 0; }
  .christmas-form-wrapper .submit {
    position: absolute;
    bottom: 1rem;
    right: 1.25rem;
    height: 3.325rem;
    padding: 0.5rem 0.75rem; }
    .christmas-form-wrapper .submit .icon {
      margin: 0; }
    .christmas-form-wrapper .submit span {
      position: absolute;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px, 1px, 1px, 1px); }
    @media (min-width: 460px) {
      .christmas-form-wrapper .submit {
        right: 4rem; } }
    @media all and (min-width: 768px) {
      .christmas-form-wrapper .submit {
        bottom: 1rem;
        height: 3.125rem; } }
    @media (min-width: 980px) {
      .christmas-form-wrapper .submit {
        bottom: 1.5rem; } }
  @media (min-width: 980px) {
    .christmas-form-wrapper {
      padding: 2.5rem; } }
  .christmas-form-wrapper .search__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #004874;
    text-align: center; }
    .christmas-form-wrapper .search__form h2 {
      font-size: 1.75rem;
      line-height: 2.625rem;
      font-family: "Open Sans", Arial, sans-serif; }
    .christmas-form-wrapper .search__form p {
      font-size: 1rem;
      line-height: 1.375rem;
      margin: 0 0 1rem 0; }
    .christmas-form-wrapper .search__form .form-p {
      font-size: 1.25rem;
      margin: 0; }
    .christmas-form-wrapper .search__form .search__bar {
      width: unset; }
    .christmas-form-wrapper .search__form .search__bar,
    .christmas-form-wrapper .search__form .custom__field-value,
    .christmas-form-wrapper .search__form .custom__field-icon {
      border-color: #004874;
      border-width: 2px;
      background-color: #ffffff; }
      .christmas-form-wrapper .search__form .search__bar .icon,
      .christmas-form-wrapper .search__form .custom__field-value .icon,
      .christmas-form-wrapper .search__form .custom__field-icon .icon {
        color: #004874; }

.christmas-video-header {
  position: relative; }
  @media all and (min-width: 1440px) {
    .christmas-video-header {
      background-color: #000000; }
      .christmas-video-header::after {
        display: block;
        content: '';
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, black 20%, black 100%);
        width: calc(100% - 60rem);
        height: 100%;
        position: absolute;
        top: 0;
        right: 0; } }
  .christmas-video-header .video-buttons {
    position: absolute;
    bottom: 25.5rem;
    left: calc(50% - 2.75rem);
    display: flex; }
    @media all and (min-width: 347px) {
      .christmas-video-header .video-buttons {
        bottom: 24rem; } }
    @media all and (min-width: 980px) {
      .christmas-video-header .video-buttons {
        bottom: 27rem;
        left: 1rem; } }
    @media all and (min-width: 1440px) {
      .christmas-video-header .video-buttons {
        bottom: 1rem;
        left: 1rem; } }
  .christmas-video-header #mute-button,
  .christmas-video-header #unmute-button,
  .christmas-video-header #pause-button,
  .christmas-video-header #play-button {
    background-color: #E7AA22;
    border: none;
    border-radius: 50%;
    padding: 0.5rem;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center; }
    .christmas-video-header #mute-button .icon,
    .christmas-video-header #unmute-button .icon,
    .christmas-video-header #pause-button .icon,
    .christmas-video-header #play-button .icon {
      width: 20px;
      height: 20px;
      margin-right: 0; }
  .christmas-video-header #mute-button,
  .christmas-video-header #unmute-button {
    margin-left: 0.5rem; }

.christmas-video {
  width: 100%;
  height: 14rem;
  margin-top: 5rem; }
  @media all and (min-width: 380px) {
    .christmas-video {
      height: 16rem;
      margin-top: 6rem; } }
  @media all and (min-width: 410px) {
    .christmas-video {
      margin-top: 7rem; } }
  @media (min-width: 460px) {
    .christmas-video {
      height: 19rem;
      margin-top: 6rem; } }
  @media all and (min-width: 520px) {
    .christmas-video {
      height: 21rem;
      margin-top: 7rem; } }
  @media all and (min-width: 560px) {
    .christmas-video {
      margin-top: 8rem; } }
  @media all and (min-width: 590px) {
    .christmas-video {
      height: 24rem;
      margin-top: 9rem; } }
  @media (min-width: 640px) {
    .christmas-video {
      height: 26rem;
      margin-top: 10rem; } }
  @media all and (min-width: 768px) {
    .christmas-video {
      height: 29rem;
      margin-top: 11rem; } }
  @media all and (min-width: 890px) {
    .christmas-video {
      height: 32rem;
      margin-top: 13rem; } }
  @media (min-width: 980px) {
    .christmas-video {
      height: 35rem;
      margin-top: 5rem; } }
  @media all and (min-width: 1120px) {
    .christmas-video {
      height: 40rem;
      margin-top: 7rem; } }
  @media all and (min-width: 1340px) {
    .christmas-video {
      margin-top: 9rem; } }
  @media all and (min-width: 1440px) {
    .christmas-video {
      width: 72rem;
      height: 40.25rem;
      margin-top: 9rem; } }

.christmas-events-page__header {
  background-color: #382e75;
  color: #ffffff;
  padding: 2rem 3rem;
  display: flex;
  justify-content: center; }
  @media (min-width: 980px) {
    .christmas-events-page__header {
      padding: 3.375rem 2rem; } }
  .christmas-events-page__header h1 {
    font-size: 1.5rem;
    line-height: 2.25rem;
    position: relative;
    padding: 0;
    width: fit-content;
    text-align: center; }
    .christmas-events-page__header h1::after {
      left: 35%;
      bottom: -8px;
      width: 30%; }
    @media (min-width: 520px) {
      .christmas-events-page__header h1::after {
        left: 0;
        width: 6.5rem; } }
    @media (min-width: 980px) {
      .christmas-events-page__header h1 {
        text-align: left;
        font-size: 3.125rem;
        line-height: 4.6875rem; }
        .christmas-events-page__header h1::after {
          bottom: 0; } }

.christmas-page__mobile-header {
  z-index: 100; }
  .christmas-page__mobile-header .fts-logo {
    position: absolute;
    top: -1rem;
    left: 1rem;
    right: 0;
    margin: 0 auto;
    width: 8rem;
    object-fit: contain; }
    .christmas-page__mobile-header .fts-logo img {
      object-fit: contain;
      top: 0; }
    @media (min-width: 460px) {
      .christmas-page__mobile-header .fts-logo {
        width: 10rem;
        top: 0rem; } }
    @media all and (min-width: 540px) {
      .christmas-page__mobile-header .fts-logo {
        top: 1rem;
        left: 2rem; } }
    @media (min-width: 640px) {
      .christmas-page__mobile-header .fts-logo {
        width: 12rem;
        top: 4rem; } }
  .christmas-page__mobile-header img {
    object-fit: cover;
    min-height: 10rem;
    top: -2rem;
    position: relative; }
    @media (min-width: 460px) {
      .christmas-page__mobile-header img {
        top: -4rem; } }
  @media (min-width: 980px) {
    .christmas-page__mobile-header {
      display: none; } }

.christmas-results-page__wrapper {
  position: relative; }
  .christmas-results-page__wrapper::after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0rem;
    height: 20rem;
    width: 100%;
    background-image: url("../img/christmas_events_bg.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: -1; }
    @media (min-width: 980px) {
      .christmas-results-page__wrapper::after {
        height: 30rem; } }
    @media (min-width: 1280px) {
      .christmas-results-page__wrapper::after {
        height: 40rem; } }

.christmas-results-page__container {
  min-height: 42rem;
  padding-bottom: 3rem; }
  .christmas-results-page__container .side-content {
    background-color: #ffffff; }
  .christmas-results-page__container .results-content {
    margin: 0 -1rem;
    padding: 2rem 1rem; }
  @media (min-width: 980px) {
    .christmas-results-page__container {
      padding-top: 16rem;
      display: flex;
      column-gap: 2rem;
      min-height: 44rem; }
      .christmas-results-page__container .side-content {
        background: none; }
      .christmas-results-page__container .results-content {
        background-color: unset;
        margin: 0;
        padding: 0; } }
  @media (min-width: 1280px) {
    .christmas-results-page__container {
      padding-top: 22rem; } }

.christmas-landing .christmas-page__mobile-header {
  position: absolute;
  width: 100%; }

.christmas-landing .page__wrapper {
  position: relative; }

.christmas-landing .page__wrapper:before {
  content: '';
  display: block;
  background: url(../img/christmas_boxes_bg_left.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 50rem;
  height: 22rem;
  bottom: 38rem;
  left: 0; }
  @media (min-width: 460px) {
    .christmas-landing .page__wrapper:before {
      bottom: 30rem; } }
  @media (min-width: 980px) {
    .christmas-landing .page__wrapper:before {
      width: 50rem;
      height: 22rem;
      bottom: 9rem;
      left: 0; } }
  @media (min-width: 1280px) {
    .christmas-landing .page__wrapper:before {
      width: 50rem;
      height: 22rem;
      bottom: 6rem;
      left: 0; } }

.christmas-landing .page__wrapper:after {
  content: '';
  display: block;
  background: url(../img/christmas_boxes_bg_right.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 50rem;
  height: 22rem;
  bottom: 3rem;
  right: -14rem;
  z-index: -1; }
  @media (min-width: 980px) {
    .christmas-landing .page__wrapper:after {
      width: 50rem;
      height: 22rem;
      bottom: 8rem;
      right: -12rem; } }
  @media (min-width: 1280px) {
    .christmas-landing .page__wrapper:after {
      width: 50rem;
      height: 22rem;
      bottom: 4rem;
      right: -10rem; } }

.christmas-content-boxes {
  margin-bottom: 4.125rem; }
  .christmas-content-boxes div {
    background-color: #ffffff;
    border: 1px solid #E55445;
    margin: 1.375rem 1.375rem 4rem 1.375rem;
    padding: 3rem 3rem;
    position: relative; }
  @media (min-width: 640px) {
    .christmas-content-boxes {
      padding: 2rem 3rem; }
      .christmas-content-boxes div {
        padding: 3.75rem 5rem; } }
  @media (min-width: 980px) {
    .christmas-content-boxes {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1.375rem;
      margin-left: 1.375rem;
      margin-right: 1.375rem; }
      .christmas-content-boxes div {
        margin: 0; } }
  .christmas-content-boxes .box-one:before {
    content: '';
    display: block;
    background: url(../img/christmas_star.svg);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 8rem;
    height: 8rem;
    top: -4rem;
    left: 0rem; }
    @media (min-width: 640px) {
      .christmas-content-boxes .box-one:before {
        width: 11rem;
        height: 11rem;
        top: -5.5rem;
        left: 1rem; } }
  .christmas-content-boxes .box-two:before {
    content: '';
    display: block;
    background: url(../img/christmas_tree.svg);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 2.75rem;
    height: 4.5rem;
    top: -2.25rem;
    left: 3rem; }
    @media (min-width: 640px) {
      .christmas-content-boxes .box-two:before {
        width: 3.125rem;
        height: 5.125rem;
        top: -2.5rem;
        left: 5rem; } }

.christmas-pagination {
  padding: 0; }
  @media (min-width: 640px) {
    .christmas-pagination {
      justify-content: flex-end; } }
  .christmas-pagination .pages {
    align-items: flex-end; }
  .christmas-pagination .btn {
    background: none;
    border: none;
    border-bottom: 2px solid transparent !important;
    color: #004874;
    padding: 0.875rem 1rem;
    margin: 0 0.25rem;
    text-transform: none;
    font-size: 1rem;
    width: unset !important;
    height: unset !important; }
    .christmas-pagination .btn:hover {
      border-bottom: 2px solid #004874 !important; }
  .christmas-pagination .btn.next,
  .christmas-pagination .btn.prev {
    font-weight: bold;
    background-color: #E7AA22;
    color: #004874;
    border-bottom-color: #E7AA22 !important; }
    .christmas-pagination .btn.next:hover,
    .christmas-pagination .btn.prev:hover {
      border-bottom-color: #004874 !important;
      background-color: #004874; }
  .christmas-pagination .pages #pages__current {
    background: none;
    color: #004874;
    border: none !important;
    border-bottom: 2px solid #004874 !important; }

.custom-toggle {
  user-select: none;
  width: 4rem;
  height: 2rem;
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem; }
  .custom-toggle label {
    width: 4rem;
    height: 2rem;
    border-radius: 1rem;
    background-color: #E4E4E4;
    cursor: pointer;
    display: block; }
    .custom-toggle label:active:after {
      width: 3rem; }
    .custom-toggle label:after {
      width: 1.6rem;
      height: 1.6rem;
      background-color: #ffffff;
      content: "";
      position: absolute;
      border-radius: 2rem;
      top: 0;
      left: 0;
      transition: all 0.5s;
      margin: 0.2rem; }
    .custom-toggle label .text {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 1rem;
      width: 100%;
      height: 100%;
      user-select: none; }
      .custom-toggle label .text .on {
        color: #61b1b9; }
      .custom-toggle label .text .off {
        color: #292929; }
  .custom-toggle input {
    display: none; }
    .custom-toggle input:checked + label {
      background-color: #61b1b9; }
      .custom-toggle input:checked + label:after {
        left: unset;
        background-color: #ffffff;
        right: 0.2rem;
        transform: translateX(0.2rem); }

#marketplaceform {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 1rem 0; }
  #marketplaceform .grid-item {
    width: 100%;
    margin: 0.5rem 0;
    background: #ffffff;
    position: relative; }
    @media (min-width: 640px) {
      #marketplaceform .grid-item {
        display: flex;
        flex-direction: column;
        width: calc(50% - 0.5rem); } }
    #marketplaceform .grid-item.iKnow {
      padding-top: 0; }
  #marketplaceform .grid-item__buttons {
    display: flex;
    flex-direction: column;
    width: 100%; }
    #marketplaceform .grid-item__buttons a {
      margin: 0; }
    #marketplaceform .grid-item__buttons .right-aligned-btn {
      margin: 1rem 0 0; }
    @media (min-width: 1280px) {
      #marketplaceform .grid-item__buttons {
        flex-direction: row;
        justify-content: space-between; }
        #marketplaceform .grid-item__buttons .right-aligned-btn {
          margin: 0; } }
  #marketplaceform .grid-item__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem; }
    @media (min-width: 640px) {
      #marketplaceform .grid-item__header {
        height: 3.5rem; } }
    @media (min-width: 980px) {
      #marketplaceform .grid-item__header {
        height: 5rem; } }
    #marketplaceform .grid-item__header h4, #marketplaceform .grid-item__header .controls {
      margin: 0; }
  #marketplaceform .grid-item__preview {
    min-height: 9.375rem;
    background: #a688b7;
    padding: 1.5rem 2rem;
    position: relative; }
    #marketplaceform .grid-item__preview.survey {
      background: #6C93CE;
      color: #F5F5F5; }
    #marketplaceform .grid-item__preview.donate {
      background: #95317A;
      color: #F5F5F5; }
    #marketplaceform .grid-item__preview.iKnow {
      background: #a688b7;
      color: #F5F5F5; }
    #marketplaceform .grid-item__preview.everyday-faith {
      background: #6FBEC6;
      color: #F5F5F5; }
    #marketplaceform .grid-item__preview h3, #marketplaceform .grid-item__preview p {
      color: #ffffff;
      margin: 0;
      padding-bottom: 0.5rem; }
    #marketplaceform .grid-item__preview .btn {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      cursor: auto; }
      #marketplaceform .grid-item__preview .btn:hover {
        background: none; }
  #marketplaceform .grid-item__description {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 2rem 2rem; }
    #marketplaceform .grid-item__description p {
      margin-bottom: 3rem; }
    #marketplaceform .grid-item__description a {
      align-self: flex-start;
      min-width: 7.5rem; }
    #marketplaceform .grid-item__description .button {
      text-transform: none;
      text-align: center; }
    @media (min-width: 640px) {
      #marketplaceform .grid-item__description {
        flex: 1;
        justify-content: space-between; } }

.tiny-icon {
  fill: #ffffff;
  background: #4A4A4A;
  width: 1rem;
  height: 0.7rem;
  margin-right: 1rem; }

#donationsconfigurationform h4 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1rem;
  text-transform: uppercase; }

#donationsconfigurationform p {
  margin: 0.5rem 0; }

#donationsconfigurationform .hide-if-custom-url,
#donationsconfigurationform .hide-if-not-custom-url {
  margin: 2rem 0; }

#donationsconfigurationform .help-block {
  display: block;
  margin: 0.5rem 0;
  font-size: 1rem; }
  @media (min-width: 980px) {
    #donationsconfigurationform .help-block {
      margin: 1.5rem 0 2.5rem; } }
  #donationsconfigurationform .help-block input {
    margin-top: 0.5rem; }

@media (min-width: 980px) {
  #donationsconfigurationform .control-group .help-block {
    margin: 2rem 0 0 !important; } }

#donationsconfigurationform .control-group input[type=checkbox] {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

#donationsconfigurationform .control-group .controls {
  display: flex;
  flex-flow: column;
  margin-right: 3rem;
  margin-bottom: 0; }
  #donationsconfigurationform .control-group .controls input {
    order: 1; }
  #donationsconfigurationform .control-group .controls p.help-block {
    margin: 0 0 0.5rem 0 !important;
    width: 100%;
    font-size: 1.25rem; }

#donationsconfigurationform .hint-fieldset label {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

#donationsconfigurationform .control-label {
  text-transform: unset; }

.donation-error {
  color: #DE2F62; }

.statistics aside {
  padding: 0 1rem; }

@media (min-width: 640px) {
  .statistics {
    display: flex; }
    .statistics aside {
      order: 1;
      margin-top: 1rem;
      width: 40%;
      padding: 0; } }

.statistics aside {
  font-size: 0.875rem;
  font-weight: 800; }

.statistics-table {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 0 1rem; }
  .statistics-table hr {
    width: 100%; }
  .statistics-table dt {
    color: #a688b7;
    flex-shrink: 0;
    text-transform: uppercase;
    font-weight: 300; }
  .statistics-table dt, .statistics-table dd {
    width: 40%;
    margin-bottom: 0.5rem; }
  .statistics-table dd a {
    word-break: break-word; }

.everyday-faith__introduction {
  padding: 2rem !important; }

.everyday-faith__panel {
  margin-top: 1rem; }
  .everyday-faith__panel .panel__content {
    background: #FFFFFF;
    padding: 1.5rem; }
  @media all and (min-width: 768px) {
    .panel__grid + .everyday-faith__panel {
      margin-top: 0; } }

.everyday-faith__story--full-width {
  display: grid;
  gap: 2rem; }
  .everyday-faith__story--full-width .video__wrapper {
    margin-top: 1rem; }
  @media (min-width: 640px) {
    .everyday-faith__story--full-width {
      grid-template-columns: 1fr 1fr; }
      .everyday-faith__story--full-width .video__wrapper {
        margin-top: 0; } }
  @media (min-width: 1280px) {
    .everyday-faith__story--full-width {
      gap: 4rem; } }

@media (min-width: 640px) {
  .journeys__grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 1rem; }
    .journeys__grid .everyday-faith__panel:nth-child(3) {
      margin-top: 0; } }

@media (min-width: 1280px) {
  .journeys__grid {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); }
    .journeys__grid .everyday-faith__panel:nth-child(3) {
      margin-top: 1rem; } }

.featured-journey p {
  margin: 1.5rem 0; }

@media (min-width: 640px) {
  .featured-journey {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem; } }

@media (min-width: 1280px) {
  .featured-journey {
    gap: 4rem; } }

.featured-journey__image {
  max-height: 15rem;
  object-fit: contain;
  margin-top: 2rem; }
  @media (min-width: 640px) {
    .featured-journey__image {
      margin-top: 0; } }

.featured-journey .btn,
.journey .btn {
  color: #4A4A4A;
  text-transform: none;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  width: fit-content; }

.django-ckeditor-widget {
  width: 100%; }

.my-churches--page__wrapper {
  background-color: #E4E4E4; }
  .my-churches--page__wrapper .mobile-admin__navigation {
    background-color: #ffffff; }

.my-churches--block {
  background-color: #ffffff;
  padding: 2rem;
  margin-top: 10px; }
  .my-churches--block h3 {
    margin-bottom: 0; }
    .my-churches--block h3::after {
      content: '';
      display: block;
      height: 3px;
      width: 104px;
      background-color: #a688b7;
      margin-top: 20px;
      margin-bottom: 25px; }
    .my-churches--block h3 .icon {
      padding-top: 0.5rem;
      height: 2.25rem; }

.my-churches--row {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.my-churches--col-1 {
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px; }

.my-churches--col-2 {
  display: flex;
  flex-direction: row;
  align-items: center; }

.my-churches--btn {
  color: #ffffff !important;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 3px 15px;
  border: none;
  text-transform: capitalize;
  display: inline-flex;
  align-items: center; }
  .my-churches--btn .icon {
    margin-left: 6px; }
  .my-churches--btn__view-live-church {
    background: #6FBEC6; }
  .my-churches--btn__view-live-benefice {
    background: #6FBEC6; }
  .my-churches--btn__edit-church {
    background: #a688b7; }
  .my-churches--btn__edit-benefice {
    background: #95317A; }
  .my-churches--btn__add {
    background: #E8A065; }

.my-churches--tags {
  margin-top: 5px; }

.my-churches--cta {
  margin-right: 25px; }

.my-churches--stats {
  display: flex;
  flex-direction: column;
  margin-right: 33px; }
  .my-churches--stats-item {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    color: #4A4A4A;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: uppercase;
    justify-content: space-between; }
    .my-churches--stats-item span {
      margin-right: 5px; }

.my-churches--events {
  display: flex;
  flex-direction: column; }
  .my-churches--events a {
    color: #E8A065;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px; }

.my-churches--col-names {
  display: flex;
  flex-direction: row; }
  .my-churches--col-names > span {
    width: 77%; }
  .my-churches--col-names span {
    color: #4A4A4A;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: uppercase; }
  .my-churches--col-names div {
    width: 23%;
    display: flex;
    flex-direction: row-reverse; }
    .my-churches--col-names div > span {
      margin-right: 13px; }

.easter-search-page__wrapper {
  padding-top: 1rem; }
  @media (min-width: 980px) {
    .easter-search-page__wrapper {
      padding-top: 0; } }
  .easter-search-page__wrapper .easter-header-image {
    height: 8rem;
    width: auto;
    max-width: 100%;
    margin: 0 auto;
    display: block; }
    @media (min-width: 360px) {
      .easter-search-page__wrapper .easter-header-image {
        height: 10rem; } }
    @media (min-width: 640px) {
      .easter-search-page__wrapper .easter-header-image {
        height: 14rem; } }
    @media (min-width: 980px) {
      .easter-search-page__wrapper .easter-header-image {
        height: 22rem; } }
  .easter-search-page__wrapper label[for="id_event_type"] {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px); }
  .easter-search-page__wrapper .easter-form-wrapper {
    padding: 1.375rem 1.375rem 2rem 1.375rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    position: relative; }
    .easter-search-page__wrapper .easter-form-wrapper #acny_search {
      order: 0; }
    .easter-search-page__wrapper .easter-form-wrapper #div_id_acny_search_place {
      width: 100%;
      min-width: 14rem; }
    .easter-search-page__wrapper .easter-form-wrapper .submit {
      margin-top: 2rem;
      background-color: #E0A468;
      color: #ffffff;
      padding: 0.95rem 2rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      line-height: 1.5rem;
      text-transform: none;
      font-weight: 500;
      font-family: "Open Sans", Arial, sans-serif; }
      .easter-search-page__wrapper .easter-form-wrapper .submit:hover {
        background-color: #004874;
        border-color: #004874;
        color: #ffffff; }
      .easter-search-page__wrapper .easter-form-wrapper .submit .icon {
        margin-left: 0.5rem; }
    @media (min-width: 640px) {
      .easter-search-page__wrapper .easter-form-wrapper {
        padding: 1.375rem 4.375rem; } }
    @media (min-width: 980px) {
      .easter-search-page__wrapper .easter-form-wrapper {
        padding: 2.5rem 1rem;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap; }
        .easter-search-page__wrapper .easter-form-wrapper .submit {
          margin-top: 0;
          margin-left: 2rem;
          width: fit-content;
          padding: 0.73rem 2rem; } }
    @media (min-width: 1280px) {
      .easter-search-page__wrapper .easter-form-wrapper {
        padding: 2.5rem; } }
  .easter-search-page__wrapper .search__form {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .easter-search-page__wrapper .search__form p {
      font-family: "Cabin", Arial, sans-serif;
      font-size: 1.14rem;
      flex-shrink: 0;
      margin: 0.5rem 0; }
      @media (min-width: 980px) {
        .easter-search-page__wrapper .search__form p {
          font-size: 2rem;
          margin: 2rem 0; } }
    .easter-search-page__wrapper .search__form #div_id_event_type {
      margin-bottom: 1rem; }
      @media (min-width: 980px) {
        .easter-search-page__wrapper .search__form #div_id_event_type {
          margin-bottom: 0; } }
    .easter-search-page__wrapper .search__form .search__bar {
      width: unset; }
    .easter-search-page__wrapper .search__form .search__bar,
    .easter-search-page__wrapper .search__form .custom__select-toggle-text,
    .easter-search-page__wrapper .search__form .toggle-icon__holder,
    .easter-search-page__wrapper .search__form .custom__field-value,
    .easter-search-page__wrapper .search__form .custom__field-icon {
      border-color: #4A4A4A;
      border-width: 1px;
      background-color: #ffffff; }
      .easter-search-page__wrapper .search__form .search__bar .icon,
      .easter-search-page__wrapper .search__form .custom__select-toggle-text .icon,
      .easter-search-page__wrapper .search__form .toggle-icon__holder .icon,
      .easter-search-page__wrapper .search__form .custom__field-value .icon,
      .easter-search-page__wrapper .search__form .custom__field-icon .icon {
        color: #4A4A4A; }
    .easter-search-page__wrapper .search__form .custom__select-toggle {
      min-width: 13.25rem; }
    .easter-search-page__wrapper .search__form .custom__select-toggle-text {
      padding: 0.75rem 1rem;
      text-wrap: nowrap; }
    @media (min-width: 980px) {
      .easter-search-page__wrapper .search__form {
        flex-shrink: 0;
        flex-direction: row;
        align-items: center; }
        .easter-search-page__wrapper .search__form p {
          padding: 0 1rem; } }
    @media (min-width: 1280px) {
      .easter-search-page__wrapper .search__form p {
        padding: 0 2rem; } }

.easter-content-boxes {
  margin-bottom: 4.125rem; }
  .easter-content-boxes .content-box {
    display: block;
    background-color: #ffffff;
    border: 1px solid #6C9A4B;
    border-radius: 10px;
    margin: 1.375rem 1.375rem 4rem 1.375rem;
    padding: 3rem 3rem;
    position: relative;
    text-decoration: none;
    transition: all 0.5s ease; }
    .easter-content-boxes .content-box h4 {
      display: flex;
      align-items: center;
      gap: 0.5rem; }
    .easter-content-boxes .content-box svg {
      width: 1rem;
      height: 1rem;
      flex-shrink: 0; }
    .easter-content-boxes .content-box:hover {
      background-color: #fdfdf7; }
  @media (min-width: 640px) {
    .easter-content-boxes {
      padding: 2rem 3rem; }
      .easter-content-boxes .content-box {
        padding: 3.75rem 4rem; } }
  @media (min-width: 980px) {
    .easter-content-boxes {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1.375rem;
      margin-left: 1.375rem;
      margin-right: 1.375rem; }
      .easter-content-boxes .content-box {
        margin: 0; } }
  .easter-content-boxes .box-one:before, .easter-content-boxes .box-two:before {
    content: '🌱';
    display: block;
    position: absolute;
    top: -1rem;
    left: 1.2rem;
    font-size: 75px; }
    @media (min-width: 640px) {
      .easter-content-boxes .box-one:before, .easter-content-boxes .box-two:before {
        top: -1.75rem;
        left: 4rem; } }
  .easter-content-boxes .box-two:before {
    content: '🌿'; }

.easter-landing .easter-search-page__wrapper {
  padding-top: 2rem; }

.easter-landing .header-background {
  display: none; }

.easter-landing .page__wrapper {
  position: relative; }

.easter-landing .page__wrapper:before {
  content: '';
  display: block;
  background: url(../img/easter_boxes_bg_mobile.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 50rem;
  bottom: 0;
  left: 0; }
  @media (min-width: 640px) {
    .easter-landing .page__wrapper:before {
      height: 70rem; } }
  @media (min-width: 980px) {
    .easter-landing .page__wrapper:before {
      background: url(../img/easter_boxes_bg.svg);
      width: 100%;
      height: 22rem; } }

.easter-landing .page-header .cofe__navigation ul li a {
  color: #004874; }

.easter-events .header-background {
  display: none; }

.easter-events .page-header .cofe__navigation ul a {
  color: #ffffff; }

.easter-events .page-header .primary__navigation {
  margin-bottom: 0; }
  .easter-events .page-header .primary__navigation .navbar__holder {
    border-color: #012F95; }

@media (min-width: 980px) {
  .easter-events .page-header {
    background-color: #588061; } }

.easter-events .search__bar,
.easter-events .custom__select-toggle-text,
.easter-events .toggle-icon__holder,
.easter-events .custom__field-value,
.easter-events .custom__field-icon,
.easter-events .textInput,
.easter-events .textInput:focus {
  border-color: #012F95;
  border-width: 1px;
  background-color: #ffffff; }
  .easter-events .search__bar .icon,
  .easter-events .custom__select-toggle-text .icon,
  .easter-events .toggle-icon__holder .icon,
  .easter-events .custom__field-value .icon,
  .easter-events .custom__field-icon .icon,
  .easter-events .textInput .icon,
  .easter-events .textInput:focus .icon {
    color: #000000; }

.easter-events .search__bar {
  margin-bottom: 1rem; }

.easter-events .custom__select-toggle-text {
  padding: 0.75rem 1rem; }

.easter-events label[for="id_event_type"],
.easter-events label[for="id_time_0"] {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.easter-events #div_id_date {
  margin-top: 1rem; }

.easter-events #div_id_time {
  margin-top: 1rem; }
  .easter-events #div_id_time .controls {
    display: flex;
    align-items: center;
    gap: 0.75rem; }

.easter-events #div_id_distance input {
  appearance: auto; }

.easter-events #div_id_distance + p {
  margin-top: -0.5rem;
  font-size: 0.75rem; }

.easter-events #div_id_distance,
.easter-events #div_id_event_type,
.easter-events #div_id_date_range {
  margin-top: 2rem; }
  .easter-events #div_id_distance label,
  .easter-events #div_id_event_type label,
  .easter-events #div_id_date_range label {
    font-size: 1.5rem;
    font-family: "Cabin", Arial, sans-serif; }
    @media (min-width: 980px) {
      .easter-events #div_id_distance label,
      .easter-events #div_id_event_type label,
      .easter-events #div_id_date_range label {
        font-size: 2rem; } }
  .easter-events #div_id_distance .controls,
  .easter-events #div_id_event_type .controls,
  .easter-events #div_id_date_range .controls {
    margin-top: 0.5rem; }

.easter-events #div_id_event_type .controls {
  max-height: 8rem;
  overflow-y: scroll; }
  @media (min-width: 980px) {
    .easter-events #div_id_event_type .controls {
      max-height: 18rem; } }

.easter-events #div_id_event_type .controls__checkbox .checkbox {
  margin-left: 0; }

.easter-events #div_id_event_type input[type="checkbox"] {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.easter-events #div_id_event_type .custom__checkbox .icon {
  color: #004874; }

.easter-events #div_id_date_range .custom__field-icon:last-child {
  border-color: #E7AA22; }

.easter-events #div_id_date label,
.easter-events #div_id_groups_and_activities label {
  display: none;
  margin-top: 3rem;
  font-size: 2rem;
  font-family: "Cabin", Arial, sans-serif;
  margin-bottom: 1rem; }
  @media (min-width: 980px) {
    .easter-events #div_id_date label,
    .easter-events #div_id_groups_and_activities label {
      display: block; } }

.easter-events #div_id_groups_and_activities {
  display: none; }
  @media (min-width: 980px) {
    .easter-events #div_id_groups_and_activities {
      display: block; } }
  .easter-events #div_id_groups_and_activities .taglinkwidget {
    padding: 0.2rem 0;
    list-style: none; }
    .easter-events #div_id_groups_and_activities .taglinkwidget li a {
      text-decoration: none; }
    .easter-events #div_id_groups_and_activities .taglinkwidget li.selected {
      background-color: #004874;
      color: #ffffff; }
    .easter-events #div_id_groups_and_activities .taglinkwidget li:hover {
      background-color: #004874;
      border-color: #004874;
      color: #ffffff; }

.easter-events .side-content .h3 {
  font-size: 1.5rem; }
  @media (min-width: 980px) {
    .easter-events .side-content .h3 {
      font-size: 2.25rem;
      margin-bottom: 1rem; } }

.easter-events .side-content .submit {
  color: #004874;
  font-weight: 700;
  background-color: #E7AA22;
  border-color: #E7AA22;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
  font-family: "Open Sans", Arial, sans-serif; }
  .easter-events .side-content .submit:hover {
    background-color: #004874;
    border-color: #004874;
    color: #ffffff; }

.easter-events .side-content .taglinkwidget {
  padding: 0.2rem 0;
  list-style: none; }
  .easter-events .side-content .taglinkwidget li a {
    text-decoration: none; }
  .easter-events .side-content .taglinkwidget li.selected {
    background-color: #004874; }

@media all and (min-width: 1930px) {
  .easter-events .page-header {
    background-color: #F1F7FA; } }

.easter-events-page__header {
  color: #ffffff;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url(../img/easter_events_header.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom; }
  @media (min-width: 980px) {
    .easter-events-page__header {
      padding: 2.375rem 2rem 0 2rem; } }
  .easter-events-page__header h1 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
    position: relative;
    padding: 0;
    width: fit-content;
    text-align: center; }
    @media (min-width: 980px) {
      .easter-events-page__header h1 {
        font-size: 3.4375rem;
        line-height: 5.125rem;
        font-family: "fisterra-morte", sans-serif;
        font-weight: 700;
        font-style: normal; } }
  .easter-events-page__header img {
    max-height: 3rem;
    width: auto;
    margin: 0 auto; }
    @media (min-width: 640px) {
      .easter-events-page__header img {
        max-height: 4rem; } }
    @media (min-width: 980px) {
      .easter-events-page__header img {
        max-height: unset;
        max-width: 90%; } }
    @media (min-width: 1280px) {
      .easter-events-page__header img {
        max-width: 74.25rem; } }

.easter-results-page__wrapper {
  position: relative; }
  @media (min-width: 980px) {
    .easter-results-page__wrapper {
      background-color: #F3F4EE; } }
  .easter-results-page__wrapper::before {
    display: block;
    content: '';
    position: absolute;
    bottom: 0rem;
    height: calc(100% - 40rem);
    width: 100%;
    background-image: url(../img/easter_events_bg.svg);
    background-repeat: repeat-y;
    background-size: 100%;
    z-index: 0; }
    @media (min-width: 980px) {
      .easter-results-page__wrapper::before {
        height: calc(100% - 20rem); } }

.easter-results-page__container {
  padding-top: 2rem;
  min-height: 42rem; }
  .easter-results-page__container .results-content {
    margin: 0 -1rem;
    padding: 2rem 1rem;
    background-color: #F3F4EE; }
    .easter-results-page__container .results-content .h3 {
      font-size: 1.5rem; }
  .easter-results-page__container .custom__select-dropdown {
    position: relative;
    border-width: 1px; }
  @media (min-width: 980px) {
    .easter-results-page__container {
      display: flex;
      column-gap: 2rem;
      min-height: 44rem;
      padding-bottom: 3rem; }
      .easter-results-page__container .side-content {
        background: none; }
      .easter-results-page__container .results-content {
        background-color: unset;
        margin: 0;
        padding: 0; }
        .easter-results-page__container .results-content .h3 {
          margin-bottom: 1rem;
          font-size: 2.25rem; }
        .easter-results-page__container .results-content .card--event:first-of-type {
          margin-top: 0; } }
  @media (min-width: 1280px) {
    .easter-results-page__container {
      padding-top: 4rem; } }

.easter-pagination {
  padding: 0;
  position: relative; }
  @media (min-width: 640px) {
    .easter-pagination {
      justify-content: flex-end; } }
  .easter-pagination .pages {
    align-items: flex-end; }
  .easter-pagination .btn {
    background: none;
    border: none;
    border-bottom: 2px solid transparent !important;
    color: #004874;
    padding: 0.875rem 1rem;
    margin: 0 0.25rem;
    text-transform: none;
    font-size: 1rem;
    width: unset !important;
    height: unset !important; }
    .easter-pagination .btn:hover {
      border-bottom: 2px solid #004874 !important; }
  .easter-pagination .btn.next,
  .easter-pagination .btn.prev {
    font-weight: bold;
    background-color: #ffffff;
    color: #004874;
    border-bottom-color: #ffffff !important; }
    .easter-pagination .btn.next:hover,
    .easter-pagination .btn.prev:hover {
      border-bottom-color: #004874 !important;
      background-color: #004874;
      color: #ffffff; }
  .easter-pagination .pages #pages__current {
    background: none;
    color: #004874;
    border: none !important;
    border-bottom: 2px solid #004874 !important; }

@media (min-width: 980px) {
  .easter-branding .search-empty {
    padding-top: 3rem; } }

@media (min-width: 1280px) {
  .easter-branding .search-empty {
    padding-top: 6rem; } }

.easter-branding .location-intro__image {
  background-color: #588061; }
  .easter-branding .location-intro__image::after {
    background: rgba(88, 128, 97, 0.625); }

@media (min-width: 980px) {
  .easter-branding .event__intro,
  .easter-branding .news__intro {
    margin-top: 2rem !important; } }

.easter-branding .church-page__wrapper {
  overflow: visible;
  padding-top: 1rem;
  background: url(../img/easter_events_bg.svg) 90% -10rem/100% repeat-y, #EBECE2; }
  @media (min-width: 980px) {
    .easter-branding .church-page__wrapper .side-content {
      margin-top: -5rem;
      padding-top: 14rem; } }
  .easter-branding .church-page__wrapper .church__logo-holder {
    border-top: none; }
  .easter-branding .church-page__wrapper .billboard__panel--page-detail {
    background-color: #EBECE2; }
  .easter-branding .church-page__wrapper .billboard__side.no__image {
    color: #4A4A4A; }

@media all and (min-width: 980px) {
  .easter-branding .church-page__main {
    padding: 7rem 0 1rem 1rem; } }

@media (min-width: 980px) {
  .easter-branding.admin-view.my-churches .cofe__navigation {
    display: block; } }

.easter-branding.admin-view.my-churches .navbar__holder {
  padding: 0 1.875rem; }

.easter-branding.admin-view .church-page__wrapper .side-content {
  margin-top: 0;
  padding-top: 0; }

@media all and (min-width: 980px) {
  .easter-branding.admin-view .church-page__main {
    padding-top: 0; } }

.easter-branding.admin-view .benefice-page__wrapper {
  margin-top: 0; }

.easter-branding .benefice-page__wrapper,
.easter-branding.benefice-page .church-page__wrapper {
  background: url(../img/easter_events_bg.svg) 90% -10rem/100% repeat-y, #EBECE2; }
  .easter-branding .benefice-page__wrapper .billboard__panel--benefice,
  .easter-branding.benefice-page .church-page__wrapper .billboard__panel--benefice {
    background: #588061; }
  .easter-branding .benefice-page__wrapper .billboard__panel--page-detail,
  .easter-branding.benefice-page .church-page__wrapper .billboard__panel--page-detail {
    background-color: #EBECE2; }
  @media (min-width: 980px) {
    .easter-branding .benefice-page__wrapper,
    .easter-branding.benefice-page .church-page__wrapper {
      margin-top: -3rem;
      padding-top: 5rem; } }

@media (min-width: 980px) {
  .easter-branding .resource-page__wrapper,
  .easter-branding .tag-group-page .main-content,
  .easter-branding.admin-analytics .page__wrapper,
  .easter-branding.my-churches .page__wrapper,
  .easter-branding.accounts .account-page__wrapper {
    padding-top: 6rem; } }

@media (min-width: 1280px) {
  .easter-branding .resource-page__wrapper,
  .easter-branding .tag-group-page .main-content,
  .easter-branding.admin-analytics .page__wrapper,
  .easter-branding.my-churches .page__wrapper,
  .easter-branding.accounts .account-page__wrapper {
    padding-top: 10rem; } }

.easter-branding.login-view .account-page__wrapper {
  padding-top: 3rem;
  padding-bottom: 3rem; }
  @media (min-width: 980px) {
    .easter-branding.login-view .account-page__wrapper {
      padding-top: 10rem;
      padding-bottom: 5rem; } }
  .easter-branding.login-view .account-page__wrapper .main-content form {
    padding-bottom: 1rem; }

.search-layout {
  padding: 0;
  position: relative; }
  .search-layout .side-content {
    background: #fff;
    padding: 1rem; }
  .search-layout .main-content {
    order: -1;
    padding: 0; }
  @media all and (min-width: 981px) {
    .search-layout {
      padding: 0 1rem 0 0; }
      .search-layout .main-content {
        padding: 1rem 0 1rem 1rem;
        order: 1; }
      .search-layout .side-content {
        padding: 0 0 1rem 1rem;
        padding-top: 1rem;
        position: relative; } }

.search__bar input {
  border: 0;
  color: #000000; }
  .search__bar input.search__input-current-location::placeholder {
    color: #E8A065;
    font-weight: bold; }

.search__bar svg.search__button-current-location {
  color: #E8A065;
  cursor: pointer; }

.search-layout .main-content .search__map-holder {
  padding-bottom: 0;
  height: calc(100vh - 14.75rem); }
  @media all and (max-width: 980px) {
    .search-layout .main-content .search__map-holder {
      height: 20rem;
      padding-bottom: 0;
      margin-top: 0; } }

.search__string-holder {
  position: relative; }
  .search__string-holder #div_id_place {
    flex-grow: 1; }

#search_map {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.map__marker {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Cabin", Arial, sans-serif;
  font-size: 0.875rem;
  transform: translate(-50%, -50%);
  cursor: pointer; }
  .map__marker:before {
    content: '';
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: -5;
    background: #a688b7; }
  .map__marker.school {
    display: none; }
    .map__marker.school:before {
      background: #6C93CE; }
  .map__marker.active, .map__marker.legend {
    font-size: 0;
    z-index: 1000; }
    .map__marker.active:before, .map__marker.legend:before {
      width: 2rem;
      height: 2rem;
      background: #fff;
      border: 3px solid #4A4A4A; }
    .map__marker.active:after, .map__marker.legend:after {
      position: absolute;
      width: 2rem;
      height: 2rem;
      content: '';
      background: url(../img/cross.svg) center center/contain no-repeat;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.25rem;
      color: #4A4A4A; }
  .map__marker.legend:after {
    width: 0.875rem;
    height: 0.875rem; }
  .map__marker.pin {
    font-size: 0;
    z-index: -1; }
    .map__marker.pin:before {
      width: 2rem;
      height: 2rem;
      background: none;
      transform: rotate(0deg); }
    .map__marker.pin:after {
      position: absolute;
      width: 1.675rem;
      height: 1.675rem;
      content: '';
      background: url(../icons/svg/pin.svg) center center/contain no-repeat;
      display: block;
      top: -0.85rem;
      left: 0.25rem;
      transform: rotate(45deg);
      font-size: 2.25rem;
      color: #4A4A4A;
      z-index: 1000; }

.center-pin {
  position: absolute;
  background: url(../icons/svg/pin.svg) no-repeat;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin-left: -10px;
  margin-top: -34px;
  height: 34px;
  width: 20px;
  cursor: pointer; }

.search__detail #search_list_holder {
  display: none; }

.search__list #search_list_holder {
  width: calc(100% - 1rem); }
  @media all and (min-width: 981px) {
    .search__list #search_list_holder {
      position: absolute;
      top: 0;
      left: 1rem;
      right: 0;
      bottom: 0;
      padding: 1rem 0 0 0;
      overflow: auto;
      border-top: 3px solid #F5F5F5; } }

.support__headline {
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 300;
  margin-top: -0.5rem; }

.search__result {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 0;
  cursor: pointer; }
  .search__result:hover h3 {
    text-decoration: underline; }
  .search__result.school .search__result-summary span {
    color: #6C93CE; }
  .search__result + .search__result {
    border-top: 3px solid #F5F5F5; }
  .search__result.parish-church .search__result-summary:after {
    display: block;
    content: 'This is the parish church';
    color: #dea26f;
    margin-top: 0.125rem;
    font-size: 0.875rem; }

.search__detail-preview {
  font-size: 0.75rem;
  margin-bottom: 1rem; }

.search__result-summary h4,
.search__result-summary p {
  margin: 0; }

.search__result-summary .search__result-location {
  text-transform: uppercase;
  margin-top: 0.125rem;
  font-size: 0.875rem; }

.search__result-summary .search__result-upcoming-events {
  font-size: 0.75rem;
  list-style-type: '-';
  padding-left: 0.3rem;
  margin-top: 0; }
  .search__result-summary .search__result-upcoming-events a {
    color: #dea26f;
    text-decoration: none;
    padding: 0 0.2rem; }

.search__result-summary span {
  color: #a688b7; }

.search__result-summary .tag {
  color: #fff;
  margin: 0.5em 0; }

.search__meta-list {
  margin-top: 1rem;
  font-size: 1.25rem; }

.search__meta {
  display: flex;
  padding: 0.75rem 1rem 0.75rem 0; }
  .search__meta + .search__meta {
    border-top: 3px solid #F5F5F5; }
  .search__meta .icon {
    flex-shrink: 0;
    margin-right: 0.75rem;
    color: #A5A5A5; }
  .search__meta a {
    text-decoration: none; }

.search-control__row {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem 0.75rem 0;
  border-top: 3px solid #F5F5F5;
  border-bottom: 3px solid #F5F5F5;
  font-size: 1.25rem; }
  .search-control__row .icon {
    width: 1.5rem;
    height: 1.5rem;
    color: rgba(74, 74, 74, 0.5); }
    .search-control__row .icon:hover {
      color: #4A4A4A; }

#search_back {
  display: flex;
  cursor: pointer; }
  #search_back .icon {
    margin-right: 0.75rem;
    width: 1.25rem;
    height: 1.25rem; }
  #search_back:hover span {
    text-decoration: underline; }
  #search_back:hover .icon {
    color: currentColor; }

.search-navigation {
  display: flex;
  cursor: pointer; }
  .search-navigation #search_next {
    margin-left: 1rem; }

.search__detail-intro {
  padding-right: 1rem; }

.church__preview {
  flex-shrink: 0;
  width: 6rem;
  height: 6rem;
  margin-left: 0.75rem;
  position: relative;
  border: 3px solid #E4E4E4; }

.list__count {
  display: inline-flex;
  align-items: center;
  padding: 0.375rem;
  position: relative;
  color: #fff;
  z-index: 5;
  flex-shrink: 0;
  margin: 0 1rem 0 0.675rem;
  font-size: 0.875rem; }
  .list__count:before {
    content: '';
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    background: #a688b7;
    border: 3px solid #a688b7;
    z-index: -5;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); }
    .in__parish .list__count:before {
      background: #E8A065; }
    .result__type .list__count:before {
      width: 0.5rem;
      height: 0.5rem; }
    .school .list__count:before {
      background: #6C93CE;
      border-color: #6C93CE; }

#search_filter {
  padding: 2rem 0 0.5rem 0;
  margin-bottom: 0;
  border-bottom: 3px solid #F5F5F5; }
  #search_filter .result__type {
    display: block;
    position: relative;
    cursor: pointer;
    /* Create a custom checkbox */
    /* Show the checkmark when checked */ }
    #search_filter .result__type .list__count {
      display: inline-block;
      padding: 0.5rem;
      margin: 0 0.75rem 0 3rem !important; }
      #search_filter .result__type .list__count:before {
        width: 0.5rem;
        height: 0.5rem; }
    #search_filter .result__type span {
      background: none; }
    #search_filter .result__type input {
      position: absolute;
      opacity: 0;
      cursor: pointer; }
      #search_filter .result__type input ~ .checkmark {
        border: 2px solid #e4e4e4; }
    #search_filter .result__type .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px; }
      #search_filter .result__type .checkmark:after {
        content: '';
        position: absolute;
        display: none;
        left: 7px;
        top: 2px;
        width: 5px;
        height: 10px;
        border-style: solid;
        border-color: #a688b7;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg); }
      #search_filter .result__type .checkmark.school:after {
        border-color: #6C93CE; }
    #search_filter .result__type input:checked ~ .checkmark:after {
      display: block; }

.church-list__map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.parish-info {
  display: flex;
  flex-direction: row;
  margin: 0.5em 0;
  padding: 0.5rem 0;
  border-bottom: 3px solid #f5f5f5; }
  .parish-info .icon {
    width: 3.4rem;
    height: 3.4rem;
    margin-right: 0.7em; }
  .parish-info p {
    margin: 0; }

.parish__result {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-top: 1rem;
  border-top: 3px solid #F5F5F5; }
  .parish__result .parish__example {
    width: 2rem;
    height: 2rem;
    margin-right: 0.875rem;
    flex-shrink: 0; }
  .parish__result p {
    margin-top: 0; }
  .parish__result span {
    border-bottom: 1px solid #E8A065; }

.result__type {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin: 1rem 0 0.5rem 0;
  padding-bottom: 0.5rem; }
  .result__type > span {
    margin-top: 0.75rem; }
    .result__type > span .list__count {
      margin-right: 0.5rem; }
  @media all and (min-width: 768px) {
    .result__type {
      flex-flow: row nowrap;
      align-items: center; }
      .result__type > span {
        margin-top: 0; } }

.result__school .list__count:before {
  background: #6C93CE;
  border-color: #6C93CE; }

.result__school .search__result-location span {
  color: #6C93CE; }

@media all and (max-width: 980px) {
  #search_list_holder {
    display: grid; }
  #search-button, .search__form-holder {
    order: 5; }
  #search-button {
    order: 5; }
  #search_stats {
    order: 4; }
  #search_parish {
    order: 3; }
  #search_results {
    order: 1; } }

.map__marker {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Cabin", Arial, sans-serif;
  font-size: 0.875rem;
  cursor: pointer; }
  .map__marker:before {
    content: '';
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: -5;
    background: #a688b7; }
  .map__marker.school:before {
    background: #6C93CE; }
  .map__marker.active, .map__marker.venue {
    font-size: 0;
    z-index: 1000; }
    .map__marker.active:before, .map__marker.venue:before {
      width: 2rem;
      height: 2rem;
      background: #fff;
      border: 3px solid #4A4A4A; }
    .map__marker.active:after, .map__marker.venue:after {
      position: absolute;
      width: 2rem;
      height: 2rem;
      content: '';
      background: url(../img/cross.svg) center center/contain no-repeat;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.25rem;
      color: #4A4A4A; }
  .map__marker.school.active:after {
    width: 1.25rem;
    height: 1.25rem;
    background: url(../img/school.svg) center center/contain no-repeat; }
  .map__marker.small:before {
    width: 1.375rem;
    height: 1.375rem; }
  .map__marker.small:after {
    font-size: 1.5rem;
    font-weight: bold; }

@media (max-width: 767px) {
  #find_us_map {
    margin-bottom: 1rem; } }

.pac-container {
  width: calc(100% + 6px) !important;
  top: calc(100% + 3px) !important;
  left: -3px !important;
  right: -3px !important;
  box-shadow: none;
  border-top: none;
  border-radius: 0;
  font-family: "Open Sans", Arial, sans-serif; }
  .pac-container .pac-item {
    border-top: none;
    padding-left: calc(1rem + 3px);
    cursor: pointer; }
    .pac-container .pac-item + .pac-item {
      border-top: 3px solid #F5F5F5; }
  .pac-container .pac-icon {
    margin-right: 1rem;
    vertical-align: middle; }
  .pac-container .pac-item-selected,
  .pac-container .pac-item-hover,
  .pac-container .pac-item:hover {
    background: #fff; }
  .pac-container:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fcfcfc;
    z-index: -10;
    border-bottom: 1.325rem solid #a688b7; }
  .pac-container:after {
    filter: brightness(0) invert(1);
    height: 20px;
    margin-right: 2px; }

.embed-container {
  position: relative;
  padding-bottom: 100%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }

.intro__map .embed-container {
  padding-bottom: 50%; }

@media (min-width: 980px) {
  .embed-container.acny-as-homepage {
    max-width: unset;
    width: calc(100% + 5rem);
    margin-left: -3rem;
    padding-bottom: calc(100% + 5rem); } }

.embed-container embed,
.embed-container iframe,
.embed-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.christmas-events-banner {
  background-color: #003A5D;
  color: #ffffff;
  text-align: center;
  padding: 1.25rem 1.5rem;
  gap: 1rem;
  text-decoration: none;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="254" height="130" fill="none"><path fill="%23003A5D" d="M4.765 144.52c-62.796 9.592-90.891 72.819-152.401 56.933-56.448-14.58-87.951-51.519-103.414-107.809C-281.198-16.096-132.469-82.327-18.821-81.999c136.159.393 357.111 185.086 239.486 253.774-73.413 42.87-131.89-40.087-215.9-27.255Z"/></svg>'), url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="227" height="130" fill="none"><path fill="%23003A5D" d="M262.827 143.896c-63.536 9.693-91.963 73.583-154.197 57.53-57.114-14.732-88.988-52.059-104.634-108.94C-26.507-18.403 123.976-85.33 238.963-84.998c137.764.398 361.32 187.027 242.309 256.436-74.278 43.32-133.445-40.508-218.445-27.541Z"/></svg>');
  background-repeat: no-repeat;
  background-position: left top, right top;
  background-size: 11rem 100%; }
  .christmas-events-banner .christmas-logo {
    width: 7.75rem;
    margin: 0 auto; }
  .christmas-events-banner .banner-content {
    width: 100%; }
  .christmas-events-banner h3 {
    font-size: 2.5rem;
    line-height: 3rem;
    margin: 0; }
  .christmas-events-banner p {
    font-size: 1.25rem;
    line-height: 1.875rem;
    margin-top: 0; }
    .christmas-events-banner p span {
      color: #E7AA22; }
  .christmas-events-banner .button {
    background-color: #E7AA22;
    border-color: #E7AA22;
    color: #003A5D;
    margin-right: auto;
    margin-left: auto;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 700; }
  @media (min-width: 640px) {
    .christmas-events-banner {
      background-color: #004874;
      flex-wrap: nowrap; }
      .christmas-events-banner .banner-content {
        width: auto; }
      .christmas-events-banner .christmas-logo {
        margin: 0; }
      .christmas-events-banner .button {
        margin-right: 0;
        margin-left: 0; } }
  @media (min-width: 980px) {
    .christmas-events-banner {
      background-color: #003A5D;
      flex-wrap: wrap;
      margin-bottom: -1rem; }
      .christmas-events-banner .banner-content {
        width: 100%; }
      .christmas-events-banner .christmas-logo {
        margin: 0 auto; }
      .christmas-events-banner .button {
        margin-right: auto;
        margin-left: auto; } }
  @media all and (min-width: 1080px) {
    .christmas-events-banner {
      background-color: #004874;
      flex-wrap: nowrap; }
      .christmas-events-banner .banner-content {
        width: auto; }
      .christmas-events-banner .christmas-logo {
        width: 7.75rem;
        margin: 0; }
      .christmas-events-banner .button {
        margin-right: 0;
        margin-left: 0; } }
  @media (min-width: 1280px) {
    .christmas-events-banner {
      background-size: 15rem 100%; }
      .christmas-events-banner .christmas-logo {
        width: 8.75rem; }
      .christmas-events-banner .button {
        margin-right: 1rem; } }

@media (min-width: 980px) {
  .main-content .content__panel.event__intro,
  .main-content .content__panel.content__panel--with-tabs {
    padding: 0 1rem 1rem !important; } }

@media all and (max-width: 599px) {
  .calendar__panel-intro .tabs__holder,
  .content__panel.event__intro .tabs__holder,
  .content__panel.content__panel--with-tabs .tabs__holder {
    padding: 0.75rem 0.35rem 0 0.35rem; }
  .calendar__panel-intro .tab,
  .content__panel.event__intro .tab,
  .content__panel.content__panel--with-tabs .tab {
    padding: 0.75rem 0.75rem 0 0.75rem;
    font-size: 1.1rem;
    line-height: 1.35;
    display: block; }
    .calendar__panel-intro .tab span,
    .content__panel.event__intro .tab span,
    .content__panel.content__panel--with-tabs .tab span {
      display: inline-block; } }

@media all and (max-width: 399px) {
  .calendar__panel-intro .tab,
  .content__panel.event__intro .tab,
  .content__panel.content__panel--with-tabs .tab {
    flex-basis: 0; } }

.calendar__panel-intro form,
.content__panel.event__intro form,
.content__panel.content__panel--with-tabs form {
  padding: 0; }

.events__header {
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #ffffff; }

.events__copy-label {
  max-width: 38rem; }
  .events__copy-label .control-label {
    font-size: 1em;
    text-transform: unset; }

.events-calendar__wrapper {
  flex-direction: column;
  display: flex;
  border: 3px solid #E4E4E4; }
  @media (min-width: 760px) {
    .events-calendar__wrapper {
      flex-direction: row; } }

.events-calendar {
  flex-grow: 1;
  padding: 1rem;
  border-bottom: 3px solid #E4E4E4; }
  @media (min-width: 760px) {
    .events-calendar {
      border-right: 3px solid #E4E4E4;
      border-bottom: 0; } }
  .events-calendar .ui-state-highlight {
    background-color: #E8A065; }
    .events-calendar .ui-state-highlight a {
      background-color: transparent !important; }
  .events-calendar .ui-datepicker-today {
    border: 2px solid #E8A065; }
    .events-calendar .ui-datepicker-today .ui-state-highlight {
      background-color: transparent !important; }

.events-calendar__selected {
  padding: 0 1rem; }
  @media (min-width: 760px) {
    .events-calendar__selected {
      flex-basis: 60%; } }
  .events-calendar__selected .form-row {
    border-bottom: 3px solid #E4E4E4; }
    .events-calendar__selected .form-row:last-child {
      border-bottom: 0; }
  .events-calendar__selected .form-row > * {
    display: none; }
  .events-calendar__selected .events-calendar__listing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0; }
  .events-calendar__selected .events-calendar__listing-value {
    flex: 1 0 auto;
    margin: 0; }
  .events-calendar__selected .events-calendar__listing-delete {
    width: auto;
    margin: 0;
    cursor: pointer;
    padding: 0;
    border: 0; }
    .events-calendar__selected .events-calendar__listing-delete:hover {
      background-color: transparent; }
    .events-calendar__selected .events-calendar__listing-delete .icon {
      fill: #E8A065; }

.billboard__side {
  margin-bottom: 0; }

.add-to-calendar .icon {
  margin-left: 1rem;
  width: 1.25rem;
  height: 1.25rem; }

.feature-images-grid__item {
  max-width: 210px;
  width: 100%;
  max-height: 160px;
  height: 100%;
  object-fit: cover; }

.weekly p[id*="error_"] {
  background: #DE2F62;
  color: #fff !important;
  padding: 0.5rem;
  margin-top: 0; }

.event__intro .tab.active {
  pointer-events: none; }

.event__intro--benefice {
  gap: 1rem;
  padding: 0 1rem 1rem; }
  @media (min-width: 980px) {
    .event__intro--benefice {
      padding: 0; } }
  .event__intro--benefice p {
    margin: 0; }
  .event__intro--benefice #benefice-events-filter-control .icon {
    margin: 0 0 0 1ch; }

#benefice-events-filter form #div_id_venues .controls {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  padding: 0 1rem 1rem; }
  @media (min-width: 980px) {
    #benefice-events-filter form #div_id_venues .controls {
      padding: 0; } }
  #benefice-events-filter form #div_id_venues .controls > .controls__checkbox {
    flex: 1 1 auto; }
    #benefice-events-filter form #div_id_venues .controls > .controls__checkbox input[type='checkbox'], #benefice-events-filter form #div_id_venues .controls > .controls__checkbox .checkbox {
      margin: 0; }
    #benefice-events-filter form #div_id_venues .controls > .controls__checkbox .checkbox {
      padding: 0; }

#benefice-events-filter form #div_id_status {
  margin: 1rem 0 1rem 1rem; }
  @media (min-width: 980px) {
    #benefice-events-filter form #div_id_status {
      margin: 1rem 0 0; } }

.content-hint {
  color: #8B63A1; }
  .content-hint p {
    margin-top: 0.25rem; }
  @media (min-width: 980px) {
    .content-hint {
      color: #4A4A4A;
      display: flex;
      justify-content: space-between; } }
  .content-hint :first-child {
    margin-top: 1rem;
    margin-left: auto; }

textarea#id_description {
  height: 6.5rem; }

.news-edit__buttons .controls.controls__checkbox {
  display: flex; }
  @media (min-width: 980px) {
    .news-edit__buttons .controls.controls__checkbox {
      padding-left: 1rem; } }

.news-edit__buttons #div_id_is_published .checkbox {
  padding-right: 1.5rem; }

.news-edit__buttons .urgent-delete {
  min-width: 6.5rem; }

@media all and (max-width: 520px) {
  .news-edit__buttons #div_id_is_published .checkbox {
    width: 8rem;
    padding-right: 0; } }

@media (min-width: 980px) and (max-width: 1100px) {
  .news-edit__buttons {
    flex-flow: row wrap !important; } }

.news-edit__buttons #id_is_published {
  display: none; }

#error_1_id_publish_at.help-inline {
  position: absolute;
  width: calc(60% - 0.5rem);
  top: 6rem; }

.news__intro {
  position: relative;
  margin-top: 0rem !important;
  padding-bottom: 1rem;
  padding-top: 0rem !important; }
  .news__intro .headline, .news__intro .main-content form fieldset legend, .main-content form fieldset .news__intro legend,
  .news__intro #lightbox form fieldset legend, #lightbox form fieldset .news__intro legend {
    width: calc(100% - 4rem);
    max-width: 40rem;
    margin: 1rem 0 3rem 0;
    font-size: 2.25rem; }
  @media (min-width: 980px) {
    .news__intro {
      padding: 2rem 2rem 0 2rem; }
      .news__intro .headline, .news__intro .main-content form fieldset legend, .main-content form fieldset .news__intro legend,
      .news__intro #lightbox form fieldset legend, #lightbox form fieldset .news__intro legend {
        font-size: 3.375rem; } }
  body.admin-view .news__intro {
    color: #4A4A4A; }

.news__editor-links .active {
  color: #a688b7; }

.news__editor-links a {
  text-decoration: none; }

.news__editor-links .icon {
  width: 2rem;
  height: 2rem; }

.news__editor-links div {
  display: inline; }

.card__grid__create-link {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem 0 0.5rem 1rem;
  background: #a688b7;
  color: #F5F5F5;
  text-decoration: none; }
  .card__grid__create-link .btn {
    display: flex;
    float: right;
    justify-content: center;
    align-items: center;
    margin-top: -0.5rem;
    padding-top: 0.6rem; }
    .card__grid__create-link .btn .icon {
      margin: 0 auto; }
  .card__grid__create-link:only-child {
    margin-bottom: 0rem; }

.news__list__new-link {
  text-align: center; }
  .news__list__new-link a {
    padding: 0.75rem 2.5rem; }

table.news-list {
  font-size: 1rem;
  margin: 1.5rem 1rem; }
  table.news-list th a {
    text-decoration: none; }
  table.news-list th .tag {
    text-decoration: none;
    padding: 0.25rem 0.5rem; }
  table.news-list tr th:first-child {
    width: 60%; }
  table.news-list .table__name {
    display: flex;
    padding: 0.5rem 0; }
    table.news-list .table__name .image {
      display: none;
      min-width: 7rem;
      min-height: 4.5rem;
      max-height: 6rem;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center; }
    table.news-list .table__name a {
      display: block;
      font-size: 1.25rem !important; }
    table.news-list .table__name .image__fieldset-preview {
      width: 7rem;
      height: 4.5rem;
      margin-right: 0; }
    table.news-list .table__name .tag__list {
      margin: 1rem -1rem 0 0; }
      table.news-list .table__name .tag__list span {
        margin-right: 0.5rem; }
    table.news-list .table__name.notice {
      display: block; }
      table.news-list .table__name.notice a {
        font-size: 1.2rem !important;
        padding-left: 0;
        margin-bottom: 0.25rem; }
        table.news-list .table__name.notice a.table__notice-link {
          display: inline;
          font-weight: 500;
          font-size: 0.8rem !important;
          border-bottom: 1px solid #E8A065; }
    @media all and (min-width: 768px) {
      table.news-list .table__name {
        font-size: 1.2rem; }
        table.news-list .table__name .image {
          display: block; }
        table.news-list .table__name a {
          font-size: 1.1rem !important;
          padding-left: 1rem; }
        table.news-list .table__name .tag__list {
          margin: 1rem -1rem 0 1rem; }
        table.news-list .table__name.notice a {
          font-size: 1.5rem !important; }
          table.news-list .table__name.notice a.table__notice-link {
            font-size: 1rem !important; } }
  table.news-list .table__date {
    color: #A5A5A5; }
  table.news-list .table__published {
    text-align: center; }
    table.news-list .table__published .icon {
      width: 2rem;
      height: 2rem;
      margin: 0 auto; }
  @media all and (max-width: 767px) {
    table.news-list {
      font-size: 0.8rem !important; }
      table.news-list th {
        white-space: normal;
        font-size: 0.75rem; }
      table.news-list td {
        width: 30%; }
        table.news-list td:first-of-type {
          width: 100%; }
        table.news-list td:last-of-type {
          width: 20%; }
      table.news-list .table__name a {
        font-size: 0.85rem !important; } }
  @media (min-width: 980px) {
    table.news-list {
      margin: 1.5rem 0; } }

.card__summary.news-post {
  display: flex;
  flex-flow: column nowrap; }
  .card__summary.news-post .content {
    width: 100%;
    word-break: break-word; }
    @media all and (min-width: 768px) {
      .card__summary.news-post .content {
        flex-grow: 1; } }
  .card__summary.news-post .news__meta {
    min-width: 100%;
    order: 1;
    padding-top: 2rem; }
    .card__summary.news-post .news__meta .social li {
      margin-right: 0.5rem; }
    .card__summary.news-post .news__meta .social .icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0; }
    .card__summary.news-post .news__meta .meta__description {
      position: relative;
      width: 100%;
      padding: 0.75rem 0 0.75rem 2rem;
      word-wrap: break-word; }
      .card__summary.news-post .news__meta .meta__description:not(:first-child) {
        border-top: 3px solid #F5F5F5; }
      .card__summary.news-post .news__meta .meta__description .btn {
        width: calc(100% - 1.5rem);
        margin-top: 0.5rem;
        text-align: center; }
      .card__summary.news-post .news__meta .meta__description > .icon:first-child {
        position: absolute;
        height: 1.5rem;
        width: 1.5rem;
        left: 0; }
      .card__summary.news-post .news__meta .meta__description .tag__list {
        margin-bottom: 0; }
        .card__summary.news-post .news__meta .meta__description .tag__list span {
          margin-right: 0.25rem; }
    @media all and (min-width: 768px) {
      .card__summary.news-post .news__meta {
        min-width: 30%;
        padding-left: 1.5rem;
        padding-top: 0; } }
  .card__summary.news-post .card__image {
    height: 15rem; }
    @media all and (min-width: 550px) {
      .card__summary.news-post .card__image {
        height: 22rem; } }
  @media all and (min-width: 768px) {
    .card__summary.news-post {
      flex-flow: row nowrap; } }

.news__copy-label {
  max-width: 38rem; }
  .news__copy-label .control-label {
    font-size: 1em;
    text-transform: unset; }

.resource-page__wrapper {
  background: #F1F1F1; }
  .resource-page__wrapper .main-content {
    height: 100%; }
    @media (min-width: 980px) {
      .resource-page__wrapper .main-content {
        padding: 0 0 0 1.25rem; } }
  .resource-page__wrapper .side-content {
    background: none;
    width: 24rem; }
  .resource-page__wrapper .page-layout::after {
    content: none; }
  .resource-page__wrapper .pebble-trail {
    color: #4A4A4A; }
  .resource-page__wrapper .billboard__panel {
    margin-top: 0 !important;
    margin-bottom: 0; }

.resourcehub_cat_icon {
  width: 0.5em;
  height: 0.5em;
  margin-top: 8px;
  margin-left: 6px; }

.resourcehub-list__toggle:checked ~ .navlist__item-title label .open {
  display: none; }

.resourcehub-list__toggle:checked ~ .navlist__item-title label .close {
  display: inline-block; }

.resourcehub-list__toggle:checked ~ .nested__list .resourcehub_child {
  display: block !important; }

.resourcehub-select img {
  width: auto;
  float: left;
  padding-right: 14px; }

.asset-page__files {
  display: flex;
  flex-flow: row wrap; }
  @media (min-width: 640px) {
    .asset-page__files {
      width: 70%;
      margin-right: 1rem; } }

.asset-page__file-terms {
  padding: 0.5rem 1rem;
  background: #fff; }
  .asset-page__file-terms .checkbox {
    display: flex;
    font-size: inherit;
    text-transform: none;
    font-size: 0.75rem; }
    .asset-page__file-terms .checkbox .custom__checkbox,
    .asset-page__file-terms .checkbox .custom__checkbox .icon {
      border-color: #A5A5A5;
      color: #8B63A1; }
    .asset-page__file-terms .checkbox span {
      margin-left: 0.75rem; }
  @media all and (min-width: 1240px) {
    .asset-page__file-terms {
      padding: 0.5rem; } }
  .asset-page__file-terms .asset-page__file-selected {
    display: flex; }
    .asset-page__file-terms .asset-page__file-selected .checkbox {
      font-size: 1.125rem; }
    .asset-page__file-terms .asset-page__file-selected span {
      width: 80%;
      margin-left: 0rem; }

.asset-page__file {
  display: block;
  padding: 0 2rem;
  width: 12.5rem;
  margin-right: 1.35rem; }
  .asset-page__file .asset-page__file-checkbox {
    display: flex;
    width: 12.5rem;
    padding: 0.5rem 0.5rem 0.5rem 0; }
    .asset-page__file .asset-page__file-checkbox .custom__checkbox {
      margin-right: 0; }
    .asset-page__file .asset-page__file-checkbox label.checkbox {
      width: 2rem; }
    .asset-page__file .asset-page__file-checkbox .icon {
      width: 1.5rem;
      height: 1.5rem;
      color: #A5A5A5; }
  .asset-page__file .asset-page__file-label {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 0.5rem 0.5rem 0.5rem;
    text-decoration: none;
    text-transform: none;
    font-size: 0.75rem;
    line-height: 16px; }
  .asset-page__file + .asset-page__file {
    border-top: 2px solid #F5F5F5; }
  @media all and (min-width: 1240px) {
    .asset-page__file {
      padding: 0; }
      .asset-page__file .asset-page__file-checkbox {
        padding: 0.5rem; } }

.asset-page__file-preview {
  width: 12.5rem;
  height: 12.5rem;
  cursor: zoom-in;
  position: relative;
  border-left: 2px solid #F5F5F5; }
  .asset-page__file-preview:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 0;
    transition: background 0.2s ease-in-out; }
  .asset-page__file-preview:hover::before {
    background: rgba(255, 255, 255, 0.7); }

.page-files__panel {
  width: 20rem;
  height: 30rem; }
  @media (min-width: 640px) {
    .page-files__panel {
      position: sticky;
      top: 0;
      right: 1rem;
      margin-top: -2rem; } }
  .page-files__panel a.asset-page__files-download,
  .page-files__panel button.asset-page__files-download {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    width: 100%;
    padding: 0.5rem 2rem 0.5rem 1.75rem;
    min-height: 2.875rem;
    font-size: 1.3125rem; }
    .page-files__panel a.asset-page__files-download .icon,
    .page-files__panel button.asset-page__files-download .icon {
      width: 1em;
      height: 1em;
      margin-right: 0.5rem; }
    @media all and (min-width: 1240px) {
      .page-files__panel a.asset-page__files-download,
      .page-files__panel button.asset-page__files-download {
        padding: 0.5rem; } }
  .page-files__panel input:disabled + label, .page-files__panel button:disabled {
    opacity: 0.5; }
  .page-files__panel input + label {
    opacity: 1; }

.asset-detail .billboard__panel {
  min-height: 9rem; }

.asset-detail .content__panel {
  position: relative; }

.asset-page__files-download-panel {
  background: #E8A065; }
  .asset-page__files-download-panel h4 {
    padding: 1rem 0.635rem 0 0.635rem; }

@media (min-width: 640px) {
  .asset-page__form {
    overflow-x: unset;
    height: 100%;
    display: flex;
    margin-right: -2rem; } }

.asset-page__message {
  font-size: 0.75rem; }

.page__wrapper.asset-page {
  overflow: unset; }

.asset__navigation .navlist .navlist__item {
  display: block; }
  .asset__navigation .navlist .navlist__item .indented__nav .resource-nav__toggle {
    right: -0.35rem; }
  .asset__navigation .navlist .navlist__item:hover .indented__nav a {
    color: #4A4A4A; }

.asset__navigation .navlist .navlist__item-title {
  margin-right: 2rem; }

.navlist .navlist__item .nested__list {
  display: none;
  margin: 0.5rem -0.75rem -0.75rem;
  background-color: #ffffff; }
  .navlist .navlist__item .nested__list .navlist__item {
    background-color: #ffffff;
    color: #4A4A4A;
    border-top: 0 !important; }
    .navlist .navlist__item .nested__list .navlist__item .icon {
      fill: #A5A5A5 !important; }
    .navlist .navlist__item .nested__list .navlist__item:hover {
      background: none; }
  .navlist .navlist__item .nested__list[aria-hidden='false'] {
    display: block; }
  @media (min-width: 980px) {
    .navlist .navlist__item .nested__list {
      margin: 0.5rem -1.625rem -0.75rem -1.625rem; } }

.nested__list .navlist__item:last-of-type {
  border-bottom: none !important; }

.resource-nav__toggle {
  display: inline-flex;
  position: absolute;
  top: 1rem;
  right: 0.2rem;
  outline: none;
  background: none;
  border: none; }
  .resource-nav__toggle .resource-nav__toggle-icon-close,
  .resource-nav__toggle .resource-nav__toggle-icon.icon {
    position: absolute;
    right: 0.5rem;
    top: 0.15rem;
    display: none;
    padding-left: 0.5rem;
    height: 0.75rem;
    transition: all 0.5s ease-in-out;
    fill: #A5A5A5;
    stroke: #A5A5A5; }
  .resource-nav__toggle .resource-nav__toggle-icon.icon {
    transition: all 0.2s ease-in-out;
    display: block; }
  .resource-nav__toggle[aria-expanded='true'] .resource-nav__toggle-icon-close {
    display: block; }
  .resource-nav__toggle[aria-expanded='true'] .resource-nav__toggle-icon.icon {
    display: none; }

.resource-nav__toggle--full-width {
  display: block;
  width: 100%;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
  font-family: "Cabin", Arial, sans-serif;
  color: #4A4A4A;
  font-size: 1.286rem;
  position: relative;
  top: 0;
  right: 0 !important; }
  .resource-nav__toggle--full-width .icon {
    top: 0.35rem !important;
    margin-right: 0; }
    @media (min-width: 980px) {
      .resource-nav__toggle--full-width .icon {
        right: -0.45rem !important; } }

.resource-nav__subnav-icon {
  position: absolute;
  left: -2rem;
  top: 0.35rem;
  height: 1rem;
  width: 1rem;
  fill: #A5A5A5; }

.content__panel.video-generator {
  padding: 2rem !important; }

.generate-video__wrapper .content__panel.video-generator.video-examples {
  min-height: 20rem; }

.video-examples__grid {
  display: flex;
  flex-flow: row wrap;
  width: 100%; }
  .video-examples__grid img {
    max-width: calc(50% - 1.5rem);
    min-width: calc(25% - 1.5rem);
    margin: 0 auto 2.5rem; }
    @media (min-width: 640px) {
      .video-examples__grid img {
        margin: 0 0 2.5rem; } }
  @media (min-width: 640px) {
    .video-examples__grid img:nth-of-type(odd) {
      margin-right: 1.25rem; }
    .video-examples__grid img:nth-of-type(even) {
      margin-left: 1.25rem; } }

@media (min-width: 640px) {
  .menu-grid {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 2.5rem; } }

.menu-intro {
  margin-bottom: 2.1875rem; }

.menu-grid__item {
  padding-bottom: 2.1875rem;
  margin-right: 2.25rem; }
  @media (min-width: 640px) {
    .menu-grid__item {
      width: calc(50% - 1.125rem); } }
  .menu-grid__item .image-container {
    position: relative;
    display: block; }
    .menu-grid__item .image-container img {
      display: block;
      width: 100%;
      object-fit: cover; }
  .menu-grid__item .action {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0; }
    .menu-grid__item .action .icon {
      width: 1.5rem;
      height: 1.5rem;
      fill: #4A4A4A; }
  .menu-grid__item h3 {
    font-size: 1.4375rem;
    margin-top: 0.75rem; }

.menu-grid__item:nth-of-type(2n) {
  margin-right: 0; }
  @media (min-width: 640px) {
    .menu-grid__item:nth-of-type(2n):not(.menu-grid__item--christmas) {
      margin-right: 2.25rem; } }

@media (min-width: 640px) {
  .menu-grid__item:not(.menu-grid__item--christmas):first-of-type {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 3.25rem; }
    .menu-grid__item:not(.menu-grid__item--christmas):first-of-type h3 {
      font-size: 2.25rem;
      margin-top: 0; }
    .menu-grid__item:not(.menu-grid__item--christmas):first-of-type .menu-grid__item-image, .menu-grid__item:not(.menu-grid__item--christmas):first-of-type .menu-grid__item-text {
      width: calc(50% - 1.125rem); }
    .menu-grid__item:not(.menu-grid__item--christmas):first-of-type .menu-grid__item-image + .menu-grid__item-text {
      margin-left: 2.25rem; } }

.mobile-header {
  display: block;
  margin: 1rem; }
  @media (min-width: 980px) {
    .mobile-header {
      display: none; } }

.unsplash label[for=id_query] {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.unsplash form {
  display: flex;
  margin: 0 0 0.5rem 0;
  padding-top: 0; }
  .unsplash form .buttonHolder {
    position: relative; }
    .unsplash form .buttonHolder .icon {
      position: absolute;
      left: 0.8rem;
      right: 0;
      top: 0.8rem;
      bottom: 0;
      width: 2rem;
      height: 2rem;
      color: white;
      pointer-events: none; }
  .unsplash form div:first-child {
    width: calc(100% - 2.5rem);
    margin-right: 0.5rem; }
  .unsplash form #div_id_query {
    width: 100%; }

.unsplash .resource-grid-item {
  border: none;
  background: none; }

.unsplash .resource-grid-item__text {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .unsplash .resource-grid-item__text p {
    font-size: 0.75rem;
    line-height: 1.2;
    text-align: left; }
  .unsplash .resource-grid-item__text .icon {
    height: 1.5em;
    width: 1.5em;
    margin-top: -0.5rem;
    color: #A5A5A5; }

.unsplash .resource-grid {
  display: grid;
  grid-gap: 1.5vw;
  margin: 0 0 1rem 0;
  grid-template-columns: repeat(auto-fit, minmax(200px, 4fr)); }
  @media (min-width: 640px) {
    .unsplash .resource-grid .js-grid-gutter {
      width: 1rem; } }
  .unsplash .resource-grid .js-grid-sizer {
    width: 100%; }
    @media (min-width: 640px) {
      .unsplash .resource-grid .js-grid-sizer {
        width: calc(50% - 0.5rem); } }

.unsplash .resource-grid-item {
  width: 100%; }
  @media (min-width: 640px) {
    .unsplash .resource-grid-item {
      width: calc(25% - 0.5rem); } }

.unsplash .content__divider {
  margin: 2rem -1rem; }

@media (max-width: 980px) {
  .tag-group-page {
    background: transparent; } }

@media (max-width: 767px) {
  .tag-group-page h1 {
    font-size: 2.25rem; } }

.tag-group-page .headline.mt1, .tag-group-page .main-content form fieldset legend.mt1, .main-content form fieldset .tag-group-page legend.mt1,
.tag-group-page #lightbox form fieldset legend.mt1, #lightbox form fieldset .tag-group-page legend.mt1 {
  margin-top: 2rem; }

.tag-group-page .side-content {
  padding-top: 0; }
  .tag-group-page .side-content p {
    max-width: 100%;
    padding-right: 1rem; }
  @media (max-width: 980px) {
    .tag-group-page .side-content {
      padding-left: 0;
      padding-right: 0; }
      .tag-group-page .side-content > p {
        padding-left: 1rem;
        padding-right: 1rem; } }
  .tag-group-page .side-content .search__form-button {
    position: absolute;
    padding-right: 0;
    bottom: 0;
    right: 0.125rem; }
    @media (min-width: 980px) {
      .tag-group-page .side-content .search__form-button {
        bottom: 1rem; } }

.tag-group-page .side__head-area {
  background-color: #382e75;
  color: #fff;
  padding: 1rem; }
  .tag-group-page .side__head-area .christmas-action-headline {
    margin-top: 45px; }
  .tag-group-page .side__head-area .coronation-logo {
    display: block;
    width: 60%;
    max-width: 200px;
    margin: 2rem auto 0 auto; }
  .tag-group-page .side__head-area .coronation-action-headline {
    margin-top: 3rem; }
  @media (min-width: 981px) {
    .tag-group-page .side__head-area {
      padding-right: 0; } }
  @media (max-width: 767px) {
    .tag-group-page .side__head-area .standfirst {
      margin-bottom: 0; } }

.tag-group-page .headline:after, .tag-group-page .main-content form fieldset legend:after, .main-content form fieldset .tag-group-page legend:after,
.tag-group-page #lightbox form fieldset legend:after, #lightbox form fieldset .tag-group-page legend:after {
  bottom: -0.35rem; }

.tag-group-page .standfirst {
  line-height: 1.6;
  font-size: 1.1rem; }
  @media (min-width: 768px) {
    .tag-group-page .standfirst {
      font-size: 1.35rem; } }

.tag-group-page .search__form-header {
  display: none; }

.tag-group-page .search__form-body {
  display: flex;
  padding: 0; }
  @media (min-width: 541px) {
    .tag-group-page .search__form-body {
      margin-top: 1rem; } }
  @media (min-width: 981px) {
    .tag-group-page .search__form-body {
      margin-top: 4rem; } }

.tag-group-page .search__form-button {
  width: auto;
  margin-left: 0.25rem; }
  .tag-group-page .search__form-button button {
    border: 3px #E8A065 solid;
    padding: 0.75rem; }
    .tag-group-page .search__form-button button:not(:focus) {
      background: transparent; }
  .tag-group-page .search__form-button span {
    position: absolute;
    left: -999999px; }
  .tag-group-page .search__form-button .icon {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0; }

.tag-group-page .search__form-section {
  width: calc(100% - 4rem); }
  .tag-group-page .search__form-section:first-child {
    display: none; }

.tag-group-page .search__string-holder {
  background-color: #fff; }
  .tag-group-page .search__string-holder svg {
    fill: #4A4A4A; }

.tag-group-page .main-content .search__map-holder {
  height: calc(100vh - 10rem); }
  @media (max-width: 980px) {
    .tag-group-page .main-content .search__map-holder {
      height: 26rem; } }

.tag-group-page .tag-group__counter {
  text-decoration: none; }
  .tag-group-page .tag-group__counter:hover, .tag-group-page .tag-group__counter:focus {
    z-index: 100; }
    .tag-group-page .tag-group__counter:hover:after, .tag-group-page .tag-group__counter:focus:after {
      display: block; }
  .tag-group-page .tag-group__counter:focus {
    outline: none; }
    .tag-group-page .tag-group__counter:focus:before {
      border: 3px #9ecaed solid;
      box-shadow: 0 0 10px #9ecaed; }
  .tag-group-page .tag-group__counter:before {
    width: 100%;
    min-width: 0.85rem;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    padding: 0 0.35rem; }
  .tag-group-page .tag-group__counter:after {
    content: attr(tooltip);
    display: none;
    position: absolute;
    right: -0.5rem;
    top: 50%;
    z-index: 100;
    transform: translateX(100%);
    padding: 0.1rem 0.3rem;
    background-color: rgba(0, 0, 0, 0.7); }

.tag-group-page .church__legend {
  font-size: 1rem;
  line-height: 1.35; }
  .tag-group-page .church__legend .tag-group__counter {
    transform: translate(0%, 0%);
    position: static;
    margin-right: 1.25rem; }
    .tag-group-page .church__legend .tag-group__counter:after {
      display: none; }
  .tag-group-page .church__legend span {
    display: inline; }

.tag-group-page .main-content {
  order: 1; }

.tag-group-page .panel__grid {
  margin-top: 1rem; }
  @media (max-width: 980px) {
    .tag-group-page .panel__grid {
      margin: 1rem;
      margin-bottom: 2rem; } }

.tag-group-page .panel {
  margin-bottom: 0; }
  .tag-group-page .panel:nth-child(odd) .panel__image:after {
    background: rgba(166, 136, 183, 0.9); }
  .tag-group-page .panel:nth-child(even) {
    background: #95317A; }
    .tag-group-page .panel:nth-child(even) .panel__image:after {
      opacity: 0.8; }
  @media (max-width: 1199px) {
    .tag-group-page .panel h3 {
      font-size: 1.75rem; } }
  @media (max-width: 980px) {
    .tag-group-page .panel {
      margin-right: 0; } }
  @media (max-width: 980px) and (min-width: 768px) {
    .tag-group-page .panel {
      width: 50%; } }

.coronation__map-page .panel__collage.collage__map-page .panel__1 {
  background: #7D1634;
  color: #ffffff; }
  .coronation__map-page .panel__collage.collage__map-page .panel__1 .icon {
    border-color: #B5800E; }

@media all and (min-width: 600px) {
  .panel__collage.collage__map-page {
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto; } }

.panel__collage.collage__map-page .panel {
  color: #4A4A4A; }
  .panel__collage.collage__map-page .panel > .icon {
    border: 3px solid #004874;
    padding: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    bottom: 1.25rem; }
  .panel__collage.collage__map-page .panel .panel__content {
    padding: 1.25rem 1.75rem 2.7rem 1.75rem; }
    .panel__collage.collage__map-page .panel .panel__content h3 {
      margin-bottom: 0; }
    .panel__collage.collage__map-page .panel .panel__content p {
      padding-right: 0.25rem; }

.panel__collage.collage__map-page .panel__1 {
  background: #E7AA22; }
  @media (max-width: 980px) and (min-width: 768px) {
    .panel__collage.collage__map-page .panel__1 {
      width: 100%; } }

.panel__collage.collage__map-page .panel__2 {
  background: #F5F5F5; }
  @media (max-width: 980px) and (min-width: 768px) {
    .panel__collage.collage__map-page .panel__2 {
      width: 100%; } }

.panel__collage.collage__map-page .panel.panel__1 > .panel__image::after {
  background-color: transparent; }

.generate-video__wrapper .billboard__video {
  height: auto;
  width: 50%;
  display: none;
  position: absolute;
  right: 0; }
  @media all and (min-width: 981px) {
    .generate-video__wrapper .billboard__video {
      display: inline-block; } }

@media all and (min-width: 981px) {
  .generate-video__wrapper .billboard__summary {
    min-height: 7.5rem; } }

@media all and (min-width: 981px) {
  .generate-video__wrapper .embed-section {
    height: 16rem; } }

.generate-video__wrapper .embed-section p {
  width: 100%; }
  @media all and (min-width: 981px) {
    .generate-video__wrapper .embed-section p {
      width: 40%; } }

.generate-video__wrapper .content__panel {
  margin-top: 0px; }
  .generate-video__wrapper .content__panel.media-asset-generator #div_id_social_platforms .controls {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .generate-video__wrapper .content__panel.media-asset-generator #div_id_social_platforms .controls .help-block {
      display: block;
      width: 100%; }
    .generate-video__wrapper .content__panel.media-asset-generator #div_id_social_platforms .controls .controls__checkbox {
      margin-right: 2rem; }
    .generate-video__wrapper .content__panel.media-asset-generator #div_id_social_platforms .controls input {
      margin: 0;
      padding: 0;
      height: 0; }
    .generate-video__wrapper .content__panel.media-asset-generator #div_id_social_platforms .controls label {
      margin: 0;
      padding: 0; }
  .generate-video__wrapper .content__panel.media-asset-generator .form__holder::before {
    height: 2px;
    width: 100%; }
  .generate-video__wrapper .content__panel.media-asset-generator .form__holder:first-of-type {
    margin-top: 0;
    padding-top: 0; }
    .generate-video__wrapper .content__panel.media-asset-generator .form__holder:first-of-type::before {
      display: none; }
  @media all and (min-width: 981px) {
    .generate-video__wrapper .content__panel.media-asset-generator {
      min-height: 32em; } }
  .generate-video__wrapper .content__panel.media-asset-generator form > * {
    margin-bottom: 10px; }
  .generate-video__wrapper .content__panel.media-asset-generator label {
    line-height: 2rem; }
  .generate-video__wrapper .content__panel.media-asset-generator form {
    width: 100%; }
    @media all and (min-width: 981px) {
      .generate-video__wrapper .content__panel.media-asset-generator form {
        width: 100%;
        display: inline-block; } }
  .generate-video__wrapper .content__panel.media-asset-generator [name=subtitle] {
    height: 6rem; }
  .generate-video__wrapper .content__panel.media-asset-generator .headline, .generate-video__wrapper .content__panel.media-asset-generator .main-content form fieldset legend, .main-content form fieldset .generate-video__wrapper .content__panel.media-asset-generator legend,
  .generate-video__wrapper .content__panel.media-asset-generator #lightbox form fieldset legend, #lightbox form fieldset .generate-video__wrapper .content__panel.media-asset-generator legend {
    margin-top: 0; }
  .generate-video__wrapper .content__panel.media-asset-generator .video-preview-container {
    height: auto;
    width: 100%;
    margin-bottom: 30px; }
    .generate-video__wrapper .content__panel.media-asset-generator .video-preview-container div {
      font-size: 1.3125rem;
      text-align: center; }
    .generate-video__wrapper .content__panel.media-asset-generator .video-preview-container .video-preview {
      position: relative;
      text-align: center;
      color: white; }
    .generate-video__wrapper .content__panel.media-asset-generator .video-preview-container .video-title {
      position: absolute;
      left: 50%;
      top: 20%;
      transform: translate(-50%, 0%);
      font-family: 'Cabin';
      font-weight: bold;
      margin: 0;
      word-wrap: break-word;
      width: calc(100% - 100px);
      font-size: 3.5vw; }
      @media all and (min-width: 981px) {
        .generate-video__wrapper .content__panel.media-asset-generator .video-preview-container .video-title {
          top: 20%;
          font-size: 1.4vw; } }
    .generate-video__wrapper .content__panel.media-asset-generator .video-preview-container .video-subtitle {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, 0%);
      font-family: 'Cabin';
      font-weight: bold;
      margin: 0;
      word-wrap: break-word;
      width: calc(100% - 100px);
      font-size: 2.3vw; }
      @media all and (min-width: 981px) {
        .generate-video__wrapper .content__panel.media-asset-generator .video-preview-container .video-subtitle {
          font-size: 0.9vw;
          top: 50%; } }
    .generate-video__wrapper .content__panel.media-asset-generator .video-preview-container .video-preview-cta {
      background-color: #E4E4E4;
      padding: 5px 7px;
      color: #4A4A4A;
      display: flex;
      align-items: center; }
    .generate-video__wrapper .content__panel.media-asset-generator .video-preview-container .video-preview-btn {
      border: none;
      font-size: 14px;
      text-transform: capitalize;
      padding: 0;
      margin-left: 8px; }
    .generate-video__wrapper .content__panel.media-asset-generator .video-preview-container--landscape .video-title {
      top: 25%; }
      @media all and (min-width: 981px) {
        .generate-video__wrapper .content__panel.media-asset-generator .video-preview-container--landscape .video-title {
          top: 25%;
          font-size: 1.1vw;
          line-height: 1.2vw; } }
    .generate-video__wrapper .content__panel.media-asset-generator .video-preview-container--landscape .video-subtitle {
      left: 50%;
      bottom: 30%; }
      @media all and (min-width: 981px) {
        .generate-video__wrapper .content__panel.media-asset-generator .video-preview-container--landscape .video-subtitle {
          bottom: 30%;
          font-size: 0.7vw;
          line-height: 0.8vw; } }
  @media all and (min-width: 981px) {
    .generate-video__wrapper .content__panel.video-generator {
      min-height: 32em; } }
  .generate-video__wrapper .content__panel.video-generator form > * {
    margin-bottom: 10px; }
  .generate-video__wrapper .content__panel.video-generator label {
    line-height: 2rem; }
  .generate-video__wrapper .content__panel.video-generator form {
    width: 100%; }
    @media all and (min-width: 981px) {
      .generate-video__wrapper .content__panel.video-generator form {
        width: 45%;
        display: inline-block; } }
  .generate-video__wrapper .content__panel.video-generator [name=subtitle] {
    height: 6rem; }
  .generate-video__wrapper .content__panel.video-generator .headline, .generate-video__wrapper .content__panel.video-generator .main-content form fieldset legend, .main-content form fieldset .generate-video__wrapper .content__panel.video-generator legend,
  .generate-video__wrapper .content__panel.video-generator #lightbox form fieldset legend, #lightbox form fieldset .generate-video__wrapper .content__panel.video-generator legend {
    margin-top: 0; }
  .generate-video__wrapper .content__panel.video-generator .video-preview-container {
    height: auto;
    width: 100%; }
    .generate-video__wrapper .content__panel.video-generator .video-preview-container div {
      font-size: 1.3125rem;
      text-align: center;
      margin-bottom: 14px; }
    @media all and (min-width: 981px) {
      .generate-video__wrapper .content__panel.video-generator .video-preview-container {
        width: 50%;
        float: right; } }
    .generate-video__wrapper .content__panel.video-generator .video-preview-container .video-preview {
      position: relative;
      text-align: center;
      color: white; }
    .generate-video__wrapper .content__panel.video-generator .video-preview-container .video-title {
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translate(-50%, 0%);
      font-family: 'Cabin';
      font-weight: bold;
      margin: 0;
      word-wrap: break-word;
      width: 100%;
      font-size: 3.5vw; }
      @media all and (min-width: 981px) {
        .generate-video__wrapper .content__panel.video-generator .video-preview-container .video-title {
          top: 51%;
          font-size: 1.4vw; } }
    .generate-video__wrapper .content__panel.video-generator .video-preview-container .video-subtitle {
      position: absolute;
      left: 50%;
      bottom: 24%;
      transform: translate(-50%, 0%);
      font-family: 'Cabin';
      font-weight: bold;
      margin: 0;
      word-wrap: break-word;
      width: 100%;
      font-size: 2.3vw; }
      @media all and (min-width: 981px) {
        .generate-video__wrapper .content__panel.video-generator .video-preview-container .video-subtitle {
          font-size: 0.9vw;
          bottom: 23%; } }
  .generate-video__wrapper .content__panel.download-container {
    height: 6rem;
    padding-top: 1rem;
    padding-right: 2rem; }
    .generate-video__wrapper .content__panel.download-container #video-download-button {
      margin-right: 0px;
      margin-left: auto;
      display: block;
      height: 4rem;
      font-size: 21px;
      line-height: 34px;
      padding: 0.5rem 1rem;
      border: 3px solid #E8A065;
      -webkit-appearance: none;
      -moz-appearance: none;
      background: none;
      text-transform: uppercase;
      cursor: pointer;
      border-radius: 0;
      font-family: 'Cabin', Arial, sans-serif;
      color: #4a4a4a; }
  .generate-video__wrapper .content__panel form[id=social-generator-form] {
    width: 100%; }

.success-video__wrapper .headline, .success-video__wrapper .main-content form fieldset legend, .main-content form fieldset .success-video__wrapper legend,
.success-video__wrapper #lightbox form fieldset legend, #lightbox form fieldset .success-video__wrapper legend {
  max-width: 45%; }

.success-video__wrapper .social li {
  margin-right: 0.5rem; }

.success-video__wrapper .social .icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0; }

.success-video__wrapper .social-container {
  padding: 0.75rem 0 0.75rem 2rem;
  position: relative; }
  @media all and (min-width: 450px) {
    .success-video__wrapper .social-container {
      right: 35px;
      position: absolute;
      display: inline-block; } }
  @media all and (min-width: 981px) {
    .success-video__wrapper .social-container {
      right: 20px; } }
  .success-video__wrapper .social-container > .icon:first-child {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    left: 0; }

.success-video__wrapper #video-ready-preview {
  border-radius: 5px;
  margin-bottom: 20px; }

.success-video__wrapper .pebble-trail svg,
.generate-video__wrapper .pebble-trail svg {
  fill: #E8A065; }

.success-video__wrapper .billboard__panel,
.generate-video__wrapper .billboard__panel {
  display: block;
  margin-bottom: 0; }

.success-video__wrapper .billboard__summary,
.generate-video__wrapper .billboard__summary {
  font-family: 'Cabin', Arial, sans-serif;
  font-size: 1.43rem;
  width: 100%; }
  @media all and (min-width: 981px) {
    .success-video__wrapper .billboard__summary,
    .generate-video__wrapper .billboard__summary {
      width: 34rem; } }

.success-video__wrapper .campaign-two-col,
.generate-video__wrapper .campaign-two-col {
  display: flex;
  flex-direction: column; }
  @media all and (min-width: 700px) {
    .success-video__wrapper .campaign-two-col,
    .generate-video__wrapper .campaign-two-col {
      flex-direction: row; } }
  @media all and (min-width: 700px) {
    .success-video__wrapper .campaign-two-col .col-1,
    .generate-video__wrapper .campaign-two-col .col-1 {
      width: 50%; } }
  .success-video__wrapper .campaign-two-col .col-1 .controls,
  .generate-video__wrapper .campaign-two-col .col-1 .controls {
    width: fit-content; }
  .success-video__wrapper .campaign-two-col .col-2,
  .generate-video__wrapper .campaign-two-col .col-2 {
    display: flex;
    flex-direction: column; }
    @media all and (min-width: 700px) {
      .success-video__wrapper .campaign-two-col .col-2,
      .generate-video__wrapper .campaign-two-col .col-2 {
        width: 50%; } }
    .success-video__wrapper .campaign-two-col .col-2 img,
    .generate-video__wrapper .campaign-two-col .col-2 img {
      max-width: 260px;
      width: 100%; }
    .success-video__wrapper .campaign-two-col .col-2 h4,
    .generate-video__wrapper .campaign-two-col .col-2 h4 {
      font-size: 21px;
      letter-spacing: 0;
      line-height: 24px;
      text-transform: uppercase;
      text-align: left; }
  .success-video__wrapper .campaign-two-col .video-preview-container,
  .generate-video__wrapper .campaign-two-col .video-preview-container {
    padding: 0 40px; }
    .success-video__wrapper .campaign-two-col .video-preview-container h4,
    .generate-video__wrapper .campaign-two-col .video-preview-container h4 {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      text-transform: uppercase;
      text-align: left; }
  .success-video__wrapper .campaign-two-col .video-preview img,
  .generate-video__wrapper .campaign-two-col .video-preview img {
    max-width: 100%; }

.success-video__wrapper .video-preview-disclaimer,
.generate-video__wrapper .video-preview-disclaimer {
  margin-bottom: 2rem; }
  .success-video__wrapper .video-preview-disclaimer h3,
  .generate-video__wrapper .video-preview-disclaimer h3 {
    font-size: 21px;
    letter-spacing: 0;
    line-height: 24px;
    text-transform: uppercase;
    text-align: left; }
  .success-video__wrapper .video-preview-disclaimer p,
  .generate-video__wrapper .video-preview-disclaimer p {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: left; }

.register__intro .headline, .register__intro .main-content form fieldset legend, .main-content form fieldset .register__intro legend,
.register__intro #lightbox form fieldset legend, #lightbox form fieldset .register__intro legend {
  margin: 1rem 0 3rem 0; }

@media (min-width: 980px) {
  .desktop--hide {
    display: none; } }

@media all and (max-width: 980px) {
  .service-register {
    background-color: #F1F1F1; }
    .service-register .content__panel {
      padding: 1rem !important; }
    .service-register__detail {
      padding: 1rem 1rem 0; } }

.service-register .js-tabbed-nav {
  background: #ffffff; }

.service-register .admin-grid {
  padding-top: 1rem; }

.service-register .previous-records {
  padding: 1rem; }
  .service-register .previous-records:before {
    content: '';
    display: block;
    position: absolute;
    top: -1rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 1rem;
    width: calc(100%);
    background: #E4E4E4; }
    body.admin-view .service-register .previous-records:before {
      background: #F1F1F1; }

.service-register .previous-records__intro {
  padding: 0 1rem; }

.service-register__label {
  font-size: 1.3125rem;
  color: #4A4A4A;
  text-transform: uppercase; }

.service-register__header {
  color: #8B63A1;
  font-family: "Cabin", Arial, sans-serif;
  font-size: 1.3125rem; }

.intro {
  padding: 0 1rem; }

.mobile-admin__tabbed-nav {
  position: relative; }
  .mobile-admin__tabbed-nav .mobile-admin__tabbed-nav-panel .mobile-admin__tabbed-nav-panel-posts.hide {
    display: none; }

.load-more {
  display: block;
  margin: 1rem auto;
  padding: 0.5rem;
  width: 10rem;
  text-align: center;
  font-size: 1.3rem; }
  .load-more.hide {
    display: none; }

.tabs__holder-single {
  width: 100%;
  margin-left: 0;
  padding: 1rem 0 0 0;
  background: #E4E4E4;
  margin-bottom: 1.5rem; }
  .tabs__holder-single .tabs {
    padding-left: 0;
    border-bottom: 0.2rem solid #E4E4E4; }
  .tabs__holder-single .single {
    width: 100%;
    padding: 2rem 1rem 0.2rem 1rem;
    color: #8B63A1;
    cursor: default; }
    @media (min-width: 980px) {
      .tabs__holder-single .single {
        padding: 1rem; } }

.form__fields {
  padding: 2rem 0.5rem 1rem 0.5rem;
  position: relative; }
  .form__fields:first-of-type:before {
    height: 0.4375rem; }
  .form__fields:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 0.625rem;
    width: calc(100% + 2rem);
    background: #E4E4E4; }
    @media (min-width: 980px) {
      .form__fields:before {
        width: calc(100% + 4rem); } }
  .form__fields .control-label,
  .form__fields label {
    text-transform: none !important;
    margin-top: 0.75rem;
    margin-bottom: 0.25rem; }
  .form__fields #div_id_service_types .control-label {
    margin-bottom: 0; }
  .form__fields .radio-select {
    margin: 0.5rem 0 1rem 0; }
  .form__fields .persons-formset label {
    text-transform: uppercase;
    margin-top: 0.75rem;
    margin-bottom: 0;
    position: relative; }
  .form__fields .persons-formset label:last-of-type {
    display: none; }
  .form__fields .persons-formset input:first-of-type {
    position: relative;
    width: calc(100% - 4rem) !important; }
  .form__fields .persons-formset .form-row {
    position: relative;
    margin-top: 2rem; }
    .form__fields .persons-formset .form-row:before {
      content: '';
      display: block;
      position: absolute;
      top: -1rem;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      height: 0.2rem;
      width: calc(100% + 4rem);
      background: #E4E4E4; }
    .form__fields .persons-formset .form-row:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1rem;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      height: 0.2rem;
      width: calc(100% + 4rem);
      background: #E4E4E4; }
  .form__fields .persons-formset .events-calendar__listing-delete {
    height: 3.6rem; }
    @media all and (min-width: 768px) {
      .form__fields .persons-formset .events-calendar__listing-delete {
        height: 3.35rem; } }
    .form__fields .persons-formset .events-calendar__listing-delete .icon {
      margin-right: 0;
      height: 1.2rem;
      width: 1.2rem; }
  .form__fields .persons-formset .events-calendar__listing {
    position: absolute;
    top: 0.7rem;
    right: 0; }
    @media all and (min-width: 768px) {
      .form__fields .persons-formset .events-calendar__listing {
        top: 0.5rem; } }
  .form__fields .persons-formset .person_form {
    position: relative; }
    .form__fields .persons-formset .person_form .js-delete {
      position: absolute;
      height: 3.6rem;
      right: 0; }
      @media all and (min-width: 768px) {
        .form__fields .persons-formset .person_form .js-delete {
          height: 3.35rem; } }
      .form__fields .persons-formset .person_form .js-delete .icon {
        margin-right: 0;
        height: 1.2rem;
        width: 1.2rem; }
  .form__fields small {
    line-height: 0.9375rem; }
  .form__fields .checkbox {
    margin-bottom: 1.25rem; }
  .form__fields input[type='checkbox']#id_preacher_officiant_same {
    margin-top: 0; }
  .form__fields.attendees label {
    font-size: 1rem;
    text-transform: none !important;
    margin-bottom: 0; }

.form__alert {
  padding: 1rem 0.5rem 0rem 0.5rem; }

.select2-search--dropdown {
  margin-top: -3.5rem !important;
  height: 3.5rem !important; }
  .select2-search--dropdown .select2-search__field {
    height: 3.5rem !important; }

.select2-container--default .select2-selection--single {
  border: 3px solid #E4E4E4 !important;
  border-radius: 0px !important;
  height: 3.5rem !important; }

.select2-container--default {
  width: 100% !important; }
  .select2-container--default .select2-search--dropdown {
    padding: 0; }
    .select2-container--default .select2-search--dropdown .select2-search__field {
      border: none !important; }

.select2-selection__arrow {
  display: none; }

.select2-selection__clear {
  position: absolute !important;
  top: 0;
  right: 3.25rem;
  border: 3px solid #E8A065;
  border-right: none;
  padding: 0.6rem 1rem;
  font-size: 2rem;
  background: #ffffff;
  height: 100%; }

.event__field {
  position: relative; }
  .event__field + p {
    font-size: 0.875rem;
    margin-top: 0.25rem; }

.icon.search-icon {
  position: absolute;
  border: 3px solid #E8A065;
  right: 0;
  bottom: 0.475rem;
  height: 3.65rem;
  width: 3.25rem;
  padding: 0.6rem;
  background: white; }
  @media all and (min-width: 768px) {
    .icon.search-icon {
      bottom: 0.37rem;
      height: 3.35rem; } }
  @media (min-width: 980px) {
    .icon.search-icon {
      bottom: 0.36rem; } }

.error + .icon.search-icon {
  bottom: 2.2rem; }
  @media all and (min-width: 768px) {
    .error + .icon.search-icon {
      bottom: 1.9rem; } }
  @media (min-width: 980px) {
    .error + .icon.search-icon {
      bottom: 2.9rem; } }

.main-content {
  /* Firefox */ }
  .main-content form#serviceregistercreateform button.add-person, .main-content form#serviceregisterupdateform button.add-person {
    display: block;
    margin: 2.25rem auto 0.25rem auto;
    padding: 0.5rem;
    width: 12rem;
    text-align: center;
    font-size: 1.3rem; }
  .main-content form#serviceregistercreateform .error input,
  .main-content form#serviceregistercreateform .error textarea,
  .main-content form#serviceregistercreateform .error select, .main-content form#serviceregisterupdateform .error input,
  .main-content form#serviceregisterupdateform .error textarea,
  .main-content form#serviceregisterupdateform .error select {
    border-color: #DE2F62;
    text-overflow: ellipsis; }
  .main-content form#serviceregistercreateform .error .mediumeditorwidget, .main-content form#serviceregisterupdateform .error .mediumeditorwidget {
    border-color: #DE2F62; }
  .main-content form#serviceregistercreateform .error .help-inline, .main-content form#serviceregistercreateform .error .help-block, .main-content form#serviceregisterupdateform .error .help-inline, .main-content form#serviceregisterupdateform .error .help-block {
    background: #DE2F62;
    color: #fff;
    padding: 0.5rem;
    margin-top: 0; }
  .main-content form#serviceregistercreateform textarea, .main-content form#serviceregisterupdateform textarea {
    height: 6rem; }
  .main-content .form__fields-attendees {
    background-color: #E4E4E4;
    display: flex;
    flex-direction: row; }
    .main-content .form__fields-attendees span {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      padding-left: 2rem;
      font-family: "Open Sans", Arial, sans-serif;
      font-weight: bold; }
    .main-content .form__fields-attendees .control-group {
      width: 100%; }
    .main-content .form__fields-attendees input[type=number] {
      display: block;
      margin: 0;
      border-right: none !important;
      border-left: none !important;
      font-size: 1.5rem !important;
      text-align: center;
      padding: 0.5rem 1rem !important;
      font-weight: 600; }
    .main-content .form__fields-attendees #div_id_attendees_total input[type=number] {
      background-color: #E4E4E4 !important;
      text-align: right;
      padding-right: 2rem !important; }
  .main-content button.plus {
    border: 3px solid #8FAB73;
    min-width: 3.75rem;
    background-color: #ffffff !important; }
    .main-content button.plus .icon {
      margin: 0; }
  .main-content button.minus {
    border: 3px solid #E4E4E4;
    min-width: 3.75rem;
    background-color: #ffffff !important; }
    .main-content button.minus .icon {
      margin: 0; }
    .main-content button.minus.active {
      border: 3px solid #DE2F62; }
  .main-content input::-webkit-outer-spin-button,
  .main-content input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .main-content input[type=number] {
    -moz-appearance: textfield !important; }

form[id=serviceregistercreateform] .form__header,
form[id=serviceregisterupdateform] .form__header {
  font-family: "Cabin", Arial, sans-serif;
  font-size: 1.438rem; }

#save-message:not(.modal-display) {
  display: none; }

.offline-data-list {
  list-style: none;
  padding-left: 0; }
  .offline-data-list li {
    display: grid;
    grid-template-columns: 1fr max-content max-content;
    padding: 0.5rem 0;
    border-bottom: 1px solid #E4E4E4; }
  .offline-data-list li:first-of-type {
    border-top: 1px solid #E4E4E4; }
  .offline-data-list .error {
    color: #DE2F62; }

.dob-formsets-holder-baptism-christening, .dob-formsets-holder-thanksgiving {
  margin-top: 1rem; }
  .dob-formsets-holder-baptism-christening p, .dob-formsets-holder-thanksgiving p {
    margin-top: 0; }
  .dob-formsets-holder-baptism-christening li, .dob-formsets-holder-thanksgiving li {
    list-style: none;
    display: flex;
    flex-direction: column; }
  .dob-formsets-holder-baptism-christening .errorlist, .dob-formsets-holder-thanksgiving .errorlist {
    order: 2; }
  .dob-formsets-holder-baptism-christening .errorlist li, .dob-formsets-holder-thanksgiving .errorlist li {
    font-weight: bold;
    text-transform: none; }

#div_id_confirm_duplicate_event {
  display: none; }
  #div_id_confirm_duplicate_event.error {
    display: block; }
    #div_id_confirm_duplicate_event.error .controls__checkbox {
      display: flex;
      flex-direction: column-reverse; }
      #div_id_confirm_duplicate_event.error .controls__checkbox .custom__checkbox-label {
        color: #DE2F62;
        font-weight: bold; }

.big-survey {
  margin: 4.625rem 0 9rem 0;
  background-color: #F1F1F1; }
  .big-survey .page-header {
    display: none; }
  .big-survey .page-footer {
    display: none; }
  .big-survey .container.page-content {
    padding-left: 0;
    padding-right: 0; }

.big-survey__header,
.big-survey .alert-block {
  margin: 0 1rem 2rem;
  padding: 2rem 0.75rem 1.25rem;
  background-color: #FFFFFF; }
  @media all and (min-width: 22rem) {
    .big-survey__header,
    .big-survey .alert-block {
      padding: 2rem 1.75rem 1.25rem; } }
  @media all and (min-width: 70rem) {
    .big-survey__header,
    .big-survey .alert-block {
      max-width: 61.5rem;
      margin-left: auto;
      margin-right: auto; } }
  .big-survey__header p,
  .big-survey .alert-block p {
    margin-top: 1rem !important; }

.big-survey__header--report {
  position: relative;
  text-align: center; }
  .big-survey__header--report:after {
    content: "";
    display: block;
    position: absolute;
    width: 13.375rem;
    left: 50%;
    bottom: 0;
    height: 5px;
    background: #E8A065;
    transform: translateX(-50%); }

.big-survey-section,
.big-survey-section-group-intro,
.big-survey fieldset {
  margin: 0 1rem 2rem;
  padding: 2rem 1.5rem;
  border: none;
  background-color: #FFFFFF; }
  @media all and (min-width: 70rem) {
    .big-survey-section,
    .big-survey-section-group-intro,
    .big-survey fieldset {
      max-width: 61.5rem;
      margin-left: auto;
      margin-right: auto; } }

.big-survey-section-group-intro {
  margin-bottom: 0;
  font-size: 1.4375rem;
  line-height: 1.2; }

.big-survey-section-group-intro--short {
  padding-bottom: 0; }

.big-survey-section__content,
.big-survey-section-group-intro__content,
.big-survey .control-group,
.big-survey .controls,
.big-survey p,
.big-survey fieldset > legend,
.big-survey__submit-button-wrapper {
  max-width: 58.5rem;
  margin: auto; }

.big-survey__submit-button-wrapper {
  display: flex; }

.big-survey__submit-button {
  margin-left: auto; }

.big-survey__no-chart {
  text-align: center;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif; }
  .big-survey__no-chart h5 {
    font-size: 18px; }

/*
 * The styles for Big Church Survey forms are encapsulated under the parent
 * class on purpose as they are slightly different from the styles in the rest
 * of the project.
 */
.big-survey .alert-block {
  padding-top: 1.25rem;
  padding-bottom: 0.125rem;
  background-color: #DE2F62; }

.big-survey .error {
  color: #DE2F62; }

.big-survey .alert-error ul {
  font-size: 1rem;
  font-weight: bold;
  text-transform: none; }

.big-survey .help-block,
.big-survey .help-inline {
  display: block;
  width: 100%;
  margin-top: 0.375rem;
  margin-bottom: 1.25rem; }

.big-survey input:not([type='checkbox']):not([type='radio']):not([type='submit']),
.big-survey textarea {
  appearance: none;
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 0;
  background: #fff;
  vertical-align: top;
  font-size: 16px;
  font-family: inherit;
  -webkit-font-smoothing: antialiased;
  border: 3px solid #E4E4E4;
  color: inherit;
  line-height: inherit;
  resize: vertical; }
  .big-survey input:not([type='checkbox']):not([type='radio']):not([type='submit']):focus,
  .big-survey textarea:focus {
    outline: none;
    border-color: #bebebe; }

.big-survey input[type='number'] {
  max-width: 10rem; }

.big-survey .radio-select .help-block,
.big-survey .radio-select .help-inline {
  margin-top: 0; }

.big-survey .controls__checkbox {
  position: relative; }
  .big-survey .controls__checkbox input {
    position: absolute;
    left: 0;
    opacity: 0; }
  .big-survey .controls__checkbox label {
    margin-bottom: 0.5rem;
    padding-top: 0; }

.big-survey .radio-select {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .big-survey .radio-select label {
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 0.3125rem 1rem;
    border: 3px solid #E4E4E4; }
  .big-survey .radio-select input {
    margin-right: 0.5rem; }
  .big-survey .radio-select .radio-select__group-label {
    flex-basis: 100%;
    margin-top: 1rem;
    margin-bottom: 0.5rem; }

.big-survey .control-label {
  display: block;
  margin-bottom: 1.25rem;
  font-size: 1.25rem; }

.big-survey .note {
  margin-top: 2rem;
  font-size: 1rem; }

.big-survey .control-group + .control-label {
  margin-top: 1rem; }

.big-survey .label-other .control-label {
  margin-bottom: 0.75rem;
  font-size: 1rem; }

.big-survey .label-subquestion .control-label {
  margin: 1rem 0;
  font-size: 1rem; }

.big-survey .radio-column .radio-select {
  flex-direction: column; }
  .big-survey .radio-column .radio-select .radio {
    width: fit-content; }

table.question-list {
  font-size: 1rem;
  margin: 1.5rem 1rem;
  max-width: 100%; }
  table.question-list th a {
    text-decoration: none; }
  table.question-list tr th:first-child {
    width: 70%; }
  table.question-list tr th:not(:first-child) {
    text-align: center; }
  table.question-list .column__question {
    display: flex;
    padding: 0.5rem 0.5rem 0.5rem 0; }
    @media all and (min-width: 768px) {
      table.question-list .column__question {
        font-size: 1.2rem; } }
  table.question-list .column__enabled,
  table.question-list .column__edit {
    text-align: center; }
    table.question-list .column__enabled .icon,
    table.question-list .column__edit .icon {
      width: 2rem;
      height: 2rem;
      margin: 0 auto; }
  @media all and (max-width: 767px) {
    table.question-list {
      font-size: 0.8rem !important; }
      table.question-list th {
        white-space: normal;
        font-size: 0.75rem; }
      table.question-list td {
        width: 30%; }
        table.question-list td:first-of-type {
          width: 100%; }
        table.question-list td:last-of-type {
          width: 20%; } }
  @media (min-width: 980px) {
    table.question-list {
      margin: 1.5rem 0; } }

.edit-question-form form {
  padding-top: 0; }
  @media (min-width: 980px) {
    .edit-question-form form {
      padding-left: 0; } }

.edit-question-form input[type='checkbox'] {
  margin-top: 0 !important;
  position: absolute; }

.edit-question-form .controls__checkbox .checkbox {
  margin-left: 0; }

#beneficesurveyform input[type='checkbox'] {
  margin-top: 0; }

.big-survey-chart {
  width: 100%;
  height: 25rem;
  padding: 0 0 2rem; }

.big-survey-chart--height--tall {
  height: 37.5rem; }

.big-survey-chart--height--very-tall {
  height: 52.5rem; }

.big-survey-table {
  width: 100%;
  /* Adapted from Highcharts styles */
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif !important;
  margin: 0.5rem 0;
  padding: 0 0 2rem;
  text-align: center; }
  .big-survey-table > h1, .big-survey-table > .header {
    color: #333333;
    font-family: inherit;
    font-size: 18px; }
  .big-survey-table small {
    color: #666666;
    font-size: 12px; }
  .big-survey-table table {
    text-align: left;
    width: 100%;
    margin: 0 auto;
    border-spacing: 1rem; }
    .big-survey-table table .big-survey-table__headers th:nth-child(even) {
      border-bottom: 1px solid #382e75; }
    .big-survey-table table .big-survey-table__headers th:nth-child(odd) {
      border-bottom: 1px solid #A5A5A5; }
    .big-survey-table table .big-survey-table__headers th:nth-child(1) {
      width: 70%;
      border-bottom: none; }
    .big-survey-table table .big-survey-table__headers.no-labels th {
      border-bottom: 1px solid #382e75; }
      .big-survey-table table .big-survey-table__headers.no-labels th:nth-child(1) {
        width: 50%; }
    .big-survey-table table .big-survey-table__data:nth-child(odd) td:nth-child(2) {
      outline: 0.5rem solid #D7C7DF;
      background-color: #D7C7DF; }
    .big-survey-table table .big-survey-table__data:nth-child(odd) td:nth-child(3) {
      outline: 0.5rem solid #E4E4E4;
      background-color: #E4E4E4; }
  @media (min-width: 980px) {
    .big-survey-table table, .big-survey-table td, .big-survey-table .big-survey-table__headers th {
      padding: 0 1rem; } }

#benefice_map {
  height: 461px; }

.benefice__logo {
  background: #ffffff;
  padding: 2rem; }
  .benefice__logo img {
    max-height: 12rem;
    width: auto;
    margin: 0 auto;
    display: block; }
  @media all and (min-width: 541px) {
    .benefice__logo {
      display: none; } }

.benefice__event--hide-mobile {
  display: none; }
  @media all and (min-width: 768px) {
    .benefice__event--hide-mobile {
      display: block; } }

@media all and (min-width: 768px) {
  .benefice__event--show-mobile {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px); } }

.hidden {
  display: none !important; }

#search-button {
  display: none; }

@media (max-width: 540px) {
  #search_list_holder #acny_search,
  #search_list_holder .search__form-button {
    display: none; }
  #search-button {
    display: inline-block; } }
