.navbar,
.navlist {
    a {
        text-decoration: none;
    }
}

//// NAVBAR (e.g. primary nav)

.navbar__holder,
.navbar {
    display: flex;
    flex-flow: row wrap;

    @media all and (min-width: 980px) {
        align-items: center;

        .acny-as-homepage__logo-holder {
            text-decoration: none;
            display: flex;
            align-items: center;
            font-size: 1.3rem;
            margin-left: 1rem;

            .acny-as-homepage__venue-name {
                margin-left: 1rem;
            }
        }
    }
}

.navbar {
    list-style: none;
    padding: 0;
    margin: 0;

    @media all and (min-width: 980px) {
        li {
            border-left: 1px solid $color-grey;
            position: relative;

            a {
                display: inline-block;
                padding: $width-gap/2 $width-gap * 0.75;
            }

            &.active a,
            a:active,
            a:hover {
                color: $color-active;
            }

            &:last-of-type {
                border-right: 1px solid $color-grey;
            }

            .navlist__dropdown {
                display: none;
                position: absolute;
                top: 3.5rem;
                background: $color-line;
                padding: 1rem;
                z-index: 30; // needs to be above gallery eye icon
                width: 18rem;
                list-style: none;
                box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);

                .dropdown__item {
                    display: flex;
                    justify-content: space-between;
                    margin: 0;
                    padding: 0.5rem 0;

                    .icon--live {
                        margin: 0 0 0 1rem;

                        &:hover, &:active {
                            fill: $color-primary;
                        }
                    }
                }
            }

            .navlist__dropdown--churches {
                top: 4.5rem;
                left: 2rem;
                width: 26rem;
            }

            &:hover {
                .navlist__dropdown {
                    display: block;
                }
            }
        }
    }

    @media all and (max-width: 979px) {
        flex-flow: column nowrap;

        li + li {
            border-top: 1px solid $color-line;
        }

        .page-header & {
            li a {
                display: block;
                padding: $width-gap/2 0;
            }
        }
    }
}

.navbar__right {
    @media all and (min-width: 980px) {
        width: 100%;
        width: auto;
        margin-left: auto;
        margin-right: 2.5rem;
    }
}

///// NAVLIST (e.g. sidebar)

.navlist,
.gallery-preview__container {
    .navlist__item {
        display: flex;
        position: relative;
        flex-flow: row nowrap;
        padding: $width-gap * 0.75 $width-gap/2 $width-gap * 0.75 0;
        border-top: 3px solid $color-grey;
        align-items: flex-start;

        > .icon {
            flex-shrink: 0;
            margin-right: $width-gap * 0.75;
            color: $color-grey-light;
        }

        &:last-of-type {
            border-bottom: 3px solid $color-grey;
        }

        &.active,
        &.active .icon {
            color: $color-primary;
        }

        a:hover,
        a:active {
            color: $color-primary;
        }

        &.active {
            .navlist__item-wrapper .icon {
                color: currentColor;
            }
        }

        &.navlist__item--no-border {
            border-top: 0;
            border-bottom: 0;
        }

        .navlist__item-wrapper {
            display: inline-flex;
            flex-shrink: 1;
            align-items: flex-start;

            > .icon {
                flex-shrink: 0;
                margin-right: $width-gap * 1.125;
                color: $color-grey-light;
            }
        }

        &.indented__nav {
            margin-left: $width-gap * 2.25;
        }

        a.navlist__item-wrapper:hover .icon {
            color: inherit;
        }

        .navlist__item-content {
            width: 100%;
        }

        .nav__notifications {
            position: absolute;
            right: 0.75rem;
            top: 0.825rem;
        }

        .navlist__item-content span {
            display: block;
            font-family: $base-font;
            font-size: 0.875rem;
            opacity: 0.875;
            line-height: 1.5;
        }

        .navlist__item-title {
            display: flex;
            width: 100%;
            justify-content: space-between;
            font-size: 1.286rem;

            a.active {
                color: $color-primary;
            }
        }

        &.nesting-nav__item {
            label {
                cursor: pointer;

                .icon {
                    width: 1rem;
                    height: 1rem;
                }

                &:hover {
                    color: $color-primary;
                }
            }

            .navlist__item-content,
            .navlist__item-title {
                width: 100%;
            }
        }

        .resourcehub_child {
            margin-left: 1rem;

            &:first-of-type {
                margin-top: $width-gap * 0.75;
            }

            &:last-child {
                border-bottom: 0;
            }
        }

        .edit__icon {
            opacity: 0.25;

            &:hover {
                opacity: 1;
            }
        }

        .navlist__item-content small {
            display: block;
            font-family: $base-font;
            line-height: 1.5;
            margin-top: 0.125rem;
        }

        &.school {
            .navlist__item-content span {
                color: $color-tertiary;
            }
        }

        &.with__preview {
            padding-top: $width-gap * 1.5;

            .navlist__item-content span {
                color: $color-primary;
                font-family: inherit;
                text-transform: uppercase;
                margin-top: 0.125rem;
            }
        }

        &.in__parish {
            .navlist__item-content span {
                color: $color-action;
            }
        }

        &.share {
            position: relative;

            @include desktop {
                border-bottom: none;
            }

            li a {
                padding: 0 !important;
            }

            .icon {
                margin-right: 1.25rem;
            }

            span.navlist__item-content {
                width: auto;
            }

            ul {
                margin: $width-gap * -0.75 $width-gap * -0.5 $width-gap * -0.75 auto;
                right: 0;
                top: 0;
                bottom: 0;
                list-style: none;
                padding: 0;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;

                .icon {
                    margin: auto;
                    align-self: center;
                    opacity: 0.5;
                }

                li {
                    border-left: 3px solid $color-grey;
                    height: 100%;
                    min-height: 100%;
                    display: flex;
                    align-items: center;
                    padding: $width-gap * 0.75 $width-gap * 0.75 $width-gap * 0.5;
                }

                a:hover .icon {
                    color: $color-primary;
                    opacity: 1;
                }
            }
        }
        &.split-item{
            a:nth-of-type(2){
                border-left: 3px solid $color-grey;
                margin-left: 1rem;
                padding-left: 1rem;
            }

            .navlist__item-wrapper {
                flex-shrink: 0;
            }
        }
    }
    .nested__list {
        color: $color-text;

        &.active,
        &.active .icon {
            color: $color-primary;
        }

        a:hover,
        a:active {
            color: $color-primary;
        }

        &.active {
            .navlist__item-wrapper .icon {
                color: currentColor;
            }
        }
    }
    &.admin-nav {
        .navlist__item {
            background: $color-tertiary;
            color: $color-white;
            padding: 0.75rem 1.625rem;
            border-bottom: 10px solid $color-background-lighter-grey !important;
            border-top: 0;

            &.active {
                background: $color-primary;
                &:after {
                    border-left: 0.5rem solid $color-primary;
                }
                &:hover{
                    background: darken($color-primary, 10%);
                }
            }
            &:hover,
            &:focus {
                background: darken($color-tertiary, 10%);
                a{
                    color: $color-white;
                }
            }
            .icon {
                fill: $color-white !important;
            }
            &:last-child {
                border-bottom: none !important;
            }
            &.admin-nav__header {
                border-bottom: none !important;

                .icon {
                    margin-right: 1.25rem;
                }

                &:hover {
                    background: $color-tertiary;
                }
            }
            &.active--benefice {
                padding: 0.75rem 1.625rem !important;
                pointer-events: none;
                &:after {
                    content: none;
                }
            }
        }
        .navlist__item--live {
            background: $color-turquoise;

            &:hover,
            &:focus {
                background: darken($color-turquoise, 10%);
            }
        }
        .navlist__subnav {
            padding: 1rem 0;
            background: $color-white;
            .navlist__item {
                background: $color-white;
                color: $color-text;
                border-bottom: none !important;
                padding: 0.75rem 0.75rem 0.75rem 1.625rem;
                .icon {
                    fill: $color-grey-light !important;
                }
                .navlist__item-title {
                    font-size:1.25rem;
                }
                &:last-of-type{
                    border-bottom: none !important;
                }
                &:hover{
                    a {
                        color: $color-primary;
                    }
                }
                &.active {
                    color: $color-primary;
                    background: $color-white;
                }
            }
        }
        .navlist__subnav + .navlist__item--benefice {
            border: 10px solid $color-background-lighter-grey;
            border-width: 10px 0;
        }
    }
}

.navlist {
    display: flex;
    flex-flow: column nowrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: $base-headline-font;

    .side-content & {
        font-size: 1.25rem;

        li.active {
            position: relative;

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 100%;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: $width-gap/2 0 $width-gap/2 $width-gap/2;
                border-color: transparent transparent transparent
                    $color-background-light;
                transform: translateY(-50%);
            }
        }
    }

    .acny-as-homepage__wrapper & {
        padding: 1rem $acny-as-homepage-navlist-padding-sides;

        .icon {
            width: 1.2rem;
            height: 1.2rem;
            margin-top: 0.125rem;
        }

        .navlist__item {
            border: none;

            .navlist__item-content ul li {
                border: none;
            }
        }

        .navlist__item-title {
            font-size: 1.125rem;
        }
    }
}

.navlist--secondary {
    border-top: 10px solid $color-grey;
    padding: 1rem 0;

    .navlist__item:first-child {
        border-top: 0;
    }

    .navlist__item:last-child {
        border-bottom: 0;
    }
}

.side__navigation {
    .icon {
        width: 1.5rem;
        height: 1.5rem;
    }

    @media all and (min-width: 980px) {
        .navlist > .active {
            position: relative;

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 1.5rem;
                right: -$width-gap/2;
                transform: translateY(-50%);
                border-top: $width-gap/2 solid transparent;
                border-bottom: $width-gap/2 solid transparent;
                border-left: $width-gap/2 solid #fff;
            }
        }
    }

    @media all and (max-width: 980px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: $color-background-light;
        z-index: 100;
        box-shadow: 0px $width-gap/6 $width-gap * 1.5 -$width-gap/2 rgba(0, 0, 0, 0.175);

        .navlist > .active {
            position: relative;

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: -$width-gap/2;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-left: $width-gap/2 solid transparent;
                border-right: $width-gap/2 solid transparent;
                border-bottom: $width-gap/2 solid black;
            }
        }

        .navlist {
            flex-flow: row nowrap;
            width: 100%;

            .navlist__item {
                border-top: none;
                padding: $width-gap * 0.75;
                position: relative;
                text-align: center;
                width: 100%;
                justify-content: center;

                &.share {
                    display: none;
                }

                &.active:after {
                    left: 50%;
                    top: auto;
                    bottom: 100%;
                    transform: translateX(-50%);
                    border-width: 0 $width-gap/2 $width-gap/2 $width-gap/2;
                    border-color: transparent transparent
                        $color-background-light transparent;
                }

                &:hover,
                &:active {
                    color: $color-primary;

                    .icon {
                        color: inherit;
                    }
                    .nested__list {
                        color: $color-text;
                    }
                }

                .icon {
                    margin: $width-gap/5 auto;
                }

                &:last-of-type {
                    border-bottom: none;
                }

                a {
                    span {
                        font-size: 0;
                    }
                }
            }

            .navlist__item:not(.mobile__toggle) {
                display: none;
            }

            .icon {
                margin-right: 0;
            }
        }

        .hidden__mobile {
            display: none !important;
        }
    }
}

///// MOBILE NAV

.mobile__navigation {
    display: none;
    width: 100%;
    background-color: $color-white;

    .hidden__mobile {
        display: none !important;
    }

    .navlist .navlist__item {
        padding: 0;

        a,
        &.share {
            padding: $width-gap * 0.75;
            width: 100%;
        }

        &.admin-nav__header {
            padding: $width-gap * 0.75;
        }

        &.indented__nav {
            margin-left: $width-gap * 3;
        }

        .navlist__item-content span {
            display: none;
        }
    }

    .navlist__subnav .navlist__item {
        padding: 0 !important;

        a {
            padding: 0.75rem 0.75rem 0.75rem 1.625rem;
        }
    }

    .icon {
        width: 1.5rem;
        height: 1.5rem;
    }

    .location-image__preview {
        display: none;
    }
}

.mobile-navbar {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;

    .page__logo {
        display: inline-block;
        margin-left: 1rem;
    }

    .mobile__toggle {
        height: 100%;

        label {
            padding: 0 1rem;
            height: 100%;
            justify-content: left;
            position: static;
            display: flex;
            width: 100%;
            align-items: center;
        }
    }

    .mobile__toggle-title {
        @include visually-hidden;
    }

    .mobile__toggle-item {
        display: flex;
        align-items: center;
        flex-flow: row nowrap;

        border: none !important;
    }

    .mobile-navbar__back-button, .mobile-navbar__title {
        display: none;
    }

    @media all and (min-width: 980px) {
        display: none;
    }

    .acny-as-homepage__logo-mobile {
        width: 2.5rem;
        height: 3rem;
        margin: 0.8rem 1rem 0.8rem 1.5rem;
    }

    .acny-as-homepage__venue-name {
        font-size: 1.3rem;
    }
}

.close {
    display: none;
}

@media all and (max-width: 979px) {
    #mobile_toggle:checked {
        ~ .page-header {
            .mobile__navigation {
                display: block;
            }

            .mobile__toggle-item {
                display: none;
            }

            .mobile__toggle .close {
                display: inline-block;
            }
        }

        ~ .page__wrapper,
        ~ .page-header .admin__header,
        ~ .billboard__panel.acny-as-homepage {
            display: none;
        }
    }
}

///// PEBBLETRAIL

.pebble-trail {
    display: none;
    position: relative;
    padding-top: 1rem;
    padding-left: 1rem;
    color: $color-white;

    a {
        text-decoration: none;
    }
    &__analytics-page {
        color: $color-primary-dark;
    }

    @media all and (min-width: 481px) {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        width: 100%;
        max-width: $width-container;
        margin-bottom: 1.125rem;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.2;

        .icon {
            color: $color-primary;
            width: 0.75rem;
            margin: 0 0.5rem;
        }
    }

    &.pebble-trail-include {
        @media all and (min-width: 481px) {
            justify-content: space-between;

            span,
            a {
                display: inline-block;
            }
        }
    }

    .main-content & {
        @media all and (max-width: 980px) {
            display: none;
        }
    }

    &.action-pebble .icon {
        color: $color-action;
    }
    body.admin-view & {
        color: $color-text;
        padding: 0 2rem 0 0;
        @media all and (max-width: 980px) {
            display: none;
        }
    }
    .acny-as-homepage__wrapper &{
        color: $color-text;
    }
    .pebble-trail__button-container{
        display:flex;
    }
}

///// TABS

.tabs__holder {
    padding: $width-gap $width-gap 0 0;
    background: $color-grey;
    margin-bottom: $width-gap * 1.5;

    .calendar__panel & {
        width: calc(100% + #{$width-gap * 4});
        margin-left: -$width-gap * 2;
    }

    .headline__meta {
        margin: 0 1rem 0 auto;
    }

    .tabs {
        position: relative;
    }

    .form__tabs-holder & {
        width: calc(100% + #{$width-gap * 2});
    }

    @include desktop {
        width: calc(100% + #{$width-gap * 2});
        margin-left: -$width-gap;

        .form__tabs-holder & {
            width: calc(100% + #{$width-gap * 4});
            margin-left: -$width-gap * 2;
        }
    }
}

.tabs__message {
    max-width: 40em;
    margin-left: 2rem;

    @include desktop {
        margin-left: 1rem;
    }
}

.tab__meta {
    font-family: $base-headline-font;
    margin-left: auto;
    padding: $width-gap $width-gap 0 0;
    opacity: 0.5;

    @include desktop {
        font-size: 1.5rem;
    }
}

.tabs {
    padding-left: 1rem;
    display: flex;
    flex-flow: row nowrap;

    @include desktop {
        display: flex;
        flex-flow: row nowrap;
    }
}

.tab {
    padding: $width-gap $width-gap 0 $width-gap;
    font-family: $base-headline-font;
    font-size: 1.5rem;
    user-select: none;
    cursor: pointer;
    text-decoration: none;

    > span {
        display: inline-flex;
        align-items: center;
        padding-bottom: $width-gap/2;
        border-bottom: 3px solid transparent;
    }

    &.active {
        background: $color-background-light;

        > span {
            border-color: $color-primary;
        }
    }

    &.christmas {
        @media all and (max-width: 440px) {
            display: none;
        }
    }
}

.nav__notifications {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background: $color-error;
    font-size: 1rem;
    text-align: center;
    line-height: 1.2rem;
    color: $color-background-light;
}
