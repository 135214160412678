.calendar {
    padding: $width-gap;

    .prev__month,
    .next__month {
        > * {
            opacity: 0.2;
        }
    }

    .event {
        width: 100%;
        padding: $width-gap/4 $width-gap/2;
        background: $color-primary;
        color: $color-background-light;
        display: block;
        text-decoration: none;
        line-height: 1.5;

        + .event { margin-top: 2px; }

        &.school { background: $color-tertiary }
        &.communion { background: $color-tertiary-light; }
    }

    @media all and (min-width: 768px) {
        padding: 0;

        .event { font-size: 0.75rem; }
    }
}

.calendar__days {
    display: flex;
    flex-flow: column nowrap;

    @media all and (min-width: 768px) {
        flex-flow: row wrap;

        > div {
            display: flex;
            flex-flow: column nowrap;
            width: calc(100%/7.001);
            border-bottom: 3px solid $color-line;
            border-right: 3px solid $color-line;
            min-height: 5rem;

            &:nth-of-type(7n) {
                border-right: none;
            }
        }

        .day__label { padding: $width-gap/4 $width-gap/2; }

    }

    @media all and (max-width: 767px) {
        > div + div {
            margin-top: $width-gap*1.5;
            padding-top: $width-gap*1.5;
            border-top: 3px solid $color-line;
        }

        .no__events {
            display: none;
        }

        .day__label {
            display: block;
            margin-bottom: $width-gap/2;
        }
    }
}

.calendar__panel-intro {
    padding: 0 $width-gap*2;
}

.month__label {
    @media all and (min-width: 768px) {
        display: none;
    }
}

.calendar__labels {
    display: none;
    flex-flow: row wrap;
    font-size: 0.875rem;
    font-weight: 600;
    border-bottom: 3px solid $color-line;

    > div {
        // 100%/7 was resulting in a bug on edge, as it wasn't rounding down correctly
        // This should fix it until the browser is updated!
        width: calc(100%/7.001);
        padding: $width-gap/2;
    }

    @media all and (min-width: 768px) {
        display: flex;
    }
}

.calendar__header-option-area {
    position: relative;
    width: 100%;
    margin: #{$width-gap * -1} 0 $width-gap;
}

// This element is used to balance out the flex layout on larger screens only
.calendar__header-option-flex-first {
    @media all and (max-width: 1199px) {
        display: none;
    }
}

.calendar__controls {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: $width-gap;

    @media (max-width: 767px) {
        padding-left: 0;
    }

    a .icon {
        padding: $width-gap/4;
        width: $width-gap*2;
        height: $width-gap*2;
        border: 3px solid $color-action;
        vertical-align: top;
    }

    .calendar__month {
        margin: 0 $width-gap;
        font-size: 1.5em;
        font-family: $base-headline-font;

        @media (max-width: 399px) {
          font-size: 1.3em;
          margin: 0 $width-gap/2;
        }
    }
}
